import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { ClientNewsSource } from '@DigitaleDoerfer/digitale-doerfer-api/models';
import { TextField } from '@mui/material';

interface Props {
	pageOfClientNewsSource: ClientNewsSource[];
	handleSearch: (searchString: string) => void;
}

const SearchView = (props: Props): JSX.Element => {
	const { pageOfClientNewsSource, handleSearch } = props;

	const makefullListofitemsfromContent = (clientNewsSource: ClientNewsSource[]): Array<string | undefined> => {
		const listOfNewsSourceSiteName = clientNewsSource.map(option => option.siteName);
		const listOfNewsSourceSiteUrl = clientNewsSource.map(option => option.siteUrl);
		const listOfNewsSourceSiteNameandURL = [];
		for (let i = 0; i < listOfNewsSourceSiteName.length; i++) {
			listOfNewsSourceSiteNameandURL[i] = listOfNewsSourceSiteName[i] + ' | ' + listOfNewsSourceSiteUrl[i];
		}
		return listOfNewsSourceSiteNameandURL;
	};

	const handleChange = (event: React.ChangeEvent<unknown>, value: string): void => {
		if (event.type === 'click') {
			//workaround for @typescript-eslint/no-unused-vars
		}
		const valueURL = value.split('|')[1].trimLeft();
		pageOfClientNewsSource.forEach(options => {
			if (options.siteUrl) {
				if (valueURL === options.siteUrl) {
					handleSearch(options.id as string);
				}
			}
		});
	};
	return (
		<Autocomplete
			freeSolo
			disableClearable
			options={makefullListofitemsfromContent(pageOfClientNewsSource)}
			onChange={handleChange}
			renderInput={(params): JSX.Element => (
				<TextField
					variant="standard"
					{...params}
					label="NewsSource suchen"
					placeholder="Seitename, URL"
					InputProps={{ ...params.InputProps, type: 'search' }}
					id="standard-search"
					type="search"
					InputLabelProps={{
						shrink: true
					}}
					fullWidth
					autoFocus
				/>
			)}
		/>
	);
};

export default SearchView;
