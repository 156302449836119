import React, { Component } from 'react';
import { ActionMenuItemProps } from './ActionMenu';

interface Props extends ActionMenuItemProps {
	icon: JSX.Element;
	text: string;
	action: () => void;
}

class ActionMenuItemView extends Component<Props> {
	render(): JSX.Element {
		const { renderMenuItem, icon, text, action } = this.props;
		return <>{renderMenuItem(icon, text, action)}</>;
	}
}

export default ActionMenuItemView;
