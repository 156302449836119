import React from 'react';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';

type WithText = {
	text: string;
};

const ErrorView: React.FunctionComponent<WithText> = (props: React.PropsWithChildren<WithText>): JSX.Element => {
	const { text } = props;
	return (
		<Grid item container justifyContent="center" alignItems="center">
			<Typography variant="h5">{text}</Typography>
		</Grid>
	);
};

export const NotFoundErrorView: React.FunctionComponent<unknown> = (): JSX.Element => (
	<ErrorView text="Diese Seite existiert nicht." />
);

export const PERMISSION_ERROR_URL = '/permission-error';

export const PermissionErrorView: React.FunctionComponent<unknown> = (): JSX.Element => (
	<ErrorView text="Für diese Aktion hast du keine ausreichende Berechtigung" />
);
