import React from 'react';
import { css } from '@emotion/core';
import BarLoader from 'react-spinners/BarLoader';
import { Theme, useTheme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
	emptyDiv: {
		height: '4px'
	}
}));

export interface Props {
	color?: string;
	loading?: boolean;
}

function BarLoadingIndicator(props: Props): JSX.Element {
	const theme: Theme = useTheme();
	const classes = useStyles();
	const { loading } = props;
	let { color } = props;
	const override = css`
		width: 100%;
	`;
	color = color ? color : theme.palette.primary.main; // default color
	return loading ? <BarLoader css={override} color={color} loading={loading} /> : <div className={classes.emptyDiv} />;
}
export default BarLoadingIndicator;
