import React, { Component } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Grid, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import { ActionMenuItemProps } from './ActionMenu';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-unused-vars
const styles = (theme: Theme) => {
	return createStyles({
		actionMenuButton: {
			padding: '2px'
		},
		actionMenuPaper: {
			padding: 0
		}
	});
};

interface Props extends WithStyles<typeof styles> {
	renderItems: (props: ActionMenuItemProps) => JSX.Element[];
}

interface State {
	menuAnchor: HTMLElement | null;
}

class ActionsMenuView extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = { menuAnchor: null };
		this.renderItem = this.renderItem.bind(this);
	}

	renderItem(icon: JSX.Element, label: string, action: () => void): JSX.Element {
		return (
			<MenuItem
				key={label}
				onClick={(): void => {
					this.setState({ menuAnchor: null });
					action();
				}}
			>
				{icon}
				<Box mr={1} />
				<Typography>{label}</Typography>
			</MenuItem>
		);
	}

	render(): JSX.Element {
		const { renderItems, classes } = this.props;
		const { menuAnchor } = this.state;
		return (
			<Grid item xs={12}>
				<IconButton
					aria-label="more"
					aria-controls="long-menu"
					aria-haspopup="true"
					onClick={(event): void => {
						this.setState({ menuAnchor: event.currentTarget });
					}}
					className={classes.actionMenuButton}
					size="large"
				>
					<MoreVertIcon />
				</IconButton>
				<Menu
					id="long-menu"
					anchorEl={menuAnchor}
					anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
					transformOrigin={{ horizontal: 'right', vertical: 0 }}
					keepMounted
					disableScrollLock
					open={!!menuAnchor}
					onClose={(): void => {
						this.setState({ menuAnchor: null });
					}}
					classes={{
						paper: clsx(classes.actionMenuPaper)
					}}
				>
					{/*
						From the docs, only MenuItems should be provided here, but then
							"Warning: Function components cannot be given refs. Attempts to access this ref will fail.
							Did you mean to use React.forwardRef()?"
						is shown. Using a span fixes this warning.
						See https://stackoverflow.com/a/56309771 and https://github.com/mui-org/material-ui/issues/15903
					 */}
					<span>{renderItems({ renderMenuItem: this.renderItem })}</span>
				</Menu>
			</Grid>
		);
	}
}

export default withStyles(styles)(ActionsMenuView);
