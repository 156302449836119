import React from 'react';
import { GetAllExternalPostsByNewsSourceUsingGETPostTypesEnum as PostTypeEnum } from '@DigitaleDoerfer/digitale-doerfer-api/apis';
import { FormControl, Input, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';

interface ExternalPostType {
	name: string;
	postType?: PostTypeEnum;
}
interface Props {
	selectedPostTypes: string | undefined;
	externalPostsTypes: ExternalPostType[];
	handleChangePostType: (newPostType: string) => void;
}

const FiltersView = (props: Props): JSX.Element => {
	const { externalPostsTypes, selectedPostTypes, handleChangePostType } = props;
	const handleChange = (event: SelectChangeEvent<string>): void => {
		handleChangePostType(event.target.value);
	};

	return (
		<div>
			<div>
				<div>
					<FormControl variant="standard" fullWidth>
						<InputLabel>Art der Beiträge</InputLabel>
						<Select variant="standard" value={selectedPostTypes} input={<Input />} onChange={handleChange}>
							{externalPostsTypes.map(ep => {
								if (ep.postType === undefined) {
									return (
										<MenuItem value={'Alles'} key={'Alles'}>
											{'Alles'}
										</MenuItem>
									);
								}
								return (
									<MenuItem value={ep.postType} key={ep.name}>
										{ep.name}
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>
				</div>
			</div>
		</div>
	);
};

export default FiltersView;
