import { HideSnackbar, ShowSnackbar, SNACKBAR_HIDE, SNACKBAR_SHOW } from './SnackbarNotificationActionTypes';
import { Dispatch } from 'react';
import { wrapIntoErrorObject } from '../../../errors/ErrorWrapper';

export interface SnackbarAction {
	type?: SnackbarType;
	message: string;
	url?: string;
}

export enum SnackbarType {
	SNACKBAR_SUCCESS = 'success',
	SNACKBAR_ERROR = 'error',
	SNACKBAR_INFO = 'info',
	SNACKBAR_WARNING = 'warning'
}

export const showSnackbar = (snackbar: SnackbarAction) => {
	return async (dispatch: Dispatch<ShowSnackbar>): Promise<void> => {
		dispatch({ type: SNACKBAR_SHOW, payload: snackbar });
	};
};

// return type hard to specify and anyway not needed
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const showSnackbarError = (error: unknown) => {
	return showSnackbar({
		type: SnackbarType.SNACKBAR_ERROR,
		message: wrapIntoErrorObject(error).displayMessage
	});
};

export const hideSnackbar = () => {
	return async (dispatch: Dispatch<HideSnackbar>): Promise<void> => {
		dispatch({ type: SNACKBAR_HIDE });
	};
};
