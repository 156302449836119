import React from 'react';
import FormFooterView from '../../../../shared/views/PersonDetail/FormFooter.view';
import NavButton from '../../../../shared/views/Buttons/NavButton.view';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FLAGGED_CONTENTS_URL } from '../../FlaggedContentRouting.container';

const FlaggedContentDetailsFooterView = withRouter((props: RouteComponentProps): JSX.Element => {
	const goToTableHandler = (): void => {
		props.history.push(FLAGGED_CONTENTS_URL);
	};

	return (
		<FormFooterView>
			<NavButton onClick={goToTableHandler}>Zu den gemeldeten Beiträgen</NavButton>
		</FormFooterView>
	);
});

export default FlaggedContentDetailsFooterView;
