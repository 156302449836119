import { Button, TextareaAutosize, Typography } from '@mui/material';
import React, { FC, useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import { changeAdditionalNotes } from '../store/ContractAudit.actions';
import { ListType } from '../types';
import { useQueryClient } from 'react-query';

type AdditionalNotesProps = {
	notes: string;
	contractId: string;
};

const AdditionalNotes: FC<AdditionalNotesProps> = ({ notes, contractId }: AdditionalNotesProps) => {
	const [additionalNotes, setAdditionalNotes] = useState<string>(notes);
	const [newAdditionalNotes, setNewAdditionalNotes] = useState<string>(notes);
	const queryClient = useQueryClient();
	const handleChangeTextArea = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
		setNewAdditionalNotes(event.target.value);
	};

	const saveAdditionalNotes = (): void => {
		changeAdditionalNotes({
			changedNotes: newAdditionalNotes,
			contractId
		}).then(() => queryClient.resetQueries([ListType.CONTRACT, { tenantIds: [], appVariantIds: [], search: '' }]));

		setAdditionalNotes(newAdditionalNotes);
	};

	return (
		<>
			<TextareaAutosize
				aria-label="additionalNotes"
				style={{ width: '50%' }}
				value={newAdditionalNotes}
				onChange={handleChangeTextArea}
			/>

			<Typography>
				<Button
					variant="outlined"
					startIcon={<CheckIcon />}
					size="small"
					style={{ marginRight: '0.25rem' }}
					disabled={newAdditionalNotes === additionalNotes}
					onClick={saveAdditionalNotes}
				>
					Speichern
				</Button>
				<Button
					variant="contained"
					startIcon={<CancelIcon />}
					size="small"
					disabled={newAdditionalNotes === additionalNotes}
					onClick={(): void => {
						setNewAdditionalNotes(additionalNotes);
					}}
				>
					Abbrechen
				</Button>
			</Typography>
		</>
	);
};

export default AdditionalNotes;
