import { Box, IconButton, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Chip from '@mui/material/Chip';
import React, { FC } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import WidgetsIcon from '@mui/icons-material/Widgets';
import MapIcon from '@mui/icons-material/Map';
import { AppVariant, Filter, Filters, FilterType, Tenant } from '../types';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			justifyContent: 'flex-start',
			flexWrap: 'wrap',
			listStyle: 'none',
			padding: theme.spacing(0.5),
			margin: 0,
			minHeight: '48px'
		},
		chip: {
			margin: theme.spacing(0.5)
		},
		container: {
			marginLeft: 'auto',
			marginTop: 'auto',
			marginBottom: 'auto'
		},
		appVariantColor: {
			color: theme.palette.primary.main,
			borderColor: theme.palette.primary.light,
			'& > .MuiChip-icon': {
				color: theme.palette.primary.main
			},
			'& > .MuiChip-deleteIcon ': {
				color: theme.palette.primary.main
			}
		},
		tenantColor: {
			color: theme.palette.secondary.main,
			borderColor: theme.palette.secondary.light,
			'& > .MuiChip-icon': {
				color: theme.palette.secondary.main
			},
			'& > .MuiChip-deleteIcon ': {
				color: theme.palette.secondary.main
			}
		}
	})
);

type FilterBarProps = {
	filters: Filters;
	handleRemoveFilter: (removeFilter: Filter<AppVariant | Tenant>) => void;
	handleRemoveAllFilter: () => void;
};

const FilterBar: FC<FilterBarProps> = ({ filters, handleRemoveFilter, handleRemoveAllFilter }: FilterBarProps) => {
	const classes = useStyles();
	const appVariantFilters = Object.values(filters[FilterType.APP_VARIANT]).filter(
		filterItem => !filterItem?.isParentNode
	);
	const tenantFilters = Object.values(filters[FilterType.TENANT]);
	const noFilters = appVariantFilters.length === 0 && tenantFilters.length === 0;

	const ChipElement = ({ filter }: { filter: Filter<AppVariant | Tenant> }): JSX.Element => {
		const icon = filter.type === FilterType.APP_VARIANT ? <WidgetsIcon /> : <MapIcon />;
		const filterColor = filter.type === FilterType.APP_VARIANT ? classes.appVariantColor : classes.tenantColor;
		return (
			<li>
				<Chip
					icon={icon}
					label={filter.name}
					variant="outlined"
					onDelete={(): void => handleRemoveFilter(filter)}
					className={`${classes.chip} ${filterColor}`}
				/>
			</li>
		);
	};

	return (
		<>
			<Box component="ul" className={classes.root}>
				{appVariantFilters.map(filter => {
					// eslint-disable-next-line react/prop-types
					return <ChipElement key={filter.id} filter={filter} />;
				})}
				{tenantFilters.map(filter => {
					// eslint-disable-next-line react/prop-types
					return <ChipElement key={filter.id} filter={filter} />;
				})}
				{noFilters && <Typography className={classes.container}>Keine Filter gesetzt</Typography>}
			</Box>
			<Box className={classes.container}>
				<IconButton aria-label="clear all filters" onClick={handleRemoveAllFilter} disabled={noFilters} size="large">
					<ClearIcon />
				</IconButton>
			</Box>
		</>
	);
};
export default FilterBar;
