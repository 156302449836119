import React from 'react';
import { Input, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { ReadOnlyTextFieldView } from '../ReadOnlyTextField.view';
import appIds from '../../../constants/export_apps.json';
interface Props {
	newsSourcesAppId: string;
	isReadOnlyField?: boolean;
	newsSourcesAppIDChange: (newAppId: string) => void;
}

class NewsSourcesChooseAppID extends React.Component<Props> {
	constructor(props: Props) {
		super(props);
		this.handleNewsSourcesAppIDChange = this.handleNewsSourcesAppIDChange.bind(this);
	}

	handleNewsSourcesAppIDChange(event: SelectChangeEvent<string>): void {
		const value = `${event.target.value || ''}`;
		const { newsSourcesAppIDChange } = this.props;
		newsSourcesAppIDChange && newsSourcesAppIDChange(value);
	}

	render(): JSX.Element {
		const { newsSourcesAppId, isReadOnlyField } = this.props;
		return isReadOnlyField ? (
			<ReadOnlyTextFieldView
				id="AppID"
				name="AppID"
				label="AppID"
				value={newsSourcesAppId}
				InputLabelProps={{ shrink: true }}
				InputProps={{ readOnly: true }}
				fullWidth
			/>
		) : (
			<>
				<InputLabel htmlFor="AppID">App auswählen</InputLabel>
				<Select
					variant="standard"
					name="AppID"
					value={newsSourcesAppId}
					onChange={this.handleNewsSourcesAppIDChange}
					input={<Input id="AppID" />}
					fullWidth
				>
					{appIds.map(app => {
						return (
							<MenuItem key={app.id} value={app.id}>
								{app.name}
							</MenuItem>
						);
					})}
				</Select>
			</>
		);
	}
}

export default NewsSourcesChooseAppID;
