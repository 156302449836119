import { BaseError } from '../shared/errors/Errors';
import {
	ClientCommunity,
	ClientGeoArea,
	ClientGeoAreaExtended,
	ClientPersonChangeEmailAddressRequest,
	ClientPersonOwn,
	ClientRoleInformation
} from '@DigitaleDoerfer/digitale-doerfer-api/models';
import { RoleManagementPermission } from '../shared/services/Role.service';

export const REFRESH_GLOBAL_STATE_REQUEST = 'REFRESH_GLOBAL_STATE_REQUEST';
export const REFRESH_GLOBAL_STATE_SUCCESS = 'REFRESH_GLOBAL_STATE_SUCCESS';
export const REFRESH_GLOBAL_STATE_FAILURE = 'REFRESH_GLOBAL_STATE_FAILURE';

export const GET_GEO_AREA_REQUEST = 'GET_GEO_AREA_REQUEST';
export const GET_GEO_AREA_SUCCESS = 'GET_GEO_AREA_SUCCESS';
export const GET_GEO_AREA_FAILURE = 'GET_GEO_AREA_FAILURE';

export const GET_GEO_AREAS_REQUEST = 'GET_GEO_AREAS_REQUEST';
export const GET_GEO_AREAS_SUCCESS = 'GET_GEO_AREAS_SUCCESS';
export const GET_GEO_AREAS_FAILURE = 'GET_GEO_AREAS_FAILURE';

export const CHANGE_EMAIL_ADDRESS_REQUEST = 'CHANGE_EMAIL_ADDRESS_REQUEST';
export const CHANGE_EMAIL_ADDRESS_SUCCESS = 'CHANGE_EMAIL_ADDRESS_SUCCESS';
export const CHANGE_EMAIL_ADDRESS_FAILURE = 'CHANGE_EMAIL_ADDRESS_FAILURE';

export interface RefreshGlobalStateRequestAction {
	type: typeof REFRESH_GLOBAL_STATE_REQUEST;
}

export interface RefreshGlobalStateSuccessAction {
	type: typeof REFRESH_GLOBAL_STATE_SUCCESS;
	tenants: ClientCommunity[];
	allAvailableRoles: ClientRoleInformation[];
	assignableRoles: ClientRoleInformation[];
	roleManagementPermission: RoleManagementPermission;
	person: ClientPersonOwn;
}

export interface RefreshGlobalStateFailureAction {
	type: typeof REFRESH_GLOBAL_STATE_FAILURE;
	error: BaseError | null;
}

export interface GetGeoAreaRequestAction {
	type: typeof GET_GEO_AREA_REQUEST;
	geoAreaId: string;
}

export interface GetGeoAreaSuccessAction {
	type: typeof GET_GEO_AREA_SUCCESS;
	geoArea: ClientGeoArea;
}

export interface GetGeoAreaFailureAction {
	type: typeof GET_GEO_AREA_FAILURE;
	error: BaseError | null;
}

export interface GetGeoAreasRequestAction {
	type: typeof GET_GEO_AREAS_REQUEST;
}

export interface GetGeoAreasSuccessAction {
	type: typeof GET_GEO_AREAS_SUCCESS;
	geoAreas: ClientGeoAreaExtended[];
}

export interface GetGeoAreasFailureAction {
	type: typeof GET_GEO_AREAS_FAILURE;
	error: BaseError | null;
}

export interface ChangeEmailAddressRequestAction {
	type: typeof CHANGE_EMAIL_ADDRESS_REQUEST;
	changeEmailAddressRequestParams: ClientPersonChangeEmailAddressRequest;
}
export interface ChangeEmailAddressSuccessAction {
	type: typeof CHANGE_EMAIL_ADDRESS_SUCCESS;
	person: ClientPersonOwn;
}

export interface ChangeEmailAddressFailureAction {
	type: typeof CHANGE_EMAIL_ADDRESS_FAILURE;
	error: BaseError | null;
}

export type GlobalActionTypes =
	| RefreshGlobalStateRequestAction
	| RefreshGlobalStateSuccessAction
	| RefreshGlobalStateFailureAction
	| GetGeoAreaRequestAction
	| GetGeoAreaSuccessAction
	| GetGeoAreaFailureAction
	| GetGeoAreasRequestAction
	| GetGeoAreasSuccessAction
	| GetGeoAreasFailureAction
	| ChangeEmailAddressRequestAction
	| ChangeEmailAddressSuccessAction
	| ChangeEmailAddressFailureAction;
