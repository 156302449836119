import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PlatformAdminUIState, PlatformAdminUIThunkType } from '../../../store/store';
import UserDetailsView from './UserDetails.view';
import { RouteComponentProps } from 'react-router-dom';
import { Container, Grid } from '@mui/material';
import { cancelChangeEmail, getUser, setEmailVerifiedByAdmin } from '../store/User.actions';
import {
	ClientOauthAccountAuthenticationMethodsEnum,
	ClientPersonExtended
} from '@DigitaleDoerfer/digitale-doerfer-api/models';
import { RelatedEntity } from '../store/User.state';
import { UserActionTypes } from '../store/UserActionTypes';
import RoleAssignmentFormContainer from './UserDetailsRoleAssignment.container';
import MainLoadingIndicator from '../../../shared/views/LoadingIndicators/MainLoadingIndicator.view';
import PersonDetailFormView from '../../../shared/views/PersonDetail/PersonDetailForm.view';
import UserDetailsActionsMenuContainer from './UserDetailsActionMenu.container';
import { USERS_URL } from '../UsersRouting.container';
import AppVariantUsagesView from '../../../shared/views/PersonDetail/AppVariantUsages.view';

interface Identifiable {
	id?: string;
}

interface Props extends RouteComponentProps<Identifiable> {
	loading: boolean;
	selectedUser: ClientPersonExtended | null;
	relatedEntities: RelatedEntity[];
	getUser: (userId: string) => void;
	confirmChangeEmail: (userId: string) => void;
	cancelChangeEmail: (userId: string) => void;
}

interface State {
	contentRefreshed: boolean;
}

class UserDetailsContainer extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = { contentRefreshed: false };
	}

	componentDidMount(): void {
		const {
			match: { params },
			getUser
		} = this.props;
		const userId = params.id;
		if (userId) {
			getUser(userId);
			this.setState({ contentRefreshed: true });
		}
	}

	goBackHandler = (): void => {
		this.props.history.push(USERS_URL);
	};

	render(): JSX.Element {
		const { loading, selectedUser, confirmChangeEmail, cancelChangeEmail } = this.props;
		const { contentRefreshed } = this.state;
		if (selectedUser === null) {
			return <></>;
		}
		const title = `${selectedUser.firstName || ''} ${selectedUser.lastName || ''} ${
			selectedUser.deleted ? 'Gelöschter Benutzer' : ''
		}`;
		const authenticationMethods: ClientOauthAccountAuthenticationMethodsEnum[] =
			(selectedUser.oauthAccount &&
				selectedUser.oauthAccount.authenticationMethods &&
				selectedUser.oauthAccount.authenticationMethods.map(methods => methods)) ||
			[];
		const userIsNotDeleted = !selectedUser.deleted;
		const { appVariantUsages } = selectedUser;
		return (
			<Container fixed maxWidth="md">
				<MainLoadingIndicator loading={!contentRefreshed || loading} />
				{!loading && contentRefreshed && (
					<PersonDetailFormView
						title={title}
						textToCopy={selectedUser.id}
						authenticationMethods={authenticationMethods}
						actionMenu={userIsNotDeleted ? <UserDetailsActionsMenuContainer /> : undefined}
						goToTableHandler={this.goBackHandler}
					>
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<UserDetailsView
									selectedUser={selectedUser}
									confirmChangeEmail={confirmChangeEmail}
									cancelChangeEmail={cancelChangeEmail}
								/>
							</Grid>
							<Grid item xs={12}>
								<RoleAssignmentFormContainer canAddNewRoleAssignments={userIsNotDeleted} />
							</Grid>
							{appVariantUsages && appVariantUsages.length > 0 && (
								<Grid item xs={12}>
									<AppVariantUsagesView appVariantUsages={appVariantUsages} />
								</Grid>
							)}
						</Grid>
					</PersonDetailFormView>
				)}
			</Container>
		);
	}
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: PlatformAdminUIState) => {
	return {
		loading: state.user.detail.loading,
		selectedUser: state.user.detail.user
	};
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch: PlatformAdminUIThunkType<UserActionTypes>) => {
	return {
		getUser: (userId: string): Promise<void> => dispatch(getUser(userId, true)),
		confirmChangeEmail: (userId: string): Promise<void> => dispatch(setEmailVerifiedByAdmin(userId)),
		cancelChangeEmail: (userId: string): Promise<void> => dispatch(cancelChangeEmail(userId))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDetailsContainer);
