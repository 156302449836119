import React, { FC } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { NotFoundErrorView } from '../error/ErrorPages.view';
import ContractAuditDetailsContainer from './contract-audit-details/ContractAuditDetails.container';

const ContractAuditRoutingContainer: FC = () => {
	const { url } = useRouteMatch();
	return (
		<Switch>
			<Route exact path={url} component={ContractAuditDetailsContainer} />
			<Route component={NotFoundErrorView} />
		</Switch>
	);
};

export default ContractAuditRoutingContainer;
