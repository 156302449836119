import { Action, applyMiddleware, combineReducers, compose, createStore, Store } from 'redux';
import { authReducer } from '../modules/auth/store/Auth.reducer';
import globalReducer from './Global.reducer';
import { snackbarNotificationReducer } from '../../src/shared/views/SnackbarNotification/store/SnackbarNotification.reducer';
import userReducer from '../modules/users/store/User.reducer';
import pushEventReducer from '../modules/push-events/store/PushEvent.reducer';
import flaggedContentReducer from '../modules/flagged-contents/store/FlaggedContent.reducer';
import groupReducer from '../modules/groups/store/Group.reducer';
import appConfigReducer from '../modules/app-configs/store/AppConfig.reducer';
import newsSourceReducer from '../modules/News-Sources/store/NewsSources.reducer';
import thunk, { ThunkDispatch } from 'redux-thunk';

const rootReducer = combineReducers({
	auth: authReducer,
	user: userReducer,
	pushEvent: pushEventReducer,
	flaggedContent: flaggedContentReducer,
	group: groupReducer,
	appConfig: appConfigReducer,
	newsSource: newsSourceReducer,
	snackbar: snackbarNotificationReducer,
	global: globalReducer
});

/**
 * The __REDUX_DEVTOOLS_EXTENSION_COMPOSE__ enhancer allows us to activate the redux devtools extension.
 * If the extension is not installed, we use the Redux compose.
 * More info in: https://github.com/zalmoxisus/redux-devtools-extension.
 */
// The __REDUX_DEVTOOLS_EXTENSION_COMPOSE__ property is only there if the extension is installed.
// Hence, it is not known by the default Window type.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const composeEnhancers = (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export type PlatformAdminUIState = ReturnType<typeof rootReducer>;

export type PlatformAdminUIThunkType<T extends Action> = ThunkDispatch<PlatformAdminUIState, undefined, T>;

export default function configureStore(): Store {
	const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
	return store;
}

export const store = configureStore();
