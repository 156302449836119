import React from 'react';
import { TextField } from '@mui/material';
import { ReadOnlyTextFieldView } from '../ReadOnlyTextField.view';

interface Props {
	newsSourceschangeNotes: string;
	isReadOnlyField?: boolean;
	newsSourceschangeNotesChange: (newchangeNotes: string) => void;
}

class NewsSourcesChangeNotesView extends React.Component<Props> {
	constructor(props: Props) {
		super(props);
		this.handleNewsSourceschangeNotesChange = this.handleNewsSourceschangeNotesChange.bind(this);
	}

	handleNewsSourceschangeNotesChange(event: React.ChangeEvent<{ value: string }>): void {
		const { newsSourceschangeNotesChange } = this.props;
		newsSourceschangeNotesChange && newsSourceschangeNotesChange(event.target.value);
	}

	render(): JSX.Element {
		const { newsSourceschangeNotes, isReadOnlyField } = this.props;
		return isReadOnlyField ? (
			<ReadOnlyTextFieldView
				id="newsSourceschangeNotes"
				name="newsSourceschangeNotes"
				label="Change Notes"
				value={newsSourceschangeNotes}
				InputLabelProps={{ shrink: true }}
				InputProps={{ readOnly: true }}
				fullWidth
			/>
		) : (
			<TextField
				variant="standard"
				required
				id="newsSourceschangeNotes"
				name="newsSourceschangeNotes"
				label="Change Notes"
				value={newsSourceschangeNotes}
				onChange={this.handleNewsSourceschangeNotesChange}
				InputLabelProps={{ shrink: true }}
				fullWidth
			/>
		);
	}
}

export default NewsSourcesChangeNotesView;
