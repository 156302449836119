import React from 'react';
import { Box, Divider, Typography } from '@mui/material';

export interface Props {
	title?: React.ReactNode;
	subtitle?: React.ReactNode;
	actionMenu?: JSX.Element;
}

function FormHeaderView(props: React.PropsWithChildren<Props>): JSX.Element {
	const { title, subtitle, actionMenu } = props;

	return (
		<Box
			sx={{
				marginBottom: 3
			}}
		>
			<Box
				sx={{
					paddingBottom: 0.5,
					display: 'flex'
				}}
			>
				{(title || subtitle) && (
					<Box display="flex" flexDirection="column">
						{title && <Typography variant="h1">{title}</Typography>}
						{subtitle && <Typography variant="h6">{subtitle}</Typography>}
					</Box>
				)}
				{props.children}
				{actionMenu && (
					<Box
						sx={{
							marginLeft: 'auto'
						}}
					>
						{actionMenu}
					</Box>
				)}
			</Box>
			<Divider variant="fullWidth" />
		</Box>
	);
}

export default FormHeaderView;
