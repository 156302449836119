import { Checkbox, FormControlLabel } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { FC } from 'react';
import { useStore } from '../../store';
import { ListType, ValueOf } from '../../types';

export type SelectAllProps = {
	type: ValueOf<typeof ListType>;
	isCheckboxDisabled: boolean;
	disableCheckbox: () => void;
};

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
		paddingLeft: 7,
		paddingRight: 23,
		justifyContent: (props: SelectAllProps): string =>
			props.type === ListType.CONTRACT ? 'space-between' : 'flex-start',
		color: theme.palette.info.dark,
		'& > .MuiFormControlLabel-label': {
			fontWeight: 'bold'
		}
	}
}));

const SelectAll: FC<SelectAllProps> = ({ type, disableCheckbox, isCheckboxDisabled }: SelectAllProps) => {
	const classes = useStyles({ type, disableCheckbox, isCheckboxDisabled });
	const { shouldUseAllContracts, toggleUseAllContracts } = useStore();

	const handleCheckboxClick = (): void => {
		disableCheckbox();
		toggleUseAllContracts();
	};

	return (
		<FormControlLabel
			className={classes.root}
			disabled={isCheckboxDisabled}
			labelPlacement={'start'}
			control={<Checkbox checked={shouldUseAllContracts} onClick={handleCheckboxClick} size={'small'} />}
			label="Alle selektieren"
		/>
	);
};

export default SelectAll;
