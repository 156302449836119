import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PlatformAdminUIState, PlatformAdminUIThunkType } from '../../store/store';
import { getAppConfigs } from './store/AppConfig.actions';
import AppConfigListView from './AppConfigList.view';
import { RouteComponentProps } from 'react-router-dom';
import { AppConfigActionTypes } from './store/AppConfigActionTypes';
import { ClientTenantAndAppVariantEntry } from '@DigitaleDoerfer/digitale-doerfer-api/models';

interface Props extends RouteComponentProps {
	appConfigs: ClientTenantAndAppVariantEntry[];
	loading: boolean;
	getAppConfigs: () => void;
}

class AppConfigListContainer extends Component<Props> {
	componentDidMount(): void {
		this.props.getAppConfigs();
	}

	render(): JSX.Element {
		const { appConfigs, loading } = this.props;
		return <AppConfigListView appConfigs={appConfigs} loading={loading} />;
	}
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: PlatformAdminUIState) => {
	return {
		appConfigs: state.appConfig.list.appConfigs,
		loading: state.appConfig.list.loading
	};
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch: PlatformAdminUIThunkType<AppConfigActionTypes>) => {
	return {
		getAppConfigs: (): Promise<void> => dispatch(getAppConfigs())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AppConfigListContainer);
