import React from 'react';
import { Grid, Typography } from '@mui/material';
import { ClientAppVariantUsageExtended } from '@DigitaleDoerfer/digitale-doerfer-api/models';
import AppVariantElementView from './AppVariantElement.view';

interface Props {
	appVariantUsages: ClientAppVariantUsageExtended[];
}

const AppVariantUsagesView = (props: Props): JSX.Element => {
	const { appVariantUsages } = props;
	return (
		<Grid container>
			<Grid item xs={12}>
				<Typography variant="h2" gutterBottom>
					Benutzte Anwendungen
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<Grid container spacing={3}>
					{appVariantUsages.map(appV => {
						const { appVariant } = appV;
						return <AppVariantElementView key={appVariant.id} appVariantUsage={appV} />;
					})}
				</Grid>
			</Grid>
		</Grid>
	);
};
export default AppVariantUsagesView;
