/* Module is mostly adpated from an already existing module in the Digital Vital project */
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React, { FC, PropsWithChildren, useCallback } from 'react';

export const ALERT_BTN_CONFIRM = 'Ok';
export const ALERT_BTN_BACK = 'Zurück';

type AbstractDialogProps = {
	open: boolean;
	icon?: JSX.Element;
	title?: string;
	disableModalCloseActions?: boolean;
	hideActionBtn?: boolean;
	btnActionText?: string;
	hideCancelBtn?: boolean;
	btnCancelText?: string;
	onAction: () => void;
	onClose: () => void;
	maxWidth?: string;
	isActionDisabled?: boolean;
};

const AbstractDialog: FC<PropsWithChildren<AbstractDialogProps>> = (props: PropsWithChildren<AbstractDialogProps>) => {
	const {
		open,
		title,
		disableModalCloseActions,
		hideActionBtn,
		btnActionText,
		hideCancelBtn,
		btnCancelText,
		onAction,
		onClose,
		isActionDisabled,
		children
	} = props;

	const handleClose = useCallback(
		(_event: object, reason: string) => {
			if (!_event) {
				return;
			}
			if (disableModalCloseActions && (reason === 'backdropClick' || reason === 'escapeKeyDown')) {
				return;
			}
			onClose();
		},
		[disableModalCloseActions, onClose]
	);

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<Box
				sx={{
					margin: '2rem',
					border: 1,

					borderRadius: 2,
					paddingTop: 1
				}}
			>
				{title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
				<DialogContent>{children}</DialogContent>
				<DialogActions>
					{!hideCancelBtn && (
						<Button variant="contained" color="secondary" onClick={onClose}>
							{btnCancelText || ALERT_BTN_BACK}
						</Button>
					)}
					{!hideActionBtn && (
						<Button
							variant="contained"
							color="primary"
							onClick={onAction}
							autoFocus
							disabled={isActionDisabled || false}
						>
							{btnActionText || ALERT_BTN_CONFIRM}
						</Button>
					)}
				</DialogActions>
			</Box>
		</Dialog>
	);
};

export default AbstractDialog;
