import { Theme } from '@mui/material';

import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-unused-vars
const styles = (theme: Theme) => {
	return createStyles({
		checkField: {
			overflowY: 'visible',
			height: 100
		},
		errorText: {
			fontWeight: 'bold',
			color: theme.palette.error.main
		}
	});
};

export type FlagActionDialogStyles = WithStyles<typeof styles>;

export const withFlagActionDialogStyes = withStyles(styles);

export const disableEnterKeyEventHandler = (event: React.KeyboardEvent<HTMLInputElement>): void => {
	if (event.key === 'Enter') {
		event.preventDefault();
		event.stopPropagation();
	}
};
