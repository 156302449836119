import React, { Component } from 'react';
import { Box, Grid, Paper } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import {
	ClientCommunity,
	ClientGeoArea,
	ClientGroupExtended,
	ClientGroupExtendedAccessibilityEnum,
	ClientGroupExtendedVisibilityEnum
} from '@DigitaleDoerfer/digitale-doerfer-api/models';
import { EmptyField, initialGroupState, ListSearchParams } from '../store/Group.state';
import BarLoadingIndicator from '../../../shared/views/LoadingIndicators/BarLoadingIndicator.view';
import TableView from '../../../shared/views/Table/Table.view';
import { MouseEventType } from '../../../shared/utils/Util';
import { TableCell, TableCellTooltip, TableColumn } from '../../../shared/views/Table/TableColumn';
import { TableSorting } from '../../../shared/views/Table/TableSorting';
import TablePaginationView from '../../../shared/views/Table/TablePagination.view';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import NavActionButton from '../../../shared/views/Buttons/NavActionButton.view';
import TenantsAutocompleteView from '../../../shared/views/Group/TenantsAutocomplete.view';

const FIXED_SMALL_COLUMN_WIDTH = 25;
const FIXED_COLUMN_WIDTH = 200;
const MINWIDTH_COLUMN_WIDTH = 320;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-unused-vars
const styles = () => {
	return createStyles({
		minWidthColumn: {
			minWidth: MINWIDTH_COLUMN_WIDTH
		},
		fixedColumn: {
			minWidth: FIXED_COLUMN_WIDTH,
			maxWidth: FIXED_COLUMN_WIDTH
		},
		fixedSmallColumn: {
			width: FIXED_SMALL_COLUMN_WIDTH
		},
		createButtonContainer: {
			margin: '8px'
		}
	});
};

interface Props extends WithStyles<typeof styles> {
	loading: boolean;
	tenants: ClientCommunity[];
	groups: ClientGroupExtended[];
	searchParams: ListSearchParams;
	tenantChangedHandler: (tenantId: string) => void;
	getGroupsByText: (params: ListSearchParams) => void;
	viewGroupDetailsHandler: (groupId: string) => void;
	openNewGroupDetailsTab: (groupId: string) => void;
	createGroupHandler: () => void;
}

const paginationRowsPerPageOptions = [
	initialGroupState.list.searchParams.pagination.rowsPerPage,
	50,
	100,
	200
] as const;

interface State {
	newSearchParams: ListSearchParams;
}
class GroupListView extends Component<Props, State> {
	searchNeedsUpdate = false;

	constructor(props: Props) {
		super(props);
		const { searchParams } = props;
		this.state = {
			newSearchParams: searchParams
		};
		this.updateSearch = this.updateSearch.bind(this);
		this.handleSearchReset = this.handleSearchReset.bind(this);
		this.handleSearchTextChange = this.handleSearchTextChange.bind(this);
		this.handleSearchTenantChange = this.handleSearchTenantChange.bind(this);
		this.handleChangePage = this.handleChangePage.bind(this);
		this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
		this.handleSortingChange = this.handleSortingChange.bind(this);
		this.handleMouseEvent = this.handleMouseEvent.bind(this);
		this.getColumns = this.getColumns.bind(this);
	}

	updateSearch(): void {
		if (!this.props.loading) {
			this.searchNeedsUpdate = false;
			const { getGroupsByText } = this.props;
			const { newSearchParams } = this.state;
			const { text, tenantId } = newSearchParams;
			if (tenantId !== EmptyField.NONE) {
				getGroupsByText(newSearchParams);
			} else {
				if (text.length > 2 || text.length === 0) {
					getGroupsByText(newSearchParams);
				}
			}
		} else {
			this.searchNeedsUpdate = true;
		}
	}

	findTenantbyID(tenantId: string): ClientCommunity | undefined {
		return this.props.tenants.find(tenant => tenant.id === tenantId);
	}

	renderIconType(cell: TableCell<ClientGroupExtended>): React.ReactElement | null {
		const group: ClientGroupExtended = cell.row;
		return group.accessibility === ClientGroupExtendedAccessibilityEnum.PUBLIC ? <LockOpenIcon /> : <LockIcon />;
	}

	renderGeoAreas(cell: TableCell<ClientGroupExtended>): React.ReactElement | null {
		const geoAreas: ClientGeoArea[] = cell.row.includedGeoAreas ?? [];
		const firstGeoArea = geoAreas[0] ? `${geoAreas[0].name}` : '';
		const secondGeoArea = geoAreas[1] ? `, ${geoAreas[1].name}` : '';
		const aditionalGeoAreas = geoAreas.length - 2 > 0 ? `, +${geoAreas.length - 2}` : '';
		return (
			<>
				{firstGeoArea}
				{secondGeoArea}
				{aditionalGeoAreas}
			</>
		);
	}

	getGroupVisibilityText(groupVisivility: ClientGroupExtendedVisibilityEnum): string {
		let groupVisivilityText = '';
		switch (groupVisivility) {
			case ClientGroupExtendedVisibilityEnum.ANYONE:
				groupVisivilityText = 'Global';
				break;
			case ClientGroupExtendedVisibilityEnum.MEMBERS:
				groupVisivilityText = 'Mitglieder';
				break;
			case ClientGroupExtendedVisibilityEnum.SAMEHOMEAREA:
				groupVisivilityText = 'HomeArea';
				break;
			default:
				groupVisivilityText = 'Nicht definiert';
				break;
		}
		return groupVisivilityText;
	}

	getColumns(): TableColumn<ClientGroupExtended>[] {
		const { classes } = this.props;
		const { minWidthColumn, fixedSmallColumn, fixedColumn } = classes;
		return [
			{
				id: 'groupName',
				headerLabel: 'Gruppenname',
				cellClassName: minWidthColumn,
				cellStyles: {},
				getCellStringValue: (group: ClientGroupExtended): string => group.name,
				filterable: true,
				sortable: false
			},
			{
				id: 'type',
				headerLabel: 'Typ',
				cellClassName: fixedSmallColumn,
				cellStyles: {},
				getCellStringValue: (group: ClientGroupExtended): string => group.accessibility,
				renderCell: this.renderIconType,
				filterable: true,
				sortable: false
			},
			{
				id: 'visibility',
				headerLabel: 'Sichtbarkeit',
				cellClassName: fixedSmallColumn,
				cellStyles: {},
				getCellStringValue: (group: ClientGroupExtended): string => this.getGroupVisibilityText(group.visibility),
				filterable: true,
				sortable: false
			},
			{
				id: 'members',
				headerLabel: 'Größe',
				cellClassName: fixedSmallColumn,
				cellStyles: {},
				getCellStringValue: (group: ClientGroupExtended): string => group.memberCount.toString(),
				filterable: true,
				sortable: false
			},
			{
				id: 'tenant',
				headerLabel: 'Mandant',
				cellClassName: fixedColumn,
				cellStyles: {},
				getCellStringValue: (group: ClientGroupExtended): string =>
					this.findTenantbyID(group.tenantId ?? '')?.name ?? '',
				filterable: true,
				sortable: false
			},
			{
				id: 'geoAreas',
				headerLabel: 'Heimatgemeinde',
				cellClassName: minWidthColumn,
				cellStyles: {},
				getCellStringValue: (group: ClientGroupExtended): string => group.includedGeoAreas?.length.toString() ?? '',
				getCellTooltip: (cell: TableCell<ClientGroupExtended>): TableCellTooltip => ({
					title: cell.row.includedGeoAreas?.map(geoArea => geoArea.name)?.toString(),
					placement: 'bottom-start'
				}),
				renderCell: this.renderGeoAreas,
				filterable: true,
				sortable: false
			}
		];
	}

	getKeyForRow(group: ClientGroupExtended): React.Key {
		return group.id;
	}

	componentDidMount(): void {
		this.updateSearch();
	}

	componentDidUpdate(): void {
		if (this.searchNeedsUpdate) {
			this.updateSearch();
		}
	}

	handleSearchReset(): void {
		this.setState((state: State): State => {
			return {
				...state,
				newSearchParams: initialGroupState.list.searchParams
			};
		}, this.updateSearch);
	}

	handleSearchTextChange(searchCriteria: string): void {
		this.setState((state: State): State => {
			return {
				...state,
				newSearchParams: {
					...state.newSearchParams,
					pagination: { ...state.newSearchParams.pagination, page: 0 },
					text: searchCriteria
				}
			};
		}, this.updateSearch);
	}

	handleSearchTenantChange(tenant: ClientCommunity): void {
		this.setState((state: State): State => {
			return {
				...state,
				newSearchParams: {
					...state.newSearchParams,
					tenantId: tenant.id,
					pagination: { ...state.newSearchParams.pagination, page: 0 }
				}
			};
		}, this.updateSearch);
	}

	handleSortingChange(newSorting: TableSorting): void {
		this.setState((state: State): State => {
			return {
				...state,
				newSearchParams: {
					...state.newSearchParams,
					pagination: { ...state.newSearchParams.pagination, page: 0 },
					sorting: newSorting
				}
			};
		}, this.updateSearch);
	}

	handleChangePage(newPage: number): void {
		this.setState((state: State): State => {
			return {
				...state,
				newSearchParams: {
					...state.newSearchParams,
					pagination: { ...state.newSearchParams.pagination, page: newPage }
				}
			};
		}, this.updateSearch);
	}

	handleChangeRowsPerPage(newRowsPerPage: number): void {
		this.setState((state: State): State => {
			return {
				...state,
				newSearchParams: {
					...state.newSearchParams,
					pagination: { ...state.newSearchParams.pagination, rowsPerPage: newRowsPerPage, page: 0 }
				}
			};
		}, this.updateSearch);
	}

	/**
	 * Handle the next mouse events:
	 * - (ctrlkey & left click) or middle click --> Open a new tab.
	 * - left click --> Open detail view.
	 * @param event Mouse event.
	 * @param group Grop data.
	 */
	handleMouseEvent(event: React.MouseEvent, group: ClientGroupExtended): void {
		const { viewGroupDetailsHandler, openNewGroupDetailsTab } = this.props;
		const mouseEvent = event.nativeEvent.which;
		const groupId = group.id || '';
		if ((event.ctrlKey && MouseEventType.MOUSE_LEFT === mouseEvent) || mouseEvent === MouseEventType.MOUSE_MIDDLE) {
			openNewGroupDetailsTab(groupId);
		} else if (mouseEvent === MouseEventType.MOUSE_LEFT) {
			viewGroupDetailsHandler(groupId);
		}
	}

	render(): JSX.Element {
		const { tenants, groups, searchParams, loading, createGroupHandler, classes } = this.props;
		const { sorting, tenantId } = searchParams;
		const { page, rowsPerPage, total } = searchParams.pagination;
		return (
			<>
				<Box className={classes.createButtonContainer}>
					<NavActionButton onClick={(): void => createGroupHandler()}>Neue Gruppe</NavActionButton>
				</Box>
				<Paper>
					<BarLoadingIndicator loading={loading} />
					<Grid container spacing={2}>
						<TenantsAutocompleteView
							searchedTenant={tenantId}
							tenants={tenants}
							handleSearchTenantChange={this.handleSearchTenantChange}
							handleReset={this.handleSearchReset}
						/>
						<Grid item xs={12}>
							<TableView<ClientGroupExtended>
								columns={this.getColumns()}
								rows={groups}
								getKeyForRow={this.getKeyForRow}
								sorting={sorting}
								handleSortingChange={this.handleSortingChange}
								handleRowClicked={this.handleMouseEvent}
							/>
							<TablePaginationView
								totalRows={total}
								rowLabelPlural="Gruppen"
								page={page}
								rowsPerPage={rowsPerPage}
								rowsPerPageOptions={paginationRowsPerPageOptions}
								handleChangePage={this.handleChangePage}
								handleChangeRowsPerPage={this.handleChangeRowsPerPage}
							/>
						</Grid>
					</Grid>
				</Paper>
			</>
		);
	}
}

export default withStyles(styles)(GroupListView);
