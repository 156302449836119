import React from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import TablePaginationView from '../../shared/views/Table/TablePagination.view';
import TableView from '../../shared/views/Table/Table.view';
import { TableCell, TableColumn } from '../../shared/views/Table/TableColumn';
import { TableColumnSortingDirection, TableSorting } from '../../shared/views/Table/TableSorting';
import { Pagination } from '../../shared/services/InMemoryTable.service';
import { inMemoryTableService } from '../../ServiceFactory';
import { arraysEqual } from '../../shared/utils/Util';
import { ClientCommunity } from '@DigitaleDoerfer/digitale-doerfer-api/models';
import SearchBarWithResetButtonView from '../../shared/views/SearchBarWithResetButton/SearchBarWithResetButton.view';
import { ImageSizes } from '../../shared/services/Image.service';
import AvatarView from '../../shared/views/Avatar.view';

interface Props {
	tenants: ClientCommunity[];
}

const renderText = (cell: TableCell<ClientCommunity>): React.ReactElement | null => (
	<span>{cell.column.getCellStringValue(cell.row)}</span>
);

const renderTenantCell = (tenant: ClientCommunity): React.ReactElement | null => {
	return (
		<Grid container spacing={1} direction="row" wrap="nowrap">
			<Grid item>
				<AvatarView avatarPicture={tenant?.profilePicture} deleted={false} size={ImageSizes.THUMBNAIL} />
			</Grid>
			<Grid item>
				<Typography variant="h6" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
					{tenant.name}
				</Typography>
			</Grid>
		</Grid>
	);
};

const renderTenant = (cell: TableCell<ClientCommunity>): React.ReactElement | null => {
	return renderTenantCell(cell.row);
};

const columns: readonly TableColumn<ClientCommunity>[] = [
	{
		id: 'name',
		headerLabel: 'Mandant',
		cellStyles: {
			width: 500
		},
		getCellStringValue: (tenant: ClientCommunity): string => tenant.name ?? 'kein Name',
		renderCell: renderTenant,
		filterable: true,
		sortable: true
	},
	{
		id: 'id',
		headerLabel: 'ID',
		cellStyles: {},
		getCellStringValue: (tenant: ClientCommunity): string => tenant.id ?? 'keine ID',
		renderCell: renderText,
		filterable: true,
		sortable: true
	}
] as const;

function idToColumn(columnId: string): TableColumn<ClientCommunity> | undefined {
	return columns.find(column => column.id === columnId);
}

const paginationRowsPerPageOptions = [20, 50, 100, 200] as const;
const initialPaginationRowsPerPage: (typeof paginationRowsPerPageOptions)[number] = 100;

const initialSearchParams = {
	searchCriteria: '',
	pagination: { page: 0, rowsPerPage: initialPaginationRowsPerPage, total: 0 },
	sorting: { columnId: columns[0].id, direction: TableColumnSortingDirection.ASC }
};

interface State {
	displayedTenants: ClientCommunity[];
	searchCriteria: string;
	pagination: Pagination;
	sorting: TableSorting;
}

class TenantsListView extends React.Component<Props, State> {
	searchNeedsUpdate = false;

	constructor(props: Props) {
		super(props);

		this.state = {
			displayedTenants: [],
			...initialSearchParams
		};
		this.updateDisplayedRowsAndPagination = this.updateDisplayedRowsAndPagination.bind(this);
		this.handleChangePage = this.handleChangePage.bind(this);
		this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
		this.handleSortingChange = this.handleSortingChange.bind(this);
		this.handleSearchChange = this.handleSearchChange.bind(this);
		this.handleSearchReset = this.handleSearchReset.bind(this);
	}

	updateDisplayedRowsAndPagination(): void {
		const tenantsAfterFiltering = inMemoryTableService().applyFilter(
			this.props.tenants,
			this.state.searchCriteria,
			columns
		);

		const tenantsAfterSorting = inMemoryTableService().applySorting(
			tenantsAfterFiltering,
			this.state.sorting,
			idToColumn
		);
		const tenantsAfterPagination = inMemoryTableService().applyPagination(tenantsAfterSorting, this.state.pagination);

		this.setState({
			pagination: { ...this.state.pagination, total: tenantsAfterSorting.length },
			displayedTenants: tenantsAfterPagination
		});
	}

	componentDidMount(): void {
		this.updateDisplayedRowsAndPagination();
	}

	componentDidUpdate(prevProps: Props): void {
		if (!arraysEqual(prevProps.tenants, this.props.tenants)) {
			this.updateDisplayedRowsAndPagination();
		}
	}

	handleChangePage(newPage: number): void {
		this.setState((state: State): State => {
			return { ...state, pagination: { ...state.pagination, page: newPage } };
		}, this.updateDisplayedRowsAndPagination);
	}

	handleChangeRowsPerPage(newRowsPerPage: number): void {
		this.setState((state: State): State => {
			return { ...state, pagination: { ...state.pagination, rowsPerPage: newRowsPerPage, page: 0 } };
		}, this.updateDisplayedRowsAndPagination);
	}

	handleSortingChange(newSorting: TableSorting): void {
		this.setState((state: State): State => {
			return {
				...state,
				pagination: { ...state.pagination, page: 0 },
				sorting: newSorting
			};
		}, this.updateDisplayedRowsAndPagination);
	}

	handleSearchChange(searchCriteria: string): void {
		this.setState(
			{ ...this.state, searchCriteria, pagination: { ...this.state.pagination, page: 0 } },
			this.updateDisplayedRowsAndPagination
		);
	}

	handleSearchReset(): void {
		this.setState((state: State): State => {
			return {
				...state,
				...initialSearchParams
			};
		}, this.updateDisplayedRowsAndPagination);
	}

	getKeyForRow(tenant: ClientCommunity): React.Key {
		const { id, name } = tenant;
		return `${id} ${name}`;
	}

	render(): JSX.Element {
		const { displayedTenants, pagination, searchCriteria, sorting } = this.state;
		const { page, rowsPerPage, total } = pagination;

		return (
			<Paper>
				<Grid container spacing={2}>
					<SearchBarWithResetButtonView
						text={searchCriteria}
						label="Mandant suchen"
						placeholder="Mandant und/oder ID eingeben"
						handleTextChange={this.handleSearchChange}
						handleReset={this.handleSearchReset}
					/>
					<Grid item xs={12}>
						<TableView<ClientCommunity>
							columns={columns}
							rows={displayedTenants}
							getKeyForRow={this.getKeyForRow}
							sorting={sorting}
							handleSortingChange={this.handleSortingChange}
						/>
						<TablePaginationView
							totalRows={total}
							rowLabelPlural="Mandanten"
							page={page}
							rowsPerPage={rowsPerPage}
							rowsPerPageOptions={paginationRowsPerPageOptions}
							handleChangePage={this.handleChangePage}
							handleChangeRowsPerPage={this.handleChangeRowsPerPage}
						/>
					</Grid>
				</Grid>
			</Paper>
		);
	}
}

export default TenantsListView;
