import React from 'react';
import { Box, Grid, TableCell, TableRow, Theme, Tooltip } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { ClientGeoAreaExtended, ClientGrapevineGeoAreaInfo } from '@DigitaleDoerfer/digitale-doerfer-api/models';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import StatisticsView from './Statistics.view';
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const styles = (theme: Theme) => {
	return createStyles({
		geoAreaCell: {
			width: '40%',
			marginBottom: '2px'
		},
		geoAreaName: {
			marginTop: '2px',
			cursor: 'default',
			// eslint-disable-next-line
			['@media (max-width:770px)']: {
				overflow: 'hidden',
				whiteSpace: 'nowrap',
				textOverflow: 'ellipsis',
				maxWidth: '20%'
			}
		},
		tenantNameCell: {
			fontWeight: 600
		},
		copyIcon: {
			marginTop: '4px',
			height: '16px',
			marginLeft: '5px',
			cursor: 'pointer',
			color: theme.palette.primary.main
		},
		expandIconParent: {
			height: '25px',
			minWidth: '10px'
		},
		copyIconParent: {
			height: '25px',
			minWidth: '10px'
		},
		searchMatch: {
			fontWeight: 600
		},
		statisticIcon: {
			cursor: 'pointer',
			color: theme.palette.primary.main
		}
	});
};

interface Props extends WithStyles<typeof styles> {
	geoArea: ClientGeoAreaExtended;
	forceExpand: boolean;
	geoAreaLayer: number;
	renderTenantName: (tenant: string | undefined) => string;
	renderGeoAreaName: (geoArea: ClientGeoAreaExtended) => string;
	isGeoAreaMatching: (geoArea: ClientGeoAreaExtended) => boolean;
}

interface State {
	expanded: boolean;
	showCopyIcon: boolean;
	wasIdCopied: boolean;
	wasStatsCopied: boolean;
	openStatisticDialog: boolean;
	geoAreaStatistics?: ClientGrapevineGeoAreaInfo;
}

class GeoAreaElementView extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		const { geoArea, isGeoAreaMatching } = this.props;
		this.state = {
			expanded: isGeoAreaMatching(geoArea),
			showCopyIcon: false,
			wasIdCopied: false,
			wasStatsCopied: false,
			openStatisticDialog: false
		};

		this.toggleExpanded = this.toggleExpanded.bind(this);
		this.showCopyIcon = this.showCopyIcon.bind(this);
		this.hideCopyIcon = this.hideCopyIcon.bind(this);
		this.handleCopyIdButton = this.handleCopyIdButton.bind(this);
		this.handleCopyStatsButton = this.handleCopyStatsButton.bind(this);
		this.handleOpenStatisticsButton = this.handleOpenStatisticsButton.bind(this);
		this.handleCloseStatisticsButton = this.handleCloseStatisticsButton.bind(this);
	}
	componentDidUpdate(prevProps: Props): void {
		const { forceExpand } = this.props;
		if (prevProps.forceExpand !== forceExpand) {
			this.setState({ expanded: forceExpand });
		}
	}

	toggleExpanded(): void {
		const { expanded } = this.state;
		this.setState({
			expanded: !expanded
		});
	}

	handleCopyIdButton(geoArea: ClientGeoAreaExtended): void {
		navigator.clipboard.writeText(geoArea.id);
		this.setState({
			wasIdCopied: true
		});
	}

	handleCopyStatsButton(geoAreaStatistics?: ClientGrapevineGeoAreaInfo): void {
		if (geoAreaStatistics !== undefined) {
			const { geoArea } = this.props;
			const geoAreaName = geoArea.name?.toString();
			const registeredUser = geoAreaStatistics.personCount?.toString();
			const numberOfPosts = geoAreaStatistics.postCount?.toString();
			const numberOfComments = geoAreaStatistics.commentCount?.toString();

			navigator.clipboard.writeText(
				'Zahlen für ' +
					geoAreaName +
					'\n' +
					'Registrierte Nutzer: ' +
					registeredUser +
					'\n' +
					'Anzahl der Beiträge: ' +
					numberOfPosts + // Changed label
					'\n' +
					'Anzahl der Kommentare: ' +
					numberOfComments // Changed label
			);
			this.setState({
				wasStatsCopied: true
			});
		}
	}
	handleOpenStatisticsButton(): void {
		this.setState({ openStatisticDialog: true });
	}

	handleCloseStatisticsButton(): void {
		this.setState({ openStatisticDialog: false });
	}

	hideCopyIcon(): void {
		this.setState({
			showCopyIcon: false,
			wasIdCopied: false,
			wasStatsCopied: false
		});
	}

	showCopyIcon(): void {
		this.setState({
			showCopyIcon: true
		});
	}

	getRowKey(): string {
		const { geoArea } = this.props;
		return geoArea.id || '';
	}

	hasChildren(): boolean {
		const { geoArea } = this.props;
		return !!geoArea.childGeoAreas && geoArea.childGeoAreas.length > 0;
	}
	render(): JSX.Element {
		const { geoArea, geoAreaLayer, renderTenantName, renderGeoAreaName, classes, isGeoAreaMatching } = this.props;
		const { expanded, showCopyIcon, wasIdCopied, wasStatsCopied, openStatisticDialog } = this.state;
		const key = this.getRowKey();
		const hasChildren = this.hasChildren();
		const isMatch = isGeoAreaMatching(geoArea);
		return (
			<>
				{openStatisticDialog && (
					<StatisticsView
						geoArea={geoArea}
						handleCloseStatisticsButton={this.handleCloseStatisticsButton}
						handleCopyStatsButton={this.handleCopyStatsButton}
						openStatisticDialog={openStatisticDialog}
						wasStatsCopied={wasStatsCopied}
					/>
				)}
				<TableRow key={key}>
					<TableCell scope="row" className={classes.geoAreaCell}>
						<Grid
							container
							style={{
								paddingLeft: geoAreaLayer * 5.5 + '%'
							}}
							onMouseOver={this.showCopyIcon}
							onMouseLeave={this.hideCopyIcon}
						>
							<Grid item onClick={this.toggleExpanded} className={classes.expandIconParent}>
								{hasChildren && !expanded && <ArrowRightIcon />}
								{hasChildren && expanded && <ArrowDropDownIcon />}
							</Grid>

							<Grid item onClick={this.toggleExpanded} className={classes.geoAreaName}>
								<Tooltip title={'ID: ' + geoArea.id} enterDelay={2000} arrow>
									<Box className={`${isMatch ? classes.searchMatch : ''}`}>{renderGeoAreaName(geoArea)}</Box>
								</Tooltip>
							</Grid>

							<Grid item onClick={(): void => this.handleCopyIdButton(geoArea)} className={classes.copyIconParent}>
								{showCopyIcon && !wasIdCopied && <FileCopyOutlinedIcon className={classes.copyIcon} />}
								{showCopyIcon && wasIdCopied && <CheckCircleIcon className={classes.copyIcon} />}
							</Grid>
						</Grid>
					</TableCell>
					<TableCell>
						{!hasChildren && (
							<EqualizerIcon
								className={classes.statisticIcon}
								onClick={(): void => this.handleOpenStatisticsButton()}
							/>
						)}
					</TableCell>
					<TableCell className={classes.tenantNameCell}>{renderTenantName(geoArea.tenantId)}</TableCell>
				</TableRow>
				{expanded &&
					geoArea.childGeoAreas &&
					geoArea.childGeoAreas.map(function (geoArea, index) {
						return (
							<GeoAreaElementView
								renderGeoAreaName={renderGeoAreaName}
								renderTenantName={renderTenantName}
								isGeoAreaMatching={isGeoAreaMatching}
								forceExpand={isGeoAreaMatching(geoArea)}
								geoAreaLayer={geoAreaLayer + 1}
								geoArea={geoArea}
								key={index}
								classes={classes}
							/>
						);
					})}
			</>
		);
	}
}

export default withStyles(styles)(GeoAreaElementView);
