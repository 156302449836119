import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PlatformAdminUIState, PlatformAdminUIThunkType } from '../../../store/store';
import Auth0DetailsView from './Auth0Details.view';
import { RouteComponentProps } from 'react-router-dom';
import { Container } from '@mui/material';
import { getAuth0User } from '../store/User.actions';
import {
	ClientOauthAccount,
	ClientOauthAccountAuthenticationMethodsEnum
} from '@DigitaleDoerfer/digitale-doerfer-api/models';
import { RelatedEntity } from '../store/User.state';
import { UserActionTypes } from '../store/UserActionTypes';
import PersonDetailFormView from '../../../shared/views/PersonDetail/PersonDetailForm.view';
import { USERS_URL } from '../UsersRouting.container';

interface Props extends RouteComponentProps {
	loading: boolean;
	selectedUser: ClientOauthAccount;
	relatedEntities: RelatedEntity[];
	getUser: (userId: string) => void;
}

interface Identifiable {
	email: string;
}

class Auth0DetailsContainer extends Component<Props> {
	componentDidMount(): void {
		const { match, getUser } = this.props;
		const id = match.params as Identifiable;
		if (id.email) {
			getUser(id.email);
		}
	}

	gotToTableHandler = (): void => {
		this.props.history.push(USERS_URL);
	};

	render(): JSX.Element {
		const { loading, selectedUser, match } = this.props;
		const { email } = match.params as Identifiable;
		const title = `Auth0-Account ${selectedUser && selectedUser.name ? selectedUser.name : email}`;
		const authenticationMethods: ClientOauthAccountAuthenticationMethodsEnum[] =
			(selectedUser &&
				selectedUser.authenticationMethods &&
				selectedUser.authenticationMethods.map(methods => methods)) ||
			[];
		return (
			<Container fixed maxWidth="md">
				<PersonDetailFormView
					title={title}
					textToCopy={selectedUser.oauthId ?? ''}
					authenticationMethods={authenticationMethods}
					goToTableHandler={this.gotToTableHandler}
				>
					<Auth0DetailsView email={email} loading={loading} selectedUser={selectedUser} />
				</PersonDetailFormView>
			</Container>
		);
	}
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: PlatformAdminUIState) => {
	return {
		loading: state.user.auth0Detail.loading,
		selectedUser: state.user.auth0Detail.auth0User
	};
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch: PlatformAdminUIThunkType<UserActionTypes>) => {
	return {
		getUser: (email: string): Promise<void> => dispatch(getAuth0User(email))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Auth0DetailsContainer);
