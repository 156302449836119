import { connect } from 'react-redux';
import React, { Component } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { Box, Link, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import Snackbar from '@mui/material/Snackbar';
import WarningIcon from '@mui/icons-material/Warning';
import IconButton from '@mui/material/IconButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SnackbarContent from '@mui/material/SnackbarContent';
import { UserActionTypes } from '../../../modules/users/store/UserActionTypes';
import { hideSnackbar, SnackbarAction, SnackbarType } from './store/SnackbarNotification.actions';
import { PlatformAdminUIState, PlatformAdminUIThunkType } from '../../../store/store';
import { SnackbarNotificationActionTypes } from './store/SnackbarNotificationActionTypes';

const variantIcon = {
	success: CheckCircleIcon,
	warning: WarningIcon,
	error: ErrorIcon,
	info: InfoIcon
};

interface Props {
	snackbarAction: SnackbarAction;
	showSnackbar: boolean;
	className?: string;
	hideSnackbar: () => void;
}

const getColor = (type?: SnackbarType): React.CSSProperties['backgroundColor'] => {
	switch (type) {
		case SnackbarType.SNACKBAR_ERROR:
			return 'error.dark';
		case SnackbarType.SNACKBAR_WARNING:
			return 'warning.main';
		case SnackbarType.SNACKBAR_SUCCESS:
			return 'success.main';
		default:
			return 'primary.main';
	}
};

class SnackbarNotificationContainerAndView extends Component<Props> {
	constructor(props: Props) {
		super(props);
		this.handleClose = this.handleClose.bind(this);
	}

	handleClose(): void {
		const { hideSnackbar } = this.props;
		hideSnackbar();
	}

	render(): JSX.Element {
		const { showSnackbar, snackbarAction } = this.props;
		const Icon = variantIcon[snackbarAction.type || SnackbarType.SNACKBAR_INFO];
		return (
			<Snackbar
				open={showSnackbar}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				autoHideDuration={snackbarAction.type === SnackbarType.SNACKBAR_ERROR ? null : 5000}
				onClose={this.handleClose}
				aria-describedby="client-snackbar"
			>
				<SnackbarContent
					sx={{ backgroundColor: getColor(snackbarAction.type) }}
					aria-describedby="client-snackbar"
					message={
						<Box
							id="client-snackbar"
							sx={{
								display: 'flex',
								alignItems: 'center',
								lineHeight: '26px',
								whiteSpace: 'pre-line',
								wordBreak: 'break-word',
								maxWidth: 600
							}}
						>
							<Icon sx={{ fontSize: 20, opacity: 0.9, marginRight: 2 }} />
							<Box
								component="span"
								sx={{
									marginTop: 1,
									marginBottom: 1,
									/* This will push the close button to the right as far as possible within the shared Material UI snackbar message container. */
									marginRight: 'auto'
								}}
							>
								{snackbarAction.url ? (
									<Link href={snackbarAction.url} color="inherit">
										{snackbarAction.message}
									</Link>
								) : (
									<Typography id="client-snackbar" variant="body2" component="span">
										{snackbarAction.message}
									</Typography>
								)}
							</Box>
							<IconButton
								key="close"
								aria-label="close"
								color="inherit"
								onClick={this.handleClose}
								sx={{ marginLeft: 1, marginRight: -1 }}
								size="large"
							>
								<CloseIcon sx={{ fontSize: 20 }} />
							</IconButton>
						</Box>
					}
				/>
			</Snackbar>
		);
	}
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: PlatformAdminUIState) => ({
	snackbarAction: state.snackbar.snackbarAction,
	showSnackbar: state.snackbar.showSnackbar
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch: PlatformAdminUIThunkType<UserActionTypes | SnackbarNotificationActionTypes>) => {
	return {
		hideSnackbar: (): Promise<void> => dispatch(hideSnackbar())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SnackbarNotificationContainerAndView);
