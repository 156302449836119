import { ThunkAction } from 'redux-thunk';
import {
	GET_GROUP_FAILURE,
	GET_GROUP_REQUEST,
	GET_GROUP_SUCCESS,
	GET_GROUPS_FAILURE,
	GET_GROUPS_REQUEST,
	GET_GROUPS_SUCCESS,
	GetGroupFailureAction,
	GetGroupRequestAction,
	GetGroupsFailureAction,
	GetGroupsRequestAction,
	GetGroupsSuccessAction,
	GetGroupSuccessAction,
	GROUP_ADD_GROUP_MEMBERSHIP_BY_ADMIN_FAILURE,
	GROUP_ADD_GROUP_MEMBERSHIP_BY_ADMIN_REQUEST,
	GROUP_ADD_GROUP_MEMBERSHIP_BY_ADMIN_SUCCESS,
	GROUP_ADD_PERSON_FAILURE,
	GROUP_ADD_PERSON_REQUEST,
	GROUP_ADD_PERSON_SUCCESS,
	GROUP_CHANGE_GROUP_ACCESSIBILITY_FAILURE,
	GROUP_CHANGE_GROUP_ACCESSIBILITY_REQUEST,
	GROUP_CHANGE_GROUP_ACCESSIBILITY_SUCCESS,
	GROUP_CHANGE_NAME_BY_ADMIN_FAILURE,
	GROUP_CHANGE_NAME_BY_ADMIN_REQUEST,
	GROUP_CHANGE_NAME_BY_ADMIN_SUCCESS,
	GROUP_CREATE_BY_ADMIN_FAILURE,
	GROUP_CREATE_BY_ADMIN_REQUEST,
	GROUP_CREATE_BY_ADMIN_SUCCESS,
	GROUP_DELETE_BY_ADMIN_FAILURE,
	GROUP_DELETE_BY_ADMIN_REQUEST,
	GROUP_DELETE_BY_ADMIN_SUCCESS,
	GROUP_MODIFY_GEO_AREAS_BY_ADMIN_FAILURE,
	GROUP_MODIFY_GEO_AREAS_BY_ADMIN_REQUEST,
	GROUP_MODIFY_GEO_AREAS_BY_ADMIN_SUCCESS,
	GROUP_REMOVE_GROUP_MEMBERSHIP_ADMIN_FAILURE,
	GROUP_REMOVE_GROUP_MEMBERSHIP_ADMIN_REQUEST,
	GROUP_REMOVE_GROUP_MEMBERSHIP_ADMIN_SUCCESS,
	GroupActionTypes,
	GroupAddGroupMembershipByAdminFailureAction,
	GroupAddGroupMembershipByAdminRequestAction,
	GroupAddGroupMembershipByAdminSuccessAction,
	GroupAddPersonFailureAction,
	GroupAddPersonRequestAction,
	GroupAddPersonSuccessAction,
	GroupChangeGroupAccessibilityFailureAction,
	GroupChangeGroupAccessibilityRequestAction,
	GroupChangeGroupAccessibilitySuccessAction,
	GroupChangeNameByAdminFailureAction,
	GroupChangeNameByAdminRequestAction,
	GroupChangeNameByAdminSuccessAction,
	GroupCreateByAdminFailureAction,
	GroupCreateByAdminRequestAction,
	GroupCreateByAdminSuccessAction,
	GroupDeleteByAdminFailureAction,
	GroupDeleteByAdminRequestAction,
	GroupDeleteByAdminSuccessAction,
	GroupModifyGeoAreasByAdminFailureAction,
	GroupModifyGeoAreasByAdminRequestAction,
	GroupModifyGeoAreasByAdminSuccessAction,
	GroupRemoveGroupMembershipAdminFailureAction,
	GroupRemoveGroupMembershipAdminRequestAction,
	GroupRemoveGroupMembershipAdminSuccessAction
} from './GroupActionTypes';
import { PlatformAdminUIState, PlatformAdminUIThunkType } from '../../../store/store';
import {
	ClientGroupAddPersonRequest,
	ClientGroupChangeNameByAdminRequest,
	ClientGroupChangeVisibilityAndAccessibilityByAdminRequest,
	ClientGroupCreateByAdminRequest,
	ClientGroupExtended,
	ClientGroupExtendedDetail
} from '@DigitaleDoerfer/digitale-doerfer-api/models';
import { grapevineAdminuiGroupApi, grapevineAdminuiGroupEventsApi } from '../../../ServiceFactory';
import { BaseError } from '../../../shared/errors/Errors';
import { wrapIntoErrorObject } from '../../../shared/errors/ErrorWrapper';
import { initialGroupState, ListSearchParams } from './Group.state';
import { Pagination } from '../../../shared/services/InMemoryTable.service';
import history from '../../../history';
import { GROUPS_URL } from '../GroupRouting.container';
import {
	showSnackbar,
	showSnackbarError,
	SnackbarType
} from '../../../shared/views/SnackbarNotification/store/SnackbarNotification.actions';
import { EmptyField } from '../../users/store/User.state';

export type ThunkResult<R> = ThunkAction<R, PlatformAdminUIState, undefined, GroupActionTypes>;

export const getGroupsRequest = (searchParams: ListSearchParams): GetGroupsRequestAction => {
	return {
		type: GET_GROUPS_REQUEST,
		searchParams
	};
};

export const getGroupsSuccess = (
	groups: ClientGroupExtended[],
	pagination: Pagination,
	tenantId: string
): GetGroupsSuccessAction => {
	return {
		type: GET_GROUPS_SUCCESS,
		groups,
		pagination,
		tenantId
	};
};

export const getGroupsFailure = (error: BaseError): GetGroupsFailureAction => {
	return {
		type: GET_GROUPS_FAILURE,
		error
	};
};

export const getGroups = (searchParams: ListSearchParams): ThunkResult<Promise<void>> => {
	return async (
		dispatch: PlatformAdminUIThunkType<GroupActionTypes>,
		getState: () => PlatformAdminUIState
	): Promise<void> => {
		const { pagination, tenantId } = searchParams;
		const { page, rowsPerPage } = pagination;
		const tenant = tenantId === EmptyField.NONE ? undefined : tenantId;
		try {
			dispatch(getGroupsRequest(searchParams));

			const response = await grapevineAdminuiGroupApi().getGroupsUsingGET({
				tenantId: tenant,
				page,
				count: rowsPerPage
			});

			// Prepate data to dispatch getGroupsSuccess
			const groups = response.content ?? [];
			const pagination: Pagination = {
				page: response.number ?? 0,
				rowsPerPage: response.size ?? initialGroupState.list.searchParams.pagination.rowsPerPage,
				total: response.totalElements ?? 0
			};
			dispatch(getGroupsSuccess(groups, pagination, tenantId));
		} catch (error) {
			dispatch(showSnackbarError(error));
			dispatch(getGroupsFailure(wrapIntoErrorObject(error)));
		}
	};
};

export const getGroupRequest = (groupId: string): GetGroupRequestAction => {
	return {
		type: GET_GROUP_REQUEST,
		groupId
	};
};

export const getGroupSuccess = (group: ClientGroupExtendedDetail): GetGroupSuccessAction => {
	return {
		type: GET_GROUP_SUCCESS,
		group
	};
};

export const getGroupFailure = (error: BaseError): GetGroupFailureAction => {
	return {
		type: GET_GROUP_FAILURE,
		error
	};
};

export const getGroup = (groupId: string): ThunkResult<Promise<void>> => {
	return async (dispatch: PlatformAdminUIThunkType<GroupActionTypes>): Promise<void> => {
		try {
			dispatch(getGroupRequest(groupId));

			const group: ClientGroupExtendedDetail = await grapevineAdminuiGroupApi().getGroupByIdUsingGET({ groupId });

			dispatch(getGroupSuccess(group));
		} catch (error) {
			history.push(GROUPS_URL);
			dispatch(showSnackbarError(error));
			dispatch(getGroupFailure(wrapIntoErrorObject(error)));
		}
	};
};

export const groupAddGroupMembershipByAdminRequest = (
	groupId: string,
	membershipAdminIdToAdd: string
): GroupAddGroupMembershipByAdminRequestAction => {
	return {
		type: GROUP_ADD_GROUP_MEMBERSHIP_BY_ADMIN_REQUEST,
		groupId,
		membershipAdminIdToAdd
	};
};

export const groupAddGroupMembershipByAdminSuccess = (
	updatedGroup: ClientGroupExtendedDetail
): GroupAddGroupMembershipByAdminSuccessAction => {
	return {
		type: GROUP_ADD_GROUP_MEMBERSHIP_BY_ADMIN_SUCCESS,
		updatedGroup
	};
};

export const groupAddGroupMembershipByAdminFailure = (
	error: BaseError
): GroupAddGroupMembershipByAdminFailureAction => {
	return {
		type: GROUP_ADD_GROUP_MEMBERSHIP_BY_ADMIN_FAILURE,
		error
	};
};

export const groupAddGroupMembershipAdmin = (
	groupId: string,
	membershipAdminIdToAdd: string
): ThunkResult<Promise<void>> => {
	return async (
		dispatch: PlatformAdminUIThunkType<GroupActionTypes>,
		getState: () => PlatformAdminUIState
	): Promise<void> => {
		try {
			dispatch(groupAddGroupMembershipByAdminRequest(groupId, membershipAdminIdToAdd));

			const response = await grapevineAdminuiGroupEventsApi().onGroupAddGroupMembershipAdminRequestUsingPOST({
				request: { groupId, membershipAdminIdToAdd }
			});

			const temporalFakeUpdatedGroup: ClientGroupExtendedDetail = Object.create({}); // TODO remove when DD-10946 is resolved
			const updatedGroup: ClientGroupExtendedDetail = response.updatedGroup ?? temporalFakeUpdatedGroup;

			dispatch(groupAddGroupMembershipByAdminSuccess(updatedGroup));
		} catch (error) {
			dispatch(showSnackbarError(error));
			dispatch(groupAddGroupMembershipByAdminFailure(wrapIntoErrorObject(error)));
		}
	};
};

export const groupAddPersonRequest = (
	clientGroupAddPersonRequest: ClientGroupAddPersonRequest
): GroupAddPersonRequestAction => {
	return {
		type: GROUP_ADD_PERSON_REQUEST,
		clientGroupAddPersonRequest
	};
};

export const groupAddPersonSuccess = (updatedGroup: ClientGroupExtended): GroupAddPersonSuccessAction => {
	return {
		type: GROUP_ADD_PERSON_SUCCESS,
		updatedGroup
	};
};

export const groupAddPersonFailure = (error: BaseError): GroupAddPersonFailureAction => {
	return {
		type: GROUP_ADD_PERSON_FAILURE,
		error
	};
};

export const groupAddPerson = (
	clientGroupAddPersonRequest: ClientGroupAddPersonRequest
): ThunkResult<Promise<void>> => {
	return async (
		dispatch: PlatformAdminUIThunkType<GroupActionTypes>,
		getState: () => PlatformAdminUIState
	): Promise<void> => {
		try {
			dispatch(groupAddPersonRequest(clientGroupAddPersonRequest));

			const response = await grapevineAdminuiGroupEventsApi().onGroupAddPersonRequestUsingPOST({
				request: clientGroupAddPersonRequest
			});
			const temporalFakeUpdatedGroup: ClientGroupExtended = Object.create({}); // TODO remove when DD-10946 is resolved
			const updatedGroup: ClientGroupExtended = response.updatedGroup ?? temporalFakeUpdatedGroup;

			dispatch(groupAddPersonSuccess(updatedGroup));
		} catch (error) {
			dispatch(showSnackbarError(error));
			dispatch(groupAddPersonFailure(wrapIntoErrorObject(error)));
		}
	};
};

export const groupChangeNameByAdminRequest = (
	clientGroupChangeNameByAdminRequest: ClientGroupChangeNameByAdminRequest
): GroupChangeNameByAdminRequestAction => {
	return {
		type: GROUP_CHANGE_NAME_BY_ADMIN_REQUEST,
		clientGroupChangeNameByAdminRequest
	};
};

export const groupChangeNameByAdminSuccess = (
	updatedGroup: ClientGroupExtended
): GroupChangeNameByAdminSuccessAction => {
	return {
		type: GROUP_CHANGE_NAME_BY_ADMIN_SUCCESS,
		updatedGroup
	};
};

export const groupChangeNameByAdminFailure = (error: BaseError): GroupChangeNameByAdminFailureAction => {
	return {
		type: GROUP_CHANGE_NAME_BY_ADMIN_FAILURE,
		error
	};
};

export const groupChangeNameByAdmin = (
	clientGroupChangeNameByAdminRequest: ClientGroupChangeNameByAdminRequest
): ThunkResult<Promise<void>> => {
	return async (
		dispatch: PlatformAdminUIThunkType<GroupActionTypes>,
		getState: () => PlatformAdminUIState
	): Promise<void> => {
		try {
			dispatch(groupChangeNameByAdminRequest(clientGroupChangeNameByAdminRequest));

			const response = await grapevineAdminuiGroupEventsApi().onGroupChangeNameByAdminRequestUsingPOST({
				request: clientGroupChangeNameByAdminRequest
			});
			const temporalFakeUpdatedGroup: ClientGroupExtended = Object.create({}); // TODO remove when DD-10946 is resolved
			const updatedGroup: ClientGroupExtended = response.updatedGroup ?? temporalFakeUpdatedGroup;

			dispatch(
				showSnackbar({
					type: SnackbarType.SNACKBAR_SUCCESS,
					message: 'Die Gruppe wurde erfolgreich umbenannt'
				})
			);
			dispatch(groupChangeNameByAdminSuccess(updatedGroup));
		} catch (error) {
			dispatch(showSnackbarError(error));
			dispatch(groupChangeNameByAdminFailure(wrapIntoErrorObject(error)));
		}
	};
};

export const groupCreateByAdminRequest = (
	clientGroupCreateByAdminRequest: ClientGroupCreateByAdminRequest
): GroupCreateByAdminRequestAction => {
	return {
		type: GROUP_CREATE_BY_ADMIN_REQUEST,
		clientGroupCreateByAdminRequest
	};
};

export const groupCreateByAdminSuccess = (createdGroup: ClientGroupExtendedDetail): GroupCreateByAdminSuccessAction => {
	return {
		type: GROUP_CREATE_BY_ADMIN_SUCCESS,
		createdGroup
	};
};

export const groupCreateByAdminFailure = (error: BaseError): GroupCreateByAdminFailureAction => {
	return {
		type: GROUP_CREATE_BY_ADMIN_FAILURE,
		error
	};
};

export const groupCreateByAdmin = (
	clientGroupCreateByAdminRequest: ClientGroupCreateByAdminRequest
): ThunkResult<Promise<void>> => {
	return async (
		dispatch: PlatformAdminUIThunkType<GroupActionTypes>,
		getState: () => PlatformAdminUIState
	): Promise<void> => {
		try {
			dispatch(groupCreateByAdminRequest(clientGroupCreateByAdminRequest));

			const response = await grapevineAdminuiGroupEventsApi().onGroupCreateByAdminRequestUsingPOST({
				request: clientGroupCreateByAdminRequest
			});

			const temporalFakeCreatedGroup: ClientGroupExtendedDetail = Object.create({}); // TODO remove when DD-10946 is resolved
			const createdGroup: ClientGroupExtendedDetail = response.createdGroup ?? temporalFakeCreatedGroup;

			dispatch(groupCreateByAdminSuccess(createdGroup));
			dispatch(
				showSnackbar({
					type: SnackbarType.SNACKBAR_SUCCESS,
					message: 'Eine neue Gruppe wurde erfolgreich angelegt.',
					url: 'details/' + createdGroup.id
				})
			);
		} catch (error) {
			dispatch(showSnackbarError(error));
			dispatch(groupCreateByAdminFailure(wrapIntoErrorObject(error)));
		}
	};
};

export const groupDeleteByAdminRequest = (
	forceDeleteNonEmptyGroup: boolean,
	groupId: string
): GroupDeleteByAdminRequestAction => {
	return {
		type: GROUP_DELETE_BY_ADMIN_REQUEST,
		forceDeleteNonEmptyGroup,
		groupId
	};
};

export const groupDeleteByAdminSuccess = (
	deletedMemberCount: number,
	groupId: string
): GroupDeleteByAdminSuccessAction => {
	return {
		type: GROUP_DELETE_BY_ADMIN_SUCCESS,
		deletedMemberCount,
		groupId
	};
};

export const groupDeleteByAdminFailure = (error: BaseError): GroupDeleteByAdminFailureAction => {
	return {
		type: GROUP_DELETE_BY_ADMIN_FAILURE,
		error
	};
};

export const groupDeleteByAdmin = (forceDeleteNonEmptyGroup: boolean, groupId: string): ThunkResult<Promise<void>> => {
	return async (
		dispatch: PlatformAdminUIThunkType<GroupActionTypes>,
		getState: () => PlatformAdminUIState
	): Promise<void> => {
		try {
			dispatch(groupDeleteByAdminRequest(forceDeleteNonEmptyGroup, groupId));

			const response = await grapevineAdminuiGroupEventsApi().onGroupDeleteByAdminRequestUsingPOST({
				request: { forceDeleteNonEmptyGroup, groupId }
			});

			dispatch(groupDeleteByAdminSuccess(response.deletedMemberCount ?? 0, groupId)); // TODO remove when DD-10946 is resolved
			dispatch(
				showSnackbar({
					type: SnackbarType.SNACKBAR_SUCCESS,
					message: 'Die Gruppe wurde entfernt.'
				})
			);
			// go back to table since this group is deleted
			history.push(GROUPS_URL);
		} catch (error) {
			dispatch(showSnackbarError(error));
			dispatch(groupDeleteByAdminFailure(wrapIntoErrorObject(error)));
		}
	};
};

export const groupModifyGeoAreasByAdminRequest = (
	groupId: string,
	newGeoAreaIds: string[]
): GroupModifyGeoAreasByAdminRequestAction => {
	return {
		type: GROUP_MODIFY_GEO_AREAS_BY_ADMIN_REQUEST,
		groupId,
		newGeoAreaIds
	};
};

export const groupModifyGeoAreasByAdminSuccess = (
	updatedGroup: ClientGroupExtended
): GroupModifyGeoAreasByAdminSuccessAction => {
	return {
		type: GROUP_MODIFY_GEO_AREAS_BY_ADMIN_SUCCESS,
		updatedGroup
	};
};

export const groupModifyGeoAreasByAdminFailure = (error: BaseError): GroupModifyGeoAreasByAdminFailureAction => {
	return {
		type: GROUP_MODIFY_GEO_AREAS_BY_ADMIN_FAILURE,
		error
	};
};

export const groupModifyGeoAreasByAdmin = (groupId: string, newGeoAreaIds: string[]): ThunkResult<Promise<void>> => {
	return async (
		dispatch: PlatformAdminUIThunkType<GroupActionTypes>,
		getState: () => PlatformAdminUIState
	): Promise<void> => {
		try {
			dispatch(groupModifyGeoAreasByAdminRequest(groupId, newGeoAreaIds));

			const response = await grapevineAdminuiGroupEventsApi().onGroupModifyGeoAreasByAdminRequestUsingPOST({
				request: { groupId, includedGeoAreaIds: newGeoAreaIds }
			});

			const temporalFakeCreatedGroup: ClientGroupExtended = Object.create({}); // TODO remove when DD-10946 is resolved
			const updatedGroup: ClientGroupExtended = response.updatedGroup ?? temporalFakeCreatedGroup;

			dispatch(groupModifyGeoAreasByAdminSuccess(updatedGroup));

			dispatch(
				showSnackbar({
					type: SnackbarType.SNACKBAR_SUCCESS,
					message: 'Die Änderungen wurden gespeichert.'
				})
			);
		} catch (error) {
			dispatch(showSnackbarError(error));
			dispatch(groupModifyGeoAreasByAdminFailure(wrapIntoErrorObject(error)));
		}
	};
};

export const groupRemoveGroupMembershipAdminRequest = (
	groupId: string,
	membershipAdminIdToRemove: string
): GroupRemoveGroupMembershipAdminRequestAction => {
	return {
		type: GROUP_REMOVE_GROUP_MEMBERSHIP_ADMIN_REQUEST,
		groupId,
		membershipAdminIdToRemove
	};
};

export const groupRemoveGroupMembershipAdminSuccess = (
	updatedGroup: ClientGroupExtendedDetail
): GroupRemoveGroupMembershipAdminSuccessAction => {
	return {
		type: GROUP_REMOVE_GROUP_MEMBERSHIP_ADMIN_SUCCESS,
		updatedGroup
	};
};

export const groupRemoveGroupMembershipAdminFailure = (
	error: BaseError
): GroupRemoveGroupMembershipAdminFailureAction => {
	return {
		type: GROUP_REMOVE_GROUP_MEMBERSHIP_ADMIN_FAILURE,
		error
	};
};

export const groupRemoveGroupMembershipAdmin = (
	groupId: string,
	membershipAdminIdToRemove: string
): ThunkResult<Promise<void>> => {
	return async (
		dispatch: PlatformAdminUIThunkType<GroupActionTypes>,
		getState: () => PlatformAdminUIState
	): Promise<void> => {
		try {
			dispatch(groupRemoveGroupMembershipAdminRequest(groupId, membershipAdminIdToRemove));

			const response = await grapevineAdminuiGroupEventsApi().onGroupRemoveGroupMembershipAdminRequestUsingPOST({
				request: { groupId, membershipAdminIdToRemove }
			});

			const temporalFakeCreatedGroup: ClientGroupExtendedDetail = Object.create({}); // TODO remove when DD-10946 is resolved
			const updatedGroup: ClientGroupExtendedDetail = response.updatedGroup ?? temporalFakeCreatedGroup;

			dispatch(groupRemoveGroupMembershipAdminSuccess(updatedGroup));
		} catch (error) {
			dispatch(showSnackbarError(error));
			dispatch(groupRemoveGroupMembershipAdminFailure(wrapIntoErrorObject(error)));
		}
	};
};

export const groupChangeAccessibiltyRequest = (
	clientGroupChangeVisibilityAndAccessibilityByAdminRequest: ClientGroupChangeVisibilityAndAccessibilityByAdminRequest
): GroupChangeGroupAccessibilityRequestAction => {
	return {
		type: GROUP_CHANGE_GROUP_ACCESSIBILITY_REQUEST,
		clientGroupChangeVisibilityAndAccessibilityByAdminRequest
	};
};

export const groupChangeAccessibiltySuccess = (
	updatedGroup: ClientGroupExtended
): GroupChangeGroupAccessibilitySuccessAction => {
	return {
		type: GROUP_CHANGE_GROUP_ACCESSIBILITY_SUCCESS,
		updatedGroup
	};
};

export const groupChangeAccessibiltyFailure = (error: BaseError): GroupChangeGroupAccessibilityFailureAction => {
	return {
		type: GROUP_CHANGE_GROUP_ACCESSIBILITY_FAILURE,
		error
	};
};

export const groupChangeAccessibilty = (
	clientGroupChangeVisibilityAndAccessibilityByAdminRequest: ClientGroupChangeVisibilityAndAccessibilityByAdminRequest
): ThunkResult<Promise<void>> => {
	return async (
		dispatch: PlatformAdminUIThunkType<GroupActionTypes>,
		getState: () => PlatformAdminUIState
	): Promise<void> => {
		try {
			dispatch(groupChangeAccessibiltyRequest(clientGroupChangeVisibilityAndAccessibilityByAdminRequest));

			const response =
				await grapevineAdminuiGroupEventsApi().onGroupChangeVisibilityAndAccessibilityByAdminRequestUsingPOST({
					request: clientGroupChangeVisibilityAndAccessibilityByAdminRequest
				});

			const updatedGroup: ClientGroupExtended = response.updatedGroup;

			dispatch(groupChangeAccessibiltySuccess(updatedGroup));

			dispatch(
				showSnackbar({
					type: SnackbarType.SNACKBAR_SUCCESS,
					message: 'Zugangsbeschränkung für Gruppe wurde geändert'
				})
			);
		} catch (error) {
			dispatch(showSnackbarError(error));
			dispatch(groupChangeAccessibiltyFailure(wrapIntoErrorObject(error)));
		}
	};
};
