import {
	BLOCK_LOGIN_BY_ADMIN_FAILURE,
	BLOCK_LOGIN_BY_ADMIN_REQUEST,
	BLOCK_LOGIN_BY_ADMIN_SUCCESS,
	BlockLoginByAdminFailureAction,
	BlockLoginByAdminRequestAction,
	BlockLoginByAdminSuccessAction,
	CANCEL_CHANGE_EMAIL_FAILURE,
	CANCEL_CHANGE_EMAIL_REQUEST,
	CANCEL_CHANGE_EMAIL_SUCCESS,
	CancelChangeEmailFailureAction,
	CancelChangeEmailRequestAction,
	CancelChangeEmailSuccessAction,
	CHANGE_EMAIL_ADDRESS_BY_ADMIN_FAILURE,
	CHANGE_EMAIL_ADDRESS_BY_ADMIN_REQUEST,
	CHANGE_EMAIL_ADDRESS_BY_ADMIN_SUCCESS,
	CHANGE_NAME_BY_ADMIN_FAILURE,
	CHANGE_NAME_BY_ADMIN_REQUEST,
	CHANGE_NAME_BY_ADMIN_SUCCESS,
	CHANGE_STATUS_BY_ADMIN_FAILURE,
	CHANGE_STATUS_BY_ADMIN_REQUEST,
	CHANGE_STATUS_BY_ADMIN_SUCCESS,
	ChangeEmailAddressByAdminFailureAction,
	ChangeEmailAddressByAdminRequestAction,
	ChangeEmailAddressByAdminSuccessAction,
	ChangeNameByAdminFailureAction,
	ChangeNameByAdminRequestAction,
	ChangeNameByAdminSuccessAction,
	ChangeStatusByAdminFailureAction,
	ChangeStatusByAdminRequestAction,
	ChangeStatusByAdminSuccessAction,
	CREATE_USER_FAILURE,
	CREATE_USER_REQUEST,
	CREATE_USER_SUCCESS,
	CreateUserFailureAction,
	CreateUserRequestAction,
	CreateUserSuccessAction,
	DELETE_USER_BY_ADMIN_FAILURE,
	DELETE_USER_BY_ADMIN_REQUEST,
	DELETE_USER_BY_ADMIN_SUCCESS,
	DeleteUserByAdminFailureAction,
	DeleteUserByAdminRequestAction,
	DeleteUserByAdminSuccessAction,
	GET_AUTH0USER_FAILURE,
	GET_AUTH0USER_REQUEST,
	GET_AUTH0USER_SUCCESS,
	GET_USER_FAILURE,
	GET_USER_REQUEST,
	GET_USER_SUCCESS,
	GET_USERS_BY_ROLE_FAILURE,
	GET_USERS_BY_ROLE_REQUEST,
	GET_USERS_BY_ROLE_SUCCESS,
	GET_USERS_BY_TEXT_FAILURE,
	GET_USERS_BY_TEXT_REQUEST,
	GET_USERS_BY_TEXT_SUCCESS,
	GetAuth0UserFailureAction,
	GetAuth0UserRequestAction,
	GetAuth0UserSuccessAction,
	GetUserFailureAction,
	GetUserRequestAction,
	GetUsersByRoleFailureAction,
	GetUsersByRoleRequestAction,
	GetUsersByRoleSuccessAction,
	GetUsersByTextFailureAction,
	GetUsersByTextRequestAction,
	GetUsersByTextSuccessAction,
	GetUserSuccessAction,
	REMOVE_ROLE_ASSIGNMENT_FAILURE,
	REMOVE_ROLE_ASSIGNMENT_REQUEST,
	REMOVE_ROLE_ASSIGNMENT_SUCCESS,
	RemoveRoleAssignmentFailureAction,
	RemoveRoleAssignmentRequestAction,
	RemoveRoleAssignmentSuccessAction,
	RESEND_VERIFICATION_MAIL_BY_ADMIN_FAILURE,
	RESEND_VERIFICATION_MAIL_BY_ADMIN_REQUEST,
	RESEND_VERIFICATION_MAIL_BY_ADMIN_SUCCESS,
	ResendVerificationMailByAdminFailureAction,
	ResendVerificationMailByAdminRequestAction,
	ResendVerificationMailByAdminSuccessAction,
	RESET_PASSWORD_BY_ADMIN_FAILURE,
	RESET_PASSWORD_BY_ADMIN_REQUEST,
	RESET_PASSWORD_BY_ADMIN_SUCCESS,
	ResetPasswordByAdminFailureAction,
	ResetPasswordByAdminRequestAction,
	ResetPasswordByAdminSuccessAction,
	ROLE_ASSIGNMENT_FAILURE,
	ROLE_ASSIGNMENT_REQUEST,
	ROLE_ASSIGNMENT_SUCCESS,
	RoleAssignmentFailureAction,
	RoleAssignmentRequestAction,
	RoleAssignmentSuccessAction,
	SET_EMAIL_VERIFIED_BY_ADMIN_FAILURE,
	SET_EMAIL_VERIFIED_BY_ADMIN_REQUEST,
	SET_EMAIL_VERIFIED_BY_ADMIN_SUCCESS,
	SetEmailVerifiedByAdminFailureAction,
	SetEmailVerifiedByAdminRequestAction,
	SetEmailVerifiedByAdminSuccessAction,
	UNBLOCK_AUTOMATICALLY_BLOCKED_LOGIN_BY_ADMIN_FAILURE,
	UNBLOCK_AUTOMATICALLY_BLOCKED_LOGIN_BY_ADMIN_REQUEST,
	UNBLOCK_AUTOMATICALLY_BLOCKED_LOGIN_BY_ADMIN_SUCCESS,
	UNBLOCK_LOGIN_BY_ADMIN_FAILURE,
	UNBLOCK_LOGIN_BY_ADMIN_REQUEST,
	UNBLOCK_LOGIN_BY_ADMIN_SUCCESS,
	UnblockAutomaticallyBlockedLoginByAdminFailureAction,
	UnblockAutomaticallyBlockedLoginByAdminRequestAction,
	UnblockAutomaticallyBlockedLoginByAdminSuccessAction,
	UnblockLoginByAdminFailureAction,
	UnblockLoginByAdminRequestAction,
	UnblockLoginByAdminSuccessAction,
	UserActionTypes
} from './UserActionTypes';
import { EmptyField, initialUserState, UserState } from './User.state';
import { ClientPersonExtendedVerificationStatusesEnum } from '@DigitaleDoerfer/digitale-doerfer-api/models';

const getUsersByTextRequest = (state: UserState, action: GetUsersByTextRequestAction): UserState => {
	return {
		...state,
		list: {
			...state.list,
			error: null,
			loading: true
		}
	};
};

const getUsersByTextSuccess = (state: UserState, action: GetUsersByTextSuccessAction): UserState => {
	return {
		...state,
		list: {
			...state.list,
			users: action.users,
			searchParams: {
				...state.list.searchParams,
				text: action.text,
				role: EmptyField.NONE,
				pagination: action.pagination,
				sorting: action.sorting
			},
			loading: false
		}
	};
};

const getUsersByTextFailure = (state: UserState, action: GetUsersByTextFailureAction): UserState => {
	return {
		...state,
		list: {
			...state.list,
			error: action.error,
			loading: false
		}
	};
};

const getUserRequest = (state: UserState, action: GetUserRequestAction): UserState => {
	return {
		...state,
		detail: {
			...state.detail,
			user: { id: '' },
			loading: true
		}
	};
};

const getUserSuccess = (state: UserState, action: GetUserSuccessAction): UserState => {
	return {
		...state,
		detail: {
			...state.detail,
			user: action.user,
			loading: false
		}
	};
};

const getUserFailure = (state: UserState, action: GetUserFailureAction): UserState => {
	return {
		...state,
		detail: {
			...state.detail,
			loading: false
		}
	};
};

const createUserRequest = (state: UserState, action: CreateUserRequestAction): UserState => {
	return {
		...state,
		create: {
			...state.create,
			error: null,
			loading: true,
			userHasBeenCreated: false
		}
	};
};

const createUserSuccess = (state: UserState, action: CreateUserSuccessAction): UserState => {
	return {
		...state,
		create: {
			...state.create,
			loading: false,
			userHasBeenCreated: true
		}
	};
};

const createUserFailure = (state: UserState, action: CreateUserFailureAction): UserState => {
	return {
		...state,
		create: {
			...state.create,
			error: action.error,
			loading: false,
			userHasBeenCreated: false
		}
	};
};

const roleAssignmentRequest = (state: UserState, action: RoleAssignmentRequestAction): UserState => {
	return {
		...state,
		detail: {
			...state.detail,
			loadingRoleAssignments: true
		}
	};
};

const roleAssignmentSuccess = (state: UserState, action: RoleAssignmentSuccessAction): UserState => {
	const roleAssignments = state.detail.user?.roleAssignments ? state.detail.user.roleAssignments : [];
	const roleAlreadyExits = roleAssignments.some(role => role.id === action.clientRoleAssignment.id);
	if (!roleAlreadyExits) {
		return {
			...state,
			detail: {
				...state.detail,
				loadingRoleAssignments: false,
				user: state.detail.user
					? {
							...state.detail.user,
							roleAssignments: [
								...roleAssignments.slice(0, 0),
								action.clientRoleAssignment,
								...roleAssignments.slice(0)
							]
					  }
					: null
			}
		};
	}
	return {
		...state,
		detail: {
			...state.detail,
			loadingRoleAssignments: false
		}
	};
};

const roleAssignmentFailure = (state: UserState, action: RoleAssignmentFailureAction): UserState => {
	return {
		...state,
		detail: {
			...state.detail,
			loadingRoleAssignments: false
		}
	};
};

const removeRoleAssignmentRequest = (state: UserState, action: RemoveRoleAssignmentRequestAction): UserState => {
	return {
		...state,
		detail: {
			...state.detail,
			loadingRoleAssignments: true
		}
	};
};

const removeRoleAssignmentSuccess = (state: UserState, action: RemoveRoleAssignmentSuccessAction): UserState => {
	const roleAssignment = state.detail.user?.roleAssignments ? state.detail.user.roleAssignments : [];
	const deleteIndexRole = roleAssignment.findIndex(role => role.id === action.roleAssignmentId);
	return {
		...state,
		detail: {
			...state.detail,
			loadingRoleAssignments: false,
			user: state.detail.user
				? {
						...state.detail.user,
						roleAssignments: [...roleAssignment.slice(0, deleteIndexRole), ...roleAssignment.slice(deleteIndexRole + 1)]
				  }
				: null
		}
	};
};

const removeRoleAssignmentFailure = (state: UserState, action: RemoveRoleAssignmentFailureAction): UserState => {
	return {
		...state,
		detail: {
			...state.detail,
			loadingRoleAssignments: false
		}
	};
};

const getAuth0UserRequest = (state: UserState, action: GetAuth0UserRequestAction): UserState => {
	return {
		...state,
		auth0Detail: { ...state.auth0Detail, loading: true, auth0User: {} }
	};
};

const getAuth0UserSuccess = (state: UserState, action: GetAuth0UserSuccessAction): UserState => {
	return {
		...state,
		auth0Detail: { ...state.auth0Detail, loading: false, auth0User: action.user }
	};
};

const getAuth0UserFailure = (state: UserState, action: GetAuth0UserFailureAction): UserState => {
	return {
		...state,
		auth0Detail: { ...state.auth0Detail, loading: false }
	};
};

const getUsersByRoleRequest = (state: UserState, action: GetUsersByRoleRequestAction): UserState => {
	return {
		...state,
		list: {
			...state.list,
			error: null,
			loading: true
		}
	};
};

const getUsersByRoleSuccess = (state: UserState, action: GetUsersByRoleSuccessAction): UserState => {
	return {
		...state,
		list: {
			...state.list,
			users: action.users,
			searchParams: {
				...state.list.searchParams,
				text: '',
				role: action.role,
				sorting: action.sorting,
				pagination: action.pagination
			},
			loading: false
		}
	};
};

const getUsersByRoleFailure = (state: UserState, action: GetUsersByRoleFailureAction): UserState => {
	return {
		...state,
		list: {
			...state.list,
			error: action.error,
			loading: false
		}
	};
};

const changeEmailAddressByAdminRequest = (
	state: UserState,
	action: ChangeEmailAddressByAdminRequestAction
): UserState => {
	return { ...state };
};

const changeEmailAddressByAdminSuccess = (
	state: UserState,
	action: ChangeEmailAddressByAdminSuccessAction
): UserState => {
	return {
		...state,
		detail: { ...state.detail, user: action.user }
	};
};

const changeEmailAddressByAdminFailure = (
	state: UserState,
	action: ChangeEmailAddressByAdminFailureAction
): UserState => {
	return { ...state };
};

const changeNameByAdminRequest = (state: UserState, action: ChangeNameByAdminRequestAction): UserState => {
	return { ...state };
};

const changeNameByAdminSuccess = (state: UserState, action: ChangeNameByAdminSuccessAction): UserState => {
	return {
		...state,
		detail: { ...state.detail, user: action.user }
	};
};

const changeNameByAdminFailure = (state: UserState, action: ChangeNameByAdminFailureAction): UserState => {
	return { ...state };
};

const blockLoginByAdminRequest = (state: UserState, action: BlockLoginByAdminRequestAction): UserState => {
	return { ...state };
};

const blockLoginByAdminSuccess = (state: UserState, action: BlockLoginByAdminSuccessAction): UserState => {
	return {
		...state,
		detail: { ...state.detail, user: action.user }
	};
};

const blockLoginByAdminFailure = (state: UserState, action: BlockLoginByAdminFailureAction): UserState => {
	return { ...state };
};

const unblockLoginByAdminRequest = (state: UserState, action: UnblockLoginByAdminRequestAction): UserState => {
	return { ...state };
};

const unblockLoginByAdminSuccess = (state: UserState, action: UnblockLoginByAdminSuccessAction): UserState => {
	return {
		...state,
		detail: { ...state.detail, user: action.user }
	};
};

const unblockLoginByAdminFailure = (state: UserState, action: UnblockLoginByAdminFailureAction): UserState => {
	return { ...state };
};

const unblockAutomaticallyBlockedLoginByAdminRequest = (
	state: UserState,
	action: UnblockAutomaticallyBlockedLoginByAdminRequestAction
): UserState => {
	return { ...state };
};

const unblockAutomaticallyBlockedLoginByAdminSuccess = (
	state: UserState,
	action: UnblockAutomaticallyBlockedLoginByAdminSuccessAction
): UserState => {
	return {
		...state,
		detail: { ...state.detail, user: action.user }
	};
};

const unblockAutomaticallyBlockedLoginByAdminFailure = (
	state: UserState,
	action: UnblockAutomaticallyBlockedLoginByAdminFailureAction
): UserState => {
	return { ...state };
};

const resendVerificationMailByAdminRequest = (
	state: UserState,
	action: ResendVerificationMailByAdminRequestAction
): UserState => {
	return { ...state };
};

const resendVerificationMailByAdminSuccess = (
	state: UserState,
	action: ResendVerificationMailByAdminSuccessAction
): UserState => {
	return { ...state };
};

const resendVerificationMailByAdminFailure = (
	state: UserState,
	action: ResendVerificationMailByAdminFailureAction
): UserState => {
	return { ...state };
};

const resetPasswordByAdminRequest = (state: UserState, action: ResetPasswordByAdminRequestAction): UserState => {
	return { ...state };
};

const resetPasswordByAdminSuccess = (state: UserState, action: ResetPasswordByAdminSuccessAction): UserState => {
	return { ...state };
};

const resetPasswordByAdminFailure = (state: UserState, action: ResetPasswordByAdminFailureAction): UserState => {
	return { ...state };
};

const setEmailVerifiedByAdminRequest = (state: UserState, action: SetEmailVerifiedByAdminRequestAction): UserState => {
	return { ...state };
};

const setEmailVerifiedByAdminSuccess = (state: UserState, action: SetEmailVerifiedByAdminSuccessAction): UserState => {
	return {
		...state,
		detail: {
			...state.detail,
			user: state.detail.user
				? {
						...state.detail.user,
						pendingNewEmail: undefined,
						email: action.verifiedEmail,
						verificationStatuses: [
							...(state.detail.user.verificationStatuses ?? []),
							ClientPersonExtendedVerificationStatusesEnum.EMAILVERIFIED
						]
				  }
				: null
		}
	};
};

const setEmailVerifiedByAdminFailure = (state: UserState, action: SetEmailVerifiedByAdminFailureAction): UserState => {
	return { ...state };
};

const deleteUserByAdminRequest = (state: UserState, action: DeleteUserByAdminRequestAction): UserState => {
	return { ...state };
};

const deleteUserByAdminSuccess = (state: UserState, action: DeleteUserByAdminSuccessAction): UserState => {
	return { ...state };
};

const deleteUserByAdminFailure = (state: UserState, action: DeleteUserByAdminFailureAction): UserState => {
	return { ...state };
};

const changeStatusByAdminRequest = (state: UserState, action: ChangeStatusByAdminRequestAction): UserState => {
	return { ...state };
};

const changeStatusByAdminSuccess = (state: UserState, action: ChangeStatusByAdminSuccessAction): UserState => {
	return {
		...state,
		detail: {
			...state.detail,
			user: state.detail.user
				? {
						...state.detail.user,
						statuses: action.user.statuses
				  }
				: null
		}
	};
};

const changeStatusByAdminFailure = (state: UserState, action: ChangeStatusByAdminFailureAction): UserState => {
	return { ...state };
};

const cancelChangeEmailRequest = (state: UserState, action: CancelChangeEmailRequestAction): UserState => {
	return { ...state };
};

const cancelChangeEmailSuccess = (state: UserState, action: CancelChangeEmailSuccessAction): UserState => {
	return {
		...state,
		detail: {
			...state.detail,
			user: state.detail.user
				? {
						...state.detail.user,
						pendingNewEmail: undefined
				  }
				: null
		}
	};
};

const cancelChangeEmailFailure = (state: UserState, action: CancelChangeEmailFailureAction): UserState => {
	return { ...state };
};

const reducer = (state = initialUserState, action: UserActionTypes): UserState => {
	switch (action.type) {
		case GET_USERS_BY_TEXT_REQUEST:
			return getUsersByTextRequest(state, action);
		case GET_USERS_BY_TEXT_SUCCESS:
			return getUsersByTextSuccess(state, action);
		case GET_USERS_BY_TEXT_FAILURE:
			return getUsersByTextFailure(state, action);
		case GET_USER_REQUEST:
			return getUserRequest(state, action);
		case GET_USER_SUCCESS:
			return getUserSuccess(state, action);
		case GET_USER_FAILURE:
			return getUserFailure(state, action);
		case CREATE_USER_REQUEST:
			return createUserRequest(state, action);
		case CREATE_USER_SUCCESS:
			return createUserSuccess(state, action);
		case CREATE_USER_FAILURE:
			return createUserFailure(state, action);
		case ROLE_ASSIGNMENT_REQUEST:
			return roleAssignmentRequest(state, action);
		case ROLE_ASSIGNMENT_SUCCESS:
			return roleAssignmentSuccess(state, action);
		case ROLE_ASSIGNMENT_FAILURE:
			return roleAssignmentFailure(state, action);
		case REMOVE_ROLE_ASSIGNMENT_REQUEST:
			return removeRoleAssignmentRequest(state, action);
		case REMOVE_ROLE_ASSIGNMENT_SUCCESS:
			return removeRoleAssignmentSuccess(state, action);
		case REMOVE_ROLE_ASSIGNMENT_FAILURE:
			return removeRoleAssignmentFailure(state, action);
		case GET_AUTH0USER_REQUEST:
			return getAuth0UserRequest(state, action);
		case GET_AUTH0USER_SUCCESS:
			return getAuth0UserSuccess(state, action);
		case GET_AUTH0USER_FAILURE:
			return getAuth0UserFailure(state, action);
		case GET_USERS_BY_ROLE_REQUEST:
			return getUsersByRoleRequest(state, action);
		case GET_USERS_BY_ROLE_SUCCESS:
			return getUsersByRoleSuccess(state, action);
		case GET_USERS_BY_ROLE_FAILURE:
			return getUsersByRoleFailure(state, action);
		case CHANGE_EMAIL_ADDRESS_BY_ADMIN_REQUEST:
			return changeEmailAddressByAdminRequest(state, action);
		case CHANGE_EMAIL_ADDRESS_BY_ADMIN_SUCCESS:
			return changeEmailAddressByAdminSuccess(state, action);
		case CHANGE_EMAIL_ADDRESS_BY_ADMIN_FAILURE:
			return changeEmailAddressByAdminFailure(state, action);
		case CHANGE_NAME_BY_ADMIN_REQUEST:
			return changeNameByAdminRequest(state, action);
		case CHANGE_NAME_BY_ADMIN_SUCCESS:
			return changeNameByAdminSuccess(state, action);
		case CHANGE_NAME_BY_ADMIN_FAILURE:
			return changeNameByAdminFailure(state, action);
		case BLOCK_LOGIN_BY_ADMIN_REQUEST:
			return blockLoginByAdminRequest(state, action);
		case BLOCK_LOGIN_BY_ADMIN_SUCCESS:
			return blockLoginByAdminSuccess(state, action);
		case BLOCK_LOGIN_BY_ADMIN_FAILURE:
			return blockLoginByAdminFailure(state, action);
		case UNBLOCK_LOGIN_BY_ADMIN_REQUEST:
			return unblockLoginByAdminRequest(state, action);
		case UNBLOCK_LOGIN_BY_ADMIN_SUCCESS:
			return unblockLoginByAdminSuccess(state, action);
		case UNBLOCK_LOGIN_BY_ADMIN_FAILURE:
			return unblockLoginByAdminFailure(state, action);
		case UNBLOCK_AUTOMATICALLY_BLOCKED_LOGIN_BY_ADMIN_REQUEST:
			return unblockAutomaticallyBlockedLoginByAdminRequest(state, action);
		case UNBLOCK_AUTOMATICALLY_BLOCKED_LOGIN_BY_ADMIN_SUCCESS:
			return unblockAutomaticallyBlockedLoginByAdminSuccess(state, action);
		case UNBLOCK_AUTOMATICALLY_BLOCKED_LOGIN_BY_ADMIN_FAILURE:
			return unblockAutomaticallyBlockedLoginByAdminFailure(state, action);
		case RESEND_VERIFICATION_MAIL_BY_ADMIN_REQUEST:
			return resendVerificationMailByAdminRequest(state, action);
		case RESEND_VERIFICATION_MAIL_BY_ADMIN_SUCCESS:
			return resendVerificationMailByAdminSuccess(state, action);
		case RESEND_VERIFICATION_MAIL_BY_ADMIN_FAILURE:
			return resendVerificationMailByAdminFailure(state, action);
		case RESET_PASSWORD_BY_ADMIN_REQUEST:
			return resetPasswordByAdminRequest(state, action);
		case RESET_PASSWORD_BY_ADMIN_SUCCESS:
			return resetPasswordByAdminSuccess(state, action);
		case RESET_PASSWORD_BY_ADMIN_FAILURE:
			return resetPasswordByAdminFailure(state, action);
		case SET_EMAIL_VERIFIED_BY_ADMIN_REQUEST:
			return setEmailVerifiedByAdminRequest(state, action);
		case SET_EMAIL_VERIFIED_BY_ADMIN_SUCCESS:
			return setEmailVerifiedByAdminSuccess(state, action);
		case SET_EMAIL_VERIFIED_BY_ADMIN_FAILURE:
			return setEmailVerifiedByAdminFailure(state, action);
		case DELETE_USER_BY_ADMIN_REQUEST:
			return deleteUserByAdminRequest(state, action);
		case DELETE_USER_BY_ADMIN_SUCCESS:
			return deleteUserByAdminSuccess(state, action);
		case DELETE_USER_BY_ADMIN_FAILURE:
			return deleteUserByAdminFailure(state, action);
		case CHANGE_STATUS_BY_ADMIN_REQUEST:
			return changeStatusByAdminRequest(state, action);
		case CHANGE_STATUS_BY_ADMIN_SUCCESS:
			return changeStatusByAdminSuccess(state, action);
		case CHANGE_STATUS_BY_ADMIN_FAILURE:
			return changeStatusByAdminFailure(state, action);
		case CANCEL_CHANGE_EMAIL_REQUEST:
			return cancelChangeEmailRequest(state, action);
		case CANCEL_CHANGE_EMAIL_SUCCESS:
			return cancelChangeEmailSuccess(state, action);
		case CANCEL_CHANGE_EMAIL_FAILURE:
			return cancelChangeEmailFailure(state, action);
		default:
			return state;
	}
};

export default reducer;
