import React from 'react';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import { NotFoundErrorView } from '../error/ErrorPages.view';
import FlaggedContents from './FlaggedContentList.container';
import { flaggedContentService } from '../../ServiceFactory';
import { FlaggedType } from './store/FlaggedContent.state';
import FlaggedChatContainer from './flagged-content-details/FlaggedChat.container';
import FlaggedCommentContainer from './flagged-content-details/FlaggedComment.container';
import FlaggedPostContainer from './flagged-content-details/FlaggedPost.container';
import FlaggedOtherContainer from './flagged-content-details/FlaggedOther.container';
import FlaggedGroupContainer from './flagged-content-details/FlaggedGroup.container';

export const FLAGGED_CONTENTS_URL = '/flagged-contents';
const FLAGGED_CONTENTS_DETAILS_URL_BASE = FLAGGED_CONTENTS_URL + '/details/:entityType/:id';

export function getFlaggedContentsDetailsUrlWithEntityType(flaggedContentEntityType: string): string {
	return FLAGGED_CONTENTS_DETAILS_URL_BASE.replace(':entityType', flaggedContentEntityType);
}

export function getFlaggedContentsDetailsUrlWithEntityTypeAndId(
	flaggedContentEntityType: string,
	flaggedContentId: string
): string {
	return FLAGGED_CONTENTS_DETAILS_URL_BASE.replace(':entityType', flaggedContentEntityType).replace(
		':id',
		flaggedContentId
	);
}

interface Props extends RouteComponentProps {}

class FlaggedContentRoutingContainer extends React.Component<Props> {
	renderDetails(): JSX.Element {
		return (
			<Switch>
				<Route
					path={getFlaggedContentsDetailsUrlWithEntityType(
						flaggedContentService().getShortEntityTypeName(FlaggedType.CHAT)
					)}
					component={FlaggedChatContainer}
				/>
				<Route
					path={getFlaggedContentsDetailsUrlWithEntityType(
						flaggedContentService().getShortEntityTypeName(FlaggedType.COMMENT)
					)}
					component={FlaggedCommentContainer}
				/>
				<Route
					path={getFlaggedContentsDetailsUrlWithEntityType(
						flaggedContentService().getShortEntityTypeName(FlaggedType.POST)
					)}
					component={FlaggedPostContainer}
				/>
				<Route
					path={getFlaggedContentsDetailsUrlWithEntityType(
						flaggedContentService().getShortEntityTypeName(FlaggedType.GROUP)
					)}
					component={FlaggedGroupContainer}
				/>
				<Route component={FlaggedOtherContainer} />
			</Switch>
		);
	}

	render(): JSX.Element {
		const {
			match: { url }
		} = this.props;

		return (
			<Switch>
				<Route exact path={url} component={FlaggedContents} />
				<Route exact path={FLAGGED_CONTENTS_DETAILS_URL_BASE} render={this.renderDetails} />
				<Route component={NotFoundErrorView} />
			</Switch>
		);
	}
}

export default withRouter(FlaggedContentRoutingContainer);
