import { blue, grey, pink } from '@mui/material/colors';

import { createTheme } from '@mui/material';

const BLACK = '#292F36';
const BLACK_50 = 'rgba(41, 47, 54, 0.5)';
const BLACK_60 = 'rgba(41, 47, 54, 0.6)';

const theme = createTheme({
	palette: {
		background: {
			default: grey[50]
		},
		primary: {
			// light: will be calculated from palette.primary.main,
			main: '#0083c5', //as specified in DD-6864
			// dark: will be calculated from palette.primary.main,
			// contrastText: will be calculated to contrast with palette.primary.main
			light: blue[200]
		},
		secondary: {
			main: pink[500],
			light: pink[200]
		},
		success: {
			main: '#009374' // as specified in DD-9728
		}
	},
	typography: {
		//as specified in DD-11596
		h1: {
			fontSize: '24px',
			fontWeight: 500,
			color: BLACK,
			lineHeight: '28px'
		},
		h2: {
			fontSize: '22px',
			fontWeight: 'normal',
			color: BLACK_50,
			lineHeight: '26px'
		},
		h3: {
			fontSize: '18px',
			fontWeight: 500,
			color: BLACK,
			lineHeight: '21px'
		},
		h4: {
			fontSize: '16px',
			fontWeight: 500,
			color: BLACK,
			lineHeight: '19px'
		},
		h5: {
			fontSize: '16px',
			fontWeight: 300,
			color: BLACK_60,
			lineHeight: '19px'
		},
		h6: {
			fontSize: '15px',
			fontWeight: 'normal',
			color: '#333333',
			lineHeight: '18px'
		},
		body1: {
			fontSize: '12px',
			fontWeight: 500,
			color: BLACK_60,
			lineHeight: '14px'
		},
		body2: {
			fontSize: '11px',
			fontWeight: 'normal',
			color: BLACK_60,
			lineHeight: '13px'
		},
		subtitle1: { fontSize: '12px', fontWeight: 'normal', color: BLACK_60, lineHeight: '15px' },
		subtitle2: { fontSize: '9px', fontWeight: 'normal', color: BLACK_60, lineHeight: '11px' }
	},
	components: {
		MuiSnackbar: {
			styleOverrides: {
				root: {
					// Material UI defines a media query with the same predicate as this, so this will overwrite it.
					// Our snackbar should have some minimum width outside of mobile resolution (<600px = xs breakpoint) in order to avoid excessive line breaks.
					'@media (min-width: 600px)': {
						minWidth: 600
					}
				}
			}
		},
		MuiSnackbarContent: {
			styleOverrides: {
				root: {
					paddingTop: 0,
					paddingBottom: 0
				},
				message: {
					paddingTop: 6,
					paddingBottom: 6,
					// In mobile resolution, the snackbar will be stretched to full width (default Material UI behavior).
					// Since we want the close button to be aligned to the very right end, the content of the snackbar should fill it entirely.
					width: '100%'
				}
			}
		},
		MuiAppBar: {
			styleOverrides: {
				root: {
					padding: 0,
					margin: 0
				}
			}
		},
		MuiDrawer: {
			styleOverrides: {
				paper: {
					top: 64,
					padding: 0,
					margin: 0,
					width: 240,
					height: 'calc(100% - 64px)'
				}
			}
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					padding: 16,
					margin: 8
				}
			}
		},
		MuiListItem: {
			styleOverrides: {
				button: {
					'&:hover': {
						backgroundColor: '#EBEBEB'
					}
				}
			}
		},
		MuiListItemIcon: {
			styleOverrides: {
				root: {
					minWidth: 40
				}
			}
		},
		MuiInputLabel: {
			styleOverrides: {
				shrink: {
					transform: 'translate(0, 1.5px) scale(1)',
					transformOrigin: 'top left'
				},
				root: {
					fontSize: '12px',
					fontWeight: 500,
					color: BLACK_60,
					lineHeight: '14px'
				}
			}
		},
		MuiInput: {
			styleOverrides: {
				root: {
					fontSize: '18px',
					fontWeight: 'normal',
					color: '#333333',
					lineHeight: '21px'
				}
			}
		},
		MuiTableCell: {
			styleOverrides: {
				head: {
					fontWeight: 500,
					fontSize: '16px',
					lineHeight: '19px',
					fontStyle: 'normal'
				},
				stickyHeader: {
					backgroundColor: '#EEEEEE'
				},
				body: {
					fontSize: '14px',
					lineHeight: '1.43'
				}
			}
		},
		MuiTextField: {
			defaultProps: {
				variant: 'standard'
			}
		},
		MuiMenuItem: {
			styleOverrides: {
				root: { fontSize: '1rem', fontWeight: 400, lineHeight: 1.5, paddingTop: '6px', color: BLACK }
			}
		}
	},
	zIndex: {
		mobileStepper: 1000,
		appBar: 1200,
		drawer: 1100,
		modal: 1300,
		snackbar: 1400,
		tooltip: 1500
	}
});

export { theme as default };
