import React from 'react';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import {
	ClientGroupExtendedAccessibilityEnum,
	ClientGroupExtendedContentVisibilityEnum
} from '@DigitaleDoerfer/digitale-doerfer-api/models';

interface Props {
	groupType: ClientGroupExtendedAccessibilityEnum;
	groupVisibility: ClientGroupExtendedContentVisibilityEnum | null;
	groupContentVisibility: boolean;
	handleInputChange: (event: React.ChangeEvent<{ value: string }>) => void;
}

const GroupVisibilityView = (props: Props): JSX.Element => {
	const { groupType, groupVisibility, groupContentVisibility, handleInputChange } = props;
	return (
		<FormControl variant="standard">
			<FormLabel>Sichtbarkeit der Gruppe </FormLabel>
			<RadioGroup id="groupVisibility" name="groupVisibility" value={groupVisibility} onChange={handleInputChange} row>
				<FormControlLabel value={ClientGroupExtendedContentVisibilityEnum.ANYONE} control={<Radio />} label="Global" />
				<FormControlLabel
					value={ClientGroupExtendedContentVisibilityEnum.SAMEHOMEAREA}
					control={<Radio />}
					label="Heimatgemeinde"
				/>
				<FormControlLabel
					value={ClientGroupExtendedContentVisibilityEnum.MEMBERS}
					control={<Radio />}
					label="nur Mitglieder"
					disabled={groupType === ClientGroupExtendedAccessibilityEnum.PUBLIC || groupContentVisibility}
				/>
			</RadioGroup>
		</FormControl>
	);
};

export default GroupVisibilityView;
