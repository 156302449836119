import React from 'react';
import { TextField } from '@mui/material';
import { ReadOnlyTextFieldView } from '../ReadOnlyTextField.view';

interface Props {
	groupShortName: string;
	isReadOnlyField?: boolean;
	handleGroupShortNameChange?: (newGroupShortName: string) => void;
}

class GroupShortNameView extends React.Component<Props> {
	constructor(props: Props) {
		super(props);
		this.handleGroupShortNameChange = this.handleGroupShortNameChange.bind(this);
	}

	handleGroupShortNameChange(event: React.ChangeEvent<{ value: string }>): void {
		const { handleGroupShortNameChange } = this.props;
		handleGroupShortNameChange && handleGroupShortNameChange(event.target.value);
	}

	render(): JSX.Element {
		const { groupShortName, isReadOnlyField } = this.props;
		return isReadOnlyField ? (
			<ReadOnlyTextFieldView
				id="groupShortName"
				name="groupShortName"
				label="Gruppenkürzel"
				value={groupShortName}
				InputLabelProps={{ shrink: true }}
				InputProps={{ readOnly: true }}
				fullWidth
			/>
		) : (
			<TextField
				variant="standard"
				required
				id="groupShortName"
				name="groupShortName"
				label="Gruppenkürzel"
				value={groupShortName}
				onChange={this.handleGroupShortNameChange}
				InputLabelProps={{ shrink: true }}
				inputProps={{ maxLength: 2 }}
				fullWidth
			/>
		);
	}
}

export default GroupShortNameView;
