import React from 'react';
import CommonButtonProps from './ButtonProps';
import BaseButton from './BaseButton.view';

function NavButton(props: CommonButtonProps) {
	return (
		<BaseButton color="primary" variant="contained" {...props}>
			{props.children}
		</BaseButton>
	);
}

export default NavButton;
