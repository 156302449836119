import React from 'react';
import { Grid } from '@mui/material';
import { flaggedContentViewService } from '../../../../ServiceFactory';
import { ReadOnlyTextFieldView } from '../../../../shared/views/ReadOnlyTextField.view';

interface Props {
	status?: string;
	lastStatusUpdate?: string;
}

class FlaggedContentDetailsStatusView extends React.Component<Props> {
	render(): JSX.Element {
		const { status, lastStatusUpdate } = this.props;
		return (
			<>
				<Grid item xs={12} md={6}>
					<ReadOnlyTextFieldView
						id="flaggedStatus"
						name="flaggedStatus"
						label="Status"
						value={flaggedContentViewService().renderInfo(
							flaggedContentViewService().translateFlagStatusDE(status ?? '')
						)}
						InputProps={{ readOnly: true }}
						InputLabelProps={{
							shrink: true
						}}
						fullWidth
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<ReadOnlyTextFieldView
						id="lastUpdate"
						name="lastUpdate"
						label="Letzte Änderung"
						value={flaggedContentViewService().renderInfo(lastStatusUpdate)}
						InputProps={{ readOnly: true }}
						InputLabelProps={{
							shrink: true
						}}
						fullWidth
					/>
				</Grid>
			</>
		);
	}
}

export default FlaggedContentDetailsStatusView;
