import {
	AppConfigActionTypes,
	GET_APP_CONFIGS_FAILURE,
	GET_APP_CONFIGS_REQUEST,
	GET_APP_CONFIGS_SUCCESS,
	GetAppConfigsFailureAction,
	GetAppConfigsRequestAction,
	GetAppConfigsSuccessAction
} from './AppConfigActionTypes';
import { AppConfigState, initialAppConfigState } from './AppConfig.state';

const getAppConfigsRequest = (state: AppConfigState, action: GetAppConfigsRequestAction): AppConfigState => {
	return {
		...state,
		list: {
			...state.list,
			loading: true,
			error: null
		}
	};
};

const getAppConfigsSuccess = (state: AppConfigState, action: GetAppConfigsSuccessAction): AppConfigState => {
	return {
		...state,
		list: {
			...state.list,
			loading: false,
			appConfigs: action.appConfigs
		}
	};
};

const getAppConfigsFailure = (state: AppConfigState, action: GetAppConfigsFailureAction): AppConfigState => {
	return {
		...state,
		list: {
			...state.list,
			loading: false,
			error: action.error
		}
	};
};

const reducer = (state = initialAppConfigState, action: AppConfigActionTypes): AppConfigState => {
	switch (action.type) {
		case GET_APP_CONFIGS_REQUEST:
			return getAppConfigsRequest(state, action);
		case GET_APP_CONFIGS_SUCCESS:
			return getAppConfigsSuccess(state, action);
		case GET_APP_CONFIGS_FAILURE:
			return getAppConfigsFailure(state, action);
		default:
			return state;
	}
};

export default reducer;
