import { GetState, SetState } from 'zustand';
import { App, AppVariant, Contract, ListType, SearchQuery, Tenant, ValueOf } from '../../types';
import { Store } from '../store';

export type SelectedContracts = { [key: string]: Contract };

export type SelectedListItem = {
	item: Contract | App | Tenant | undefined;
	type: ValueOf<typeof ListType> | undefined;
};

export interface TreeListSlice {
	selectedListItem: SelectedListItem;
	selectedContracts: SelectedContracts;
	currentAppVariantPage?: number;
	currentTenantsPage?: number;
	currentContractsPage?: number;
	searchAppVariant: string;
	searchTenant: string;
	searchContract: string;
	shouldUseAllContracts: boolean;
	toggleSelectedContract: (contract: Contract) => void;
	selectContracts: (contracts: Contract[]) => void;
	unselectContracts: (contracts: Contract[]) => void;
	unselectAllContracts: () => void;
	handleContractsGeoAreas: () => void;
	setCurrentListPage: (listType: ValueOf<typeof ListType>, newPage?: number) => void;
	addSearchQuery: (newSearchQuery: SearchQuery) => void;
	removeSearchQuery: (type: ValueOf<typeof ListType>) => void;
	removeAllSearchQueries: () => void;
	setSelectedListItem: (item: AppVariant | Tenant | Contract, type: ValueOf<typeof ListType>) => void;
	toggleUseAllContracts: () => void;
	resetShouldUseAllContracts: () => void;
}

const createTreeListSlice = (
	set: SetState<TreeListSlice>,
	get: GetState<Store>
	// api: StoreApi<Store>
): TreeListSlice => ({
	selectedListItem: { item: undefined, type: undefined },
	selectedContracts: {},
	currentAppVariantPage: 0,
	currentTenantsPage: 0,
	currentContractsPage: 0,
	searchAppVariant: '',
	searchTenant: '',
	searchContract: '',
	shouldUseAllContracts: false,
	setSelectedListItem: (item: AppVariant | Tenant | Contract, type: ValueOf<typeof ListType>): void =>
		set(state => ({ selectedListItem: (state.selectedListItem = { item, type }) })),
	toggleSelectedContract: (contract: Contract): void =>
		set(state => {
			if (state.selectedContracts[contract.id]) {
				delete state.selectedContracts[contract.id];
			} else {
				state.selectedContracts[contract.id] = contract;
			}
			state.handleContractsGeoAreas();
		}),
	selectContracts: (contracts): void =>
		set(state => {
			contracts.forEach(contract => {
				state.selectedContracts[contract.id] = contract;
			});
			state.handleContractsGeoAreas();
		}),
	unselectContracts: (contracts): void =>
		set(state => {
			const selectedContracts = get().selectedContracts;
			contracts.forEach(contract => {
				delete selectedContracts[contract.id];
			});

			state.selectedContracts = { ...selectedContracts };
			state.handleContractsGeoAreas();
		}),
	unselectAllContracts: (): void =>
		set(state => {
			state.selectedContracts = {};
			state.handleContractsGeoAreas();
		}),

	handleContractsGeoAreas: (): void => {
		const selectedContracts = get().selectedContracts;
		const geoAreaIds = Object.values(selectedContracts).reduce(
			(geoAreaIds: { included: string[]; excluded: string[] }, contract) => {
				return {
					included: [...geoAreaIds.included, ...(contract?.geoAreaIdsIncluded || [])],
					excluded: [...geoAreaIds.excluded, ...(contract?.geoAreaIdsExcluded || [])]
				};
			},
			{ included: [], excluded: [] }
		);
		get().setIncludedGeoAreaIds(Array.from(new Set(geoAreaIds.included)));
		get().setExcludedGeoAreaIds(Array.from(new Set(geoAreaIds.excluded)));
	},
	setCurrentListPage: (listType: ValueOf<typeof ListType>, newCurrentPage?: number): void =>
		set(state => {
			switch (listType) {
				case ListType.APP_VARIANT:
					state.currentAppVariantPage = newCurrentPage;
					break;
				case ListType.CONTRACT:
					state.currentContractsPage = newCurrentPage;
					break;
				case ListType.TENANT:
					state.currentTenantsPage = newCurrentPage;
					break;
				default:
					break;
			}
		}),
	addSearchQuery: (newSearchQuery: SearchQuery): void =>
		set(state => {
			if (newSearchQuery.type === ListType.APP_VARIANT) state.searchAppVariant = newSearchQuery.query;
			if (newSearchQuery.type === ListType.TENANT) state.searchTenant = newSearchQuery.query;
			if (newSearchQuery.type === ListType.CONTRACT) state.searchContract = newSearchQuery.query;
		}),
	removeSearchQuery: (type: ValueOf<typeof ListType>): void =>
		set(state => {
			if (type === ListType.APP_VARIANT) state.searchAppVariant = '';
			if (type === ListType.TENANT) state.searchTenant = '';
			if (type === ListType.CONTRACT) state.searchContract = '';
		}),
	removeAllSearchQueries: (): void =>
		set(state => {
			state.searchAppVariant = '';
			state.searchTenant = '';
			state.searchContract = '';
		}),
	toggleUseAllContracts: (): void =>
		set(state => {
			state.shouldUseAllContracts = !state.shouldUseAllContracts;
			if (!state.shouldUseAllContracts) {
				state.unselectAllContracts();
			}
		}),
	resetShouldUseAllContracts: (): void =>
		set(state => {
			state.shouldUseAllContracts = false;
			state.unselectAllContracts();
		})
});

export default createTreeListSlice;
