import React from 'react';
import { Box, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import CategoryInfoView from './CategoryInfo.view';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-unused-vars
const styles = (theme: Theme) => {
	return createStyles({
		categoryInfoContainer: {
			maxWidth: 500
		}
	});
};

interface Props extends WithStyles<typeof styles> {
	name: string;
	description: string;
	id: string;
	internalName: string;
}

function CategoryNameView(props: Props): React.ReactElement {
	const { name, description, id, internalName, classes } = props;

	return (
		<Box display="flex" flexDirection="row" alignItems="center">
			<Box pr={0.5}>{name}</Box>
			{(description || id || internalName) && (
				<CategoryInfoView>
					{/* {withStyles(styles)(({ classes }: Props) => ( */}
					<div className={classes.categoryInfoContainer}>
						{description && (
							<Box mb={3}>
								<Typography variant="body2">{description}</Typography>
							</Box>
						)}
						{id && (
							<div>
								<Typography variant="body2">{`ID: ${id}`}</Typography>
							</div>
						)}
						{internalName && (
							<div>
								<Typography variant="body2">{`Interner Name: ${internalName}`}</Typography>
							</div>
						)}
					</div>
					{/* ))} */}
				</CategoryInfoView>
			)}
		</Box>
	);
}

export default withStyles(styles)(CategoryNameView);
