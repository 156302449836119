import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './store/store';
import * as serviceWorker from './serviceWorker';
import PlatformAdminUIContainer from './PlatformAdminUI.container';
import { Route, Router, Switch } from 'react-router-dom';
import './index.css';
import history from './history';
import { isLocal } from './shared/utils/EnvironmentSettings';
import PrototypesRoutingContainer, { PROTOTYPES_URL } from './prototypes/PrototypesRouting.container';
import 'typeface-roboto';

const container = document.getElementById('root');
ReactDOM.render(
	<StrictMode>
		<Provider store={store}>
			<Router history={history}>
				{
					/* Putting the whole switch as conditional since it adds unnecessary complexity to production code */
					isLocal() ? (
						<Switch>
							<Route path={PROTOTYPES_URL}>
								<PrototypesRoutingContainer />
							</Route>
							<Route>
								<PlatformAdminUIContainer />
							</Route>
						</Switch>
					) : (
						<PlatformAdminUIContainer />
					)
				}
			</Router>
		</Provider>
	</StrictMode>,
	container
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
