import React from 'react';
import { LabelDisplayedRowsArgs, Table, TableBody, TablePagination, TableRow } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

interface Props {
	totalRows: number;
	rowLabelPlural: string;
	page: number;
	rowsPerPage: number;
	rowsPerPageOptions: readonly number[];
	handleChangePage: (newPage: number) => void;
	handleChangeRowsPerPage: (newRowsPerPage: number) => void;
}

const StyledTablePagination = withStyles({
	spacer: {
		flex: '0'
	}
})(TablePagination);

class TablePaginationView extends React.Component<Props> {
	constructor(props: Props) {
		super(props);

		this.handleChangePage = this.handleChangePage.bind(this);
		this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
	}

	handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number): void {
		if (event) {
			event.preventDefault();
		}

		this.props.handleChangePage(newPage);
	}

	handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>): void {
		event.preventDefault();
		const newRowsPerPage = +event.target.value;

		this.props.handleChangeRowsPerPage(newRowsPerPage);
	}

	render(): JSX.Element {
		const { totalRows, rowLabelPlural, page, rowsPerPage, rowsPerPageOptions } = this.props;

		return (
			<Table>
				<TableBody>
					<TableRow>
						<StyledTablePagination
							rowsPerPageOptions={[...rowsPerPageOptions]} // make the array mutable for consumer
							count={totalRows}
							rowsPerPage={rowsPerPage}
							page={page}
							backIconButtonProps={{
								'aria-label': 'previous page'
							}}
							nextIconButtonProps={{
								'aria-label': 'next page'
							}}
							onPageChange={this.handleChangePage}
							onRowsPerPageChange={this.handleChangeRowsPerPage}
							labelRowsPerPage={`Anzahl ${rowLabelPlural} pro Seite`}
							labelDisplayedRows={(info: LabelDisplayedRowsArgs): string => `${info.from}-${info.to} von ${info.count}`}
						/>
					</TableRow>
				</TableBody>
			</Table>
		);
	}
}

export default TablePaginationView;
