import moment from 'moment';
import 'moment/locale/de';

export enum TimestampFormat {
	DD_MM_YYYY_AT_HH_MM_O_CLOCK = 'DD.MM.YYYY [um] HH:mm [Uhr]',
	DD_MM_YYYY_HH_MM = 'DD.MM.YYYY HH:mm',
	DD_MM_YY_HH_MM = 'DD.MM.YY, HH:mm',
	DD_MM_YYYY = 'DD.MM.YYYY',
	DD_MM_YY = 'DD.MM.YY',
	DD_MMMM = 'DD.MMM'
}

export class TimeService {
	parseTimestamp(timestamp: number, format: TimestampFormat): string {
		return moment(timestamp).format(format);
	}

	parseTimestampDE(timestamp: number, format: TimestampFormat): string {
		return moment(timestamp).locale('de').format(format);
	}
}
