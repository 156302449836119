import { ClientStatisticsReportReference } from '@DigitaleDoerfer/digitale-doerfer-api';
import { sharedAdminuiStatisticsApi } from '../../ServiceFactory';

export const getStatisticsReferences = async (): Promise<ClientStatisticsReportReference[]> => {
	return sharedAdminuiStatisticsApi().getStatisticsReportReferencesUsingGET({});
};

export const getStatisticsReportContent = async (fileName: string): Promise<string> => {
	return sharedAdminuiStatisticsApi().getStatisticsReportContentUsingGET({ reportFileName: fileName });
};
