import React, { Component } from 'react';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { ActionMenuItemProps } from '../../../../shared/views/ActionMenu/ActionMenu';
import ActionMenuItemView from '../../../../shared/views/ActionMenu/ActionMenuItem.view';

interface Props extends ActionMenuItemProps {
	handleSave: () => void;
}

class ResendVerificationMailActionView extends Component<Props> {
	render(): JSX.Element {
		const { handleSave, renderMenuItem } = this.props;
		return (
			<ActionMenuItemView
				renderMenuItem={renderMenuItem}
				icon={<MailOutlineIcon color="primary" />}
				text="E-Mail-Bestätigung anfordern"
				action={handleSave}
			/>
		);
	}
}

export default ResendVerificationMailActionView;
