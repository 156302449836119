import { BaseError } from '../../../shared/errors/Errors';
import { ClientNewsSource, ClientNewsSourceCreateRequest } from '@DigitaleDoerfer/digitale-doerfer-api/models';
export const GET_NEWS_SOURCES_REQUEST = 'GET_NEWS_SOURCES_REQUEST';
export const GET_NEWS_SOURCES_SUCCESS = 'GET_NEWS_SOURCES_SUCCESS';
export const GET_NEWS_SOURCES_FAILURE = 'GET_NEWS_SOURCES_FAILURE';
export const CREATE_NEWS_SOURCES_REQUEST = 'CREATE_NEWS_SOURCES_REQUEST';
export const CREATE_NEWS_SOURCES_SUCCESS = 'CREATE_NEWS_SOURCES_SUCCESS';
export const CREATE_NEWS_SOURCES_FAILURE = 'CREATE_NEWS_SOURCES_FAILURE';

export interface GetNewsSourcesRequestAction {
	type: typeof GET_NEWS_SOURCES_REQUEST;
}

export interface GetNewsSourcesSuccessAction {
	type: typeof GET_NEWS_SOURCES_SUCCESS;
	clientNewsSource: ClientNewsSource[];
}

export interface GetNewsSourcesFailureAction {
	type: typeof GET_NEWS_SOURCES_FAILURE;
	error: BaseError | null;
}

export interface CreateNewsSourcesRequestAction {
	type: typeof CREATE_NEWS_SOURCES_REQUEST;
	newsSourcesRequestData: ClientNewsSourceCreateRequest;
}

export interface CreateNewsSourcesSuccessAction {
	type: typeof CREATE_NEWS_SOURCES_SUCCESS;
	newsSourcesSuccessData: ClientNewsSource;
}

export interface CreateNewsSourcesFailureAction {
	type: typeof CREATE_NEWS_SOURCES_FAILURE;
	error: BaseError | null;
}

export type NewsSourcesActionTypes =
	| CreateNewsSourcesRequestAction
	| CreateNewsSourcesFailureAction
	| CreateNewsSourcesSuccessAction
	| GetNewsSourcesRequestAction
	| GetNewsSourcesSuccessAction
	| GetNewsSourcesFailureAction;
