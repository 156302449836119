import React, { Component } from 'react';
import {
	Box,
	Breadcrumbs,
	Checkbox,
	IconButton,
	Link,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableCellProps,
	TableContainer,
	TableRow,
	Theme,
	Typography
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { ClientGeoArea, ClientGeoAreaExtended } from '@DigitaleDoerfer/digitale-doerfer-api/models';
import HomeIcon from '@mui/icons-material/Home';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import DeleteIcon from '@mui/icons-material/Delete';
import { AnyGeoArea } from '../../utils/AnyGeoArea';
import { GeoAreaChildrenElement, GeoAreaElement } from '../../utils/GeoAreaElements';

/**
 * Returns a list of GeoAreaChildrenElement sorted by the next condition:
 * 1. All selected geoAreas or with at least one user, should be at the top of the list.
 * @param geoAreaChildren GeoAreas to be sorted.
 */
function getSortedGeoAreas(geoAreaChildren: GeoAreaChildrenElement[]): GeoAreaChildrenElement[] {
	return [...geoAreaChildren].sort((geoAreaChild1, geoAreaChild2) => {
		if (geoAreaChild2.users.length > 0 || geoAreaChild2.manualSelected) {
			return 1;
		}
		if (geoAreaChild1.users.length > 0 || geoAreaChild1.manualSelected) {
			return -1;
		}
		return 0;
	});
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/explicit-function-return-type
const styles = (theme: Theme) => {
	return createStyles({
		tableContainer: {
			margin: 0
		},
		fixedUsersColumn: {
			width: 350
		},
		nodeTableRow: {
			paddingLeft: '17px',
			lineHeight: 20
		},
		geoAreaChildIconContainer: {
			marginRight: 4
		}
	});
};

interface Props extends WithStyles<typeof styles> {
	geoAreaElement: GeoAreaElement;
	hideParent?: boolean;
	hideUnselectedChildren?: boolean;
	sliceSizeBeforeExpansion?: number;
	nodeTableCellProps?: TableCellProps;
	handleRemoveGeoAreaElement?: (geoAreaElement: GeoAreaElement) => void;
	handleManualParentGeoAreaSelection?: (geoAreaSelected: boolean, parentGeoArea: AnyGeoArea) => void;
	handleManualGeoAreaSelection?: (
		geoAreaSelected: boolean,
		childGeoArea: AnyGeoArea,
		parentGeoArea: AnyGeoArea
	) => void;
}

interface State {
	expandGeoAreas: boolean;
}

const initialState: State = {
	expandGeoAreas: false
};

class GroupGeoAreaElementView extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = initialState;
		this.handleExpandGeoAreaTable = this.handleExpandGeoAreaTable.bind(this);
	}

	handleExpandGeoAreaTable = (event: React.MouseEvent): void => {
		event.preventDefault();
		const { expandGeoAreas } = this.state;
		this.setState({ expandGeoAreas: !expandGeoAreas });
	};

	render(): JSX.Element | null {
		const {
			geoAreaElement,
			hideParent,
			hideUnselectedChildren,
			sliceSizeBeforeExpansion,
			nodeTableCellProps,
			classes,
			handleManualParentGeoAreaSelection,
			handleManualGeoAreaSelection,
			handleRemoveGeoAreaElement
		} = this.props;
		const { expandGeoAreas } = this.state;
		const { geoAreaChildren, geoAreaParent, geoAreaParentPaths, isRemovable } = geoAreaElement;
		const isRoot = geoAreaChildren.length === 1 && geoAreaParent && geoAreaParent.id === geoAreaChildren[0].geoArea.id;
		if (!geoAreaParent) {
			return null;
		}
		const sortedGeoAreaChildren = getSortedGeoAreas(
			hideUnselectedChildren ? geoAreaChildren.filter(geoArea => geoArea.manualSelected) : geoAreaChildren
		);
		const filteredGeoAreaChildren = expandGeoAreas
			? sortedGeoAreaChildren
			: sortedGeoAreaChildren.slice(0, sliceSizeBeforeExpansion ?? 3);
		const allGeoAreaChildrenSelected = sortedGeoAreaChildren.every(geoArea => geoArea.manualSelected);

		return (
			<>
				<Box display="flex" alignItems="center" justifyContent="flex-start">
					<Breadcrumbs aria-label="breadcrumb" color="textPrimary">
						{geoAreaParentPaths.map((geoArea: ClientGeoArea | ClientGeoAreaExtended | undefined, index: number) => {
							// Ignore last geoAreaParent path element
							return index !== geoAreaParentPaths.length - 1 ? (
								<Typography key={geoArea?.id} color="textPrimary" variant="caption">
									{geoArea?.name}
								</Typography>
							) : undefined;
						})}
					</Breadcrumbs>
					{isRemovable && handleRemoveGeoAreaElement && (
						<IconButton
							edge="end"
							onClick={(): void => handleRemoveGeoAreaElement && handleRemoveGeoAreaElement(geoAreaElement)}
							size="small"
						>
							<DeleteIcon color="primary" fontSize="small" />
						</IconButton>
					)}
				</Box>
				<TableContainer component={Paper} className={classes.tableContainer}>
					<Table size="small" padding="none">
						{sortedGeoAreaChildren.length > (sliceSizeBeforeExpansion ?? 3) ? (
							<caption>
								<Link component="button" variant="body2" color="primary" onClick={this.handleExpandGeoAreaTable}>
									{expandGeoAreas ? 'weniger anzeigen ...' : `alle aus ${geoAreaParent.name} ...`}
								</Link>
							</caption>
						) : (
							<></>
						)}

						<TableBody>
							{!hideParent && !isRoot && (
								<TableRow>
									<TableCell>
										<Box display="flex" alignItems="center" justifyContent="flex-start">
											{handleManualParentGeoAreaSelection ? (
												<Checkbox
													id="directParent"
													name="directParent"
													value={geoAreaParent}
													onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
														handleManualParentGeoAreaSelection(event.target.checked, geoAreaParent)
													}
													checked={allGeoAreaChildrenSelected}
												/>
											) : undefined}
											<Typography variant="body2"> {geoAreaParent?.name} </Typography>
										</Box>
									</TableCell>
									<TableCell align="left"></TableCell>
								</TableRow>
							)}
							{filteredGeoAreaChildren.map(geoAreaChild => {
								const { users, manualSelected, geoArea } = geoAreaChild;
								const isSelected = (manualSelected || users.length > 0) ?? false;
								const checkedFieldColor = isSelected ? 'primary' : 'inherit';
								const isReadOnly = users.length > 0;
								return (
									<TableRow key={`${geoArea?.id}${users[0]?.id}`}>
										<TableCell className={classes.nodeTableRow} {...nodeTableCellProps}>
											<Box display="flex" alignItems="center" justifyContent="flex-start">
												{handleManualGeoAreaSelection ? (
													<Checkbox
														id="childGeoArea"
														name="childGeoArea"
														value={JSON.stringify(geoArea)}
														checked={isSelected}
														disabled={isReadOnly}
														onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
															handleManualGeoAreaSelection(event.target.checked, geoArea, geoAreaParent)
														}
													/>
												) : undefined}
												{geoArea?.childGeoAreas ? (
													<AccountTreeIcon
														fontSize="small"
														color={checkedFieldColor}
														className={classes.geoAreaChildIconContainer}
													/>
												) : (
													<HomeIcon
														fontSize="small"
														color={checkedFieldColor}
														className={classes.geoAreaChildIconContainer}
													/>
												)}
												<Typography variant="body2" color={checkedFieldColor}>
													{geoArea?.name}
												</Typography>
											</Box>
										</TableCell>
										<TableCell className={classes.fixedUsersColumn}>
											<Typography variant="body2" color={checkedFieldColor}>
												{users.map(user => user?.firstName).join(', ')}
											</Typography>
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
			</>
		);
	}
}

export default withStyles(styles)(GroupGeoAreaElementView);
