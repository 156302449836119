import React from 'react';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Theme } from '@mui/material';

import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const styles = (theme: Theme) => {
	return createStyles({
		copyIcon: {
			height: '16px',
			cursor: 'pointer',
			color: theme.palette.primary.main
		},
		copyIconParent: {
			display: 'flex',
			height: '100%',
			width: '15px',
			paddingLeft: '10px',
			paddingRight: '5px',
			justifyContent: 'center',
			alignItems: 'center'
		}
	});
};

interface Props extends WithStyles<typeof styles> {
	textToCopy: string;
	showCopyIcon: boolean;
}

interface State {
	wasTextCopied: boolean;
}

const initialState = {
	wasTextCopied: false
};

class ClipboardView extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = initialState;
		this.handleCopyIdButton = this.handleCopyIdButton.bind(this);
	}

	componentDidUpdate(prevProps: Props): void {
		const { showCopyIcon } = this.props;
		if (prevProps.showCopyIcon !== showCopyIcon) {
			this.setState({ wasTextCopied: false });
		}
	}

	handleCopyIdButton(): void {
		const { textToCopy } = this.props;
		navigator.clipboard.writeText(textToCopy);
		this.setState({
			wasTextCopied: true
		});
	}

	render(): JSX.Element {
		const { classes, showCopyIcon } = this.props;
		const { wasTextCopied } = this.state;
		return (
			<Box onClick={(): void => this.handleCopyIdButton()} className={classes.copyIconParent}>
				{showCopyIcon && !wasTextCopied && <FileCopyOutlinedIcon className={classes.copyIcon} />}
				{showCopyIcon && wasTextCopied && <CheckCircleIcon className={classes.copyIcon} />}
			</Box>
		);
	}
}

export default withStyles(styles)(ClipboardView);
