import React from 'react';
import { Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { ClientCommunity, ClientGeoAreaExtended } from '@DigitaleDoerfer/digitale-doerfer-api/models';
import BarLoadingIndicator from '../../shared/views/LoadingIndicators/BarLoadingIndicator.view';
import GeoAreaElementView from './GeoAreaElement/GeoAreaElement.view';
import SearchBarWithResetButtonView from '../../shared/views/SearchBarWithResetButton/SearchBarWithResetButton.view';
import EqualizerIcon from '@mui/icons-material/Equalizer';
const CHAR_LENGTH_NEEDED_FOR_SEARCH = 3;
interface Props {
	geoAreas: ClientGeoAreaExtended[];
	loading: boolean;
	tenants: ClientCommunity[];
	initialFilterText?: string;
	getGeoAreas: () => void;
}

interface State {
	filterText: string;
	isSearching: boolean;
}

class GeoAreasListView extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		const { initialFilterText } = this.props;
		if (initialFilterText) {
			this.state = { filterText: initialFilterText, isSearching: true };
		} else {
			this.state = { filterText: '', isSearching: false };
		}
		this.handleSearch = this.handleSearch.bind(this);
		this.handleResetButton = this.handleResetButton.bind(this);
		this.renderTenantName = this.renderTenantName.bind(this);
		this.isGeoAreaMatching = this.isGeoAreaMatching.bind(this);
		this.isGeoAreaNameOrIdMatchingFilter = this.isGeoAreaNameOrIdMatchingFilter.bind(this);
	}

	handleSearch(filterText: string): void {
		const isSearching = filterText.length >= CHAR_LENGTH_NEEDED_FOR_SEARCH;
		this.setState({ filterText, isSearching });
	}

	handleResetButton(): void {
		this.setState({ filterText: '', isSearching: false });
	}

	isSearchResultEmpty(): boolean {
		const { geoAreas, loading } = this.props;
		const { isSearching } = this.state;
		if (loading || !isSearching) {
			return false;
		}
		const matchingAreas = this.getMatchingAreas(geoAreas);
		return matchingAreas.length === 0;
	}

	getMatchingAreas(geoAreas: ClientGeoAreaExtended[]): ClientGeoAreaExtended[] {
		return geoAreas.filter((geoArea: ClientGeoAreaExtended) => {
			return this.isGeoAreaMatching(geoArea);
		});
	}

	isGeoAreaNameOrIdMatchingFilter(geoArea: ClientGeoAreaExtended, filterText: string): boolean {
		const nameMatch = !!geoArea.name && geoArea.name.toLowerCase().indexOf(filterText.toLowerCase()) !== -1;
		const idMatch = !!geoArea.id && geoArea.id.toLowerCase().indexOf(filterText.toLowerCase()) !== -1;
		const isNameOrIdMatch = idMatch || nameMatch;
		return isNameOrIdMatch;
	}

	isGeoAreaMatching(geoArea: ClientGeoAreaExtended): boolean {
		const { isSearching, filterText } = this.state;
		if (!isSearching) {
			return false;
		}

		const isNameOrIdMatch = this.isGeoAreaNameOrIdMatchingFilter(geoArea, filterText);

		let hasChildMatch = false;
		if (!isNameOrIdMatch && geoArea.childGeoAreas) {
			const childMatches = this.getMatchingAreas(geoArea.childGeoAreas);
			hasChildMatch = childMatches.length > 0;
		}
		if (isNameOrIdMatch) {
			return true;
		}
		return hasChildMatch;
	}

	renderTenantName(tenantId: string | undefined): string {
		const { tenants } = this.props;
		const tenant = tenants.find(tenant => {
			return tenant.id === tenantId;
		});
		return tenant && tenant.name ? tenant.name : '';
	}

	renderGeoAreaName(geoArea: ClientGeoAreaExtended): string {
		const { name } = geoArea;
		if (!name) {
			return '';
		}
		return name;
	}

	render(): JSX.Element {
		const { loading, geoAreas } = this.props;
		const { filterText, isSearching } = this.state;
		const isSearchResultEmpty = isSearching ? this.isSearchResultEmpty() : false;
		return (
			<Paper>
				<BarLoadingIndicator loading={loading} />
				<Grid container spacing={2}>
					<SearchBarWithResetButtonView
						text={filterText}
						label="Geo-Areas durchsuchen"
						placeholder="Suchbegriff oder ID eingeben"
						handleTextChange={this.handleSearch}
						handleReset={this.handleResetButton}
					/>
					<Grid item container>
						{isSearchResultEmpty && <Typography>Es konnten keine Geo-Areas gefunden werden.</Typography>}
						{!isSearchResultEmpty && (
							<Table aria-label="simple table" stickyHeader={true} size="small">
								<TableHead>
									<TableRow>
										<TableCell>Geo-Area</TableCell>
										<TableCell>
											<EqualizerIcon />
										</TableCell>
										<TableCell>Tenant</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{geoAreas.map((geoArea, index) => (
										<GeoAreaElementView
											renderGeoAreaName={this.renderGeoAreaName}
											renderTenantName={this.renderTenantName}
											isGeoAreaMatching={this.isGeoAreaMatching}
											geoAreaLayer={0}
											geoArea={geoArea}
											key={index}
											forceExpand={this.isGeoAreaMatching(geoArea)}
										/>
									))}
								</TableBody>
							</Table>
						)}
					</Grid>
				</Grid>
			</Paper>
		);
	}
}

export default GeoAreasListView;
