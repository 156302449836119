import React from 'react';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import { NotFoundErrorView } from '../error/ErrorPages.view';
import StatisticsContainer from './Statistics.container';
export const STATISTICS_URL = '/statistics';

interface Props extends RouteComponentProps {}

class StatisticsRoutingContainer extends React.Component<Props> {
	render(): JSX.Element {
		const {
			match: { url }
		} = this.props;

		return (
			<Switch>
				<Route exact path={url} component={StatisticsContainer} />
				<Route exact path={STATISTICS_URL} component={StatisticsContainer} />
				<Route component={NotFoundErrorView} />
			</Switch>
		);
	}
}

export default withRouter(StatisticsRoutingContainer);
