import React, { Component, PropsWithChildren } from 'react';
import { ActionMenuItemProps } from './ActionMenu';
import ActionMenuItemView from './ActionMenuItem.view';
import ActionMenuDialogView from './ActionMenuDialog.view';

interface Props {
	itemProps: ActionMenuItemProps & {
		icon: JSX.Element;
		text: string;
	};
	dialogProps: {
		danger?: boolean;
		titleText: string;
		saveButtonText: string;
		wide?: boolean;
		ignoreKeyDownKeys?: string[];
		handleOpen?: () => void;
		handleSave: () => void;
		handleIsSaveDisabled?: () => boolean;
	};
}

interface State {
	dialogOpen: boolean;
}

class DialogActionView extends Component<PropsWithChildren<Props>, State> {
	constructor(props: PropsWithChildren<Props>) {
		super(props);

		this.state = { dialogOpen: false };
		this.handleOpen = this.handleOpen.bind(this);
		this.handleClose = this.handleClose.bind(this);
	}

	handleOpen(): void {
		this.setState({ dialogOpen: true });

		const { handleOpen } = this.props.dialogProps;

		if (handleOpen) {
			handleOpen();
		}
	}

	handleClose(): void {
		this.setState({ dialogOpen: false });
	}

	renderItem(): JSX.Element {
		const { renderMenuItem, icon, text } = this.props.itemProps;

		return <ActionMenuItemView renderMenuItem={renderMenuItem} icon={icon} text={text} action={this.handleOpen} />;
	}

	renderDialog(): JSX.Element | null {
		const { children } = this.props;
		const { danger, titleText, saveButtonText, handleSave, handleIsSaveDisabled, wide, ignoreKeyDownKeys } =
			this.props.dialogProps;
		const { dialogOpen } = this.state;

		if (!dialogOpen) {
			return null;
		}

		return (
			<ActionMenuDialogView
				danger={danger}
				wide={wide}
				titleText={titleText}
				saveButtonText={saveButtonText}
				handleSave={handleSave}
				handleIsSaveDisabled={handleIsSaveDisabled}
				handleClose={this.handleClose}
				ignoreKeyDownKeys={ignoreKeyDownKeys}
			>
				{children}
			</ActionMenuDialogView>
		);
	}

	render(): JSX.Element {
		return (
			<>
				{this.renderItem()}
				{this.renderDialog()}
			</>
		);
	}
}

export default DialogActionView;
