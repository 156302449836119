import React, { Component } from 'react';
import { Grid, TextField, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { ActionMenuItemProps } from '../../../../shared/views/ActionMenu/ActionMenu';
import DialogActionView from '../../../../shared/views/ActionMenu/DialogAction.view';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-unused-vars
const styles = (theme: Theme) => {
	return createStyles({
		checkField: {
			overflowY: 'visible',
			height: 100
		},
		emphasized: {
			fontWeight: 'bold'
		}
	});
};

interface Props extends ActionMenuItemProps, WithStyles<typeof styles> {
	handleDelete: (check: string) => void;
	firstName: string;
	lastName: string;
	email: string;
}

interface State {
	check: string;
	checkValid: boolean;
}

const initialState = {
	check: '',
	checkValid: false
};

class DeleteUserActionView extends Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = initialState;
		this.handleOpenDialog = this.handleOpenDialog.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.isSubmitDisabled = this.isSubmitDisabled.bind(this);
	}

	handleOpenDialog(): void {
		this.setState(initialState);
	}

	handleSubmit(): void {
		this.props.handleDelete(this.state.check);
	}

	handleChange(event: React.ChangeEvent<{ value: string }>): void {
		const newCheck = event.target.value;
		this.setState({ check: newCheck, checkValid: newCheck.trim() !== '' });
	}

	isSubmitDisabled(): boolean {
		return !this.state.checkValid;
	}

	render(): JSX.Element {
		const { renderMenuItem, classes, firstName, lastName, email } = this.props;
		const { check } = this.state;
		return (
			<DialogActionView
				itemProps={{
					renderMenuItem,
					icon: <DeleteForeverIcon color="secondary" />,
					text: 'Person löschen'
				}}
				dialogProps={{
					danger: true,
					titleText: 'Person dauerhaft löschen',
					saveButtonText: 'Löschen',
					handleOpen: this.handleOpenDialog,
					handleSave: this.handleSubmit,
					handleIsSaveDisabled: this.isSubmitDisabled
				}}
			>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Typography color="error" className={classes.emphasized}>
							WARNUNG: {firstName} {lastName} ({email}) wird dauerhaft gelöscht! Diese Aktion kann nicht rückgängig
							gemacht werden.
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography>Bitte beantworte die Sicherheitsabfrage!</Typography>
					</Grid>
					<Grid item xs={12}>
						<TextField
							variant="standard"
							id="check"
							name="check"
							label="Sicherheitsabfrage"
							autoComplete="off"
							value={check}
							className={classes.checkField}
							onChange={this.handleChange}
							fullWidth
							autoFocus
						/>
					</Grid>
				</Grid>
			</DialogActionView>
		);
	}
}

export default withStyles(styles)(DeleteUserActionView);
