import React from 'react';
import { Grid } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import SearchFieldView from '../SearchField.view';
import ResetButton from '../Buttons/ResetButton.view';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-unused-vars
const styles = (theme: Theme) => {
	return createStyles({
		searchFieldContainer: {
			maxWidth: '540px'
		},
		resetButtonContainer: {
			paddingTop: '24px !important',
			height: '65px !important'
		}
	});
};

interface Props extends WithStyles<typeof styles> {
	text: string;
	tooltipText?: string;
	label: string;
	placeholder: string;
	handleTextChange: (searchCriteria: string) => void;
	handleReset: () => void;
}

interface State {
	newText: string;
}

class SearchBarWithResetButton extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		const { text } = this.props;
		this.state = { newText: text };
		this.handleTextInstantChange = this.handleTextInstantChange.bind(this);
		this.handleReset = this.handleReset.bind(this);
	}

	handleTextInstantChange(text: string): void {
		this.setState({ newText: text });
	}

	handleReset(): void {
		this.setState({ newText: '' }, () => this.props.handleReset());
	}

	render(): JSX.Element {
		const { handleTextChange: handleSearchTextChange, classes, label, placeholder, tooltipText } = this.props;
		const { newText } = this.state;
		const showResetButton = newText;

		return (
			<>
				<Grid item xs={12} className={classes.searchFieldContainer}>
					<SearchFieldView
						handleDeferredChange={handleSearchTextChange}
						handleInstantChange={this.handleTextInstantChange}
						label={label}
						placeHolder={placeholder}
						value={newText}
						tooltipText={tooltipText}
					/>
				</Grid>
				<Grid item className={classes.resetButtonContainer}>
					{showResetButton && <ResetButton onClick={this.handleReset}>Zurücksetzen</ResetButton>}
				</Grid>
			</>
		);
	}
}

export default withStyles(styles)(SearchBarWithResetButton);
