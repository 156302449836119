import React, { Component } from 'react';
import { Grid } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import EditIcon from '@mui/icons-material/Edit';
import { ActionMenuItemProps } from '../../../../shared/views/ActionMenu/ActionMenu';
import DialogActionView from '../../../../shared/views/ActionMenu/DialogAction.view';
import GroupNameView from '../../../../shared/views/Group/GroupName.view';
import GroupShortNameView from '../../../../shared/views/Group/GroupShortName.view';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-unused-vars
const styles = (theme: Theme) => {
	return createStyles({
		checkField: {
			overflowY: 'visible',
			height: 100
		},
		emphasized: {
			fontWeight: 'bold'
		}
	});
};

interface Props extends ActionMenuItemProps, WithStyles<typeof styles> {
	groupName: string;
	groupShortName: string;
	handleRename: (newName: string, newShortName: string) => void;
}

interface State {
	newName: string;
	newShortName: string;
	isChangeNeeded: boolean;
}

const initialState = {
	isChangeNeeded: false,
	newName: '',
	newShortName: ''
};

class RenameGroupActionView extends Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = initialState;
		this.handleOpenDialog = this.handleOpenDialog.bind(this);
		this.handleNameChange = this.handleNameChange.bind(this);
		this.handleShortNameChange = this.handleShortNameChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.isSubmitDisabled = this.isSubmitDisabled.bind(this);
	}

	handleOpenDialog(): void {
		const { groupName, groupShortName } = this.props;
		this.setState({
			newName: groupName,
			newShortName: groupShortName,
			isChangeNeeded: true
		});
	}

	handleSubmit(): void {
		const { newName, newShortName } = this.state;
		this.props.handleRename(newName, newShortName);
	}

	handleNameChange(newName: string): void {
		const newNameValue = newName;
		this.setState({ newName: newNameValue });
	}

	handleShortNameChange(newShortName: string): void {
		const shortNameValue = newShortName.trim();
		this.setState({ newShortName: shortNameValue });
	}

	isSubmitDisabled(): boolean {
		const { newName, newShortName } = this.state;
		const { groupName, groupShortName } = this.props;
		return (newName === groupName && groupShortName === newShortName) || newName === '' || newShortName === '';
	}

	render(): JSX.Element {
		const { renderMenuItem } = this.props;
		const { newName, newShortName } = this.state;
		return (
			<DialogActionView
				itemProps={{
					renderMenuItem,
					icon: <EditIcon color="primary" />,
					text: 'Name/Kürzel ändern'
				}}
				dialogProps={{
					danger: false,
					titleText: 'Basisinformationen bearbeiten',
					saveButtonText: 'speichern',
					handleOpen: this.handleOpenDialog,
					handleSave: this.handleSubmit,
					handleIsSaveDisabled: this.isSubmitDisabled
				}}
			>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<GroupNameView groupName={newName} handleGroupNameChange={this.handleNameChange} />
					</Grid>
					<Grid item xs={4}>
						<GroupShortNameView groupShortName={newShortName} handleGroupShortNameChange={this.handleShortNameChange} />
					</Grid>
				</Grid>
			</DialogActionView>
		);
	}
}

export default withStyles(styles)(RenameGroupActionView);
