import {
	ClientUserGeneratedContentFlag,
	ClientUserGeneratedContentFlagChat,
	ClientUserGeneratedContentFlagComment,
	ClientUserGeneratedContentFlagDetail,
	ClientUserGeneratedContentFlagGroup,
	ClientUserGeneratedContentFlagPost,
	ClientUserGeneratedContentFlagStatusRecord
} from '@DigitaleDoerfer/digitale-doerfer-api/models';
import { BaseError } from '../../../shared/errors/Errors';
import { INCLUDED_STATUS_MAPPING_UNRESOLVED, IncludedStatusMapping } from '../services/FlaggedContent.service';
import { TableColumnSortingDirection, TableSorting } from '../../../shared/views/Table/TableSorting';
import { Pagination } from '../../../shared/services/InMemoryTable.service';
import { FlagSummary } from '../flagged-content-details/shared/FlaggedContentDetailsCommons';

export enum FlaggedType {
	POST = 'POST',
	COMMENT = 'COMMENT',
	CHAT = 'CHAT',
	GROUP = 'GROUP',
	OTHER = 'OTHER'
}

export interface ListSearchParams {
	includedStatus: IncludedStatusMapping;
	sorting: TableSorting;
	pagination: Pagination;
}

export interface FlaggedContentState {
	list: {
		flaggedContents: ClientUserGeneratedContentFlag[];
		searchParams: ListSearchParams;
		loading: boolean;
		error: BaseError | null;
	};
	detail: {
		type: FlaggedType;
		post: ClientUserGeneratedContentFlagPost | undefined;
		chat: ClientUserGeneratedContentFlagChat | undefined;
		comment: ClientUserGeneratedContentFlagComment | undefined;
		group: ClientUserGeneratedContentFlagGroup | undefined;
		other: ClientUserGeneratedContentFlagDetail | undefined;
		flagId: string;
		loading: boolean;
		statusRecords: ClientUserGeneratedContentFlagStatusRecord[];
		flagSummary: FlagSummary;
	};
}

export const initialFlaggedContentState: FlaggedContentState = {
	list: {
		flaggedContents: [],
		searchParams: {
			includedStatus: INCLUDED_STATUS_MAPPING_UNRESOLVED,
			sorting: {
				columnId: 'lastStatusUpdate',
				direction: TableColumnSortingDirection.DESC
			},
			pagination: {
				page: 0,
				rowsPerPage: 20,
				total: 0
			}
		},
		loading: false,
		error: null
	},
	detail: {
		type: FlaggedType.OTHER,
		post: undefined,
		chat: undefined,
		comment: undefined,
		group: undefined,
		other: undefined,
		flagId: '',
		loading: false,
		statusRecords: [],
		flagSummary: {
			text: undefined,
			tenantName: undefined,
			reason: undefined,
			status: undefined,
			lastStatusUpdate: undefined,
			highlightedCommentId: undefined,
			flagCreator: undefined
		}
	}
};
