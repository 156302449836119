import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PlatformAdminUIState, PlatformAdminUIThunkType } from '../../store/store';
import { getPushEvents } from './store/PushEvent.actions';
import PushEventListView from './PushEventList.view';
import { RouteComponentProps } from 'react-router-dom';
import { PushEventActionTypes } from './store/PushEventActionTypes';
import { ListPushEvent } from './store/PushEvent.state';

interface Props extends RouteComponentProps {
	pushEvents: ListPushEvent[];
	loading: boolean;
	getPushEvents: () => void;
}

class PushEventListContainer extends Component<Props> {
	componentDidMount(): void {
		this.props.getPushEvents();
	}

	render(): JSX.Element {
		const { pushEvents, loading } = this.props;
		return <PushEventListView pushEvents={pushEvents} loading={loading} />;
	}
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: PlatformAdminUIState) => {
	return {
		pushEvents: state.pushEvent.list.pushEvents,
		loading: state.pushEvent.list.loading
	};
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch: PlatformAdminUIThunkType<PushEventActionTypes>) => {
	return {
		getPushEvents: (): Promise<void> => dispatch(getPushEvents())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PushEventListContainer);
