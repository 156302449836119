import React from 'react';
import { ClickAwayListener, IconButton, Paper, Popper } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { Theme } from '@mui/material/styles';

import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-unused-vars
const styles = (theme: Theme) => {
	return createStyles({
		button: {
			padding: 0
		},
		paperContainer: {
			backgroundColor: '#494B4F',
			color: 'white'
		}
	});
};

type Props = React.PropsWithChildren<object> & WithStyles<typeof styles>;

interface State {
	anchorEl: HTMLElement | null;
}

class ClickInfoTooltipView extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			anchorEl: null
		};
	}

	toggleAnchor(target: HTMLElement | null): void {
		this.setState({ anchorEl: this.state.anchorEl ? null : target });
	}

	render(): JSX.Element {
		const { classes } = this.props;
		const { anchorEl } = this.state;
		return (
			<ClickAwayListener
				mouseEvent="onMouseDown"
				onClickAway={(): void => {
					this.toggleAnchor(null);
				}}
			>
				{/* wrapper is required for ClickAwayListener */}
				<span>
					<IconButton
						className={classes.button}
						onMouseDown={(event): void => {
							this.toggleAnchor(event.currentTarget);
						}}
						onKeyDown={(event): void => {
							if (['Enter', ' '].includes(event.key)) {
								this.toggleAnchor(event.currentTarget);
							}
						}}
						size="large"
					>
						<InfoIcon />
					</IconButton>
					<Popper open={!!anchorEl} anchorEl={anchorEl} placement="bottom-start">
						<Paper elevation={8} className={classes.paperContainer}>
							{this.props.children}
						</Paper>
					</Popper>
				</span>
			</ClickAwayListener>
		);
	}
}

export default withStyles(styles)(ClickInfoTooltipView);
