import { ThunkAction } from 'redux-thunk';
import {
	AppConfigActionTypes,
	GET_APP_CONFIGS_FAILURE,
	GET_APP_CONFIGS_REQUEST,
	GET_APP_CONFIGS_SUCCESS,
	GetAppConfigsFailureAction,
	GetAppConfigsRequestAction,
	GetAppConfigsSuccessAction
} from './AppConfigActionTypes';
import { BaseError } from '../../../shared/errors/Errors';
import { wrapIntoErrorObject } from '../../../shared/errors/ErrorWrapper';
import { PlatformAdminUIState, PlatformAdminUIThunkType } from '../../../store/store';
import { ClientTenantAndAppVariantEntry } from '@DigitaleDoerfer/digitale-doerfer-api/models';
import { sharedAdminuiAppApi } from '../../../ServiceFactory';

export type ThunkResult<R> = ThunkAction<R, PlatformAdminUIState, undefined, AppConfigActionTypes>;

export const getAppConfigsRequest = (): GetAppConfigsRequestAction => {
	return {
		type: GET_APP_CONFIGS_REQUEST
	};
};

export const getAppConfigsSuccess = (appConfigs: ClientTenantAndAppVariantEntry[]): GetAppConfigsSuccessAction => {
	return {
		type: GET_APP_CONFIGS_SUCCESS,
		appConfigs
	};
};

export const getAppConfigsFailure = (error: BaseError): GetAppConfigsFailureAction => {
	return {
		type: GET_APP_CONFIGS_FAILURE,
		error
	};
};

export const getAppConfigs = (): ThunkResult<Promise<void>> => {
	return async (
		dispatch: PlatformAdminUIThunkType<AppConfigActionTypes>,
		getState: () => PlatformAdminUIState
	): Promise<void> => {
		try {
			dispatch(getAppConfigsRequest());

			// Let's get the app configurations
			const response = await sharedAdminuiAppApi().appVariantByTenantUsingGET({});

			// Prepate data to dispatch getAppConfigsSuccess
			const appConfigs = response ? response : [];
			dispatch(getAppConfigsSuccess(appConfigs));
		} catch (error) {
			dispatch(getAppConfigsFailure(wrapIntoErrorObject(error)));
		}
	};
};
