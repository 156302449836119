import { ThunkAction } from 'redux-thunk';
import {
	GET_PUSH_EVENTS_FAILURE,
	GET_PUSH_EVENTS_REQUEST,
	GET_PUSH_EVENTS_SUCCESS,
	GetPushEventsFailureAction,
	GetPushEventsRequestAction,
	GetPushEventsSuccessAction,
	PushEventActionTypes
} from './PushEventActionTypes';
import { BaseError } from '../../../shared/errors/Errors';
import { wrapIntoErrorObject } from '../../../shared/errors/ErrorWrapper';
import { PlatformAdminUIState, PlatformAdminUIThunkType } from '../../../store/store';
import { sharedAdminuiPushApi } from '../../../ServiceFactory';
import { ListPushEvent } from './PushEvent.state';
import { ClientPushEventDocumentation } from '@DigitaleDoerfer/digitale-doerfer-api/models';

export type ThunkResult<R> = ThunkAction<R, PlatformAdminUIState, undefined, PushEventActionTypes>;

export const getPushEventsRequest = (): GetPushEventsRequestAction => {
	return {
		type: GET_PUSH_EVENTS_REQUEST
	};
};

export const getPushEventsSuccess = (pushEvents: ListPushEvent[]): GetPushEventsSuccessAction => {
	return {
		type: GET_PUSH_EVENTS_SUCCESS,
		pushEvents
	};
};

export const getPushEventsFailure = (error: BaseError): GetPushEventsFailureAction => {
	return {
		type: GET_PUSH_EVENTS_FAILURE,
		error
	};
};

export const getPushEvents = (): ThunkResult<Promise<void>> => {
	return async (
		dispatch: PlatformAdminUIThunkType<PushEventActionTypes>,
		getState: () => PlatformAdminUIState
	): Promise<void> => {
		try {
			dispatch(getPushEventsRequest());

			const response = await sharedAdminuiPushApi().getPushEventOverviewJsonUsingGET({});

			// Prepate data to dispatch getPushedEventsSuccess
			const pushEvents = response
				? response.map((pushEvent: ClientPushEventDocumentation, index: number) => ({ ...pushEvent, id: index }))
				: [];
			dispatch(getPushEventsSuccess(pushEvents));
		} catch (error) {
			dispatch(getPushEventsFailure(wrapIntoErrorObject(error)));
		}
	};
};
