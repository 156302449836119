import React from 'react';
import { Grid } from '@mui/material';
import { ReadOnlyTextFieldView } from '../../../../shared/views/ReadOnlyTextField.view';

interface Props {
	flagPost?: {
		newsItem?: {
			url?: string;
		};
		happening?: {
			url?: string;
		};
	};
}

class FlaggedContentDetailsUrlView extends React.Component<Props> {
	render(): JSX.Element | null {
		const { flagPost } = this.props;
		const url = flagPost?.newsItem?.url || flagPost?.happening?.url;

		if (!url) {
			return null; // If URL is null, don't render anything
		}

		return (
			<Grid item xs={12}>
				<ReadOnlyTextFieldView
					id="url"
					name="url"
					label="URL"
					value={url}
					InputProps={{ readOnly: true }}
					InputLabelProps={{
						shrink: true
					}}
					fullWidth
				/>
			</Grid>
		);
	}
}

export default FlaggedContentDetailsUrlView;
