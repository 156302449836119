import React, { Component, PropsWithChildren } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Theme } from '@mui/material/styles';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import CancelButton from '../Buttons/CancelButton.view';
import ConfirmButton from '../Buttons/ConfirmButton.view';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const styles = (theme: Theme) => {
	return createStyles({
		content: {
			padding: theme.spacing(2),
			paddingTop: theme.spacing(6),
			paddingBottom: theme.spacing(5)
		},
		title: {
			padding: theme.spacing(2)
		},
		closeButton: {
			position: 'absolute',
			right: theme.spacing(1),
			top: theme.spacing(1),
			color: theme.palette.grey[500]
		},
		actions: {
			margin: 0,
			padding: theme.spacing(2)
		}
	});
};

interface Props extends WithStyles<typeof styles> {
	danger?: boolean;
	titleText: string;
	saveButtonText: string;
	handleSave: () => void;
	wide?: boolean;
	ignoreKeyDownKeys?: string[];
	handleIsSaveDisabled?: () => boolean;
	handleClose: () => void;
}

class ActionMenuDialogView extends Component<PropsWithChildren<Props>> {
	constructor(props: PropsWithChildren<Props>) {
		super(props);

		this.handleSave = this.handleSave.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleKeyDown = this.handleKeyDown.bind(this);
	}

	handleSave(): void {
		this.props.handleSave();
		this.handleClose();
	}

	handleClose(): void {
		this.props.handleClose();
	}

	handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>): void {
		const { handleSave, ignoreKeyDownKeys } = this.props;
		if (!ignoreKeyDownKeys || (ignoreKeyDownKeys && ignoreKeyDownKeys.indexOf(event.key) === -1)) {
			if (event.key === 'Enter') {
				handleSave();
				this.handleClose();
				event.preventDefault();
			} else if (event.key === 'Escape') {
				this.handleClose();
				event.preventDefault();
			}
			event.stopPropagation();
		}
	}

	renderTitle(): JSX.Element {
		const { titleText, classes } = this.props;
		return (
			<DialogTitle id="title" className={classes.title}>
				<Typography variant="h6">{titleText}</Typography>
				<IconButton aria-label="close" className={classes.closeButton} onClick={this.handleClose} size="large">
					<CloseIcon />
				</IconButton>
			</DialogTitle>
		);
	}

	renderContent(): JSX.Element {
		const { classes, children } = this.props;
		return (
			<DialogContent dividers className={classes.content}>
				{children}
			</DialogContent>
		);
	}

	renderActions(): JSX.Element {
		const { danger, saveButtonText, handleIsSaveDisabled, classes } = this.props;
		return (
			<DialogActions className={classes.actions}>
				<Grid container item alignContent="flex-start" justifyContent="flex-end" spacing={3}>
					<Grid item>
						<CancelButton onClick={this.handleClose} danger={danger}>
							Abbrechen
						</CancelButton>
					</Grid>
					<Grid item>
						<ConfirmButton
							disabled={handleIsSaveDisabled ? handleIsSaveDisabled() : false}
							onClick={this.handleSave}
							danger={danger}
						>
							{saveButtonText}
						</ConfirmButton>
					</Grid>
				</Grid>
			</DialogActions>
		);
	}

	render(): JSX.Element {
		const { wide } = this.props;
		return (
			<Dialog
				aria-labelledby="title"
				open={true}
				fullWidth
				maxWidth={wide ? 'lg' : undefined}
				onKeyDown={this.handleKeyDown}
			>
				{this.renderTitle()}
				{this.renderContent()}
				{this.renderActions()}
			</Dialog>
		);
	}
}

export default withStyles(styles)(ActionMenuDialogView);
