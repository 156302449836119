import React from 'react';
import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Switch, Typography } from '@mui/material';
import { ClientGroupExtendedAccessibilityEnum } from '@DigitaleDoerfer/digitale-doerfer-api/models';
import ClickInfoTooltipView from '../../../shared/views/ClickInfoTooltip.view';

const OPEN_TYPE_MESSAGE = 'Der Inhalt der Gruppe ist für alle Nutzer der Anwendung sichtbar.';
const PRIVATE_TYPE_MESSAGE = 'Inhalt nach außen sichtbar.';

interface Props {
	groupContentVisibility: boolean;
	groupType: ClientGroupExtendedAccessibilityEnum | null;
	handleGroupTypeChange: (event: React.ChangeEvent<{ value: string }>) => void;
	handleGroupContentVisibilityChange: (event: React.ChangeEvent<{ checked: boolean }>) => void;
}

const GroupTypeView = (props: Props): JSX.Element => {
	const { groupType, groupContentVisibility, handleGroupTypeChange, handleGroupContentVisibilityChange } = props;
	return (
		<>
			<FormControl variant="standard">
				<FormLabel>
					Gruppentyp
					<ClickInfoTooltipView>
						<Box width={250}>
							<Box>
								<Typography variant="body2" style={{ color: 'white' }}>
									Öffentlich
								</Typography>
							</Box>
							<Box>
								<Typography variant="body2" style={{ color: 'white' }}>
									Jeder Nutzer kann der Gruppe beitreten. Der Inhalt ist für alle sichtbar.
								</Typography>
							</Box>
							<Box pt={1}>
								<Typography variant="body2" style={{ color: 'white' }}>
									Privat
								</Typography>
							</Box>
							<Box>
								<Typography variant="body2" style={{ color: 'white' }}>
									Nutzer können nur durch Administratoren eingeladen werden.
								</Typography>
							</Box>
						</Box>
					</ClickInfoTooltipView>
				</FormLabel>
				<RadioGroup id="groupType" name="groupType" value={groupType} onChange={handleGroupTypeChange} row>
					<FormControlLabel
						value={ClientGroupExtendedAccessibilityEnum.PUBLIC}
						control={<Radio />}
						label="Öffentlich"
					/>
					<FormControlLabel
						value={ClientGroupExtendedAccessibilityEnum.APPROVALREQUIRED}
						control={<Radio />}
						label="Privat"
					/>
				</RadioGroup>
			</FormControl>
			<Box width={1}>
				{groupType ? (
					<Typography component={'span'} variant="body2">
						{groupType === ClientGroupExtendedAccessibilityEnum.PUBLIC ? (
							<Box height="30px" pt={1}>
								{OPEN_TYPE_MESSAGE}
							</Box>
						) : (
							<Box height="30px">
								{PRIVATE_TYPE_MESSAGE}
								<Switch
									checked={groupContentVisibility}
									value={groupContentVisibility}
									onChange={handleGroupContentVisibilityChange}
									color="primary"
									name="groupContentVisibility"
									id="groupContentVisibility"
									inputProps={{ 'aria-label': 'primary checkbox' }}
								/>
							</Box>
						)}
					</Typography>
				) : (
					<></>
				)}
			</Box>
		</>
	);
};

export default GroupTypeView;
