import { ListType, ValueOf } from './types';

export const getCheckboxColorByType = (type: ValueOf<typeof ListType>): 'primary' | 'secondary' | 'default' => {
	switch (type) {
		case 'appVariant':
			return 'primary';
		case 'tenant':
			return 'secondary';
		case 'contract':
			return 'default';
	}
};

export const getBackgroundColorByType = (type: ValueOf<typeof ListType>): string => {
	switch (type) {
		case 'appVariant':
			return '#f0f8ff';
		case 'tenant':
			return '#fffff0';
		case 'contract':
			return '#f0fff0';
		default:
			return '#fff';
	}
};

/**
 * Get the next page of the paginated list.
 * @param currentPage current page of the paginated list.
 * @returns Next page to be fetchted. undefined when there is no  more that to fetch
 */
export const getNextPage = (currentPage?: number): number | undefined => {
	return currentPage !== undefined ? currentPage + 1 : currentPage;
};

/**
 * Get current page of the paginated list.
 * @param currentPage current page of the paginated list.
 * @returns Current page of the paginated list. undefined when totalPage is the same as currentPage.
 */
export const getCurrentPage = (currentPage?: number, totalPages?: number): number | undefined => {
	return totalPages === currentPage ? undefined : currentPage;
};

/**
 * Checks if the given string input is null or empty (whitespace)
 * @param input input to be chekcked.
 * @returns True if the input is null or empty, false otherwise
 */
export const isEmptyOrNull = (input: string | undefined | null): boolean => {
	return !input?.trim() as boolean;
};

/**
 * Ellipsifies the given input and tructates it to the given size
 * @param input input to be ellipsified
 * @param size expected size of the input after tructate
 * @returns Ellipsified and truncated input
 */
export const ellipsify = (input: string | undefined, size: number): string => {
	// We will never run into this first case but we need it, as sometimes the input can be null.
	// But we are calling isStringEmptyOrNull before calling this method.
	if (!input) return '';
	if (input.length <= size) return input;
	return input.slice(0, size) + '...';
};
