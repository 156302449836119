import React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { ClientUserGeneratedContentFlagStatusRecord } from '@DigitaleDoerfer/digitale-doerfer-api/models';
import { Divider, Grid, Typography } from '@mui/material';
import { flaggedContentViewService, timeService } from '../../../../ServiceFactory';
import { TimestampFormat } from '../../../../shared/services/Time.service';
import { commonStyles, FlagSummary } from './FlaggedContentDetailsCommons';
import { getCompletePersonName } from '../../../../shared/views/PersonDetail/PersonName.view';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
	...commonStyles,
	...createStyles({
		root: {
			width: '100%',
			backgroundColor: theme.palette.background.paper
		},
		inline: {
			display: 'inline',
			paddingLeft: 25,
			fontStyle: 'Italic'
		},
		primary: {
			display: 'inline'
		},
		status: {
			fontStyle: 'Italic'
		}
	})
}));

export interface Props {
	flagSummary: FlagSummary;
	statusRecords: ClientUserGeneratedContentFlagStatusRecord[];
}

function FlaggedContentStatusHistoryView(props: Props): JSX.Element {
	const classes = useStyles();
	const { statusRecords, flagSummary } = props;
	const createdTime = flaggedContentViewService().renderInfo(
		statusRecords && statusRecords[0] && statusRecords[0].created
			? timeService().parseTimestamp(statusRecords[0].created, TimestampFormat.DD_MM_YYYY_HH_MM)
			: undefined
	);
	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Typography variant="h2" gutterBottom className={classes.typographyTitle}>
					Statusverlauf
				</Typography>
			</Grid>
			<Grid item xs={12} className={classes.gridFirstItem}>
				<List className={classes.root}>
					<Divider component="li" />
					{statusRecords.map((statusRecord, i, list) => {
						const initiator = statusRecord.initiator;
						const previousStatusRecord = list[i - 1];
						const previousStatus =
							previousStatusRecord && previousStatusRecord.status
								? flaggedContentViewService().translateFlagStatusDE(previousStatusRecord.status)
								: '';
						const currentStatus = statusRecord.status
							? flaggedContentViewService().translateFlagStatusDE(statusRecord.status)
							: '';
						const statusUpdate = statusRecord.created
							? timeService().parseTimestamp(statusRecord.created, TimestampFormat.DD_MM_YYYY_HH_MM)
							: undefined;
						let statusText;
						const isFirstStatusRecord = i === 0;
						if (!flagSummary.flagCreator) {
							statusText = (
								<Typography component="span" variant="h6" className={classes.primary} color="textPrimary">
									{`Dieser Inhalt wurde von KI gemeldet - ${createdTime}`}
								</Typography>
							);
						} else {
							if (isFirstStatusRecord) {
								statusText = (
									<Typography component="span" variant="h6" className={classes.primary} color="textPrimary">
										{`${getCompletePersonName(flagSummary?.flagCreator)} hat den Inhalt gemeldet - ${createdTime}`}
									</Typography>
								);
							} else if (previousStatus === currentStatus) {
								const text = initiator
									? `${getCompletePersonName(initiator)} hat die Meldung kommentiert - ${statusUpdate}`
									: `Meldung wurde kommentiert - ${statusUpdate}`;
								statusText = (
									<Typography component="span" variant="h6" className={classes.primary}>
										{text}
									</Typography>
								);
							} else {
								const text = initiator ? `${getCompletePersonName(initiator)} hat den Status von` : `Status wurde von`;
								statusText = (
									<Typography component="span" variant="h6" className={classes.primary}>
										{text} <span className={classes.status}>{previousStatus}</span> zu{' '}
										<span className={classes.status}>{currentStatus}</span> geändert - {statusUpdate}
									</Typography>
								);
							}
						}

						return (
							<React.Fragment key={statusRecord.id}>
								<ListItem className={classes.listItem}>
									<ListItemText
										primary={<>{statusText}</>}
										secondary={
											<>
												<Typography
													component="span"
													variant="h6"
													className={clsx(classes.textWrap, classes.inline)}
													color="textPrimary"
												>
													{statusRecord.comment ?? ''}
												</Typography>
											</>
										}
									/>
								</ListItem>
								<Divider component="li" />
							</React.Fragment>
						);
					})}
				</List>
			</Grid>
		</Grid>
	);
}
export default FlaggedContentStatusHistoryView;
