import {
	CommunicationAdminuiFlagApi,
	GrapevineAdminuiFlagApi,
	GrapevineAdminuiGroupApi,
	GrapevineAdminuiGroupEventsApi,
	GrapevineAdminuiNewssourceApi,
	GrapevineAdminuiNewssourceEventsApi,
	GrapevineAdminuiPostEventApi,
	GrapevineAdminuiStatisticsApi,
	GrapevineGroupApi,
	GrapevinePostApi,
	LogisticsPoolingstationApi,
	MobileKioskSellingpointApi,
	ParticipantsAdminuiGeoareaApi,
	ParticipantsAdminuiPersonApi,
	ParticipantsAdminuiPersonEventsApi,
	ParticipantsAdminuiTenantApi,
	ParticipantsCommunityApi,
	ParticipantsGeoareaApi,
	ParticipantsPersonApi,
	ParticipantsPersonEventsApi,
	ParticipantsRoleAssignmentApi,
	ParticipantsRoleAssignmentEventsApi,
	ParticipantsShopApi,
	SharedAdminDataprivacyApi,
	SharedAdminuiAppApi,
	SharedAdminuiFlagApi,
	SharedAdminuiFlagEventsApi,
	SharedAdminuiPushApi,
	SharedAdminuiStatisticsApi,
	SharedMediaApi
} from '@DigitaleDoerfer/digitale-doerfer-api/apis';
import { BaseAPI } from '@DigitaleDoerfer/digitale-doerfer-api/runtime';

import { ImageService } from './shared/services/Image.service';
import DigitaleDoerferAPIFactory from './shared/utils/DigitaleDoerferAPIFactory';
import { TimeService } from './shared/services/Time.service';
import { RoleService } from './shared/services/Role.service';
import { RoleAssignmentRelatedEntityService } from './shared/services/RoleAssignmentRelatedEntity.service';
import { FlaggedContentService } from './modules/flagged-contents/services/FlaggedContent.service';
import { FlaggedContentViewService } from './modules/flagged-contents/services/FlaggedContent.view.service';
import { InMemoryTableService } from './shared/services/InMemoryTable.service';
import { GeoAreaService } from './shared/services/GeoArea.service';

interface ClassWithNoArgsConstructor<T> {
	new (): T;
}

export abstract class AbstractServiceFactory {
	private initializingServices: Set<string> = new Set<string>();
	private initializedServices: string[] = [];

	/**
	 * Only for unit testing. Do not call or even rely on this data in production code.
	 */
	public getInitializedServices(): string[] {
		// using slice to create a copy
		return this.initializedServices.slice();
	}

	protected lazyInit<T>(uniqueServiceName: string, init: () => T): () => T {
		let value: T;

		return (): T => {
			if (!value) {
				if (this.initializingServices.has(uniqueServiceName)) {
					throw new Error(
						`Trying to re-initialize already initialized service ${uniqueServiceName}. This is ` +
							`a possible indication for cyclic dependencies. Init trace: ${this.initializedServices}`
					);
				}
				this.initializingServices.add(uniqueServiceName);
				value = init();
				this.initializingServices.delete(uniqueServiceName);
				this.initializedServices.push(uniqueServiceName);
			}
			return value;
		};
	}

	protected lazyInitSimpleService<T>(uniqueServiceName: string, Service: ClassWithNoArgsConstructor<T>): () => T {
		return this.lazyInit(uniqueServiceName, () => new Service());
	}

	protected lazyInitApiWithMiddleWare<T extends BaseAPI>(
		uniqueServiceName: string,
		Api: ClassWithNoArgsConstructor<T>
	): () => T {
		return this.lazyInit(uniqueServiceName, () => DigitaleDoerferAPIFactory.createDigitaleDoerferAPI(new Api()));
	}
}

/**
 * Main service factory. This class is exported only for unit testing. Never use or instantiate manually in production code.
 *
 * Unfortunately, during production build, the name attributes of classes are minified and become ambiguous. Thus, we have
 * to specify a unique name for every service. Currently, this is just the service name itself. You should also name the
 * attribute exactly the same (only starting with a small letter). This is tested in ServiceFactory.spec.ts. So if this
 * test fails, you either introduced a cyclic dependency or you misspelled a service name.
 */
export class MainServiceFactory extends AbstractServiceFactory {
	// API Services
	participantsPersonApi = this.lazyInitApiWithMiddleWare<ParticipantsPersonApi>(
		'ParticipantsPersonApi',
		ParticipantsPersonApi
	);
	participantsAdminuiPersonApi = this.lazyInitApiWithMiddleWare<ParticipantsAdminuiPersonApi>(
		'ParticipantsAdminuiPersonApi',
		ParticipantsAdminuiPersonApi
	);
	grapevineAdminuiGroupApi = this.lazyInitApiWithMiddleWare<GrapevineAdminuiGroupApi>(
		'GrapevineAdminuiGroupApi',
		GrapevineAdminuiGroupApi
	);
	participantsPersonEventsApi = this.lazyInitApiWithMiddleWare<ParticipantsPersonEventsApi>(
		'ParticipantsPersonEventsApi',
		ParticipantsPersonEventsApi
	);
	participantsRoleAssignmentApi = this.lazyInitApiWithMiddleWare<ParticipantsRoleAssignmentApi>(
		'ParticipantsRoleAssignmentApi',
		ParticipantsRoleAssignmentApi
	);
	participantsCommunityApi = this.lazyInitApiWithMiddleWare<ParticipantsCommunityApi>(
		'ParticipantsCommunityApi',
		ParticipantsCommunityApi
	);
	participantsRoleAssignmentEventsApi = this.lazyInitApiWithMiddleWare<ParticipantsRoleAssignmentEventsApi>(
		'ParticipantsRoleAssignmentEventsApi',
		ParticipantsRoleAssignmentEventsApi
	);
	logisticsPoolingstationApi = this.lazyInitApiWithMiddleWare<LogisticsPoolingstationApi>(
		'LogisticsPoolingstationApi',
		LogisticsPoolingstationApi
	);
	grapevineGroupApi = this.lazyInitApiWithMiddleWare<GrapevineGroupApi>('GrapevineGroupApi', GrapevineGroupApi);
	participantsShopApi = this.lazyInitApiWithMiddleWare<ParticipantsShopApi>('ParticipantsShopApi', ParticipantsShopApi);
	mobileKioskSellingpointApi = this.lazyInitApiWithMiddleWare<MobileKioskSellingpointApi>(
		'MobileKioskSellingpointApi',
		MobileKioskSellingpointApi
	);
	participantsAdminuiPersonEventsApi = this.lazyInitApiWithMiddleWare<ParticipantsAdminuiPersonEventsApi>(
		'ParticipantsAdminuiPersonEventsApi',
		ParticipantsAdminuiPersonEventsApi
	);
	sharedAdminDataprivacyApi = this.lazyInitApiWithMiddleWare<SharedAdminDataprivacyApi>(
		'SharedAdminDataprivacyApi',
		SharedAdminDataprivacyApi
	);
	sharedAdminuiFlagApi = this.lazyInitApiWithMiddleWare<SharedAdminuiFlagApi>(
		'SharedAdminuiFlagApi',
		SharedAdminuiFlagApi
	);
	grapevineAdminuiFlagApi = this.lazyInitApiWithMiddleWare<GrapevineAdminuiFlagApi>(
		'GrapevineAdminuiFlagApi',
		GrapevineAdminuiFlagApi
	);
	sharedAdminuiFlagEventsApi = this.lazyInitApiWithMiddleWare<SharedAdminuiFlagEventsApi>(
		'SharedAdminuiFlagEventsApi',
		SharedAdminuiFlagEventsApi
	);
	participantsGeoareaApi = this.lazyInitApiWithMiddleWare<ParticipantsGeoareaApi>(
		'ParticipantsGeoareaApi',
		ParticipantsGeoareaApi
	);
	participantsAdminuiGeoareaApi = this.lazyInitApiWithMiddleWare<ParticipantsAdminuiGeoareaApi>(
		'ParticipantsAdminuiGeoareaApi',
		ParticipantsAdminuiGeoareaApi
	);
	communicationAdminuiFlagApi = this.lazyInitApiWithMiddleWare<CommunicationAdminuiFlagApi>(
		'CommunicationAdminuiFlagApi',
		CommunicationAdminuiFlagApi
	);
	grapevineAdminuiGroupEventsApi = this.lazyInitApiWithMiddleWare<GrapevineAdminuiGroupEventsApi>(
		'GrapevineAdminuiGroupEventsApi',
		GrapevineAdminuiGroupEventsApi
	);
	sharedAdminuiAppApi = this.lazyInitApiWithMiddleWare<SharedAdminuiAppApi>('SharedAdminuiAppApi', SharedAdminuiAppApi);
	sharedAdminuiPushApi = this.lazyInitApiWithMiddleWare<SharedAdminuiPushApi>(
		'SharedAdminuiPushApi',
		SharedAdminuiPushApi
	);
	sharedAdminuiStatisticsApi = this.lazyInitApiWithMiddleWare<SharedAdminuiStatisticsApi>(
		'SharedAdminuiStatisticsApi',
		SharedAdminuiStatisticsApi
	);
	grapevineAdminuiStatisticsApi = this.lazyInitApiWithMiddleWare<GrapevineAdminuiStatisticsApi>(
		'GrapevineAdminuiStatisticsApi',
		GrapevineAdminuiStatisticsApi
	);
	participantsAdminuiTenantApi = this.lazyInitApiWithMiddleWare<ParticipantsAdminuiTenantApi>(
		'ParticipantsAdminuiTenantApi',
		ParticipantsAdminuiTenantApi
	);

	grapevineAdminuiNewssourceApi = this.lazyInitApiWithMiddleWare<GrapevineAdminuiNewssourceApi>(
		'GrapevineAdminuiNewssourceApi',
		GrapevineAdminuiNewssourceApi
	);
	grapevineAdminuiNewssourceEventsApi = this.lazyInitApiWithMiddleWare<GrapevineAdminuiNewssourceEventsApi>(
		'GrapevineAdminuiNewssourceEventsApi',
		GrapevineAdminuiNewssourceEventsApi
	);
	grapevineAdminuiPostEventApi = this.lazyInitApiWithMiddleWare<GrapevineAdminuiPostEventApi>(
		'GrapevineAdminuiPostEventApi',
		GrapevineAdminuiPostEventApi
	);
	grapevinePostApi = this.lazyInitApiWithMiddleWare<GrapevinePostApi>('GrapevinePostApi', GrapevinePostApi);
	sharedMediaApi = this.lazyInitApiWithMiddleWare<SharedMediaApi>('SharedMediaApi', SharedMediaApi);
	// Business Services
	imageService = this.lazyInit('ImageService', () => new ImageService());
	timeService = this.lazyInit('TimeService', () => new TimeService());
	roleService = this.lazyInit('RoleService', () => new RoleService(this.participantsRoleAssignmentApi()));
	roleAssignmentRelatedEntityService = this.lazyInit(
		'RoleAssignmentRelatedEntityService',
		() =>
			new RoleAssignmentRelatedEntityService(
				this.grapevineAdminuiGroupApi(),
				this.grapevineAdminuiNewssourceApi(),
				this.participantsCommunityApi(),
				this.participantsRoleAssignmentApi(),
				this.roleService()
			)
	);
	flaggedContentService = this.lazyInit('FlaggedContentService', () => new FlaggedContentService(this.timeService()));
	flaggedContentViewService = this.lazyInit('FlaggedContentViewService', () => new FlaggedContentViewService());
	inMemoryTableService = this.lazyInit('InMemoryTableService', () => new InMemoryTableService());
	geoAreaService = this.lazyInit('GeoAreaService', () => new GeoAreaService());
}

const mainServiceFactory = new MainServiceFactory();

// API Services
export const participantsPersonApi = mainServiceFactory.participantsPersonApi;
export const participantsAdminuiPersonApi = mainServiceFactory.participantsAdminuiPersonApi;
export const grapevineAdminuiGroupApi = mainServiceFactory.grapevineAdminuiGroupApi;
export const participantsPersonEventsApi = mainServiceFactory.participantsPersonEventsApi;
export const participantsRoleAssignmentApi = mainServiceFactory.participantsRoleAssignmentApi;
export const participantsCommunityApi = mainServiceFactory.participantsCommunityApi;
export const participantsRoleAssignmentEventsApi = mainServiceFactory.participantsRoleAssignmentEventsApi;
export const logisticsPoolingstationApi = mainServiceFactory.logisticsPoolingstationApi;
export const grapevineGroupApi = mainServiceFactory.grapevineGroupApi;
export const participantsShopApi = mainServiceFactory.participantsShopApi;
export const mobileKioskSellingpointApi = mainServiceFactory.mobileKioskSellingpointApi;
export const participantsAdminuiPersonEventsApi = mainServiceFactory.participantsAdminuiPersonEventsApi;
export const sharedAdminDataprivacyApi = mainServiceFactory.sharedAdminDataprivacyApi;
export const sharedAdminFlagUi = mainServiceFactory.sharedAdminuiFlagApi;
export const grapevineAdminuiFlagApi = mainServiceFactory.grapevineAdminuiFlagApi;
export const sharedAdminuiFlagEventsApi = mainServiceFactory.sharedAdminuiFlagEventsApi;
export const participantsGeoareaApi = mainServiceFactory.participantsGeoareaApi;
export const participantsAdminuiGeoareaApi = mainServiceFactory.participantsAdminuiGeoareaApi;
export const communicationAdminuiFlagApi = mainServiceFactory.communicationAdminuiFlagApi;
export const grapevineAdminuiGroupEventsApi = mainServiceFactory.grapevineAdminuiGroupEventsApi;
export const sharedAdminuiAppApi = mainServiceFactory.sharedAdminuiAppApi;
export const sharedAdminuiPushApi = mainServiceFactory.sharedAdminuiPushApi;
export const sharedAdminuiStatisticsApi = mainServiceFactory.sharedAdminuiStatisticsApi;
export const grapevineAdminuiStatisticsApi = mainServiceFactory.grapevineAdminuiStatisticsApi;
export const participantsAdminuiTenantApi = mainServiceFactory.participantsAdminuiTenantApi;
export const grapevineAdminuiNewssourceApi = mainServiceFactory.grapevineAdminuiNewssourceApi;
export const grapevinePostApi = mainServiceFactory.grapevinePostApi;
export const grapevineAdminuiNewssourceEventsApi = mainServiceFactory.grapevineAdminuiNewssourceEventsApi;
export const grapevineAdminuiPostEventApi = mainServiceFactory.grapevineAdminuiPostEventApi;
export const sharedMediaApi = mainServiceFactory.sharedMediaApi;
// Business Services
export const imageService = mainServiceFactory.imageService;
export const timeService = mainServiceFactory.timeService;
export const roleService = mainServiceFactory.roleService;
export const roleAssignmentRelatedEntityService = mainServiceFactory.roleAssignmentRelatedEntityService;
export const flaggedContentService = mainServiceFactory.flaggedContentService;
export const flaggedContentViewService = mainServiceFactory.flaggedContentViewService;
export const inMemoryTableService = mainServiceFactory.inMemoryTableService;
export const geoAreaService = mainServiceFactory.geoAreaService;
