import React from 'react';
import { Avatar } from '@mui/material';
import apple from '../../../assets/images/social-media/apple.svg';
import google from '../../../assets/images/social-media/google.svg';
import facebook from '../../../assets/images/social-media/facebook.svg';
import database from '../../../assets/images/database.svg';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
	iconsContainer: {
		paddingLeft: '5px',
		paddingRight: '5px',
		display: 'flex'
	}
}));
export interface Props {
	authenticationMethods: string[];
}

function SocialMediaListView(props: Props): JSX.Element {
	const { authenticationMethods } = props;
	const classes = useStyles();
	const iconsList = [];
	if (authenticationMethods.includes('USERNAME_PASSWORD')) {
		iconsList.push(<Avatar key="1" src={database} className="icon-sm" />);
	}
	if (authenticationMethods.includes('GOOGLE')) {
		iconsList.push(<Avatar key="2" src={google} className="icon-sm" />);
	}
	if (authenticationMethods.includes('FACEBOOK')) {
		iconsList.push(<Avatar key="3" src={facebook} className="icon-sm" />);
	}
	if (authenticationMethods.includes('APPLE')) {
		iconsList.push(<Avatar key="4" src={apple} className="icon-sm" />);
	}
	return <div className={classes.iconsContainer}>{iconsList}</div>;
}
export default SocialMediaListView;
