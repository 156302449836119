import { ClientPushEventDocumentation } from '@DigitaleDoerfer/digitale-doerfer-api/models';
import { BaseError } from '../../../shared/errors/Errors';

export type ListPushEvent = ClientPushEventDocumentation & { id: number };

export interface PushEventState {
	list: {
		pushEvents: ListPushEvent[];
		loading: boolean;
		error: BaseError | null;

		searchCriteria: string;
	};
}

export const initialPushEventState: PushEventState = {
	list: {
		pushEvents: [],
		loading: false,
		error: null,

		searchCriteria: ''
	}
};
