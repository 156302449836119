import React from 'react';
import { ActionButtonProps } from './ButtonProps';
import BaseButton from './BaseButton.view';

function ResetButton(props: ActionButtonProps): JSX.Element {
	return (
		<BaseButton color="primary" variant="text" {...props}>
			{props.children}
		</BaseButton>
	);
}

export default ResetButton;
