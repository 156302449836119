import React from 'react';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import { PrototypeHomeView } from './PrototypeHome.view';
import { NotFoundErrorView } from '../modules/error/ErrorPages.view';
import { PrototypeUserDetailsView } from './PrototypeUserDetails.view';

export const PROTOTYPES_URL = '/prototypes';

const PROTOTYPE_USER_DETAIL_URL = PROTOTYPES_URL + '/users/details';

interface Props extends RouteComponentProps {}

class PrototypesRoutingContainer extends React.Component<Props> {
	render(): JSX.Element {
		const {
			match: { url }
		} = this.props;

		return (
			<Switch>
				<Route exact path={url} component={PrototypeHomeView} />
				<Route path={PROTOTYPE_USER_DETAIL_URL} component={PrototypeUserDetailsView} />
				<Route component={NotFoundErrorView} />
			</Switch>
		);
	}
}

export default withRouter(PrototypesRoutingContainer);
