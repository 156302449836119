import React, { FC } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { ClientStatisticsReportReference } from '@DigitaleDoerfer/digitale-doerfer-api';
import StatisticsReportDownloadButton from './StatisticsReportDownload';

interface StatisticsViewProps {
	statisticsReferences: ClientStatisticsReportReference[];
}

const StatisticsView: FC<StatisticsViewProps> = ({ statisticsReferences }: StatisticsViewProps) => {
	const TimeUnitGermanNames = new Map<string, string>([
		['DAYS', 'Täglich'],
		['WEEKS', 'Wöchentlich'],
		['MONTHS', 'Monatlich']
	]);

	return (
		<>
			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Dateiname</TableCell>
							<TableCell>Zeiteinheit</TableCell>
							<TableCell>Download</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{statisticsReferences.map(row => (
							<TableRow key={row.name} hover={true}>
								<TableCell>{row.name}</TableCell>
								<TableCell>{TimeUnitGermanNames.get(row.timeUnit) || row.timeUnit}</TableCell>
								<TableCell>
									<StatisticsReportDownloadButton buttonText="CSV" fileName={row.fileNameCsv} />
									<StatisticsReportDownloadButton buttonText="Readme" fileName={row.fileNameCsvMetadata} />
									<StatisticsReportDownloadButton buttonText="TXT" fileName={row.fileNameText} />
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
};

export default StatisticsView;
