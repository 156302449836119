import { blue } from '@mui/material/colors';
import React, { FC, useEffect } from 'react';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Badge,
	CircularProgress,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Theme,
	Typography
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ExpandMore as ExpandMoreIcon, Clear as RemoveAllIcon, HighlightOff as RemoveIcon } from '@mui/icons-material';
import { getBackgroundColorByType } from '../helpers';
import useScroll from '../hooks/useScroll';
import { useStore } from '../store';
import { Contract, ListType } from '../types';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			margin: theme.spacing(1),
			padding: 0,

			backgroundColor: getBackgroundColorByType(ListType.CONTRACT),
			'&.Mui-expanded': {
				margin: theme.spacing(1),
				'&:first-child': {
					margin: theme.spacing(1)
				}
			},
			'& > .MuiAccordionSummary-root': {
				marginBottom: 5,
				height: 30,
				minHeight: 20,
				'& > .MuiAccordionSummary-content': {
					margin: 0
				}
			}
		},
		listItem: {
			backgroundColor: getBackgroundColorByType(ListType.CONTRACT),
			transition: theme.transitions.create(['background-color', 'transform'], {
				duration: '2s'
			}),

			width: '100%',
			padding: 0
		},
		listItemHighlighted: {
			backgroundColor: blue[100]
		},
		listItemText: {
			'& .clickable': {}
		},

		clickable: {
			'&:hover': {
				cursor: 'pointer'
			}
		},

		listItemIcon: {
			justifyContent: 'flex-end',
			'&:hover': {
				cursor: 'pointer'
			}
		},
		badge: {
			left: 0,
			top: 5,
			border: `2px solid ${theme.palette.background.paper}`,
			padding: '0 4px',
			marginLeft: 20,
			'& .MuiBadge-colorPrimary': {
				backgroundColor: 'rgb(27 185 27)'
			}
		}
	})
);

type SelectedContractListItemProps = {
	contract: Contract;
	onRemoveItemClick: () => void;
	highlighted: boolean;
};

const SelectedContractListItem: FC<SelectedContractListItemProps> = ({
	contract,
	onRemoveItemClick,
	highlighted
}: SelectedContractListItemProps) => {
	const classes = useStyles();
	const [executeScroll, elRef] = useScroll<HTMLLIElement>(false);
	const { setHighlightedGeoAreaIds, clearHighlightedGeoAreaIds, setSelectedListItem } = useStore();

	const allGeoAreaIds = [...(contract.geoAreaIdsIncluded ?? []), ...(contract.geoAreaIdsExcluded ?? [])];
	const hasGeoAreas = allGeoAreaIds.length > 0;

	useEffect(() => {
		if (highlighted) {
			executeScroll();
		}
	}, [highlighted, executeScroll]);

	const handleItemTextClick = (): void => {
		if (hasGeoAreas) {
			setHighlightedGeoAreaIds(allGeoAreaIds);
		} else {
			clearHighlightedGeoAreaIds();
		}
		setSelectedListItem(contract, ListType.CONTRACT);
	};

	return (
		<ListItem
			key={contract.id}
			className={`${classes.listItem} ${highlighted ? classes.listItemHighlighted : ''}`}
			ref={elRef}
		>
			<ListItemText onClick={handleItemTextClick} className={hasGeoAreas ? classes.clickable : ''}>
				{contract.name}
			</ListItemText>
			<ListItemIcon className={classes.listItemIcon} title={'Aus der Selektion entfernen'} onClick={onRemoveItemClick}>
				<RemoveIcon
					color={'error'}
					sx={{
						fontSize: 16
					}}
				/>
			</ListItemIcon>
		</ListItem>
	);
};

type SelectedContractsSectionProps = {
	hasNextPage?: boolean;
};

const SelectedContractsSection: FC<SelectedContractsSectionProps> = ({
	hasNextPage
}: SelectedContractsSectionProps) => {
	const classes = useStyles();
	const { toggleSelectedContract, resetShouldUseAllContracts, shouldUseAllContracts } = useStore();

	const selectedContracts = useStore(store => Object.values(store.selectedContracts));
	const selectedGeoAreaId = useStore(store => store.selectedGeoAreaId);

	const handleUnselectClick = (contract: Contract) => (): void => {
		toggleSelectedContract(contract);
	};

	const handleUnselectAllClick = (): void => {
		resetShouldUseAllContracts();
	};

	return (
		<Accordion className={classes.root} TransitionProps={{ unmountOnExit: true }}>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="panel-contracts-content"
				id="panel-contracts-header"
			>
				<Typography
					sx={{
						fontSize: 15,
						fontWeight: 'regular'
					}}
				>
					Ausgewählte Nutzungsverträge
				</Typography>
				{shouldUseAllContracts && hasNextPage && (
					<CircularProgress
						size="1em"
						sx={{
							marginLeft: 2.5
						}}
					/>
				)}
				{shouldUseAllContracts && !hasNextPage && (
					<Badge color={'primary'} badgeContent={selectedContracts.length} className={classes.badge} max={9999} />
				)}
			</AccordionSummary>
			<AccordionDetails style={{ maxHeight: 200, overflowY: 'scroll' }}>
				<List
					sx={{
						width: '100%'
					}}
				>
					{selectedContracts.length > 0 && (
						<ListItem key={'remove-all-contract-selections'} className={classes.listItem}>
							<ListItemText>Alle entfernen</ListItemText>
							<ListItemIcon
								className={classes.listItemIcon}
								title={'Aus der Selektion entfernen'}
								onClick={handleUnselectAllClick}
							>
								<RemoveAllIcon
									color={'error'}
									sx={{
										fontSize: 16
									}}
								/>
							</ListItemIcon>
						</ListItem>
					)}
					{selectedContracts.map((contract: Contract) => {
						const containsSelectedGeoAreaId = (selectedGeoAreaId &&
							(contract.geoAreaIdsIncluded?.includes(selectedGeoAreaId) ||
								contract.geoAreaIdsExcluded?.includes(selectedGeoAreaId))) as boolean;

						return (
							<SelectedContractListItem
								key={contract.id}
								contract={contract}
								onRemoveItemClick={handleUnselectClick(contract)}
								highlighted={containsSelectedGeoAreaId}
							/>
						);
					})}
				</List>
			</AccordionDetails>
		</Accordion>
	);
};

export default SelectedContractsSection;
