import React from 'react';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import PushEvents from './PushEventList.container';

export const PUSH_EVENTS_URL = '/push-events';

interface Props extends RouteComponentProps {}

class PushEventsRoutingContainer extends React.Component<Props> {
	render(): JSX.Element {
		const {
			match: { url }
		} = this.props;

		return (
			<Switch>
				<Route exact path={url} component={PushEvents} />
			</Switch>
		);
	}
}

export default withRouter(PushEventsRoutingContainer);
