import React, { Component, PropsWithChildren } from 'react';
import { Box, Grid, TableCellProps, Typography } from '@mui/material';
import GroupGeoAreaElementView from './GroupGeoAreaElement.view';
import { AnyGeoArea } from '../../utils/AnyGeoArea';
import { GroupStateChangeHandlers } from '../../../modules/groups/GroupStateChangeHandlers';
import { GeoAreaElement } from '../../utils/GeoAreaElements';

interface Props {
	title?: string;
	groupMembershipAdminGeoAreas?: GeoAreaElement[];
	manualSearchedGeoAreas?: GeoAreaElement[];
	groupStateChangeHandlers?: GroupStateChangeHandlers;
	hideHomeAreaOfAdministratorsTypography?: boolean;
	useAdditionalGeoAreas: boolean;
	readOnly: boolean;
	hideParent?: boolean;
	hideUnselectedChildren?: boolean;
	sliceSizeBeforeExpansion?: number;
	geoAreaElementNodeTableCellProps?: TableCellProps;
}

class GroupGeoAreasView extends Component<PropsWithChildren<Props>> {
	constructor(props: Props) {
		super(props);
		this.handleManualSearchedParentGeoAreaSelection = this.handleManualSearchedParentGeoAreaSelection.bind(this);
		this.handleManualSearchedGeoAreaSelection = this.handleManualSearchedGeoAreaSelection.bind(this);
		this.handleManualParentGeoAreaElementSelection = this.handleManualParentGeoAreaElementSelection.bind(this);
		this.handleManualGeoAreaElementSelection = this.handleManualGeoAreaElementSelection.bind(this);
		this.handleRemoveGeoAreaElement = this.handleRemoveGeoAreaElement.bind(this);
	}

	manualChildGeoAreaSelection(
		isGeoAreaSelected: boolean,
		childGeoArea: AnyGeoArea,
		geoAreaElements: GeoAreaElement[],
		parentGeoArea: AnyGeoArea
	): GeoAreaElement[] {
		const geoAreaElementIndex: number = geoAreaElements.findIndex(
			searchedGeoArea => parentGeoArea.id === searchedGeoArea.geoAreaParent?.id
		);
		geoAreaElements[geoAreaElementIndex].geoAreaChildren = geoAreaElements[geoAreaElementIndex].geoAreaChildren.map(
			geoAreaChild =>
				geoAreaChild.geoArea.id === childGeoArea.id
					? { ...geoAreaChild, manualSelected: isGeoAreaSelected }
					: { ...geoAreaChild }
		);
		return geoAreaElements;
	}

	manualParentGeoAreaSelection(
		isGeoAreaSelected: boolean,
		geoAreaElements: GeoAreaElement[],
		parentGeoArea: AnyGeoArea
	): GeoAreaElement[] {
		const geoAreaElementIndex: number = geoAreaElements.findIndex(
			searchedGeoArea => parentGeoArea.id === searchedGeoArea.geoAreaParent?.id
		);
		geoAreaElements[geoAreaElementIndex].geoAreaChildren = geoAreaElements[geoAreaElementIndex].geoAreaChildren.map(
			geoAreaChild =>
				geoAreaChild.users.length > 0 ? { ...geoAreaChild } : { ...geoAreaChild, manualSelected: isGeoAreaSelected }
		);
		return geoAreaElements;
	}

	handleManualSearchedParentGeoAreaSelection(geoAreaSelected: boolean, parentGeoArea: AnyGeoArea): void {
		const { manualSearchedGeoAreas, groupStateChangeHandlers } = this.props;
		if (!manualSearchedGeoAreas) return;
		if (groupStateChangeHandlers && groupStateChangeHandlers.handleManualSearchedGeoAreasChange) {
			const { handleManualSearchedGeoAreasChange } = groupStateChangeHandlers;
			const newSearchedGeoAreas = this.manualParentGeoAreaSelection(
				geoAreaSelected,
				[...manualSearchedGeoAreas],
				parentGeoArea
			);
			handleManualSearchedGeoAreasChange(newSearchedGeoAreas);
		}
	}

	handleManualSearchedGeoAreaSelection(
		geoAreaSelected: boolean,
		childGeoArea: AnyGeoArea,
		parentGeoArea: AnyGeoArea
	): void {
		const { manualSearchedGeoAreas, groupStateChangeHandlers } = this.props;
		if (!manualSearchedGeoAreas) return;
		const newSearchedGeoAreas = this.manualChildGeoAreaSelection(
			geoAreaSelected,
			childGeoArea,
			[...manualSearchedGeoAreas],
			parentGeoArea
		);
		if (groupStateChangeHandlers && groupStateChangeHandlers.handleManualSearchedGeoAreasChange) {
			const { handleManualSearchedGeoAreasChange } = groupStateChangeHandlers;
			handleManualSearchedGeoAreasChange(newSearchedGeoAreas);
		}
	}

	handleManualParentGeoAreaElementSelection(geoAreaSelected: boolean, parentGeoArea: AnyGeoArea): void {
		const { groupMembershipAdminGeoAreas, groupStateChangeHandlers } = this.props;
		const newGroupMembershipAdminGeoAreas = this.manualParentGeoAreaSelection(
			geoAreaSelected,
			groupMembershipAdminGeoAreas ? [...groupMembershipAdminGeoAreas] : [],
			parentGeoArea
		);
		if (groupStateChangeHandlers && groupStateChangeHandlers.handleGroupMembershipAdminGeoAreasChange) {
			const { handleGroupMembershipAdminGeoAreasChange } = groupStateChangeHandlers;
			handleGroupMembershipAdminGeoAreasChange(newGroupMembershipAdminGeoAreas);
		}
	}

	handleManualGeoAreaElementSelection(
		geoAreaSelected: boolean,
		childGeoArea: AnyGeoArea,
		parentGeoArea: AnyGeoArea
	): void {
		const { groupMembershipAdminGeoAreas, groupStateChangeHandlers } = this.props;
		const newGroupMembershipAdminGeoAreas = this.manualChildGeoAreaSelection(
			geoAreaSelected,
			childGeoArea,
			groupMembershipAdminGeoAreas ? [...groupMembershipAdminGeoAreas] : [],
			parentGeoArea
		);

		if (groupStateChangeHandlers && groupStateChangeHandlers.handleGroupMembershipAdminGeoAreasChange) {
			const { handleGroupMembershipAdminGeoAreasChange } = groupStateChangeHandlers;
			handleGroupMembershipAdminGeoAreasChange(newGroupMembershipAdminGeoAreas);
		}
	}

	handleRemoveGeoAreaElement(geoAreaElement: GeoAreaElement): void {
		const { manualSearchedGeoAreas, groupStateChangeHandlers } = this.props;
		if (!manualSearchedGeoAreas) return;
		const removableGeoAreaIndex: number = manualSearchedGeoAreas.findIndex(
			searchedGeoArea => geoAreaElement?.geoAreaParent?.id === searchedGeoArea.geoAreaParent?.id
		);
		const newSearchedGeoAreas = [...manualSearchedGeoAreas];
		newSearchedGeoAreas.splice(removableGeoAreaIndex, 1);
		if (groupStateChangeHandlers && groupStateChangeHandlers.handleManualSearchedGeoAreasChange) {
			const { handleManualSearchedGeoAreasChange } = groupStateChangeHandlers;
			const removableGeoAreaIndex: number = manualSearchedGeoAreas.findIndex(
				searchedGeoArea => geoAreaElement?.geoAreaParent?.id === searchedGeoArea.geoAreaParent?.id
			);
			const newSearchedGeoAreas = [...manualSearchedGeoAreas];
			newSearchedGeoAreas.splice(removableGeoAreaIndex, 1);
			handleManualSearchedGeoAreasChange(newSearchedGeoAreas);
		}
	}

	render(): JSX.Element {
		const {
			groupMembershipAdminGeoAreas,
			manualSearchedGeoAreas,
			title,
			hideHomeAreaOfAdministratorsTypography,
			useAdditionalGeoAreas,
			readOnly,
			hideParent,
			hideUnselectedChildren,
			sliceSizeBeforeExpansion,
			geoAreaElementNodeTableCellProps,
			children
		} = this.props;
		return (
			<Box mb={4}>
				<Grid container spacing={3}>
					{(title || !hideHomeAreaOfAdministratorsTypography) && (
						<Grid item xs={12}>
							{title && (
								<Typography variant="h2" gutterBottom>
									{title}
								</Typography>
							)}
							{!hideHomeAreaOfAdministratorsTypography && (
								<Typography variant="h3">Heimatgemeinde der Administratoren</Typography>
							)}
						</Grid>
					)}
					{groupMembershipAdminGeoAreas && (
						<Grid item xs={12}>
							{groupMembershipAdminGeoAreas.map(geoAreaElement => {
								return geoAreaElement?.geoAreaParent ? (
									<Box pt={1} key={geoAreaElement?.geoAreaParent?.id}>
										<GroupGeoAreaElementView
											geoAreaElement={geoAreaElement}
											hideParent={hideParent}
											hideUnselectedChildren={hideUnselectedChildren}
											sliceSizeBeforeExpansion={sliceSizeBeforeExpansion}
											nodeTableCellProps={geoAreaElementNodeTableCellProps}
											handleManualParentGeoAreaSelection={
												readOnly ? undefined : this.handleManualParentGeoAreaElementSelection
											}
											handleManualGeoAreaSelection={readOnly ? undefined : this.handleManualGeoAreaElementSelection}
										/>
									</Box>
								) : null;
							})}
						</Grid>
					)}
					{useAdditionalGeoAreas && (
						<>
							<Grid item xs={12}>
								<Typography variant="h3" gutterBottom>
									Weitere Gemeinden hinzufügen
								</Typography>
							</Grid>
							<Grid item xs={12} md={6} lg={3}>
								{children}
							</Grid>
							<Grid item xs={12}>
								{manualSearchedGeoAreas &&
									manualSearchedGeoAreas.map(geoAreaElement => {
										const { geoAreaParent } = geoAreaElement;
										return geoAreaParent ? (
											<Box pt={1} key={geoAreaElement?.geoAreaParent?.id}>
												<GroupGeoAreaElementView
													geoAreaElement={geoAreaElement}
													hideParent={hideParent}
													hideUnselectedChildren={hideUnselectedChildren}
													sliceSizeBeforeExpansion={sliceSizeBeforeExpansion}
													nodeTableCellProps={geoAreaElementNodeTableCellProps}
													handleManualParentGeoAreaSelection={
														readOnly ? undefined : this.handleManualSearchedParentGeoAreaSelection
													}
													handleManualGeoAreaSelection={
														readOnly ? undefined : this.handleManualSearchedGeoAreaSelection
													}
													handleRemoveGeoAreaElement={readOnly ? undefined : this.handleRemoveGeoAreaElement}
												/>
											</Box>
										) : null;
									})}
							</Grid>
						</>
					)}
				</Grid>
			</Box>
		);
	}
}

export default GroupGeoAreasView;
