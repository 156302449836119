import { ClientExceptionEntity, ClientExceptionEntityTypeEnum } from '@DigitaleDoerfer/digitale-doerfer-api/models';

export class BaseError extends Error {
	constructor(public readonly message: string) {
		super(message);
	}

	get displayMessage(): string {
		return this.message;
	}
}

const shortCode = (clientException: ClientExceptionEntity): string => {
	return clientException.type ? ` (${clientException.type})` : '';
};

const detailOrEmpty = (clientException: ClientExceptionEntity): string => {
	return clientException.detail ? clientException.detail + ' ' : '';
};

const DEFAULT_DISPLAY_MESSAGE = 'Fehler beim Serveraufruf.';
export const INTERNAL_ERROR = 'Es ist ein interner Fehler aufgetreten.';

const translate = (clientException: ClientExceptionEntity): string => {
	let message = DEFAULT_DISPLAY_MESSAGE;
	switch (clientException.type) {
		// Please maintain the lexicographic sort order when adding new keys
		case ClientExceptionEntityTypeEnum.ADDRESSINVALID:
			message = 'Die angegebene Adresse ist ungültig.';
			break;
		case ClientExceptionEntityTypeEnum.TENANTNOTFOUND:
			message = `Mandant ${detailOrEmpty(clientException)}nicht gefunden.`;
			break;
		case ClientExceptionEntityTypeEnum.EMAILALREADYREGISTERED:
			message = `Die E-Mail-Adresse ${detailOrEmpty(clientException)}wird schon von einer anderen Person verwendet.`;
			break;
		case ClientExceptionEntityTypeEnum.EMAILCHANGENOTPOSSIBLE:
			message = `Die E-Mail-Adresse konnte nicht geändert werden.`;
			if (
				clientException.message &&
				clientException.message.indexOf('Next time to change') !== -1 &&
				clientException.detail
			) {
				message += ` Nächstmöglicher Zeitpunkt zum Ändern der E-Mail-Adresse: ${clientException.detail}.`;
			}
			break;
		case ClientExceptionEntityTypeEnum.EVENTATTRIBUTEINVALID:
			// Error not related to user action but to programming bug. Use default message.
			break;
		case ClientExceptionEntityTypeEnum.NOTAUTHORIZED:
			message = 'Du hast keinen Zugriff auf diese Funktionalität.';
			break;
		case ClientExceptionEntityTypeEnum.OAUTHACCOUNTNOTFOUND:
			message = `OAuth-Account für ${detailOrEmpty(clientException)}nicht gefunden.`;
			break;
		case ClientExceptionEntityTypeEnum.OAUTHEMAILALREADYREGISTERED:
			message = `Die E-Mail ${detailOrEmpty(clientException)}ist bei Auth0 bereits registriert.`;
			break;
		case ClientExceptionEntityTypeEnum.PASSWORDCHANGENOTPOSSIBLE:
			message = 'Das Passwort konnte nicht zurückgesetzt werden.';
			break;
		case ClientExceptionEntityTypeEnum.PERSONALREADYEXISTS:
			message = `Eine Person mit der OAuth-ID ${detailOrEmpty(clientException)}existiert bereits.`;
			break;
		case ClientExceptionEntityTypeEnum.PERSONINFORMATIONINVALID:
			// Error not related to user action but to programming bug. The client should check the
			// parameters before sending them. Use default message.
			break;
		case ClientExceptionEntityTypeEnum.PERSONNOTFOUND:
			message = `Eine Person mit der ID ${detailOrEmpty(clientException)}konnte nicht gefunden werden.`;
			break;
		case ClientExceptionEntityTypeEnum.PERSONWITHOAUTHIDNOTFOUND:
			message = `Eine Person mit der OAuth-ID ${detailOrEmpty(clientException)}konnte nicht gefunden werden.`;
			break;
		case ClientExceptionEntityTypeEnum.RELATEDENTITYMUSTNOTBENULL:
			// Error not related to user action but to programming bug. Use default message.
			break;
		case ClientExceptionEntityTypeEnum.RELATEDENTITYNOTFOUND:
			// Error not related to user action but to programming bug. Use default message.
			break;
		case ClientExceptionEntityTypeEnum.ROLEMANAGEMENTNOTALLOWED:
			message = 'Das Rollen-Management für diese Rolle ist dir nicht erlaubt.';
			break;
		case ClientExceptionEntityTypeEnum.ROLENOTFOUND:
			message = `Die Rolle ${detailOrEmpty(clientException)}konnte nicht gefunden werden.`;
			break;
		case ClientExceptionEntityTypeEnum.UNSPECIFIEDAUTHORIZATIONERROR:
		case ClientExceptionEntityTypeEnum.UNSPECIFIEDBADREQUEST:
		case ClientExceptionEntityTypeEnum.UNSPECIFIEDERROR:
		case ClientExceptionEntityTypeEnum.UNSPECIFIEDEXTERNALERROR:
		case ClientExceptionEntityTypeEnum.UNSPECIFIEDNOTFOUND:
		case ClientExceptionEntityTypeEnum.UNSPECIFIEDSERVERERROR:
			// Error not related to user action but to programming bug. Use default message.
			break;
		case ClientExceptionEntityTypeEnum.USERGENERATEDCONTENTFLAGNOTFOUND:
			message = `Gemeldeten Inhalt mit ID ${detailOrEmpty(clientException)}nicht gefunden.`;
			break;
		case ClientExceptionEntityTypeEnum.WRONGCHECK:
			message = 'Die Sicherheitabfrage wurde falsch beantwortet.';
			break;
		default:
			// Exception type not yet translated. Using the English exception message as fallback if existing
			if (clientException.message) {
				message = clientException.message;
			}
			break;
	}

	return `${message}${shortCode(clientException)}`;
};

export class ClientExceptionError extends BaseError {
	constructor(public readonly response: ClientExceptionEntity) {
		super(response.message ? response.message : 'Unspecified ClientExceptionError');
	}

	get displayMessage(): string {
		return translate(this.response);
	}
}
