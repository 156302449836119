import React from 'react';
import { TextField } from '@mui/material';
import { ReadOnlyTextFieldView } from '../../../shared/views/ReadOnlyTextField.view';

interface Props {
	urlValid: boolean;
	newsSourcesURL: string;
	isReadOnlyField?: boolean;
	newsSourcesURLChange: (newWebsiteURL: string) => void;
}
const urlValidationMessage = 'Verwende bitte eine gültige URL wie https://www.example.com';
class NewsSourcesWebsiteUrlView extends React.Component<Props> {
	constructor(props: Props) {
		super(props);
		this.handleNewsSourcesURLChange = this.handleNewsSourcesURLChange.bind(this);
	}

	handleNewsSourcesURLChange(event: React.ChangeEvent<{ value: string }>): void {
		const { newsSourcesURLChange } = this.props;
		newsSourcesURLChange && newsSourcesURLChange(event.target.value);
	}

	render(): JSX.Element {
		const { newsSourcesURL, isReadOnlyField, urlValid } = this.props;
		return isReadOnlyField ? (
			<ReadOnlyTextFieldView
				id="newsSourcesURL"
				name="newsSourcesURL"
				label="URL"
				value={newsSourcesURL}
				InputLabelProps={{ shrink: true }}
				InputProps={{ readOnly: true }}
				fullWidth
			/>
		) : (
			<TextField
				variant="standard"
				required
				id="newsSourcesURL"
				name="newsSourcesURL"
				label="URL"
				value={newsSourcesURL}
				onChange={this.handleNewsSourcesURLChange}
				InputLabelProps={{ shrink: true }}
				fullWidth
				helperText={!urlValid ? urlValidationMessage : ''}
			/>
		);
	}
}

export default NewsSourcesWebsiteUrlView;
