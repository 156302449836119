import {
	HideSnackbar,
	ShowSnackbar,
	SNACKBAR_HIDE,
	SNACKBAR_SHOW,
	SnackbarNotificationActionTypes
} from './SnackbarNotificationActionTypes';
import { SnackbarAction } from './SnackbarNotification.actions';

export interface SnackbarNotificationState {
	snackbarAction: SnackbarAction;
	showSnackbar: boolean;
}

export const initialState: SnackbarNotificationState = {
	snackbarAction: { message: '', type: undefined, url: undefined },
	showSnackbar: false
};

const showSnackbar = (state: SnackbarNotificationState, action: ShowSnackbar): SnackbarNotificationState => {
	return {
		...state,
		showSnackbar: true,
		snackbarAction: action.payload
	};
};

const hideSnackbar = (state: SnackbarNotificationState, action: HideSnackbar): SnackbarNotificationState => {
	return {
		...state,
		showSnackbar: false
	};
};

export function snackbarNotificationReducer(
	state = initialState,
	action: SnackbarNotificationActionTypes
): SnackbarNotificationState {
	switch (action.type) {
		case SNACKBAR_SHOW: {
			return showSnackbar(state, action);
		}
		case SNACKBAR_HIDE: {
			return hideSnackbar(state, action);
		}
		default:
			return state;
	}
}

export default snackbarNotificationReducer;
