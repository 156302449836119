import { connect } from 'react-redux';
import React, { Component } from 'react';
import ReopenFlagActionView from './ReopenFlagAction.view';
import {
	ClientPersonOwn,
	ClientUserGeneratedContentFlagStatusChangeRequestNewStatusEnum
} from '@DigitaleDoerfer/digitale-doerfer-api/models';
import { changeFlagStatus } from '../../store/FlaggedContent.actions';
import { UserActionTypes } from '../../../users/store/UserActionTypes';
import { ActionMenuItemProps } from '../../../../shared/views/ActionMenu/ActionMenu';
import { PlatformAdminUIState, PlatformAdminUIThunkType } from '../../../../store/store';

interface Props extends ActionMenuItemProps {
	flagId: string;
	person: ClientPersonOwn | null;
	changeFlagStatus: (
		flagId: string,
		newStatus: ClientUserGeneratedContentFlagStatusChangeRequestNewStatusEnum,
		comment: string,
		snackbarMessage?: string,
		returnToTable?: boolean
	) => void;
}

class ReopenFlagActionContainer extends Component<Props> {
	constructor(props: Props) {
		super(props);
		this.handleStatusChangeAction = this.handleStatusChangeAction.bind(this);
	}

	handleStatusChangeAction(comment: string): void {
		const { flagId, person, changeFlagStatus } = this.props;
		const personName = person?.firstName;
		const personLastName = person?.lastName;
		const newComment = comment
			? comment
			: `${personName ? `${personName} ` : ''}${personLastName ? personLastName : ''} hat die Bearbeitung abgegeben.`;
		changeFlagStatus(flagId, ClientUserGeneratedContentFlagStatusChangeRequestNewStatusEnum.OPEN, newComment);
	}

	render(): JSX.Element {
		const { renderMenuItem } = this.props;
		return (
			<ReopenFlagActionView renderMenuItem={renderMenuItem} handleStatusChangeAction={this.handleStatusChangeAction} />
		);
	}
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: PlatformAdminUIState) => {
	return {
		flagId: state.flaggedContent.detail.flagId,
		person: state.global.person
	};
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch: PlatformAdminUIThunkType<UserActionTypes>) => {
	return {
		changeFlagStatus: (
			flagId: string,
			newStatus: ClientUserGeneratedContentFlagStatusChangeRequestNewStatusEnum,
			comment: string,
			snackbarMessage?: string,
			returnToTable?: boolean
		): Promise<void> => dispatch(changeFlagStatus(flagId, newStatus, comment, snackbarMessage, returnToTable))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ReopenFlagActionContainer);
