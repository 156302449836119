/* Module is mostly adpated from an already existing module in the digital vital project */
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import React, { CSSProperties, FC, useCallback, useMemo, useState } from 'react';
import Default from '../../../assets/svg/DefaultImage.svg';
import AbstractDialog from './AbstractDialog';
import { ClientTemporaryMediaItem } from '@DigitaleDoerfer/digitale-doerfer-api/models';
import { imageService, sharedMediaApi } from '../../../ServiceFactory';
import { ImageSizes } from '../../../shared/services/Image.service';
import { showSnackbarError } from '../SnackbarNotification/store/SnackbarNotification.actions';
import { useDispatch } from 'react-redux';
import { ShowSnackbar } from '../SnackbarNotification/store/SnackbarNotificationActionTypes';
import { PlatformAdminUIThunkType } from '../../../store/store';
export const UPLOAD_IMAGE_DIALOG_TITLE = 'Bild hochladen';
export const UPLOAD_IMAGE_DIALOG_BTN_UPLOAD = 'Bild hochladen';
export const UPLOAD_IMAGE_DIALOG_BTN_DELETE = 'Bild löschen';
export const UPLOAD_IMAGE_DIALOG_BTN_FINISHED = 'Fertig';
export const UPLOAD_IMAGE_DIALOG_BTN_CLOSE = 'Abbrechen';

const useStyles = (): Record<string, CSSProperties> => ({
	contentStyle: {
		display: 'flex',
		justifyContent: 'space-between',
		width: '25rem'
	},
	imageAreaStyle: {
		flex: '0 0 10rem',
		minHeight: '10rem',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	buttonAreaStyle: {
		flex: '1 1 auto',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'stretch'
	},
	buttonBoxStyle: {
		flex: '0 0 auto',
		margin: '0.25rem'
	},
	hintBoxStyle: {
		flex: '0 0 auto',
		margin: '0.25rem',
		width: '25rem'
	}
});

type UploadImageDialogProps = {
	open: boolean;
	onClose: () => void;
	onUploadFinished: (temporaryMediaItemId: ClientTemporaryMediaItem) => void;
};

const UploadImageDialog: FC<UploadImageDialogProps> = (props: UploadImageDialogProps) => {
	const { open, onClose, onUploadFinished } = props;
	const dispatch = useDispatch<PlatformAdminUIThunkType<ShowSnackbar>>();
	const styles = useStyles();
	const [processing, setProcessing] = useState<boolean>(false);
	const [temporaryMediaItem, setTemporaryMediaItem] = useState<ClientTemporaryMediaItem>();

	const deleteTemporaryMediaItem = useCallback(async (): Promise<void> => {
		if (!temporaryMediaItem) {
			return;
		}
		const { id } = temporaryMediaItem;
		await sharedMediaApi().deleteTemporaryMediaItemUsingDELETE({ temporaryMediaItemId: id });
		setTemporaryMediaItem(undefined);
	}, [temporaryMediaItem]);

	const uploadFile = useCallback(
		(File: Blob): void => {
			const uploadFileAsync = async (): Promise<void> => {
				setProcessing(true);
				try {
					await deleteTemporaryMediaItem();
					const result = await sharedMediaApi().uploadTemporaryMediaItemUsingPOST({ file: File });
					setTemporaryMediaItem(result);
				} catch (error) {
					dispatch(showSnackbarError(error));
				} finally {
					setProcessing(false);
				}
			};
			uploadFileAsync();
		},
		[deleteTemporaryMediaItem, dispatch]
	);

	const handleFileChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>): void => {
			const fileList = event.target.files;
			if (!fileList) {
				return;
			}
			const file = fileList[0];
			uploadFile(file);
		},
		[uploadFile]
	);

	const handleAction = useCallback((): void => {
		if (!temporaryMediaItem) {
			return;
		}
		onUploadFinished(temporaryMediaItem);
	}, [temporaryMediaItem, onUploadFinished]);

	const handleClose = useCallback((): void => {
		const handleCloseAsync = async (): Promise<void> => {
			await deleteTemporaryMediaItem();
			onClose();
		};
		handleCloseAsync();
	}, [onClose, deleteTemporaryMediaItem]);

	const handleDelete = useCallback(() => {
		const handleDeleteAsync = async (): Promise<void> => {
			setProcessing(true);
			try {
				await deleteTemporaryMediaItem();
			} finally {
				setProcessing(false);
			}
		};
		handleDeleteAsync();
	}, [deleteTemporaryMediaItem]);

	const imageUrl = useMemo(() => {
		if (temporaryMediaItem?.mediaItem) {
			return imageService().getAvailableImageUrl(temporaryMediaItem?.mediaItem, ImageSizes.THUMBNAIL);
		}
	}, [temporaryMediaItem]);
	return (
		<AbstractDialog
			open={open}
			title={UPLOAD_IMAGE_DIALOG_TITLE}
			btnActionText={UPLOAD_IMAGE_DIALOG_BTN_FINISHED}
			btnCancelText={UPLOAD_IMAGE_DIALOG_BTN_CLOSE}
			onAction={handleAction}
			onClose={handleClose}
			isActionDisabled={!temporaryMediaItem}
			maxWidth="35rem"
		>
			<Box sx={styles.contentStyle}>
				<Box sx={styles.imageAreaStyle}>
					{processing ? <CircularProgress /> : <img src={imageUrl ?? Default} alt="NewsSource Logo" />}
				</Box>
				<Box sx={styles.buttonAreaStyle}>
					<Box sx={styles.buttonBoxStyle}>
						<input
							type="file"
							accept="image/*"
							style={{ display: 'none' }}
							onChange={handleFileChange}
							id="image-upload"
						/>
						<label htmlFor="image-upload">
							<Button fullWidth color="primary" component="span" disabled={processing} variant="contained">
								{UPLOAD_IMAGE_DIALOG_BTN_UPLOAD}
							</Button>
						</label>
					</Box>
					<Box sx={styles.buttonBoxStyle}>
						<Button
							fullWidth
							color="secondary"
							component="span"
							disabled={processing || !temporaryMediaItem}
							onClick={handleDelete}
							variant="contained"
						>
							{UPLOAD_IMAGE_DIALOG_BTN_DELETE}
						</Button>
					</Box>
				</Box>
			</Box>
			<Box sx={styles.hintBoxStyle}>
				<Typography variant="body2">
					Bitte veröffentlichen Sie fremde Fotos nur mit dem ausdrücklichen Einverständnis des Urhebers
				</Typography>
			</Box>
		</AbstractDialog>
	);
};

export default UploadImageDialog;
