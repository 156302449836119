import React from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import BarLoadingIndicator from '../../shared/views/LoadingIndicators/BarLoadingIndicator.view';
import NoteView from './Note.view';
import TableView from '../../shared/views/Table/Table.view';
import { TableCell, TableCellTooltip, TableColumn } from '../../shared/views/Table/TableColumn';
import { TableColumnSortingDirection, TableSorting } from '../../shared/views/Table/TableSorting';
import { Pagination } from '../../shared/services/InMemoryTable.service';
import { inMemoryTableService } from '../../ServiceFactory';
import TablePaginationView from '../../shared/views/Table/TablePagination.view';
import LoudView from './Loud.view';
import CategoryNameView from './CategoryNameView';
import { arraysEqual } from '../../shared/utils/Util';
import { ListPushEvent } from './store/PushEvent.state';
import SearchBarWithResetButtonView from '../../shared/views/SearchBarWithResetButton/SearchBarWithResetButton.view';
import { searchEventsConfigsTooltipText } from '../../shared/views/HelperTexts';

interface Props {
	pushEvents: ListPushEvent[];
	loading: boolean;
}

const renderCategoryName = (cell: TableCell<ListPushEvent>): React.ReactElement => {
	const {
		categoryName: name,
		categoryDescription: description,
		categoryId: id,
		categoryInternalName: internalName
	} = cell.row;

	return <CategoryNameView name={name} description={description} id={id} internalName={internalName} />;
};

const renderLoud = (cell: TableCell<ListPushEvent>): React.ReactElement => {
	return <LoudView loud={cell.row.loud || false} />;
};

const renderNotes = (cell: TableCell<ListPushEvent>): React.ReactElement | null => {
	const { notes } = cell.row;

	if (!notes) {
		return null;
	}

	return (
		<NoteView>
			<Typography variant="body2" component="span">
				{notes}
			</Typography>
		</NoteView>
	);
};

const columns: readonly TableColumn<ListPushEvent>[] = [
	{
		id: 'appName',
		headerLabel: 'App',
		cellStyles: {},
		getCellTooltip: (cell: TableCell<ListPushEvent>): TableCellTooltip => ({
			title: `${cell.row.appId} ${cell.row.appIdentifier}`,
			placement: 'bottom-start'
		}),
		getCellStringValue: (pushEvent: ListPushEvent): string => pushEvent.appName,
		filterable: true,
		sortable: true
	},
	{
		id: 'eventName',
		headerLabel: 'Event',
		cellStyles: {},
		getCellStringValue: (pushEvent: ListPushEvent): string => pushEvent.eventName,
		filterable: true,
		sortable: true
	},
	{
		id: 'clientEventName',
		headerLabel: 'Client-Event',
		cellStyles: {},
		getCellStringValue: (pushEvent: ListPushEvent): string => pushEvent.clientEventName,
		filterable: true,
		sortable: true
	},
	{
		id: 'categoryName',
		headerLabel: 'Kategorie',
		cellStyles: {},
		getCellStringValue: (pushEvent: ListPushEvent): string => pushEvent.categoryName,
		renderCell: renderCategoryName,
		filterable: true,
		sortable: true
	},
	{
		id: 'loud',
		headerLabel: 'laut',
		cellStyles: {},
		getCellStringValue: (pushEvent: ListPushEvent): string => `${pushEvent.loud}`,
		renderCell: renderLoud,
		filterable: false,
		sortable: true
	},
	{
		id: 'notes',
		headerLabel: 'Info',
		cellStyles: {},
		getCellStringValue: (pushEvent: ListPushEvent): string => pushEvent.notes,
		renderCell: renderNotes,
		filterable: true,
		sortable: false
	}
] as const;

function idToColumn(columnId: string): TableColumn<ListPushEvent> | undefined {
	return columns.find(column => column.id === columnId);
}

const paginationRowsPerPageOptions = [20, 50, 100, 200] as const;
const initialPaginationRowsPerPage: (typeof paginationRowsPerPageOptions)[number] = 100;

const initialSearchParams = {
	searchCriteria: '',
	pagination: { page: 0, rowsPerPage: initialPaginationRowsPerPage, total: 0 },
	sorting: { columnId: columns[0].id, direction: TableColumnSortingDirection.ASC }
};

interface State {
	displayedPushEvents: ListPushEvent[];
	searchCriteria: string;
	pagination: Pagination;
	sorting: TableSorting;
}

class PushEventListView extends React.Component<Props, State> {
	searchNeedsUpdate = false;

	constructor(props: Props) {
		super(props);

		this.state = {
			displayedPushEvents: [],
			...initialSearchParams
		};
		this.updateDisplayedRowsAndPagination = this.updateDisplayedRowsAndPagination.bind(this);
		this.handleChangePage = this.handleChangePage.bind(this);
		this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
		this.handleSortingChange = this.handleSortingChange.bind(this);
		this.handleSearchChange = this.handleSearchChange.bind(this);
		this.handleSearchReset = this.handleSearchReset.bind(this);
	}

	updateDisplayedRowsAndPagination(): void {
		const pushEventsAfterFiltering = inMemoryTableService().applyFilter(
			this.props.pushEvents,
			this.state.searchCriteria,
			columns
		);

		const pushEventsAfterSorting = inMemoryTableService().applySorting(
			pushEventsAfterFiltering,
			this.state.sorting,
			idToColumn
		);
		const pushEventsAfterPagination = inMemoryTableService().applyPagination(
			pushEventsAfterSorting,
			this.state.pagination
		);

		this.setState({
			pagination: { ...this.state.pagination, total: pushEventsAfterSorting.length },
			displayedPushEvents: pushEventsAfterPagination
		});
	}

	componentDidMount(): void {
		this.updateDisplayedRowsAndPagination();
	}

	componentDidUpdate(prevProps: Props): void {
		if (!arraysEqual(prevProps.pushEvents, this.props.pushEvents)) {
			this.updateDisplayedRowsAndPagination();
		}
	}

	handleChangePage(newPage: number): void {
		this.setState((state: State): State => {
			return { ...state, pagination: { ...state.pagination, page: newPage } };
		}, this.updateDisplayedRowsAndPagination);
	}

	handleChangeRowsPerPage(newRowsPerPage: number): void {
		this.setState((state: State): State => {
			return { ...state, pagination: { ...state.pagination, rowsPerPage: newRowsPerPage, page: 0 } };
		}, this.updateDisplayedRowsAndPagination);
	}

	handleSortingChange(newSorting: TableSorting): void {
		this.setState((state: State): State => {
			return {
				...state,
				pagination: { ...state.pagination, page: 0 },
				sorting: newSorting
			};
		}, this.updateDisplayedRowsAndPagination);
	}

	handleSearchChange(searchCriteria: string): void {
		this.setState(
			{ ...this.state, searchCriteria, pagination: { ...this.state.pagination, page: 0 } },
			this.updateDisplayedRowsAndPagination
		);
	}

	handleSearchReset(): void {
		this.setState((state: State): State => {
			return {
				...state,
				...initialSearchParams
			};
		}, this.updateDisplayedRowsAndPagination);
	}

	getKeyForRow(pushEvent: ListPushEvent): React.Key {
		return pushEvent.id;
	}

	render(): JSX.Element {
		const { loading } = this.props;
		const { displayedPushEvents, pagination, searchCriteria, sorting } = this.state;
		const { page, rowsPerPage, total } = pagination;

		return (
			<Paper>
				<BarLoadingIndicator loading={loading} />
				<Grid container spacing={2}>
					<SearchBarWithResetButtonView
						text={searchCriteria}
						label="Push-Event suchen"
						placeholder="z.B. CreateConfirmation"
						tooltipText={searchEventsConfigsTooltipText}
						handleTextChange={this.handleSearchChange}
						handleReset={this.handleSearchReset}
					/>
					<Grid item xs={12}>
						<TableView<ListPushEvent>
							columns={columns}
							rows={displayedPushEvents}
							getKeyForRow={this.getKeyForRow}
							sorting={sorting}
							handleSortingChange={this.handleSortingChange}
						/>
						<TablePaginationView
							totalRows={total}
							rowLabelPlural="Push-Events"
							page={page}
							rowsPerPage={rowsPerPage}
							rowsPerPageOptions={paginationRowsPerPageOptions}
							handleChangePage={this.handleChangePage}
							handleChangeRowsPerPage={this.handleChangeRowsPerPage}
						/>
					</Grid>
				</Grid>
			</Paper>
		);
	}
}
export default PushEventListView;
