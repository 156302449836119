import {
	GET_PUSH_EVENTS_FAILURE,
	GET_PUSH_EVENTS_REQUEST,
	GET_PUSH_EVENTS_SUCCESS,
	GetPushEventsFailureAction,
	GetPushEventsRequestAction,
	GetPushEventsSuccessAction,
	PushEventActionTypes
} from './PushEventActionTypes';
import { initialPushEventState, PushEventState } from './PushEvent.state';

const getPushEventsRequest = (state: PushEventState, action: GetPushEventsRequestAction): PushEventState => {
	return {
		...state,
		list: {
			...state.list,
			loading: true,
			error: null
		}
	};
};

const getPushEventsSuccess = (state: PushEventState, action: GetPushEventsSuccessAction): PushEventState => {
	return {
		...state,
		list: {
			...state.list,
			loading: false,
			pushEvents: action.pushEvents
		}
	};
};

const getPushEventsFailure = (state: PushEventState, action: GetPushEventsFailureAction): PushEventState => {
	return {
		...state,
		list: {
			...state.list,
			loading: false,
			error: action.error
		}
	};
};

const reducer = (state = initialPushEventState, action: PushEventActionTypes): PushEventState => {
	switch (action.type) {
		case GET_PUSH_EVENTS_REQUEST:
			return getPushEventsRequest(state, action);
		case GET_PUSH_EVENTS_SUCCESS:
			return getPushEventsSuccess(state, action);
		case GET_PUSH_EVENTS_FAILURE:
			return getPushEventsFailure(state, action);
		default:
			return state;
	}
};

export default reducer;
