import React, { Component, FormEvent } from 'react';
import {
	Box,
	Grid,
	Input,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	TextField,
	Typography
} from '@mui/material';
import { BaseError } from '../../../shared/errors/Errors';
import { ClientCommunity, ClientPersonCreateRequest } from '@DigitaleDoerfer/digitale-doerfer-api/models';
import { matchesEmailPattern } from '../../../shared/utils/Util';
import ConfirmButton from '../../../shared/views/Buttons/ConfirmButton.view';
import ResetButton from '../../../shared/views/Buttons/ResetButton.view';
import CancelButton from '../../../shared/views/Buttons/CancelButton.view';

interface Props {
	tenants: ClientCommunity[];
	title: string;
	error: BaseError | null;
	loading: boolean;
	userHasBeenCreated: boolean;
	cancelHandler: () => void;
	dispatchCreateUser: (userData: ClientPersonCreateRequest) => void;
}

interface State {
	emailValid: boolean;
	firstName: string;
	lastName: string;
	email: string;
	homeCommunityId: string;
	role: string;
}

const initialState: State = { emailValid: true, homeCommunityId: '', role: '', firstName: '', lastName: '', email: '' };
const emailValidationMessage = 'Verwende bitte eine gültige E-Mail-Adresse wie anna@beispiel.de';

class UserCreateView extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = initialState;
		this.handleEmailChange = this.handleEmailChange.bind(this);
		this.handleSelectionChange = this.handleSelectionChange.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentDidUpdate(prevProps: Props): void {
		const { userHasBeenCreated } = this.props;
		// reset form if user was created
		if (!prevProps.userHasBeenCreated && userHasBeenCreated) {
			this.resetHandler();
		}
	}

	handleEmailChange(event: React.ChangeEvent<{ name?: string; value: string }>): void {
		// call general input handler of container
		this.handleInputChange(event);

		const isValidEmail: boolean = matchesEmailPattern(event.target.value);
		this.setState({ emailValid: isValidEmail });
	}

	handleSelectionChange(event: SelectChangeEvent<string>): void {
		const target = event.target;
		const value = target.value;
		const name = `${target.name || ''}`;
		this.setState({ [name]: value } as unknown as Pick<State, keyof State>);
	}

	handleInputChange(event: React.ChangeEvent<{ name?: string; value: unknown }>): void {
		const target = event.target;
		const value = target.value;
		const name = `${target.name || ''}`;
		this.setState({ [name]: value } as Pick<State, keyof State>);
	}

	handleSubmit(event: FormEvent<HTMLFormElement>): void {
		event.preventDefault();
		const { dispatchCreateUser } = this.props;
		const { email, firstName, lastName, homeCommunityId } = this.state;

		// Prepare user data request
		const userData: ClientPersonCreateRequest = {
			email,
			firstName,
			lastName,
			homeCommunityId
		};
		dispatchCreateUser(userData);
	}

	resetHandler = (): void => {
		this.setState(initialState);
	};

	render(): JSX.Element {
		const { title, tenants, cancelHandler } = this.props;
		const { firstName, lastName, email, homeCommunityId, emailValid } = this.state;
		const submitDisabled = !(firstName && lastName && email && emailValid && homeCommunityId);

		return (
			<>
				<Typography variant="h6" gutterBottom>
					{title}
				</Typography>
				<form onSubmit={this.handleSubmit}>
					<Typography>Felder mit * müssen ausgefüllt werden.</Typography>
					<Box mt={1}>
						<Grid container spacing={3}>
							<Grid item xs={12} md={6}>
								<TextField
									variant="standard"
									required
									id="firstName"
									name="firstName"
									label="Vorname"
									value={firstName}
									onChange={this.handleInputChange}
									fullWidth
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									variant="standard"
									required
									id="lastName"
									name="lastName"
									label="Nachname"
									value={lastName}
									onChange={this.handleInputChange}
									fullWidth
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									variant="standard"
									required
									id="email"
									name="email"
									label="E-Mail"
									value={email}
									onChange={this.handleEmailChange}
									fullWidth
									error={!this.state.emailValid}
									helperText={!this.state.emailValid ? emailValidationMessage : ''}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<InputLabel required htmlFor="homeCommunity">
									Mandant (Home Community)
								</InputLabel>
								<Select
									variant="standard"
									name="homeCommunityId"
									value={homeCommunityId}
									onChange={this.handleSelectionChange}
									input={<Input id="homeCommunityId" />}
									placeholder="Heimat-Mandant"
									required
									fullWidth
								>
									{tenants.map(tenant => {
										return (
											<MenuItem key={tenant.id} value={tenant.id}>
												{tenant.name}
											</MenuItem>
										);
									})}
								</Select>
							</Grid>
							<Grid item xs={12} container spacing={2}>
								<Grid item>
									<ConfirmButton disabled={submitDisabled} submit={true}>
										Anlegen
									</ConfirmButton>
								</Grid>
								<Grid item>
									<ResetButton onClick={this.resetHandler}>Zurücksetzen</ResetButton>
								</Grid>
								<Grid item>
									<CancelButton onClick={cancelHandler}>Abbrechen</CancelButton>
								</Grid>
							</Grid>
						</Grid>
					</Box>
				</form>
			</>
		);
	}
}
export default UserCreateView;
