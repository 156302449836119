import React from 'react';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import { NotFoundErrorView } from '../error/ErrorPages.view';
import ExternalPostsContainer from './ExternalPosts.container';
export const EXTERNAL_CONTRIBUTIONS_URL = '/ExternalContributions';

interface Props extends RouteComponentProps {}

class ExternalContributionsRoutingContainer extends React.Component<Props> {
	render(): JSX.Element {
		const {
			match: { url }
		} = this.props;

		return (
			<Switch>
				<Route exact path={url} component={ExternalPostsContainer} />
				<Route exact path={EXTERNAL_CONTRIBUTIONS_URL} component={ExternalPostsContainer} />
				<Route component={NotFoundErrorView} />
			</Switch>
		);
	}
}

export default withRouter(ExternalContributionsRoutingContainer);
