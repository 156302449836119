import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { Location } from 'history';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { createTheme, StyledEngineProvider, Tooltip } from '@mui/material';
import { Theme, ThemeProvider } from '@mui/material/styles';

declare module '@mui/styles' {
	interface DefaultTheme extends Theme {}
}

const theme = createTheme({
	components: {
		MuiListItemIcon: {
			styleOverrides: {
				root: {
					minWidth: 40
				}
			}
		}
	}
});

interface Props {
	icon: JSX.Element;
	primary: React.ReactNode;
	to: string;
	highlightOnlyWhenExactUrlMatch: boolean;
	/** when specified, it overrides highlightOnlyWhenExactUrlMatch */
	highlight?: (location: Location) => boolean;
	activeClassName?: string;
	subMenuClassName?: string;
}

class ListItemLinkView extends React.Component<Props> {
	static defaultProps = { highlightOnlyWhenExactUrlMatch: false };

	render(): JSX.Element {
		const { icon, primary, to, activeClassName, highlightOnlyWhenExactUrlMatch, highlight, subMenuClassName } =
			this.props;

		const renderLink = React.forwardRef<HTMLAnchorElement, NavLinkProps>(function linkView(linkProps) {
			return (
				<NavLink
					activeClassName={activeClassName}
					exact={highlightOnlyWhenExactUrlMatch}
					isActive={(match, location): boolean => {
						if (highlight) {
							return highlight(location);
						}
						return match != null && match.url === location.pathname;
					}}
					{...linkProps}
				/>
			);
		});

		return (
			<li>
				<StyledEngineProvider injectFirst>
					<ThemeProvider theme={theme}>
						<ListItemButton component={renderLink} to={to} className={subMenuClassName}>
							<ListItemIcon>{icon}</ListItemIcon>
							<ListItemText primary={primary} />
						</ListItemButton>
						<Tooltip title={primary ?? ''} arrow={true} disableFocusListener disableTouchListener>
							<span style={{ display: 'none' }}></span>
						</Tooltip>
					</ThemeProvider>
				</StyledEngineProvider>
			</li>
		);
	}
}

export default ListItemLinkView;
