import { ClientGeoArea } from '@DigitaleDoerfer/digitale-doerfer-api/models';
import React, { FC, useCallback, useMemo } from 'react';
import ExpandIcon from '@mui/icons-material/AddBoxOutlined';
import EndIcon from '@mui/icons-material/CancelOutlined';
import CollapseIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import { TreeView } from '@mui/lab';
import { useStore } from '../store';
import useFetchGeoAreasFirstLevel from './hooks/useFetchGeoAreasFirstLevel';
import useFetchSelectedContractGeoAreas from './hooks/useFetchSelectedContractGeoAreas';
import useGeoArea from './hooks/useGeoArea';
import TreeNode from './TreeNode';
import { CircularProgress } from '@mui/material';

const GeoAreaTree: FC = () => {
	const { handleExpandedGeoAreaIds, setSelectedGeoAreaId } = useStore();
	const expandedGeoAreaIds = useStore(state => state.expandedGeoAreaIds);
	const selectedGeoAreaId = useStore(state => state.selectedGeoAreaId);
	const { getGeoAreasByParentId } = useGeoArea(selectedGeoAreaId);

	const firstLevelGeoAreas = getGeoAreasByParentId(null);

	const { isLoading } = useFetchGeoAreasFirstLevel();
	const { isLoading: isLoadingSelectedContractGeoAreas } = useFetchSelectedContractGeoAreas();

	const handleGeoAreaLabelClick = useCallback(
		(geoAreaId: ClientGeoArea['id']) => {
			setSelectedGeoAreaId(geoAreaId);
		},
		[setSelectedGeoAreaId]
	);

	const handleGeoAreaExpandClick = useCallback(
		(geoAreaId: ClientGeoArea['id']) => {
			handleExpandedGeoAreaIds(geoAreaId);
			setSelectedGeoAreaId(geoAreaId);
		},
		[handleExpandedGeoAreaIds, setSelectedGeoAreaId]
	);

	const childNodes = useMemo(
		() =>
			firstLevelGeoAreas.map(geoArea => {
				return (
					<TreeNode
						key={geoArea?.id}
						geoArea={geoArea}
						selectedGeoAreaId={selectedGeoAreaId}
						onNodeLabelClick={handleGeoAreaLabelClick}
						onNodeExpandClick={handleGeoAreaExpandClick}
					/>
				);
			}),
		[firstLevelGeoAreas, handleGeoAreaLabelClick, handleGeoAreaExpandClick, selectedGeoAreaId]
	);

	if (isLoading || isLoadingSelectedContractGeoAreas) {
		return <CircularProgress />;
	}

	return (
		<TreeView
			defaultCollapseIcon={<CollapseIcon />}
			defaultExpandIcon={<ExpandIcon />}
			defaultEndIcon={<EndIcon color={'disabled'} />}
			expanded={expandedGeoAreaIds}
		>
			{childNodes}
		</TreeView>
	);
};
export default GeoAreaTree;
