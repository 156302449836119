import React, { Component } from 'react';
import { roleService } from '../../../ServiceFactory';
import { connect } from 'react-redux';
import { RoleKey, RoleManagementPermission } from '../../../shared/services/Role.service';
import { PlatformAdminUIState } from '../../../store/store';
import ActionMenuView from '../../../shared/views/ActionMenu/ActionMenu.view';
import { ActionMenuItemProps } from '../../../shared/views/ActionMenu/ActionMenu';
import ChangeEmailActionContainer from './actions/ChangeEmailAction.container';
import ChangeNameActionContainer from './actions/ChangeNameAction.container';
import BlockLoginActionContainer from './actions/BlockLoginAction.container';
import UnblockLoginActionContainer from './actions/UnblockLoginAction.container';
import ResendVerificationMailActionContainer from './actions/ResendVerificationMailAction.container';
import ResetPasswordActionContainer from './actions/ResetPasswordAction.container';
import SetEmailVerifiedActionContainer from './actions/SetEmailVerifiedAction.container';
import DeleteUserContainer from './actions/DeleteUser.container';
import ChangeStatusActionContainer from './actions/ChangeStatusAction.container';

interface Props {
	roleManagementPermission: RoleManagementPermission;
}

class UserDetailsActionsMenuContainer extends Component<Props> {
	render(): JSX.Element | null {
		const { roleManagementPermission } = this.props;

		const validRoles: RoleKey[] = [RoleKey.SUPER_ADMIN, RoleKey.USER_ADMIN, RoleKey.GLOBAL_USER_ADMIN];

		if (!roleService().hasClientRoleAssignmentSomeValidRole(roleManagementPermission.roles, validRoles)) {
			return null;
		}

		return (
			<ActionMenuView
				renderItems={(props: ActionMenuItemProps): JSX.Element[] => [
					<ChangeEmailActionContainer key="changeEmail" {...props} />,
					<ChangeNameActionContainer key="changeName" {...props} />,
					<ChangeStatusActionContainer key="changeStatus" {...props} />,
					<BlockLoginActionContainer key="blockLogin" {...props} />,
					<UnblockLoginActionContainer key="unblockLogin" {...props} />,
					<ResendVerificationMailActionContainer key="resendVerificationMail" {...props} />,
					<SetEmailVerifiedActionContainer key="setEmailVerified" {...props} />,
					<ResetPasswordActionContainer key="resetPassword" {...props} />,
					<DeleteUserContainer key="deleteUser" {...props} />
				]}
			/>
		);
	}
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: PlatformAdminUIState) => {
	return {
		roleManagementPermission: state.global.roleManagementPermission
	};
};

export default connect(mapStateToProps)(UserDetailsActionsMenuContainer);
