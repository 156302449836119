import { Divider, IconButton, InputBase, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Close, Search } from '@mui/icons-material';
import React, { ChangeEvent, FC, useState } from 'react';
import { isEmptyOrNull } from '../helpers';
import { ListType, ValueOf } from '../types';
import useSearch from './hook/useSearch';
import { pink } from '@mui/material/colors';

const useStyles = makeStyles({
	root: {
		padding: '2px 4px',
		display: 'flex',
		alignItems: 'center',
		backgroundColor: ({ shouldHighlight }: { shouldHighlight: boolean }): string =>
			shouldHighlight ? pink[50] : '#ffff'
	}
});

interface SearchFieldProps {
	placeholder: string;
	type: ValueOf<typeof ListType>;
}

const SearchField: FC<SearchFieldProps> = ({ placeholder, type }: SearchFieldProps) => {
	const { query, onChange, onSearch, onReset } = useSearch(type);
	const [shouldHighlight, setShouldHighlight] = useState<boolean>(false);
	const classes = useStyles({ shouldHighlight });

	const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
		onChange(event.target.value);
	};

	const handleReset = (): void => {
		setShouldHighlight(false);
		onReset(type);
	};

	const handleSearch = (): void => {
		if (!isEmptyOrNull(query)) {
			setShouldHighlight(true);
			onSearch(type);
		}
	};

	const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
		// prevent submit (page reload) on Enter
		if (event.key === 'Enter') {
			event.preventDefault();
			handleSearch();
		}
	};

	return (
		<Paper component="form" className={classes.root}>
			<InputBase
				placeholder={'Search ' + placeholder}
				value={query}
				onChange={handleInputChange}
				sx={{
					marginLeft: 1,
					flex: 1
				}}
				onKeyDown={handleKeyDown}
			/>
			<IconButton
				sx={{
					padding: 1
				}}
				onClick={handleSearch}
				size="large"
			>
				<Search />
			</IconButton>
			{query && (
				<>
					<Divider
						orientation="vertical"
						sx={{
							height: 3.5,
							margin: 0.5
						}}
					/>
					<IconButton
						sx={{
							padding: 1
						}}
						onClick={handleReset}
						size="large"
					>
						<Close />
					</IconButton>
				</>
			)}
		</Paper>
	);
};

export default SearchField;
