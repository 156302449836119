import { BaseError } from '../../../shared/errors/Errors';
import { ClientTenantAndAppVariantEntry } from '@DigitaleDoerfer/digitale-doerfer-api/models';

export const GET_APP_CONFIGS_REQUEST = 'GET_APP_CONFIGS_REQUEST';
export const GET_APP_CONFIGS_SUCCESS = 'GET_APP_CONFIGS_SUCCESS';
export const GET_APP_CONFIGS_FAILURE = 'GET_APP_CONFIGS_FAILURE';

export interface GetAppConfigsRequestAction {
	type: typeof GET_APP_CONFIGS_REQUEST;
}

export interface GetAppConfigsSuccessAction {
	type: typeof GET_APP_CONFIGS_SUCCESS;
	appConfigs: ClientTenantAndAppVariantEntry[];
}

export interface GetAppConfigsFailureAction {
	type: typeof GET_APP_CONFIGS_FAILURE;
	error: BaseError | null;
}

export type AppConfigActionTypes = GetAppConfigsRequestAction | GetAppConfigsSuccessAction | GetAppConfigsFailureAction;
