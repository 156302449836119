import { pink } from '@mui/material/colors';
import { createTheme } from '@mui/material';

const theme = createTheme({
	palette: {
		//primary: blue,
		primary: {
			// light: will be calculated from palette.primary.main,
			main: '#0083c5' //as specified in DD-6864
			// dark: will be calculated from palette.primary.main,
			// contrastText: will be calculated to contrast with palette.primary.main
		},
		secondary: pink
	},
	components: {
		MuiSnackbar: {
			styleOverrides: {
				root: {
					// Material UI defines a media query with the same predicate as this, so this will overwrite it.
					// Our snackbar should have some minimum width outside of mobile resolution (<600px = xs breakpoint) in order to avoid excessive line breaks.
					'@media (min-width: 600px)': {
						minWidth: 600
					}
				}
			}
		},
		MuiSnackbarContent: {
			styleOverrides: {
				root: {
					paddingTop: 0,
					paddingBottom: 0
				},
				message: {
					paddingTop: 6,
					paddingBottom: 6,
					// In mobile resolution, the snackbar will be stretched to full width (default Material UI behavior).
					// Since we want the close button to be aligned to the very right end, the content of the snackbar should fill it entirely.
					width: '100%'
				}
			}
		},
		MuiAppBar: {
			styleOverrides: {
				root: {
					padding: 0,
					margin: 0
				}
			}
		},
		MuiDrawer: {
			styleOverrides: {
				paper: {
					top: 64,
					padding: 0,
					margin: 0,
					width: 240,
					height: 'calc(100% - 64px)'
				}
			}
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					padding: 16,
					margin: 8
				}
			}
		},
		MuiListItem: {
			styleOverrides: {
				button: {
					'&:hover': {
						backgroundColor: '#EBEBEB'
					}
				}
			}
		},
		MuiListItemIcon: {
			styleOverrides: {
				root: {
					minWidth: 40
				}
			}
		},
		MuiInputLabel: {
			styleOverrides: {
				shrink: {
					transform: 'translate(0, 1.5px) scale(1)',
					transformOrigin: 'top left'
				}
			}
		}
	},
	zIndex: {
		mobileStepper: 1000,
		appBar: 1200,
		drawer: 1100,
		modal: 1300,
		snackbar: 1400,
		tooltip: 1500
	}
});

export { theme as default };
