export enum MouseEventType {
	MOUSE_LEFT = 1,
	MOUSE_MIDDLE = 2,
	MOUSE_RIGHT = 3
}

export const emailPattern = /[^@\s]+@[^@\s]+\.[^@\s]+/;

export function isValidUrl(url: string): boolean {
	try {
		new URL(url);
	} catch (e) {
		console.error(e);
		return false;
	}
	return true;
}

export function matchesEmailPattern(input: string): boolean {
	return !!input.match(emailPattern);
}

export function arraysEqual<T>(arr1: T[], arr2: T[]): boolean {
	if (arr1.length !== arr2.length) {
		return false;
	}

	for (let index = 0; index < arr1.length; ++index) {
		if (arr1[index] !== arr2[index]) {
			return false;
		}
	}

	return true;
}

export function capitalizeFirstLetter(s: string): string {
	if (!s) {
		return '';
	}
	return s.substring(0, 1).toUpperCase() + s.substring(1);
}

/**
 * Builds a comparator to e.g. be used with `Array.prototype.sort()` that compares ascending according
 * to German language rules, ignoring upper/lower case.
 *
 * @param sortCriteriaExtractor extracts the string that is used for sorting for the given type,
 * 			e.g. `(geoArea: ClientGeoArea) => geoArea.name`
 * @param reverse `false` by default. Set to true to sort descending
 */
export function nullSafeGermanStringAscComparator<T>(
	sortCriteriaExtractor: (instance: T) => string | undefined,
	reverse = false
): (first: T, second: T) => number {
	return (first: T, second: T): number =>
		(sortCriteriaExtractor(first) || '').localeCompare(sortCriteriaExtractor(second) || '', 'de', {
			sensitivity: 'accent' // ignores upper/lower case, see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/localeCompare
		}) * (reverse ? -1 : 1);
}

export class Util {}
