import React from 'react';
import { TextField } from '@mui/material';
import { ReadOnlyTextFieldView } from '../../../shared/views/ReadOnlyTextField.view';

interface Props {
	websiteName: string;
	isReadOnlyField?: boolean;
	newsSourcesWebsiteNameChange: (newWebsiteName: string) => void;
}

class NewsSourcesWebsiteNameView extends React.Component<Props> {
	constructor(props: Props) {
		super(props);
		this.handleNewsSourcesWebsiteNameChange = this.handleNewsSourcesWebsiteNameChange.bind(this);
	}

	handleNewsSourcesWebsiteNameChange(event: React.ChangeEvent<{ value: string }>): void {
		const { newsSourcesWebsiteNameChange } = this.props;
		newsSourcesWebsiteNameChange && newsSourcesWebsiteNameChange(event.target.value);
	}

	render(): JSX.Element {
		const { websiteName, isReadOnlyField } = this.props;
		return isReadOnlyField ? (
			<ReadOnlyTextFieldView
				id="websiteName"
				name="websiteName"
				label="WebsiteName"
				value={websiteName}
				InputLabelProps={{ shrink: true }}
				InputProps={{ readOnly: true }}
				fullWidth
			/>
		) : (
			<TextField
				variant="standard"
				required
				id="websiteName"
				name="websiteName"
				label="WebsiteName"
				value={websiteName}
				onChange={this.handleNewsSourcesWebsiteNameChange}
				InputLabelProps={{ shrink: true }}
				fullWidth
			/>
		);
	}
}

export default NewsSourcesWebsiteNameView;
