import React, { Component } from 'react';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import { ActionMenuItemProps } from '../../../../shared/views/ActionMenu/ActionMenu';
import DialogActionView from '../../../../shared/views/ActionMenu/DialogAction.view';
import { FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material';

interface Props extends ActionMenuItemProps {
	handleStatusChangeAction: (comment: string) => void;
}

interface State {
	comment: string;
	selectedOption: string;
	otherReason: string; // New state for "Sonstiges" text input
}

const initialState = {
	comment: '',
	selectedOption: '',
	otherReason: ''
};

class RejectFlagActionView extends Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = initialState;
		this.handleOpenDialog = this.handleOpenDialog.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.isSubmitDisabled = this.isSubmitDisabled.bind(this);
		this.handleOtherReasonChange = this.handleOtherReasonChange.bind(this);
	}

	handleOpenDialog(): void {
		this.setState(initialState);
	}

	handleSubmit(): void {
		const { selectedOption, otherReason } = this.state;

		// Check if "Sonstiges" is selected and there's text in the text field
		if (selectedOption === 'Sonstiges' && otherReason.trim() !== '') {
			this.props.handleStatusChangeAction(otherReason);
		} else {
			// If either another option is selected or "Sonstiges" is selected but no text provided, send "Sonstiges"
			this.props.handleStatusChangeAction(selectedOption);
		}
	}

	handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
		const newOption = event.target.value;
		this.setState({ selectedOption: newOption });
	}

	handleOtherReasonChange(event: React.ChangeEvent<HTMLInputElement>): void {
		this.setState({ otherReason: event.target.value });
	}

	isSubmitDisabled(): boolean {
		const { selectedOption, otherReason } = this.state;

		// If "Sonstiges" is selected and no text is provided, allow submission
		if (selectedOption === 'Sonstiges' && otherReason.trim() === '') {
			return false;
		}

		// Otherwise, disable submission if no option is selected
		return !selectedOption;
	}

	render(): JSX.Element {
		const { renderMenuItem } = this.props;
		const { selectedOption, otherReason } = this.state;

		// Sort options alphabetically
		const sortedOptions = ['Bedienungsfehler', 'Kein Verstoß'].sort();

		return (
			<DialogActionView
				itemProps={{
					renderMenuItem,
					icon: <InsertEmoticonIcon color="primary" />,
					text: 'Inhalt als unkritisch kennzeichnen'
				}}
				dialogProps={{
					danger: false,
					titleText: 'Inhalt als unkritisch kennzeichnen und Fall abschließen',
					saveButtonText: 'Fertig',
					handleOpen: this.handleOpenDialog,
					handleSave: this.handleSubmit,
					handleIsSaveDisabled: this.isSubmitDisabled
				}}
			>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Typography>
							Bitte begründe, warum der gemeldete Inhalt unbedenklich ist! Der Inhalt bleibt erhalten und dieser Fall
							wird mit Status &quot;Abgelehnt&quot; abgeschlossen.
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<RadioGroup aria-label="reason" name="reason" value={selectedOption} onChange={this.handleChange}>
							{/* Render sorted options */}
							{sortedOptions.map(option => (
								<FormControlLabel key={option} value={option} control={<Radio />} label={option} />
							))}
							<FormControlLabel value="Sonstiges" control={<Radio />} label="Sonstiges" />
						</RadioGroup>
						{/* Render text field if "Sonstiges" is selected */}
						{selectedOption === 'Sonstiges' && (
							<TextField
								label="Grund für Sonstiges"
								value={otherReason}
								onChange={this.handleOtherReasonChange}
								margin="normal"
								fullWidth
							/>
						)}
					</Grid>
				</Grid>
			</DialogActionView>
		);
	}
}

export default RejectFlagActionView;
