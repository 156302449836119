import React from 'react';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import { NotFoundErrorView } from '../error/ErrorPages.view';
import NewsSourcesContainer from './NewsSources.container';
import NewsSourcesCreateContainer from './News-Sources-Create/NewsSourcesCreate.container';

export const NEWS_SOURCES_URL = '/News-sources';
export const NEWS_SOURCES_CREATE_URL = NEWS_SOURCES_URL + '/create';
interface Props extends RouteComponentProps {}

class NewsSourcesRoutingContainer extends React.Component<Props> {
	render(): JSX.Element {
		const {
			match: { url }
		} = this.props;

		return (
			<Switch>
				<Route exact path={url} component={NewsSourcesContainer} />
				<Route path={NEWS_SOURCES_CREATE_URL} component={NewsSourcesCreateContainer} />
				<Route component={NotFoundErrorView} />
			</Switch>
		);
	}
}

export default withRouter(NewsSourcesRoutingContainer);
