import create, { GetState, SetState } from 'zustand';
import { devtools } from 'zustand/middleware';
import createTreeListSlice, { TreeListSlice } from './slices/treeList.slice';
import createFilterSlice, { FilterSlice } from './slices/filter.slice';
import createGeoAreaTreeSlice, { GeoAreaTreeSlice } from './slices/geoAreaTree.slice';

export interface Store extends GeoAreaTreeSlice, FilterSlice, TreeListSlice {}

const useStore = create<Store>(
	devtools(
		(set, get) => ({
			...createGeoAreaTreeSlice(set as unknown as SetState<GeoAreaTreeSlice>, get as unknown as GetState<Store>),
			...createFilterSlice(set as unknown as SetState<FilterSlice>),
			...createTreeListSlice(set as unknown as SetState<TreeListSlice>, get as unknown as GetState<Store>)
		}),
		{ name: 'config-ui' }
	)
);

export { useStore };
