import React, { Component } from 'react';
import { roleService } from '../../../ServiceFactory';
import { connect } from 'react-redux';
import { RoleKey, RoleManagementPermission } from '../../../shared/services/Role.service';
import { PlatformAdminUIState } from '../../../store/store';
import ActionMenuView from '../../../shared/views/ActionMenu/ActionMenu.view';
import { ActionMenuItemProps } from '../../../shared/views/ActionMenu/ActionMenu';
import DeleteGroupActionContainer from './actions/DeleteGroupAction.container';
import RenameGroupActionContainer from './actions/RenameGroupAction.container';
import ChangeGroupAdminsActionContainer from './actions/ChangeGroupAdminsAction.container';
import ChangeGroupGeoAreasActionContainer from './actions/ChangeGroupGeoAreasAction.container';
import ChangeGroupAccessibilityActionContainer from './actions/ChangeGroupAccessibilityAction.container';
interface Props {
	roleManagementPermission: RoleManagementPermission;
}

class GroupDetailsActionsMenuContainer extends Component<Props> {
	render(): JSX.Element | null {
		const { roleManagementPermission } = this.props;

		const validRoles: RoleKey[] = [RoleKey.GLOBAL_GROUP_ADMIN, RoleKey.USER_ADMIN, RoleKey.GLOBAL_USER_ADMIN];

		if (!roleService().hasClientRoleAssignmentSomeValidRole(roleManagementPermission.roles, validRoles)) {
			return null;
		}
		return (
			<ActionMenuView
				renderItems={(props: ActionMenuItemProps): JSX.Element[] => [
					<RenameGroupActionContainer key="renameGroup" {...props} />,
					<ChangeGroupAdminsActionContainer key="changeGroupAdmins" {...props} />,
					<ChangeGroupGeoAreasActionContainer key="changeGeoAreas" {...props} />,
					<ChangeGroupAccessibilityActionContainer key="changeAccessibility" {...props} />,
					<DeleteGroupActionContainer key="deleteGroup" {...props} />
				]}
			/>
		);
	}
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: PlatformAdminUIState) => {
	return {
		roleManagementPermission: state.global.roleManagementPermission
	};
};

export default connect(mapStateToProps)(GroupDetailsActionsMenuContainer);
