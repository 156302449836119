import React from 'react';
import { Button, ButtonProps } from '@mui/material';
import { ActionButtonProps } from './ButtonProps';

function BaseButton(props: ActionButtonProps): JSX.Element {
	const { color, danger, variant, disabled, onClick, submit, children } = props;
	let finalColor: ButtonProps['color'] = 'primary';
	if (color) {
		finalColor = color;
	} else {
		finalColor = danger ? 'secondary' : 'primary';
	}
	return (
		<Button
			color={finalColor}
			variant={variant}
			disabled={disabled}
			onClick={onClick}
			type={submit ? 'submit' : 'button'}
		>
			{children}
		</Button>
	);
}

export default BaseButton;
