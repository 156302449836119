import React from 'react';
import { Grid } from '@mui/material';
import { flaggedContentViewService } from '../../../../ServiceFactory';
import { ReadOnlyTextFieldView } from '../../../../shared/views/ReadOnlyTextField.view';

interface Props {
	reason?: string;
}

class FlaggedContentDetailsReasonView extends React.Component<Props> {
	render(): JSX.Element {
		const { reason } = this.props;
		return (
			<Grid item xs={12} container>
				<ReadOnlyTextFieldView
					id="reason"
					name="reason"
					label="Meldegrund"
					multiline
					maxRows="5"
					value={flaggedContentViewService().renderInfo(reason)}
					InputProps={{ readOnly: true }}
					InputLabelProps={{
						shrink: true
					}}
					fullWidth
				/>
			</Grid>
		);
	}
}

export default FlaggedContentDetailsReasonView;
