import {
	AUTH0_CLIENT_CREATED,
	AUTH0_FAILURE,
	AUTH0_LOGIN_FINISHED,
	AUTH0_LOGIN_INITIATED,
	AUTH0_LOGOUT_FINISHED,
	AuthActionTypes
} from './Auth.actions';
import { AuthState, AuthStatus, initialAuthState } from './Auth.state';

export function authReducer(state = initialAuthState, action: AuthActionTypes): AuthState {
	switch (action.type) {
		case AUTH0_FAILURE: {
			return {
				...state,
				authStatus: AuthStatus.ERROR,
				error: action.error
			};
		}
		case AUTH0_CLIENT_CREATED: {
			return {
				...state,
				auth0Client: action.auth0Client,
				error: null
			};
		}
		case AUTH0_LOGIN_INITIATED: {
			return {
				...state,
				authStatus: AuthStatus.AUTHENTICATING,
				error: null
			};
		}
		case AUTH0_LOGIN_FINISHED: {
			return {
				...state,
				authStatus: AuthStatus.AUTHENTICATED,
				error: null
			};
		}
		case AUTH0_LOGOUT_FINISHED: {
			return {
				...state,
				authStatus: AuthStatus.SIGNED_OUT,
				error: null
			};
		}
		default:
			return state;
	}
}
