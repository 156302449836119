import React, { Component } from 'react';
import { ActionMenuItemProps } from '../../../../shared/views/ActionMenu/ActionMenu';
import PublicIcon from '@mui/icons-material/Public';
import Brightness3Icon from '@mui/icons-material/Brightness3';
import ActionMenuItemView from '../../../../shared/views/ActionMenu/ActionMenuItem.view';
import DialogActionView from '../../../../shared/views/ActionMenu/DialogAction.view';
import { Grid, Typography } from '@mui/material';

interface Props extends ActionMenuItemProps {
	text: string;
	userFirstName: string;
	isInParallelWorld: boolean;
	handleSave: () => void;
}

class ChangeStatusActionView extends Component<Props> {
	render(): JSX.Element {
		const { text, isInParallelWorld, userFirstName, handleSave, renderMenuItem } = this.props;
		return isInParallelWorld ? (
			<ActionMenuItemView
				renderMenuItem={renderMenuItem}
				icon={<PublicIcon color="primary" />}
				text={text}
				action={handleSave}
			/>
		) : (
			<DialogActionView
				itemProps={{
					renderMenuItem,
					icon: <Brightness3Icon color="primary" />,
					text
				}}
				dialogProps={{
					danger: false,
					titleText: 'In die Parallelwelt schicken',
					saveButtonText: 'Ab in die Parallelwelt!',
					handleSave
				}}
			>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Typography>
							{`In der Parallelwelt kann ein Benutzer alle Beiträge lesen. Alles, was er schreibt, ist jedoch nur für
							Personen in der Parallelwelt sichtbar.`}
						</Typography>
						<p>
							<Typography>{`Soll ${userFirstName} wirklich in die Parallelwelt geschickt werden?`}</Typography>
						</p>
					</Grid>
				</Grid>
			</DialogActionView>
		);
	}
}

export default ChangeStatusActionView;
