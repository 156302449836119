import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { BaseError } from '../../shared/errors/Errors';

interface Props {
	error: BaseError | null;
}

interface State {
	open: boolean;
}

class AuthErrorDialogView extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			open: false
		};
	}

	componentDidUpdate(): void {
		const { error } = this.props;
		const { open } = this.state;
		if (!open && error) {
			this.setState({
				open: true
			});
		}
	}

	handleClose(): void {
		this.setState({
			open: false
		});
	}

	render(): JSX.Element {
		const { error } = this.props;
		const { open } = this.state;
		return (
			<Dialog
				open={open}
				onClose={this.handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{'Interner Fehler'}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Ups, da ist etwas schiefgelaufen{error ? ': ' + error.displayMessage : ''}
					</DialogContentText>
				</DialogContent>
			</Dialog>
		);
	}
}

export default AuthErrorDialogView;
