import React from 'react';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import GeoAreasList from './GeoAreasList.container';
import { NotFoundErrorView } from '../error/ErrorPages.view';

export const GEO_AREAS_URL = '/geo-areas';
export const GEO_AREAS_SEARCH_URL = GEO_AREAS_URL + '/search/:searchText';

export function getGeoAreaDetailsUrl(geoAreaId: string): string {
	return GEO_AREAS_SEARCH_URL.replace(':searchText', geoAreaId);
}

interface Props extends RouteComponentProps {}

class GeoAreasListRoutingContainer extends React.Component<Props> {
	render(): JSX.Element {
		const {
			match: { url }
		} = this.props;

		return (
			<Switch>
				<Route exact path={url} component={GeoAreasList} />
				<Route exact path={GEO_AREAS_SEARCH_URL} component={GeoAreasList} />
				<Route component={NotFoundErrorView} />
			</Switch>
		);
	}
}

export default withRouter(GeoAreasListRoutingContainer);
