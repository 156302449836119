import React from 'react';
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import Auth0DetailsContainer from './auth0-details/Auth0Details.container';
import UserCreateContainer from './user-create/UserCreate.container';
import UserDetailsContainer from './user-details/UserDetails.container';
import Users from './user-list/UserList.container';
import { NotFoundErrorView } from '../error/ErrorPages.view';
import { PlatformAdminUIState } from '../../store/store';
import { connect } from 'react-redux';

export const USERS_URL = '/users';
export const USERS_CREATE_URL = USERS_URL + '/create';
const USERS_DETAILS_URL_BASE = USERS_URL + '/details/:id';
const USERS_AUTH0_DETAILS_URL_BASE = USERS_URL + '/auth/:email';
const USERS_PROFILE_URL = USERS_URL + '/profile';

export function getUsersDetailsUrl(userId: string): string {
	return USERS_DETAILS_URL_BASE.replace(':id', userId);
}

export function getUsersAuth0DetailsUrl(email: string): string {
	return USERS_AUTH0_DETAILS_URL_BASE.replace(':email', email);
}

interface Props extends RouteComponentProps {
	currentUserId: string | undefined;
}

class UsersRoutingContainer extends React.Component<Props> {
	render(): JSX.Element {
		const {
			currentUserId,
			match: { url }
		} = this.props;

		return (
			<Switch>
				<Route exact path={url} component={Users} />
				<Route path={USERS_CREATE_URL} component={UserCreateContainer} />
				<Redirect from={USERS_PROFILE_URL} to={getUsersDetailsUrl(currentUserId || '') + '?profile'} />
				<Route path={USERS_DETAILS_URL_BASE} component={UserDetailsContainer} />
				<Route path={USERS_AUTH0_DETAILS_URL_BASE} component={Auth0DetailsContainer} />
				<Route component={NotFoundErrorView} />
			</Switch>
		);
	}
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: PlatformAdminUIState) => {
	return {
		currentUserId: state.global.person?.id
	};
};

export default withRouter(connect(mapStateToProps)(UsersRoutingContainer));
