import { useStore } from '../store';
import { AppVariant, Filter, Filters, FilterType, Tenant } from '../types';

type UseFilterBarProps = {
	filters: Filters;
	removeFilter: (removeFilter: Filter<AppVariant | Tenant>) => void;
	removeAllFilters: () => void;
	filteredTenantIds: string[];
	filteredAppVariantIds: string[];
};
const useFilterBar = (): UseFilterBarProps => {
	const { filters, removeAllFilters, removeFilter } = useStore();

	const filteredTenantIds = Object.values(filters[FilterType.TENANT])
		.filter(filter => !filter.isParentNode)
		.map(filter => filter.id);
	const filteredAppVariantIds = Object.values(filters[FilterType.APP_VARIANT])
		.filter(filter => !filter.isParentNode)
		.map(filter => filter.id);

	return { filters, removeFilter, removeAllFilters, filteredTenantIds, filteredAppVariantIds };
};

export default useFilterBar;
