import React from 'react';
import { TextField, Tooltip } from '@mui/material';

interface Props {
	handleDeferredChange: (searchCriteria: string) => void;
	handleInstantChange?: (searchCriteria: string) => void;
	label: string;
	placeHolder?: string;
	value?: string;
	disabled?: boolean;
	tooltipText?: string;
}

interface State {
	searchCriteria: string;
	typingTimeout?: NodeJS.Timeout;
}

class SearchFieldView extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			searchCriteria: props.value ?? '',
			typingTimeout: undefined
		};

		this.sendDeferredChange = this.sendDeferredChange.bind(this);
		this.sendChange = this.sendChange.bind(this);
		this.handleSearchChange = this.handleSearchChange.bind(this);
	}

	componentDidUpdate(prevProps: Props): void {
		if (this.props.value !== prevProps.value) {
			this.setState({ searchCriteria: this.props.value ?? '' });
		}
	}

	sendChange(): void {
		if (this.props.handleInstantChange) {
			this.props.handleInstantChange(this.state.searchCriteria);
		}
	}

	sendDeferredChange(): void {
		this.props.handleDeferredChange(this.state.searchCriteria);
	}

	handleSearchChange(event: React.ChangeEvent<{ value: string }>): void {
		const value = `${event.target.value || ''}`;
		const sendChange = this.sendChange;
		const sendDeferredChange = this.sendDeferredChange;
		this.setState((state: State): State => {
			if (state.typingTimeout) {
				clearTimeout(state.typingTimeout);
			}
			return {
				...state,
				searchCriteria: value,
				typingTimeout: setTimeout(function () {
					sendDeferredChange();
				}, 500)
			};
		}, sendChange);
	}

	render(): JSX.Element {
		const { label, placeHolder, disabled, tooltipText } = this.props;
		const { searchCriteria } = this.state;
		const searchTextField = (
			<TextField
				variant="standard"
				id="standard-search"
				label={label}
				placeholder={placeHolder || 'Suchbegriff eingeben'}
				type="search"
				value={searchCriteria}
				onChange={this.handleSearchChange}
				disabled={disabled}
				InputLabelProps={{
					shrink: true
				}}
				fullWidth
				autoFocus
			/>
		);
		const searchTextFieldWithTooltip = (
			<Tooltip title={tooltipText ?? ''} placement="top-start" disableFocusListener>
				{searchTextField}
			</Tooltip>
		);
		return tooltipText ? searchTextFieldWithTooltip : searchTextField;
	}
}

export default SearchFieldView;
