import { useMemo } from 'react';
import { QueryObserverResult, useQuery } from 'react-query';
import { participantsAdminuiGeoareaApi } from '../../../../ServiceFactory';
import { useStore } from '../../store';
import { GeoArea } from '../../types';

const useFetchSelectedContractGeoAreas = (): QueryObserverResult<GeoArea[]> => {
	const { addToChildren, getGeoAreaPath, addToExpandedGeoAreaIds } = useStore();
	const alreadyFetchedGeoAreaIds = useStore(state => Object.keys(state.itemsById));
	const includedAndExcludedGeoAreaIds = useStore(state => [...state.includedGeoAreaIds, ...state.excludedGeoAreaIds]);

	const geoAreaIdsToFetch = useMemo(
		() => includedAndExcludedGeoAreaIds.filter(geoAreaId => !alreadyFetchedGeoAreaIds.includes(geoAreaId)),
		[alreadyFetchedGeoAreaIds, includedAndExcludedGeoAreaIds]
	);

	return useQuery(
		['highlightedGeoAreas', { geoAreaIds: geoAreaIdsToFetch }],
		() =>
			participantsAdminuiGeoareaApi().getGeoAreaWithChildrenUsingGET({
				depth: 1,
				geoAreaIds: geoAreaIdsToFetch,
				revealVisibleGeoAreas: true
			}),
		{
			refetchOnWindowFocus: false,
			staleTime: Infinity,
			enabled: true,
			onSuccess: (data: GeoArea[]) => {
				addToChildren(data);

				geoAreaIdsToFetch.forEach(geoAreaId => {
					const path = getGeoAreaPath(geoAreaId);
					addToExpandedGeoAreaIds(path);
				});
			}
		}
	);
};

export default useFetchSelectedContractGeoAreas;
