import makeStyles from '@mui/styles/makeStyles';
import React, { FC } from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import { getBackgroundColorByType } from '../helpers';
import { App, Contract, ListType, Tenant, ValueOf } from '../types';
import TreeList from './components/TreeList';
import { FetchNextPageOptions, InfiniteData, InfiniteQueryObserverResult } from 'react-query';
import SearchField from '../Search/SearchField';

type ListSectionProps = {
	type: ValueOf<typeof ListType>;
	title: string;
	isLoading: boolean;
	showSelectAll?: boolean;
	allContracts?: Contract[];
	isTenantFilterSet?: boolean;
	isFetchingNextPage: boolean;
	data: InfiniteData<App[] | Tenant[] | Contract[]> | undefined;
	fetchNextPage: (
		options?: FetchNextPageOptions | undefined
	) => Promise<InfiniteQueryObserverResult<App[] | Tenant[] | Contract[], unknown>>;
};

const useStyles = makeStyles(theme => ({
	paper: {
		display: 'flex',
		flexDirection: 'column',
		textAlign: 'center',
		color: theme.palette.text.secondary,
		height: '100%',
		padding: 10,
		backgroundColor: ({ type }: { type: ValueOf<typeof ListType> }): string => getBackgroundColorByType(type)
	}
}));

const ListSection: FC<ListSectionProps> = ({
	type,
	title,
	data,
	showSelectAll = false,
	isTenantFilterSet = false,
	isLoading,
	fetchNextPage,
	isFetchingNextPage
}: ListSectionProps) => {
	const classes = useStyles({ type });

	return (
		<Grid item xs={12} md={4} style={{ height: 750 }}>
			<Paper className={classes.paper} variant="outlined">
				<Typography>{title}</Typography>
				<SearchField placeholder={title} type={type} />

				<TreeList
					isLoading={isLoading}
					type={type}
					fetchNextPage={fetchNextPage}
					data={data}
					isFetchingNextPage={isFetchingNextPage}
					showSelectAll={showSelectAll}
					isTenantFilterSet={isTenantFilterSet}
				/>
			</Paper>
		</Grid>
	);
};

export default ListSection;
