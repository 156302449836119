import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ClientGroupExtendedDetail } from '@DigitaleDoerfer/digitale-doerfer-api/models';
import DeleteGroupActionView from './DeleteGroupAction.view';
import {
	showSnackbar,
	SnackbarAction,
	SnackbarType
} from '../../../../shared/views/SnackbarNotification/store/SnackbarNotification.actions';
import { ActionMenuItemProps } from '../../../../shared/views/ActionMenu/ActionMenu';
import { RoleKey, RoleManagementPermission } from '../../../../shared/services/Role.service';
import { roleService } from '../../../../ServiceFactory';
import { PlatformAdminUIState, PlatformAdminUIThunkType } from '../../../../store/store';
import { UserActionTypes } from '../../../users/store/UserActionTypes';
import { groupDeleteByAdmin } from '../../store/Group.actions';

interface Props extends ActionMenuItemProps {
	group: ClientGroupExtendedDetail | null;
	roleManagementPermission: RoleManagementPermission;
	showSnackbar: (snackbarAction: SnackbarAction) => void;
	deleteGroup: (groupId: string, forceDeleteNonEmptyGroups: boolean) => void;
}

class DeleteGroupActionContainer extends Component<Props> {
	constructor(props: Props) {
		super(props);

		this.handleDelete = this.handleDelete.bind(this);
	}

	handleDelete(groupId: string, forceDeleteNonEmptyGroups: boolean): void {
		const { showSnackbar, deleteGroup } = this.props;
		if (groupId) {
			deleteGroup(groupId, forceDeleteNonEmptyGroups);
		} else {
			showSnackbar({
				type: SnackbarType.SNACKBAR_ERROR,
				message: 'Die Gruppe hat keine ID.'
			});
		}
	}

	render(): JSX.Element | null {
		const { renderMenuItem, group, roleManagementPermission } = this.props;
		if (!group) {
			return null;
		}

		if (
			!roleService().hasClientRoleAssignmentSomeValidRole(roleManagementPermission.roles, [RoleKey.GLOBAL_GROUP_ADMIN])
		) {
			return null;
		}

		return <DeleteGroupActionView renderMenuItem={renderMenuItem} group={group} handleDelete={this.handleDelete} />;
	}
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: PlatformAdminUIState) => {
	return {
		group: state.group.detail.group,
		roleManagementPermission: state.global.roleManagementPermission
	};
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch: PlatformAdminUIThunkType<UserActionTypes>) => {
	return {
		showSnackbar: (snackbarAction: SnackbarAction): Promise<void> => dispatch(showSnackbar(snackbarAction)),
		deleteGroup: (groupId: string, forceDeleteNonEmptyGroups: boolean): Promise<void> =>
			dispatch(groupDeleteByAdmin(forceDeleteNonEmptyGroups, groupId))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteGroupActionContainer);
