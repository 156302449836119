import React, { Component } from 'react';
import { FormControlLabel, Grid, Switch } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { ActionMenuItemProps } from '../../../../shared/views/ActionMenu/ActionMenu';
import DialogActionView from '../../../../shared/views/ActionMenu/DialogAction.view';
import { ClientGroupExtendedDetail } from '@DigitaleDoerfer/digitale-doerfer-api/models/ClientGroupExtendedDetail';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-unused-vars
const styles = (theme: Theme) => {
	return createStyles({
		checkField: {
			overflowY: 'visible',
			height: 100
		},
		emphasized: {
			fontWeight: 'bold'
		}
	});
};

interface Props extends ActionMenuItemProps, WithStyles<typeof styles> {
	handleDelete: (groupId: string, forceDeleteNonEmptyGroup: boolean) => void;
	group: ClientGroupExtendedDetail | null;
}

interface State {
	forceDeleteNonEmptyGroup: boolean;
}

const initialState = {
	forceDeleteNonEmptyGroup: false
};

class DeleteGroupActionView extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = initialState;
		this.handleOpenDialog = this.handleOpenDialog.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleForceDeleteSwitchChange = this.handleForceDeleteSwitchChange.bind(this);
	}

	handleOpenDialog(): void {
		this.setState(initialState);
	}

	handleSubmit(): void {
		const { forceDeleteNonEmptyGroup } = this.state;
		const { group, handleDelete } = this.props;
		if (group) {
			const { id } = group;
			handleDelete(id, forceDeleteNonEmptyGroup);
		}
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	handleForceDeleteSwitchChange(event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void {
		this.setState({ forceDeleteNonEmptyGroup: checked });
	}

	render(): JSX.Element | null {
		const { renderMenuItem, group } = this.props;
		if (!group) {
			return null;
		}
		const { forceDeleteNonEmptyGroup } = this.state;
		return (
			<DialogActionView
				itemProps={{
					renderMenuItem,
					icon: <DeleteForeverIcon color="secondary" />,
					text: 'Gruppe löschen'
				}}
				dialogProps={{
					danger: true,
					titleText: 'Soll die Gruppe dauerhaft gelöscht werden?',
					saveButtonText: 'Löschen',
					handleOpen: this.handleOpenDialog,
					handleSave: this.handleSubmit
				}}
			>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<FormControlLabel
							control={
								<Switch
									checked={forceDeleteNonEmptyGroup}
									color="primary"
									onChange={this.handleForceDeleteSwitchChange}
									name="checkedB"
									inputProps={{ 'aria-label': 'primary checkbox' }}
								/>
							}
							label="Gruppe auch löschen, wenn Beiträge oder mehr als 3 Mitglieder vorhanden sind"
						/>
					</Grid>
				</Grid>
			</DialogActionView>
		);
	}
}

export default withStyles(styles)(DeleteGroupActionView);
