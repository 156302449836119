import {
	GET_GROUP_FAILURE,
	GET_GROUP_REQUEST,
	GET_GROUP_SUCCESS,
	GET_GROUPS_FAILURE,
	GET_GROUPS_REQUEST,
	GET_GROUPS_SUCCESS,
	GetGroupFailureAction,
	GetGroupRequestAction,
	GetGroupsFailureAction,
	GetGroupsRequestAction,
	GetGroupsSuccessAction,
	GetGroupSuccessAction,
	GROUP_ADD_GROUP_MEMBERSHIP_BY_ADMIN_FAILURE,
	GROUP_ADD_GROUP_MEMBERSHIP_BY_ADMIN_REQUEST,
	GROUP_ADD_GROUP_MEMBERSHIP_BY_ADMIN_SUCCESS,
	GROUP_ADD_PERSON_FAILURE,
	GROUP_ADD_PERSON_REQUEST,
	GROUP_ADD_PERSON_SUCCESS,
	GROUP_CHANGE_GROUP_ACCESSIBILITY_FAILURE,
	GROUP_CHANGE_GROUP_ACCESSIBILITY_REQUEST,
	GROUP_CHANGE_GROUP_ACCESSIBILITY_SUCCESS,
	GROUP_CHANGE_NAME_BY_ADMIN_FAILURE,
	GROUP_CHANGE_NAME_BY_ADMIN_REQUEST,
	GROUP_CHANGE_NAME_BY_ADMIN_SUCCESS,
	GROUP_CREATE_BY_ADMIN_FAILURE,
	GROUP_CREATE_BY_ADMIN_REQUEST,
	GROUP_CREATE_BY_ADMIN_SUCCESS,
	GROUP_DELETE_BY_ADMIN_FAILURE,
	GROUP_DELETE_BY_ADMIN_REQUEST,
	GROUP_DELETE_BY_ADMIN_SUCCESS,
	GROUP_MODIFY_GEO_AREAS_BY_ADMIN_FAILURE,
	GROUP_MODIFY_GEO_AREAS_BY_ADMIN_REQUEST,
	GROUP_MODIFY_GEO_AREAS_BY_ADMIN_SUCCESS,
	GROUP_REMOVE_GROUP_MEMBERSHIP_ADMIN_FAILURE,
	GROUP_REMOVE_GROUP_MEMBERSHIP_ADMIN_REQUEST,
	GROUP_REMOVE_GROUP_MEMBERSHIP_ADMIN_SUCCESS,
	GroupActionTypes,
	GroupAddGroupMembershipByAdminFailureAction,
	GroupAddGroupMembershipByAdminRequestAction,
	GroupAddGroupMembershipByAdminSuccessAction,
	GroupAddPersonFailureAction,
	GroupAddPersonRequestAction,
	GroupAddPersonSuccessAction,
	GroupChangeGroupAccessibilityFailureAction,
	GroupChangeGroupAccessibilityRequestAction,
	GroupChangeGroupAccessibilitySuccessAction,
	GroupChangeNameByAdminFailureAction,
	GroupChangeNameByAdminRequestAction,
	GroupChangeNameByAdminSuccessAction,
	GroupCreateByAdminFailureAction,
	GroupCreateByAdminRequestAction,
	GroupCreateByAdminSuccessAction,
	GroupDeleteByAdminFailureAction,
	GroupDeleteByAdminRequestAction,
	GroupDeleteByAdminSuccessAction,
	GroupModifyGeoAreasByAdminFailureAction,
	GroupModifyGeoAreasByAdminRequestAction,
	GroupModifyGeoAreasByAdminSuccessAction,
	GroupRemoveGroupMembershipAdminFailureAction,
	GroupRemoveGroupMembershipAdminRequestAction,
	GroupRemoveGroupMembershipAdminSuccessAction
} from './GroupActionTypes';
import { GroupState, initialGroupState } from './Group.state';
import {
	ClientGroupExtendedAccessibilityEnum,
	ClientGroupExtendedDetailAccessibilityEnum
} from '@DigitaleDoerfer/digitale-doerfer-api/models';
const getGroupsRequest = (state: GroupState, action: GetGroupsRequestAction): GroupState => {
	return {
		...state,
		list: {
			...state.list,
			error: null,
			loading: true
		}
	};
};

const getGroupsSuccess = (state: GroupState, action: GetGroupsSuccessAction): GroupState => {
	return {
		...state,
		list: {
			...state.list,
			groups: action.groups,
			searchParams: {
				...state.list.searchParams,
				pagination: action.pagination,
				tenantId: action.tenantId
			},
			loading: false
		}
	};
};

const getGroupsFailure = (state: GroupState, action: GetGroupsFailureAction): GroupState => {
	return {
		...state,
		list: {
			...state.list,
			error: action.error,
			loading: false
		}
	};
};

const getGroupRequest = (state: GroupState, action: GetGroupRequestAction): GroupState => {
	return {
		...state,
		detail: {
			...state.detail,
			group: null,
			loading: true
		}
	};
};

const getGroupSuccess = (state: GroupState, action: GetGroupSuccessAction): GroupState => {
	return {
		...state,
		detail: {
			...state.detail,
			group: action.group,
			loading: false
		}
	};
};

const getGroupFailure = (state: GroupState, action: GetGroupFailureAction): GroupState => {
	return {
		...state,
		detail: {
			...state.detail,
			loading: false
		}
	};
};

const groupAddGroupMembershipByAdminRequest = (
	state: GroupState,
	action: GroupAddGroupMembershipByAdminRequestAction
): GroupState => {
	return { ...state };
};

const groupAddGroupMembershipByAdminSuccess = (
	state: GroupState,
	action: GroupAddGroupMembershipByAdminSuccessAction
): GroupState => {
	return {
		...state,
		detail: {
			...state.detail,
			group: action.updatedGroup
		}
	};
};

const groupAddGroupMembershipByAdminFailure = (
	state: GroupState,
	action: GroupAddGroupMembershipByAdminFailureAction
): GroupState => {
	return { ...state };
};

const groupAddPersonRequest = (state: GroupState, action: GroupAddPersonRequestAction): GroupState => {
	return { ...state };
};

const groupAddPersonSuccess = (state: GroupState, action: GroupAddPersonSuccessAction): GroupState => {
	return {
		...state,
		detail: {
			...state.detail,
			group: state.detail.group
				? {
						...state.detail.group,
						memberCount: action.updatedGroup.memberCount // TODO: where we add this new person? Do we need this feature?
				  }
				: null
		}
	};
};

const groupAddPersonFailure = (state: GroupState, action: GroupAddPersonFailureAction): GroupState => {
	return { ...state };
};

const groupChangeNameByAdminRequest = (state: GroupState, action: GroupChangeNameByAdminRequestAction): GroupState => {
	return { ...state };
};

const groupChangeNameByAdminSuccess = (state: GroupState, action: GroupChangeNameByAdminSuccessAction): GroupState => {
	return {
		...state,
		detail: {
			...state.detail,
			group: state.detail.group
				? {
						...state.detail.group,
						name: action.updatedGroup.name,
						shortName: action.updatedGroup.shortName
				  }
				: null
		}
	};
};

const groupChangeNameByAdminFailure = (state: GroupState, action: GroupChangeNameByAdminFailureAction): GroupState => {
	return { ...state };
};

const groupCreateByAdminRequest = (state: GroupState, action: GroupCreateByAdminRequestAction): GroupState => {
	return {
		...state,
		create: {
			...state.create,
			error: null,
			loading: true,
			groupHasBeenCreated: false
		}
	};
};

const groupCreateByAdminSuccess = (state: GroupState, action: GroupCreateByAdminSuccessAction): GroupState => {
	return {
		...state,
		create: {
			...state.create,
			loading: false,
			groupHasBeenCreated: true
		}
	};
};

const groupCreateByAdminFailure = (state: GroupState, action: GroupCreateByAdminFailureAction): GroupState => {
	return {
		...state,
		create: {
			...state.create,
			error: action.error,
			loading: false,
			groupHasBeenCreated: false
		}
	};
};

const groupDeleteByAdminRequest = (state: GroupState, action: GroupDeleteByAdminRequestAction): GroupState => {
	return { ...state };
};

const groupDeleteByAdminSuccess = (state: GroupState, action: GroupDeleteByAdminSuccessAction): GroupState => {
	const deleteIndexGroup = state.list.groups.findIndex(group => group.id === action.groupId);
	const groups = state.list.groups;
	return {
		...state,
		list: {
			...state.list,
			groups: [...groups.slice(0, deleteIndexGroup), ...groups.slice(deleteIndexGroup + 1)]
		}
	};
};

const groupDeleteByAdminFailure = (state: GroupState, action: GroupDeleteByAdminFailureAction): GroupState => {
	return { ...state };
};

const groupModifyGeoAreasByAdminRequest = (
	state: GroupState,
	action: GroupModifyGeoAreasByAdminRequestAction
): GroupState => {
	return { ...state };
};

const groupModifyGeoAreasByAdminSuccess = (
	state: GroupState,
	action: GroupModifyGeoAreasByAdminSuccessAction
): GroupState => {
	return {
		...state,
		detail: {
			...state.detail,
			group: state.detail.group
				? {
						...state.detail.group,
						includedGeoAreas: action.updatedGroup.includedGeoAreas
				  }
				: null
		}
	};
};

const groupModifyGeoAreasByAdminFailure = (
	state: GroupState,
	action: GroupModifyGeoAreasByAdminFailureAction
): GroupState => {
	return { ...state };
};

const groupRemoveGroupMembershipAdminRequest = (
	state: GroupState,
	action: GroupRemoveGroupMembershipAdminRequestAction
): GroupState => {
	return { ...state };
};

const groupRemoveGroupMembershipAdminSuccess = (
	state: GroupState,
	action: GroupRemoveGroupMembershipAdminSuccessAction
): GroupState => {
	return {
		...state,
		detail: {
			...state.detail,
			group: action.updatedGroup
		}
	};
};

const groupRemoveGroupMembershipAdminFailure = (
	state: GroupState,
	action: GroupRemoveGroupMembershipAdminFailureAction
): GroupState => {
	return { ...state };
};

const groupChangeGroupAccessibilitySuccess = (
	state: GroupState,
	action: GroupChangeGroupAccessibilitySuccessAction
): GroupState => {
	const AccessibilityFromExtended = action.updatedGroup.accessibility;
	let ChangeAccessibilityfromExtendedtoDetail: ClientGroupExtendedDetailAccessibilityEnum =
		ClientGroupExtendedDetailAccessibilityEnum.PUBLIC;
	if (AccessibilityFromExtended === ClientGroupExtendedAccessibilityEnum.PUBLIC) {
		ChangeAccessibilityfromExtendedtoDetail = ClientGroupExtendedDetailAccessibilityEnum.PUBLIC;
	}
	if (AccessibilityFromExtended === ClientGroupExtendedAccessibilityEnum.APPROVALREQUIRED) {
		ChangeAccessibilityfromExtendedtoDetail = ClientGroupExtendedDetailAccessibilityEnum.APPROVALREQUIRED;
	}
	return {
		...state,
		detail: {
			...state.detail,
			group: state.detail.group
				? {
						...state.detail.group,
						accessibility: ChangeAccessibilityfromExtendedtoDetail
				  }
				: null
		}
	};
};

const groupChangeGroupAccessibilityRequest = (
	state: GroupState,
	action: GroupChangeGroupAccessibilityRequestAction
): GroupState => {
	return { ...state };
};

const groupChangeGroupAccessibilityFailure = (
	state: GroupState,
	action: GroupChangeGroupAccessibilityFailureAction
): GroupState => {
	return { ...state };
};
const reducer = (state = initialGroupState, action: GroupActionTypes): GroupState => {
	switch (action.type) {
		case GET_GROUPS_REQUEST:
			return getGroupsRequest(state, action);
		case GET_GROUPS_SUCCESS:
			return getGroupsSuccess(state, action);
		case GET_GROUPS_FAILURE:
			return getGroupsFailure(state, action);
		case GET_GROUP_REQUEST:
			return getGroupRequest(state, action);
		case GET_GROUP_SUCCESS:
			return getGroupSuccess(state, action);
		case GET_GROUP_FAILURE:
			return getGroupFailure(state, action);
		case GROUP_ADD_GROUP_MEMBERSHIP_BY_ADMIN_REQUEST:
			return groupAddGroupMembershipByAdminRequest(state, action);
		case GROUP_ADD_GROUP_MEMBERSHIP_BY_ADMIN_SUCCESS:
			return groupAddGroupMembershipByAdminSuccess(state, action);
		case GROUP_ADD_GROUP_MEMBERSHIP_BY_ADMIN_FAILURE:
			return groupAddGroupMembershipByAdminFailure(state, action);
		case GROUP_ADD_PERSON_REQUEST:
			return groupAddPersonRequest(state, action);
		case GROUP_ADD_PERSON_SUCCESS:
			return groupAddPersonSuccess(state, action);
		case GROUP_ADD_PERSON_FAILURE:
			return groupAddPersonFailure(state, action);
		case GROUP_CHANGE_NAME_BY_ADMIN_REQUEST:
			return groupChangeNameByAdminRequest(state, action);
		case GROUP_CHANGE_NAME_BY_ADMIN_SUCCESS:
			return groupChangeNameByAdminSuccess(state, action);
		case GROUP_CHANGE_NAME_BY_ADMIN_FAILURE:
			return groupChangeNameByAdminFailure(state, action);
		case GROUP_CREATE_BY_ADMIN_REQUEST:
			return groupCreateByAdminRequest(state, action);
		case GROUP_CREATE_BY_ADMIN_SUCCESS:
			return groupCreateByAdminSuccess(state, action);
		case GROUP_CREATE_BY_ADMIN_FAILURE:
			return groupCreateByAdminFailure(state, action);
		case GROUP_DELETE_BY_ADMIN_REQUEST:
			return groupDeleteByAdminRequest(state, action);
		case GROUP_DELETE_BY_ADMIN_SUCCESS:
			return groupDeleteByAdminSuccess(state, action);
		case GROUP_DELETE_BY_ADMIN_FAILURE:
			return groupDeleteByAdminFailure(state, action);
		case GROUP_MODIFY_GEO_AREAS_BY_ADMIN_REQUEST:
			return groupModifyGeoAreasByAdminRequest(state, action);
		case GROUP_MODIFY_GEO_AREAS_BY_ADMIN_SUCCESS:
			return groupModifyGeoAreasByAdminSuccess(state, action);
		case GROUP_MODIFY_GEO_AREAS_BY_ADMIN_FAILURE:
			return groupModifyGeoAreasByAdminFailure(state, action);
		case GROUP_REMOVE_GROUP_MEMBERSHIP_ADMIN_REQUEST:
			return groupRemoveGroupMembershipAdminRequest(state, action);
		case GROUP_REMOVE_GROUP_MEMBERSHIP_ADMIN_SUCCESS:
			return groupRemoveGroupMembershipAdminSuccess(state, action);
		case GROUP_REMOVE_GROUP_MEMBERSHIP_ADMIN_FAILURE:
			return groupRemoveGroupMembershipAdminFailure(state, action);
		case GROUP_CHANGE_GROUP_ACCESSIBILITY_REQUEST:
			return groupChangeGroupAccessibilityRequest(state, action);
		case GROUP_CHANGE_GROUP_ACCESSIBILITY_SUCCESS:
			return groupChangeGroupAccessibilitySuccess(state, action);
		case GROUP_CHANGE_GROUP_ACCESSIBILITY_FAILURE:
			return groupChangeGroupAccessibilityFailure(state, action);
		default:
			return state;
	}
};

export default reducer;
