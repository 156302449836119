import React, { Component, PropsWithChildren } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import GroupUserElementView from './GroupUserElement.view';
import { AnyPerson } from '../../utils/AnyPerson';

interface Props {
	title?: string;
	admins: AnyPerson[];
	handleUserDeletion?: (user: AnyPerson) => void;
}

class GroupAdministratorsView extends Component<PropsWithChildren<Props>> {
	render(): JSX.Element {
		const { title, admins, children, handleUserDeletion } = this.props;
		return (
			<Box mb={4}>
				<Grid container>
					{title && (
						<Grid item xs={12}>
							<Typography variant="h2" gutterBottom>
								{title}
							</Typography>
						</Grid>
					)}
					<Grid item xs={12}>
						<Grid container spacing={3}>
							<Grid item xs={12} md={6} lg={3}>
								{children}
							</Grid>
						</Grid>
						<Grid container spacing={3}>
							{admins &&
								admins.map(admin => (
									<Grid key={admin.id} item>
										<GroupUserElementView
											user={admin}
											handleUserDeletion={
												handleUserDeletion
													? (): void => {
															handleUserDeletion(admin);
													  }
													: undefined
											}
										/>
									</Grid>
								))}
						</Grid>
					</Grid>
				</Grid>
			</Box>
		);
	}
}

export default GroupAdministratorsView;
