import React from 'react';

import { Grid, Table, TableBody, TableCell, TableHead, TableRow, Theme, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

interface Devices {
	clientId?: string;
	clientName?: string;
	deviceName?: string;
}

interface Props {
	devices: Devices[];
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStyles = makeStyles((theme: Theme) => ({
	roleColumn: {
		paddingLeft: 0,
		paddingRight: 12,
		width: '50%'
	}
}));

function DevicesListView(props: Props): JSX.Element | null {
	const devicesList = props.devices;
	const css = useStyles();
	if (devicesList.length > 0)
		return (
			<Grid container>
				<Grid
					item
					xs={12}
					sx={{
						paddingTop: '0 !important',
						paddingBottom: '0 !important',
						marginBottom: '0 !important'
					}}
				>
					<Typography variant="h2" gutterBottom>
						Geräte
					</Typography>
				</Grid>
				<Grid
					item
					xs={12}
					sx={{
						paddingTop: '0 !important'
					}}
				>
					<Table size="small">
						<TableHead>
							<TableRow>
								<TableCell align="left" className={css.roleColumn}>
									App
								</TableCell>
								<TableCell align="left" className={css.roleColumn}>
									Gerät
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{devicesList.map((device: Devices) => (
								<TableRow key={`${device.clientId}_${device.deviceName}`}>
									<TableCell align="left" className={css.roleColumn}>
										{device.clientName}
									</TableCell>
									<TableCell align="left" className={css.roleColumn}>
										{device.deviceName}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</Grid>
			</Grid>
		);
	else
		return (
			<Grid container>
				<Grid item xs={12}>
					<Typography variant="h2" gutterBottom>
						Geräte
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<p>Es sind keine Geräteinformationen verfügbar.</p>
				</Grid>
			</Grid>
		);
}

export default DevicesListView;
