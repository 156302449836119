import { QueryObserverResult, useQuery } from 'react-query';
import { participantsAdminuiGeoareaApi } from '../../../../ServiceFactory';
import { useStore } from '../../store';
import { GeoArea } from '../../types';

const useFetchGeoAreasFirstLevel = (): QueryObserverResult<GeoArea[]> => {
	const { addToChildren } = useStore();
	return useQuery(
		['geoAreas', null],
		() =>
			participantsAdminuiGeoareaApi().getGeoAreaWithChildrenUsingGET({
				depth: 1
			}),
		{
			refetchOnWindowFocus: false,
			staleTime: Infinity,
			onSuccess: (data: GeoArea[]) => {
				addToChildren(data);
			}
		}
	);
};

export default useFetchGeoAreasFirstLevel;
