import {
	ClientGroupAddPersonRequest,
	ClientGroupChangeNameByAdminRequest,
	ClientGroupChangeVisibilityAndAccessibilityByAdminRequest,
	ClientGroupCreateByAdminRequest,
	ClientGroupExtended,
	ClientGroupExtendedDetail
} from '@DigitaleDoerfer/digitale-doerfer-api/models';
import { BaseError } from '../../../shared/errors/Errors';
import { ListSearchParams } from './Group.state';
import { Pagination } from '../../../shared/services/InMemoryTable.service';

export const GET_GROUPS_REQUEST = 'GET_GROUPS_REQUEST';
export const GET_GROUPS_SUCCESS = 'GET_GROUPS_SUCCESS';
export const GET_GROUPS_FAILURE = 'GET_GROUPS_FAILURE';

export const GET_GROUP_REQUEST = 'GET_GROUP_REQUEST';
export const GET_GROUP_SUCCESS = 'GET_GROUP_SUCCESS';
export const GET_GROUP_FAILURE = 'GET_GROUP_FAILURE';

export const GROUP_ADD_GROUP_MEMBERSHIP_BY_ADMIN_REQUEST = 'GROUP_ADD_GROUP_MEMBERSHIP_BY_ADMIN_REQUEST';
export const GROUP_ADD_GROUP_MEMBERSHIP_BY_ADMIN_SUCCESS = 'GROUP_ADD_GROUP_MEMBERSHIP_BY_ADMIN_SUCCESS';
export const GROUP_ADD_GROUP_MEMBERSHIP_BY_ADMIN_FAILURE = 'GROUP_ADD_GROUP_MEMBERSHIP_BY_ADMIN_FAILURE';

export const GROUP_ADD_PERSON_REQUEST = 'GROUP_ADD_PERSON_REQUEST';
export const GROUP_ADD_PERSON_SUCCESS = 'GROUP_ADD_PERSON_SUCCESS';
export const GROUP_ADD_PERSON_FAILURE = 'GROUP_ADD_PERSON_FAILURE';

export const GROUP_CHANGE_NAME_BY_ADMIN_REQUEST = 'GROUP_CHANGE_NAME_BY_ADMIN_REQUEST';
export const GROUP_CHANGE_NAME_BY_ADMIN_SUCCESS = 'GROUP_CHANGE_NAME_BY_ADMIN_SUCCESS';
export const GROUP_CHANGE_NAME_BY_ADMIN_FAILURE = 'GROUP_CHANGE_NAME_BY_ADMIN_FAILURE';

export const GROUP_CREATE_BY_ADMIN_REQUEST = 'GROUP_CREATE_BY_ADMIN_REQUEST';
export const GROUP_CREATE_BY_ADMIN_SUCCESS = 'GROUP_CREATE_BY_ADMIN_SUCCESS';
export const GROUP_CREATE_BY_ADMIN_FAILURE = 'GROUP_CREATE_BY_ADMIN_FAILURE';

export const GROUP_DELETE_BY_ADMIN_REQUEST = 'GROUP_DELETE_BY_ADMIN_REQUEST';
export const GROUP_DELETE_BY_ADMIN_SUCCESS = 'GROUP_DELETE_BY_ADMIN_SUCCESS';
export const GROUP_DELETE_BY_ADMIN_FAILURE = 'GROUP_DELETE_BY_ADMIN_FAILURE';

export const GROUP_MODIFY_GEO_AREAS_BY_ADMIN_REQUEST = 'GROUP_MODIFY_GEO_AREAS_BY_ADMIN_REQUEST';
export const GROUP_MODIFY_GEO_AREAS_BY_ADMIN_SUCCESS = 'GROUP_MODIFY_GEO_AREAS_BY_ADMIN_SUCCESS';
export const GROUP_MODIFY_GEO_AREAS_BY_ADMIN_FAILURE = 'GROUP_MODIFY_GEO_AREAS_BY_ADMIN_FAILURE';

export const GROUP_REMOVE_GROUP_MEMBERSHIP_ADMIN_REQUEST = 'GROUP_REMOVE_GROUP_MEMBERSHIP_ADMIN_REQUEST';
export const GROUP_REMOVE_GROUP_MEMBERSHIP_ADMIN_SUCCESS = 'GROUP_REMOVE_GROUP_MEMBERSHIP_ADMIN_SUCCESS';
export const GROUP_REMOVE_GROUP_MEMBERSHIP_ADMIN_FAILURE = 'GROUP_REMOVE_GROUP_MEMBERSHIP_ADMIN_FAILURE';

export const GROUP_CHANGE_GROUP_ACCESSIBILITY_REQUEST = 'GROUP_CHANGE_GROUP_ACCESSIBILITY_REQUEST';
export const GROUP_CHANGE_GROUP_ACCESSIBILITY_SUCCESS = 'GROUP_CHANGE_GROUP_ACCESSIBILITY_SUCCESS';
export const GROUP_CHANGE_GROUP_ACCESSIBILITY_FAILURE = 'GROUP_CHANGE_GROUP_ACCESSIBILITY_FAILURE,';

export interface GetGroupsRequestAction {
	type: typeof GET_GROUPS_REQUEST;
	searchParams: ListSearchParams;
}

export interface GetGroupsSuccessAction {
	type: typeof GET_GROUPS_SUCCESS;
	groups: ClientGroupExtended[];
	pagination: Pagination;
	tenantId: string;
}

export interface GetGroupsFailureAction {
	type: typeof GET_GROUPS_FAILURE;
	error: BaseError | null;
}

export interface GetGroupRequestAction {
	type: typeof GET_GROUP_REQUEST;
	groupId: string;
}

export interface GetGroupSuccessAction {
	type: typeof GET_GROUP_SUCCESS;
	group: ClientGroupExtendedDetail;
}

export interface GetGroupFailureAction {
	type: typeof GET_GROUP_FAILURE;
	error: BaseError | null;
}

export interface GroupAddGroupMembershipByAdminRequestAction {
	type: typeof GROUP_ADD_GROUP_MEMBERSHIP_BY_ADMIN_REQUEST;
	groupId: string;
	membershipAdminIdToAdd: string;
}

export interface GroupAddGroupMembershipByAdminSuccessAction {
	type: typeof GROUP_ADD_GROUP_MEMBERSHIP_BY_ADMIN_SUCCESS;
	updatedGroup: ClientGroupExtendedDetail;
}

export interface GroupAddGroupMembershipByAdminFailureAction {
	type: typeof GROUP_ADD_GROUP_MEMBERSHIP_BY_ADMIN_FAILURE;
	error: BaseError | null;
}

export interface GroupAddPersonRequestAction {
	type: typeof GROUP_ADD_PERSON_REQUEST;
	clientGroupAddPersonRequest: ClientGroupAddPersonRequest;
}

export interface GroupAddPersonSuccessAction {
	type: typeof GROUP_ADD_PERSON_SUCCESS;
	updatedGroup: ClientGroupExtended;
}

export interface GroupAddPersonFailureAction {
	type: typeof GROUP_ADD_PERSON_FAILURE;
	error: BaseError | null;
}

export interface GroupChangeNameByAdminRequestAction {
	type: typeof GROUP_CHANGE_NAME_BY_ADMIN_REQUEST;
	clientGroupChangeNameByAdminRequest: ClientGroupChangeNameByAdminRequest;
}

export interface GroupChangeNameByAdminSuccessAction {
	type: typeof GROUP_CHANGE_NAME_BY_ADMIN_SUCCESS;
	updatedGroup: ClientGroupExtended;
}

export interface GroupChangeNameByAdminFailureAction {
	type: typeof GROUP_CHANGE_NAME_BY_ADMIN_FAILURE;
	error: BaseError | null;
}

export interface GroupCreateByAdminRequestAction {
	type: typeof GROUP_CREATE_BY_ADMIN_REQUEST;
	clientGroupCreateByAdminRequest: ClientGroupCreateByAdminRequest;
}

export interface GroupCreateByAdminSuccessAction {
	type: typeof GROUP_CREATE_BY_ADMIN_SUCCESS;
	createdGroup: ClientGroupExtendedDetail;
}

export interface GroupCreateByAdminFailureAction {
	type: typeof GROUP_CREATE_BY_ADMIN_FAILURE;
	error: BaseError | null;
}

export interface GroupDeleteByAdminRequestAction {
	type: typeof GROUP_DELETE_BY_ADMIN_REQUEST;
	forceDeleteNonEmptyGroup: boolean;
	groupId: string;
}

export interface GroupDeleteByAdminSuccessAction {
	type: typeof GROUP_DELETE_BY_ADMIN_SUCCESS;
	deletedMemberCount: number;
	groupId: string;
}

export interface GroupDeleteByAdminFailureAction {
	type: typeof GROUP_DELETE_BY_ADMIN_FAILURE;
	error: BaseError | null;
}

export interface GroupModifyGeoAreasByAdminRequestAction {
	type: typeof GROUP_MODIFY_GEO_AREAS_BY_ADMIN_REQUEST;
	groupId: string;
	newGeoAreaIds: string[];
}

export interface GroupModifyGeoAreasByAdminSuccessAction {
	type: typeof GROUP_MODIFY_GEO_AREAS_BY_ADMIN_SUCCESS;
	updatedGroup: ClientGroupExtended;
}

export interface GroupModifyGeoAreasByAdminFailureAction {
	type: typeof GROUP_MODIFY_GEO_AREAS_BY_ADMIN_FAILURE;
	error: BaseError | null;
}

export interface GroupRemoveGroupMembershipAdminRequestAction {
	type: typeof GROUP_REMOVE_GROUP_MEMBERSHIP_ADMIN_REQUEST;
	groupId: string;
	membershipAdminIdToRemove: string;
}

export interface GroupRemoveGroupMembershipAdminSuccessAction {
	type: typeof GROUP_REMOVE_GROUP_MEMBERSHIP_ADMIN_SUCCESS;
	updatedGroup: ClientGroupExtendedDetail;
}

export interface GroupRemoveGroupMembershipAdminFailureAction {
	type: typeof GROUP_REMOVE_GROUP_MEMBERSHIP_ADMIN_FAILURE;
	error: BaseError | null;
}

export interface GroupChangeGroupAccessibilityRequestAction {
	type: typeof GROUP_CHANGE_GROUP_ACCESSIBILITY_REQUEST;
	clientGroupChangeVisibilityAndAccessibilityByAdminRequest: ClientGroupChangeVisibilityAndAccessibilityByAdminRequest;
}
export interface GroupChangeGroupAccessibilitySuccessAction {
	type: typeof GROUP_CHANGE_GROUP_ACCESSIBILITY_SUCCESS;
	updatedGroup: ClientGroupExtended;
}
export interface GroupChangeGroupAccessibilityFailureAction {
	type: typeof GROUP_CHANGE_GROUP_ACCESSIBILITY_FAILURE;
	error: BaseError | null;
}
export type GroupActionTypes =
	| GetGroupsRequestAction
	| GetGroupsSuccessAction
	| GetGroupsFailureAction
	| GetGroupRequestAction
	| GetGroupSuccessAction
	| GetGroupFailureAction
	| GroupAddGroupMembershipByAdminRequestAction
	| GroupAddGroupMembershipByAdminSuccessAction
	| GroupAddGroupMembershipByAdminFailureAction
	| GroupAddPersonRequestAction
	| GroupAddPersonSuccessAction
	| GroupAddPersonFailureAction
	| GroupChangeNameByAdminRequestAction
	| GroupChangeNameByAdminSuccessAction
	| GroupChangeNameByAdminFailureAction
	| GroupCreateByAdminRequestAction
	| GroupCreateByAdminSuccessAction
	| GroupCreateByAdminFailureAction
	| GroupDeleteByAdminRequestAction
	| GroupDeleteByAdminSuccessAction
	| GroupDeleteByAdminFailureAction
	| GroupModifyGeoAreasByAdminRequestAction
	| GroupModifyGeoAreasByAdminSuccessAction
	| GroupModifyGeoAreasByAdminFailureAction
	| GroupRemoveGroupMembershipAdminRequestAction
	| GroupRemoveGroupMembershipAdminSuccessAction
	| GroupRemoveGroupMembershipAdminFailureAction
	| GroupChangeGroupAccessibilityRequestAction
	| GroupChangeGroupAccessibilitySuccessAction
	| GroupChangeGroupAccessibilityFailureAction;
