import React, { Component } from 'react';
import { roleService } from '../../../../ServiceFactory';
import { connect } from 'react-redux';
import { RoleKey, RoleManagementPermission } from '../../../../shared/services/Role.service';
import { PlatformAdminUIState } from '../../../../store/store';
import ActionMenuView from '../../../../shared/views/ActionMenu/ActionMenu.view';
import { ActionMenuItemProps } from '../../../../shared/views/ActionMenu/ActionMenu';
import ReopenFlagActionContainer from '../actions/ReopenFlagAction.container';
import RejectFlagActionContainer from '../actions/RejectFlagAction.container';
import AcceptFlagActionContainer from '../actions/AcceptFlagAction.container';
import { ListFlagsUsingGETIncludedStatusEnum } from '@DigitaleDoerfer/digitale-doerfer-api/apis';
import {
	ClientUserGeneratedContentFlagChatStatusEnum,
	ClientUserGeneratedContentFlagCommentStatusEnum,
	ClientUserGeneratedContentFlagDetailStatusEnum,
	ClientUserGeneratedContentFlagGroupStatusEnum,
	ClientUserGeneratedContentFlagPostStatusEnum
} from '@DigitaleDoerfer/digitale-doerfer-api/models';

interface Props {
	roleManagementPermission: RoleManagementPermission;
	flagStatus:
		| ClientUserGeneratedContentFlagDetailStatusEnum
		| ClientUserGeneratedContentFlagChatStatusEnum
		| ClientUserGeneratedContentFlagCommentStatusEnum
		| ClientUserGeneratedContentFlagGroupStatusEnum
		| ClientUserGeneratedContentFlagPostStatusEnum
		| undefined;
}

function getValidStatusChangeActionsByStatus(
	props: ActionMenuItemProps,
	flagStatus:
		| ClientUserGeneratedContentFlagDetailStatusEnum
		| ClientUserGeneratedContentFlagChatStatusEnum
		| ClientUserGeneratedContentFlagCommentStatusEnum
		| ClientUserGeneratedContentFlagGroupStatusEnum
		| ClientUserGeneratedContentFlagPostStatusEnum
): JSX.Element[] {
	switch (flagStatus.toString()) {
		case ListFlagsUsingGETIncludedStatusEnum.OPEN:
			return [
				<AcceptFlagActionContainer key="acceptFlagAction" {...props} />,
				<RejectFlagActionContainer key="rejectFlagAction" {...props} />
			];
		case ListFlagsUsingGETIncludedStatusEnum.INPROGRESS:
			return [
				<ReopenFlagActionContainer key="reopenFlagAction" {...props} />,
				<AcceptFlagActionContainer key="acceptFlagAction" {...props} />,
				<RejectFlagActionContainer key="rejectFlagAction" {...props} />
			];
		default:
			return [];
	}
}

class FlaggedDetailsActionsMenuContainer extends Component<Props> {
	render(): JSX.Element | null {
		const { roleManagementPermission, flagStatus } = this.props;
		const validRoles: RoleKey[] = [RoleKey.GLOBAL_USER_GENERATED_CONTENT_ADMIN, RoleKey.USER_GENERATED_CONTENT_ADMIN];

		if (
			!roleService().hasClientRoleAssignmentSomeValidRole(roleManagementPermission.roles, validRoles) ||
			!flagStatus ||
			flagStatus.toString() === ListFlagsUsingGETIncludedStatusEnum.ACCEPTED ||
			flagStatus.toString() === ListFlagsUsingGETIncludedStatusEnum.REJECTED
		) {
			return null;
		}

		return (
			<ActionMenuView
				renderItems={(props: ActionMenuItemProps): JSX.Element[] =>
					getValidStatusChangeActionsByStatus(props, flagStatus)
				}
			/>
		);
	}
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: PlatformAdminUIState) => {
	return {
		roleManagementPermission: state.global.roleManagementPermission
	};
};

export default connect(mapStateToProps)(FlaggedDetailsActionsMenuContainer);
