export enum TableColumnSortingDirection {
	ASC = 'asc',
	DESC = 'desc'
}

export const defaultTableColumnSortingDirection = TableColumnSortingDirection.ASC;

export interface TableSorting {
	columnId: string;
	direction: TableColumnSortingDirection;
}
