import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PlatformAdminUIState } from '../../store/store';
import TenantsListView from './TenantsList.view';
import { RouteComponentProps } from 'react-router-dom';
import { ClientCommunity } from '@DigitaleDoerfer/digitale-doerfer-api/models';

interface Props extends RouteComponentProps {
	tenants: ClientCommunity[];
}

class TenantsListContainer extends Component<Props> {
	render(): JSX.Element {
		const { tenants } = this.props;
		return <TenantsListView tenants={tenants} />;
	}
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: PlatformAdminUIState) => {
	return {
		tenants: state.global.tenants
	};
};

export default connect(mapStateToProps)(TenantsListContainer);
