import { connect } from 'react-redux';
import HomeView from './Home.view';

export const HOME_URL = '/';

const mapDispatchToProps = (): Record<string, never> => {
	return {};
};

const HomeContainer = connect(null, mapDispatchToProps)(HomeView);

export default HomeContainer;
