import { Checkbox } from '@mui/material';
import React, { FC } from 'react';
import { getCheckboxColorByType } from '../../helpers';
import { AppVariant, Contract, ListType, Tenant, TreeNodeItem, ValueOf } from '../../types';

type TreeListNodeCheckboxProps = {
	isChecked: boolean;
	className: string;
	id: string;
	name: string;
	type: ValueOf<typeof ListType>;
	onClick: (item: TreeNodeItem<AppVariant | Tenant | Contract>) => void;
};

const TreeListNodeCheckbox: FC<TreeListNodeCheckboxProps> = ({
	isChecked,
	className,
	id,
	type,
	name,
	onClick
}: TreeListNodeCheckboxProps) => {
	const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
		event.stopPropagation();
		onClick({ id, type, name });
	};

	return (
		<Checkbox
			checked={isChecked}
			onClick={handleClick}
			color={getCheckboxColorByType(type)}
			size={'small'}
			className={className}
		/>
	);
};

export default TreeListNodeCheckbox;
