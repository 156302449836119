import React from 'react';
import { Theme } from '@mui/material/styles';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import {
	Table,
	TableBody,
	TableCell as TableCellView,
	TableHead,
	TableRow,
	TableSortLabel,
	Tooltip
} from '@mui/material';
import { TableCell, TableColumn } from './TableColumn';
import { defaultTableColumnSortingDirection, TableColumnSortingDirection, TableSorting } from './TableSorting';
import clsx from 'clsx';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-unused-vars
const styles = (theme: Theme) => {
	return createStyles({
		tableContainer: {
			overflowX: 'auto',
			width: '100%',
			height: 'calc(100vh - 310px)'
		},
		tableBodyColumn: {
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis'
		},
		rowDeleted: {
			background: '#ef5350'
		},
		rowClickable: {
			cursor: 'pointer'
		}
	});
};

interface Props<T> extends React.PropsWithChildren<object>, WithStyles<typeof styles> {
	className?: string;
	columns: readonly TableColumn<T>[];
	rows: readonly T[];
	getKeyForRow: (row: T) => React.Key;
	isRowDeleted?: ((row: T) => boolean) | (() => boolean);
	sorting: TableSorting;
	handleSortingChange: (newSorting: TableSorting) => void;
	handleRowClicked?: (event: React.MouseEvent, row: T) => void;
}

export function renderBasicTableCell<T>(cell: TableCell<T>): React.ReactElement {
	return <span>{cell.column.getCellStringValue(cell.row)}</span>;
}

class TableView<T> extends React.Component<Props<T>> {
	handleSortingChange(newColumn: TableColumn<T>): void {
		const { sorting } = this.props;

		const newColumnId = newColumn.id;

		const toggleDirection = newColumnId === sorting.columnId;
		let newDirection: TableColumnSortingDirection;

		if (toggleDirection) {
			newDirection =
				sorting.direction === TableColumnSortingDirection.ASC
					? TableColumnSortingDirection.DESC
					: TableColumnSortingDirection.ASC;
		} else {
			newDirection = TableColumnSortingDirection.ASC; //initial sort oder
		}

		const newSorting: TableSorting = { columnId: newColumnId, direction: newDirection };

		this.props.handleSortingChange(newSorting);
	}

	renderTableHeader(): JSX.Element {
		const { columns, sorting } = this.props;

		return (
			<TableHead>
				<TableRow>
					{columns.map(column => {
						const tooltipProps = column.headerTooltip;

						const tooltipTitle = tooltipProps?.title ?? '';
						const tooltipPlacement = tooltipProps?.placement ?? 'left';

						return (
							<Tooltip key={column.id} title={tooltipTitle} placement={tooltipPlacement}>
								<TableCellView key={column.id}>
									{column.sortable ? (
										<TableSortLabel
											active={sorting.columnId === column.id}
											direction={
												sorting.columnId === column.id
													? sorting.direction
													: column.initialSortingDirection || defaultTableColumnSortingDirection
											}
											onClick={(): void => this.handleSortingChange(column)}
										>
											{column.headerLabel}
										</TableSortLabel>
									) : (
										column.headerLabel
									)}
								</TableCellView>
							</Tooltip>
						);
					})}
				</TableRow>
			</TableHead>
		);
	}

	renderCell(cell: TableCell<T>): JSX.Element {
		const { column } = cell;
		const { classes } = this.props;

		if (column.renderCell) {
			return (
				<TableCellView
					key={column.id}
					className={clsx(classes.tableBodyColumn, column.cellClassName)}
					style={column.cellStyles}
				>
					{column.renderCell(cell)}
				</TableCellView>
			);
		}

		return (
			<TableCellView
				key={column.id}
				className={clsx(classes.tableBodyColumn, column.cellClassName)}
				style={column.cellStyles}
			>
				{renderBasicTableCell(cell)}
			</TableCellView>
		);
	}

	getClassNameForRow(row: T): string {
		const { handleRowClicked, isRowDeleted, classes } = this.props;
		return clsx({
			[classes.rowClickable]: handleRowClicked,
			[classes.rowDeleted]: isRowDeleted && isRowDeleted(row)
		});
	}

	renderTableBody(): JSX.Element {
		const { columns, rows, getKeyForRow, handleRowClicked } = this.props;

		return (
			<TableBody>
				{rows.map((row: T) => (
					<TableRow
						key={getKeyForRow(row)}
						hover
						className={this.getClassNameForRow(row)}
						onMouseDown={
							handleRowClicked
								? (event: React.MouseEvent<Element, MouseEvent>): void => handleRowClicked(event, row)
								: undefined
						}
					>
						{columns.map(column => {
							const cell = { column, row };
							const tooltip = column.getCellTooltip && column.getCellTooltip(cell);
							const title = tooltip?.title?.toString().trim();

							if (tooltip && title) {
								return (
									<Tooltip key={column.id} {...tooltip} title={title}>
										{this.renderCell(cell)}
									</Tooltip>
								);
							}

							return this.renderCell(cell);
						})}
					</TableRow>
				))}
			</TableBody>
		);
	}

	render(): JSX.Element {
		const { className, classes, children } = this.props;

		return (
			<div className={classes.tableContainer}>
				<Table stickyHeader size="small" className={className}>
					{this.renderTableHeader()}
					{this.renderTableBody()}
					{children}
				</Table>
			</div>
		);
	}
}

/*
	https://github.com/mui-org/material-ui/issues/14544#issuecomment-539215122

	withStyles removes the generic parametrization from the component, unfortunately.
	This is a workaround which casts the result of withStyles to the generic type again,
	so you can then write <TableView<SpecificRowType> ...props>...children</TableView>.
*/
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type GenericWithStyles<T extends WithStyles<any, any>> = Omit<T, 'theme' | 'classes'> & {
	classes?: T['classes'];
} & ('theme' extends keyof T ? { theme?: Theme } : unknown);
export default withStyles(styles)(TableView) as <T>(props: GenericWithStyles<Props<T>>) => React.ReactElement;
