import { GetState, SetState } from 'zustand';
import { GeoArea } from '../../types';
import { Store } from '../store';

type ItemsById = { [key: string]: GeoArea };

export interface GeoAreaTreeSlice {
	highlightedGeoAreaIds: string[];
	selectedGeoAreaId: string | null;
	includedGeoAreaIds: string[];
	excludedGeoAreaIds: string[];
	itemsById: ItemsById;
	itemsByParentId: { [key: string]: string[] };
	itemsFirstLevel: string[];
	expandedGeoAreaIds: string[];
	addToChildren: (data: GeoArea[], geoAreaParentIds?: string[]) => void;
	handleExpandedGeoAreaIds: (geoAreaId: string) => void;
	setIncludedGeoAreaIds: (geoAreaIds: string[]) => void;
	setExcludedGeoAreaIds: (geoAreaIds: string[]) => void;
	addToExpandedGeoAreaIds: (geoAreaIds: string[]) => void;
	getGeoAreaPath: (geoAreaId: string) => string[];
	setSelectedGeoAreaId: (geoAreaId: string) => void;
	setHighlightedGeoAreaIds: (geoAreaIds: string[]) => void;
	clearHighlightedGeoAreaIds: () => void;
}

const containsGeoAreaId = (container: string[], geoAreaId: string): boolean => container?.indexOf(geoAreaId) > -1;

const createGeoAreaTreeSlice = (set: SetState<GeoAreaTreeSlice>, get: GetState<Store>): GeoAreaTreeSlice => ({
	highlightedGeoAreaIds: [],
	selectedGeoAreaId: null,
	includedGeoAreaIds: [],
	excludedGeoAreaIds: [],
	itemsById: {},
	itemsByParentId: {},
	itemsFirstLevel: [],
	expandedGeoAreaIds: [],

	setHighlightedGeoAreaIds: (geoAreaIds: string[]): void =>
		set(state => {
			state.highlightedGeoAreaIds = geoAreaIds;
		}),
	clearHighlightedGeoAreaIds: (): void =>
		set(state => {
			state.highlightedGeoAreaIds = [];
		}),
	setSelectedGeoAreaId: (geoAreaId: string): void =>
		set(state => {
			state.selectedGeoAreaId = geoAreaId;
		}),

	addToChildren: (data, geoAreaParentIds): void =>
		set(state => {
			geoAreaParentIds?.forEach(geoAreaId => {
				if (!state.itemsByParentId?.[geoAreaId]) {
					state.itemsByParentId[geoAreaId] = [];
				}
			});

			data.forEach(geoArea => {
				const { id, parentId } = geoArea;
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				const { childGeoAreas, ...geoAreaWithoutChildGeoAreas } = geoArea;

				state.itemsById[id] = geoAreaWithoutChildGeoAreas;
				if (parentId) {
					if (!state.itemsByParentId?.[parentId]) {
						state.itemsByParentId[parentId] = [];
					}

					if (!containsGeoAreaId(state.itemsByParentId[parentId], geoArea.id)) {
						state.itemsByParentId[parentId].push(geoArea.id);
					}
				} else if (!containsGeoAreaId(state.itemsFirstLevel, geoArea.id)) {
					state.itemsFirstLevel.push(geoArea.id);
				}

				if (geoArea.childGeoAreas) {
					state.addToChildren([...geoArea.childGeoAreas], [id]);
				}
			});
		}),
	handleExpandedGeoAreaIds: (geoAreaId): void =>
		set(state => {
			const index = state.expandedGeoAreaIds.indexOf(geoAreaId);
			if (index > -1) {
				state.expandedGeoAreaIds.splice(index, 1);
			} else {
				state.expandedGeoAreaIds.push(geoAreaId);
			}
		}),
	addToExpandedGeoAreaIds: (geoAreaIds: string[]): void =>
		set(state => {
			state.expandedGeoAreaIds = Array.from(new Set([...get().expandedGeoAreaIds, ...geoAreaIds]));
		}),
	setIncludedGeoAreaIds: (geoAreaIds: string[]): void =>
		set(state => {
			state.includedGeoAreaIds = geoAreaIds;
			if (geoAreaIds.length < 1) {
				state.clearHighlightedGeoAreaIds();
			}
		}),
	setExcludedGeoAreaIds: (geoAreaIds: string[]): void =>
		set(state => {
			state.excludedGeoAreaIds = geoAreaIds;
		}),
	getGeoAreaPath: (geoAreaId: string): string[] => {
		const path = [];
		let currentGeoAreaId: string | undefined = geoAreaId;

		const itemsById = get().itemsById;
		do {
			path.push(currentGeoAreaId);
			currentGeoAreaId = itemsById?.[currentGeoAreaId]?.parentId;
		} while (currentGeoAreaId && itemsById?.[currentGeoAreaId]);

		return path;
	}
});

export default createGeoAreaTreeSlice;
