import {
	CHANGE_EMAIL_ADDRESS_FAILURE,
	CHANGE_EMAIL_ADDRESS_REQUEST,
	CHANGE_EMAIL_ADDRESS_SUCCESS,
	ChangeEmailAddressFailureAction,
	ChangeEmailAddressRequestAction,
	ChangeEmailAddressSuccessAction,
	GET_GEO_AREA_FAILURE,
	GET_GEO_AREA_REQUEST,
	GET_GEO_AREA_SUCCESS,
	GET_GEO_AREAS_FAILURE,
	GET_GEO_AREAS_REQUEST,
	GET_GEO_AREAS_SUCCESS,
	GetGeoAreaFailureAction,
	GetGeoAreaRequestAction,
	GetGeoAreasFailureAction,
	GetGeoAreasRequestAction,
	GetGeoAreasSuccessAction,
	GetGeoAreaSuccessAction,
	GlobalActionTypes,
	REFRESH_GLOBAL_STATE_FAILURE,
	REFRESH_GLOBAL_STATE_REQUEST,
	REFRESH_GLOBAL_STATE_SUCCESS,
	RefreshGlobalStateFailureAction,
	RefreshGlobalStateRequestAction,
	RefreshGlobalStateSuccessAction
} from './GlobalActionTypes';

import { GlobalState, initialGlobalState } from './Global.state';

const refreshGlobalStateRequest = (state: GlobalState, action: RefreshGlobalStateRequestAction): GlobalState => {
	return { ...state, error: null, loading: true };
};

const refreshGlobalStateSuccess = (state: GlobalState, action: RefreshGlobalStateSuccessAction): GlobalState => {
	return {
		...state,
		loading: false,
		allAvailableRoles: action.allAvailableRoles,
		assignableRoles: action.assignableRoles,
		tenants: action.tenants,
		roleManagementPermission: action.roleManagementPermission,
		person: action.person
	};
};

const refreshGlobalStateFailure = (state: GlobalState, action: RefreshGlobalStateFailureAction): GlobalState => {
	return { ...state, error: action.error, loading: false };
};

const getGeoAreaRequest = (state: GlobalState, action: GetGeoAreaRequestAction): GlobalState => {
	return { ...state, geoAreaData: { geoArea: null, loading: true, error: null } };
};

const getGeoAreaSuccess = (state: GlobalState, action: GetGeoAreaSuccessAction): GlobalState => {
	return { ...state, geoAreaData: { ...state.geoAreaData, geoArea: action.geoArea, loading: false } };
};

const getGeoAreaFailure = (state: GlobalState, action: GetGeoAreaFailureAction): GlobalState => {
	return { ...state, geoAreaData: { ...state.geoAreaData, error: action.error, loading: false } };
};

const getGeoAreasRequest = (state: GlobalState, action: GetGeoAreasRequestAction): GlobalState => {
	return {
		...state,
		geoAreasData: {
			geoAreas: [],
			loading: true,
			error: null
		}
	};
};

const getGeoAreasSuccess = (state: GlobalState, action: GetGeoAreasSuccessAction): GlobalState => {
	return {
		...state,
		geoAreasData: {
			geoAreas: action.geoAreas,
			loading: false,
			error: null
		}
	};
};

const getGeoAreasFailure = (state: GlobalState, action: GetGeoAreasFailureAction): GlobalState => {
	return { ...state, geoAreasData: { ...state.geoAreasData, error: action.error, loading: false } };
};

const changeEmailAddressRequest = (state: GlobalState, action: ChangeEmailAddressRequestAction): GlobalState => {
	return { ...state };
};

const changeEmailAddressSuccess = (state: GlobalState, action: ChangeEmailAddressSuccessAction): GlobalState => {
	return {
		...state,
		person: action.person
	};
};

const changeEmailAddressFailure = (state: GlobalState, action: ChangeEmailAddressFailureAction): GlobalState => {
	return { ...state };
};

const reducer = (state = initialGlobalState, action: GlobalActionTypes): GlobalState => {
	switch (action.type) {
		case REFRESH_GLOBAL_STATE_REQUEST:
			return refreshGlobalStateRequest(state, action);
		case REFRESH_GLOBAL_STATE_SUCCESS:
			return refreshGlobalStateSuccess(state, action);
		case REFRESH_GLOBAL_STATE_FAILURE:
			return refreshGlobalStateFailure(state, action);
		case GET_GEO_AREA_REQUEST:
			return getGeoAreaRequest(state, action);
		case GET_GEO_AREA_SUCCESS:
			return getGeoAreaSuccess(state, action);
		case GET_GEO_AREA_FAILURE:
			return getGeoAreaFailure(state, action);
		case GET_GEO_AREAS_REQUEST:
			return getGeoAreasRequest(state, action);
		case GET_GEO_AREAS_SUCCESS:
			return getGeoAreasSuccess(state, action);
		case GET_GEO_AREAS_FAILURE:
			return getGeoAreasFailure(state, action);
		case CHANGE_EMAIL_ADDRESS_REQUEST:
			return changeEmailAddressRequest(state, action);
		case CHANGE_EMAIL_ADDRESS_SUCCESS:
			return changeEmailAddressSuccess(state, action);
		case CHANGE_EMAIL_ADDRESS_FAILURE:
			return changeEmailAddressFailure(state, action);
		default:
			return state;
	}
};

export default reducer;
