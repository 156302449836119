import React, { Component } from 'react';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	TextField,
	Theme,
	Typography
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ModeCommentIcon from '@mui/icons-material/ModeComment';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { grapevineAdminuiStatisticsApi } from '../../../ServiceFactory';
import { ClientGeoAreaExtended, ClientGrapevineGeoAreaInfo } from '@DigitaleDoerfer/digitale-doerfer-api/models';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import BarLoadingIndicator from '../../../shared/views/LoadingIndicators/BarLoadingIndicator.view';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const styles = (theme: Theme) => {
	return createStyles({
		geoAreaCell: {
			width: '40%',
			marginBottom: '2px'
		},
		geoAreaName: {
			marginTop: '2px',
			cursor: 'default'
		},
		tenantNameCell: {
			fontWeight: 600
		},
		copyIcon: {
			marginTop: '4px',
			height: '16px',
			marginLeft: '5px',
			cursor: 'pointer',
			color: theme.palette.primary.main
		},
		copyIconParent: {
			height: '25px',
			minWidth: '10px'
		},
		statisticIcon: {
			cursor: 'pointer',
			color: theme.palette.primary.main
		},
		statisticContainer: {
			marginLeft: '10px'
		},
		idContainer: {
			display: 'flex',
			alignItems: 'center',
			height: '100%'
		}
	});
};

interface Props extends WithStyles<typeof styles> {
	openStatisticDialog: boolean;
	wasStatsCopied: boolean;
	geoArea: ClientGeoAreaExtended;
	handleCopyStatsButton: (geoAreaStatistics?: ClientGrapevineGeoAreaInfo) => void;
	handleCloseStatisticsButton: () => void;
}
interface State {
	isLoading: boolean;
	geoAreaStatistics?: ClientGrapevineGeoAreaInfo;
}

class StatisticsView extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			geoAreaStatistics: undefined,
			isLoading: true
		};
	}

	componentDidMount(): void {
		const { geoArea } = this.props;
		this.getGrapevineStatsForGeoArea(geoArea);
	}

	async getGrapevineStatsForGeoArea(geoArea: ClientGeoAreaExtended): Promise<void> {
		const geoAreaStatisticResponse: ClientGrapevineGeoAreaInfo =
			await grapevineAdminuiStatisticsApi().getGrapevineStatsForGeoAreaUsingGET({
				geoAreaId: geoArea?.id
			});
		this.setState({ geoAreaStatistics: geoAreaStatisticResponse, isLoading: false });
	}

	render(): JSX.Element {
		const {
			openStatisticDialog,
			geoArea,
			wasStatsCopied,
			classes,
			handleCopyStatsButton,
			handleCloseStatisticsButton
		} = this.props;
		const { geoAreaStatistics, isLoading } = this.state;

		return (
			<Dialog
				open={openStatisticDialog}
				onClose={handleCloseStatisticsButton}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					<Grid container>
						<Grid
							item
							xs
							onClick={(): void => handleCopyStatsButton(geoAreaStatistics)}
							className={classes.copyIconParent}
						>
							{!wasStatsCopied && (
								<Box>
									<Typography component="span" variant="h2">
										Zahlen für {geoArea.name}
									</Typography>
									<FileCopyOutlinedIcon className={classes.copyIcon} />
								</Box>
							)}
							{wasStatsCopied && (
								<Box>
									<Typography component="span" variant="h2">
										Zahlen für {geoArea.name}
									</Typography>
									<CheckCircleIcon className={classes.copyIcon} />
								</Box>
							)}
						</Grid>
					</Grid>
				</DialogTitle>
				<DialogContent>
					{isLoading ? (
						<BarLoadingIndicator loading={isLoading} />
					) : (
						<>
							<DialogContentText id="alert-dialog-description">
								<Grid container spacing={2}>
									<Grid item container direction="row" alignItems="center" xs={4}>
										<Grid item xs={2}>
											<PeopleAltIcon />
										</Grid>
										<Grid item xs={6} className={classes.statisticContainer}>
											<Typography component="span" variant="subtitle2">
												Registrierte Nutzer
											</Typography>
										</Grid>
										<Grid item xs={12}>
											<TextField
												variant="standard"
												defaultValue="0"
												value={geoAreaStatistics?.personCount ?? '0'}
												size="small"
												style={{ width: '50%' }}
												InputProps={{
													readOnly: true
												}}
											/>
										</Grid>
									</Grid>
									<Grid item container direction="row" alignItems="center" xs={4}>
										<Grid item xs={2}>
											<PostAddIcon />
										</Grid>
										<Grid item xs={6} className={classes.statisticContainer}>
											<Typography component="span" variant="subtitle2">
												Anzahl Posts
											</Typography>
										</Grid>
										<Grid item xs={12}>
											<TextField
												variant="standard"
												defaultValue="0"
												value={geoAreaStatistics?.postCount ?? '0'}
												size="small"
												style={{ width: '50%' }}
												InputProps={{
													readOnly: true
												}}
											/>
										</Grid>
									</Grid>
									<Grid item container direction="row" alignItems="center" xs={4}>
										<Grid item>
											<ModeCommentIcon />
										</Grid>
										<Grid item xs={6} className={classes.statisticContainer}>
											<Typography component="span" variant="subtitle2">
												Anzahl Kommentare
											</Typography>
										</Grid>
										<Grid item xs={12}>
											<TextField
												variant="standard"
												defaultValue="0"
												value={geoAreaStatistics?.commentCount ?? '0'}
												size="small"
												style={{ width: '50%' }}
												InputProps={{
													readOnly: true
												}}
											/>
										</Grid>
									</Grid>
								</Grid>
							</DialogContentText>
						</>
					)}
				</DialogContent>

				<DialogActions>
					<Button variant="contained" onClick={handleCloseStatisticsButton}>
						VERBERGEN
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}
export default withStyles(styles)(StatisticsView);
