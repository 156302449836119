import { BaseError } from '../../../shared/errors/Errors';
import { ListPushEvent } from './PushEvent.state';

export const GET_PUSH_EVENTS_REQUEST = 'GET_PUSH_EVENTS_REQUEST';
export const GET_PUSH_EVENTS_SUCCESS = 'GET_PUSH_EVENTS_SUCCESS';
export const GET_PUSH_EVENTS_FAILURE = 'GET_PUSH_EVENTS_FAILURE';

export interface GetPushEventsRequestAction {
	type: typeof GET_PUSH_EVENTS_REQUEST;
}

export interface GetPushEventsSuccessAction {
	type: typeof GET_PUSH_EVENTS_SUCCESS;
	pushEvents: ListPushEvent[];
}

export interface GetPushEventsFailureAction {
	type: typeof GET_PUSH_EVENTS_FAILURE;
	error: BaseError | null;
}

export type PushEventActionTypes = GetPushEventsRequestAction | GetPushEventsSuccessAction | GetPushEventsFailureAction;
