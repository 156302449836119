import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PlatformAdminUIState, PlatformAdminUIThunkType } from '../../../../store/store';
import {
	showSnackbar,
	SnackbarAction,
	SnackbarType
} from '../../../../shared/views/SnackbarNotification/store/SnackbarNotification.actions';
import { UserActionTypes } from '../../store/UserActionTypes';
import { ActionMenuItemProps } from '../../../../shared/views/ActionMenu/ActionMenu';
import { ClientPersonExtended } from '@DigitaleDoerfer/digitale-doerfer-api/models';
import { deleteUserByAdmin } from '../../store/User.actions';
import DeleteUserActionView from './DeleteUserAction.view';
import { RoleKey, RoleManagementPermission } from '../../../../shared/services/Role.service';
import { roleService } from '../../../../ServiceFactory';

interface Props extends ActionMenuItemProps {
	currentEmail?: string;
	user: ClientPersonExtended | null;
	showSnackbar: (snackbarAction: SnackbarAction) => void;
	deleteUser: (userId: string, check: string) => void;
	roleManagementPermission: RoleManagementPermission;
}

class ChangeEmailActionContainer extends Component<Props> {
	constructor(props: Props) {
		super(props);

		this.handleDelete = this.handleDelete.bind(this);
	}

	handleDelete(check: string): void {
		const { user, showSnackbar, deleteUser } = this.props;
		const personId = user?.id;
		if (personId !== undefined) {
			deleteUser(personId, check);
		} else {
			showSnackbar({
				type: SnackbarType.SNACKBAR_ERROR,
				message: 'Benutzer hat keine ID.'
			});
		}
	}

	render(): JSX.Element | null {
		const { renderMenuItem, user, roleManagementPermission } = this.props;

		if (!roleService().hasClientRoleAssignmentSomeValidRole(roleManagementPermission.roles, [RoleKey.SUPER_ADMIN])) {
			return null;
		}

		return (
			<DeleteUserActionView
				renderMenuItem={renderMenuItem}
				handleDelete={this.handleDelete}
				firstName={user?.firstName || ''}
				lastName={user?.lastName || ''}
				email={user?.email || ''}
			/>
		);
	}
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: PlatformAdminUIState) => {
	return {
		currentEmail: state.user.detail.user?.email,
		user: state.user.detail.user,
		roleManagementPermission: state.global.roleManagementPermission
	};
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch: PlatformAdminUIThunkType<UserActionTypes>) => {
	return {
		showSnackbar: (snackbarAction: SnackbarAction): Promise<void> => dispatch(showSnackbar(snackbarAction)),
		deleteUser: (userId: string, check: string): Promise<void> => dispatch(deleteUserByAdmin(userId, check))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeEmailActionContainer);
