import { BaseError } from './Errors';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const wrapIntoErrorObject = function (e: unknown): BaseError {
	if (!e) {
		return new BaseError('Unspecified error');
	} else if (e instanceof BaseError) {
		return e;
	} else if (e instanceof Error) {
		return new BaseError(e.message);
	} else if (typeof e === 'string' || e instanceof String) {
		return new BaseError(e.toString());
	} else if (typeof e === 'number' || e instanceof Number) {
		return new BaseError(`Error code ${e}`);
	} else if (typeof e === 'boolean' || e instanceof Boolean) {
		return new BaseError(`Error with attribute ${e}`);
	} else {
		// What the heck is this error then?
		throw e;
	}
};
