import React, { FC } from 'react';
import { FormControlLabel } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { AppVariant, Contract, ListType, Tenant, TreeNodeItem } from '../../types';
import useTreeList from '../hooks/useTreeList';
import TreeListNodeCheckbox from './TreeListNodeCheckbox';

const useStyles = makeStyles(theme => ({
	root: {
		marginLeft: 10,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		textAlign: 'left',
		color: theme.palette.primary.dark,
		'& > .MuiFormControlLabel-root': {
			width: '100%',
			justifyContent: (props: TreeListNodeLabelProps): string =>
				props.type === ListType.CONTRACT ? 'space-between' : 'flex-start',
			color: theme.palette.primary.dark
		},
		'& > .MuiFormControlLabel-labelPlacementStart': {
			marginLeft: -10
		}
	},
	checkbox: {
		padding: 0,
		marginRight: 6
	}
}));

type TreeListNodeLabelProps = TreeNodeItem<AppVariant | Tenant | Contract> & {
	showCheckbox?: boolean;
};

const TreeListNodeLabel: FC<TreeListNodeLabelProps> = ({ type, id, name }: TreeListNodeLabelProps) => {
	const classes = useStyles({ type, id, name });
	const { onLabelClick, onCheckboxClick, isFilterSet, isContractSelected } = useTreeList();

	const handleLabelClick = (event: React.MouseEvent<HTMLLabelElement, MouseEvent>): void => {
		event.preventDefault();
		onLabelClick({ id, name, type });
	};

	const isChecked = ListType.CONTRACT === type ? isContractSelected(id) : isFilterSet(type, id);

	return (
		<div id={id} className={classes.root}>
			<FormControlLabel
				control={
					<TreeListNodeCheckbox
						isChecked={isChecked}
						className={classes.checkbox}
						id={id}
						name={name}
						type={type}
						onClick={onCheckboxClick}
					/>
				}
				labelPlacement={type === ListType.CONTRACT ? 'start' : 'end'}
				onClick={handleLabelClick}
				label={name}
			/>
		</div>
	);
};

export default TreeListNodeLabel;
