import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ClientGroupExtendedDetail } from '@DigitaleDoerfer/digitale-doerfer-api/models';
import ChangeGroupAdminsActionView from './ChangeGroupAdminsAction.view';
import {
	showSnackbar,
	SnackbarAction
} from '../../../../shared/views/SnackbarNotification/store/SnackbarNotification.actions';
import { ActionMenuItemProps } from '../../../../shared/views/ActionMenu/ActionMenu';
import { RoleKey, RoleManagementPermission } from '../../../../shared/services/Role.service';
import { roleService } from '../../../../ServiceFactory';
import { PlatformAdminUIState, PlatformAdminUIThunkType } from '../../../../store/store';
import { UserActionTypes } from '../../../users/store/UserActionTypes';
import { groupAddGroupMembershipAdmin, groupRemoveGroupMembershipAdmin } from '../../store/Group.actions';

interface Props extends ActionMenuItemProps {
	group: ClientGroupExtendedDetail | null;
	showSnackbar: (snackbarAction: SnackbarAction) => void;
	roleManagementPermission: RoleManagementPermission;
	addAdmin: (groupId: string, adminId: string) => void;
	removeAdmin: (groupId: string, adminId: string) => void;
}

class ChangeGroupAdminsActionContainer extends Component<Props> {
	render(): JSX.Element | null {
		const { renderMenuItem, group, roleManagementPermission, addAdmin, removeAdmin } = this.props;
		if (!group) {
			return null;
		}

		if (
			!roleService().hasClientRoleAssignmentSomeValidRole(roleManagementPermission.roles, [RoleKey.GLOBAL_GROUP_ADMIN])
		) {
			return null;
		}

		return (
			<ChangeGroupAdminsActionView
				renderMenuItem={renderMenuItem}
				addAdmin={addAdmin}
				removeAdmin={removeAdmin}
				group={group}
			/>
		);
	}
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: PlatformAdminUIState) => {
	return {
		group: state.group.detail.group,
		roleManagementPermission: state.global.roleManagementPermission
	};
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch: PlatformAdminUIThunkType<UserActionTypes>) => {
	return {
		showSnackbar: (snackbarAction: SnackbarAction): Promise<void> => dispatch(showSnackbar(snackbarAction)),
		addAdmin: (groupId: string, adminId: string): Promise<void> =>
			dispatch(groupAddGroupMembershipAdmin(groupId, adminId)),

		removeAdmin: (groupId: string, adminId: string): Promise<void> =>
			dispatch(groupRemoveGroupMembershipAdmin(groupId, adminId))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeGroupAdminsActionContainer);
