import { SnackbarAction } from './SnackbarNotification.actions';

export const SNACKBAR_SHOW = 'SNACKBAR_SHOW';
export const SNACKBAR_HIDE = 'SNACKBAR_HIDE';

export interface ShowSnackbar {
	type: typeof SNACKBAR_SHOW;
	payload: SnackbarAction;
}

export interface HideSnackbar {
	type: typeof SNACKBAR_HIDE;
}

export type SnackbarNotificationActionTypes = ShowSnackbar | HideSnackbar;
