import { useEffect, useState } from 'react';
import { useStore } from '../../store';
import { ListType, SearchQuery, ValueOf } from '../../types';

type UseSearch = (type?: ValueOf<typeof ListType>) => {
	query: string;
	searchQueryAppVariant: string;
	searchQueryTenant: string;
	searchQueryContract: string;
	onChange: (query: string) => void;
	onSearch: (type: ValueOf<typeof ListType>) => void;
	onReset: (type: ValueOf<typeof ListType>) => void;
};
const useSearch: UseSearch = type => {
	const [query, setQuery] = useState<string>('');
	const { addSearchQuery, removeSearchQuery } = useStore();

	const searchQueryAppVariant = useStore(state => state.searchAppVariant);
	const searchQueryTenant = useStore(state => state.searchTenant);
	const searchQueryContract = useStore(state => state.searchContract);

	useEffect(() => {
		if (type === ListType.APP_VARIANT) setQuery(searchQueryAppVariant);
		if (type === ListType.TENANT) setQuery(searchQueryTenant);
		if (type === ListType.CONTRACT) setQuery(searchQueryContract);
	}, [type, searchQueryAppVariant, searchQueryTenant, searchQueryContract]);

	const onChange = (query: string): void => {
		setQuery(query);
	};

	const onSearch = (type: ValueOf<typeof ListType>): void => {
		const searchQuery: SearchQuery = { query, type };
		addSearchQuery(searchQuery);
	};

	const onReset = (type: ValueOf<typeof ListType>): void => {
		setQuery('');
		removeSearchQuery(type);
	};

	return {
		query,
		searchQueryAppVariant,
		searchQueryTenant,
		searchQueryContract,
		onChange,
		onSearch,
		onReset
	};
};

export default useSearch;
