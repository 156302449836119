import { useCallback } from 'react';
import { useStore } from '../../store';
import { GeoArea } from '../../types';

type UseGeoArea = (geoAreaId: GeoArea['id'] | null) => {
	getGeoAreaById: (id: GeoArea['id']) => GeoArea;
	getGeoAreasByParentId: (parentId: GeoArea['id'] | null) => GeoArea[];
	getGeoAreasByIds: (ids: GeoArea['id'][]) => GeoArea[];
	childrenIds: GeoArea['id'][];
	isIncluded: boolean;
	isExcluded: boolean;
};

const useGeoArea: UseGeoArea = geoAreaId => {
	const itemsById = useStore(useCallback(state => state.itemsById, []));
	const itemsByParentId = useStore(
		useCallback(state => (geoAreaId ? state.itemsByParentId[geoAreaId] : []), [geoAreaId])
	);
	const itemsFirstLevel = useStore(useCallback(state => state.itemsFirstLevel, []));

	const getGeoAreaById = useCallback((id: string) => itemsById?.[id], [itemsById]);

	const getGeoAreasByIds = useCallback(
		(ids: string[]) => {
			return ids?.map((id: string) => getGeoAreaById(id));
		},
		[getGeoAreaById]
	);

	const getGeoAreasByParentId = useCallback(
		(parentId: string | null): GeoArea[] => {
			if (parentId === null) {
				return itemsFirstLevel?.map((id: string) => itemsById?.[id]);
			}

			return itemsByParentId?.map((id: string) => itemsById?.[id]);
		},
		[itemsFirstLevel, itemsByParentId, itemsById]
	);

	const children = getGeoAreasByIds(itemsByParentId);

	const childrenToFetch =
		children?.filter((geoArea: GeoArea) => !geoArea.leaf)?.map((geoArea: GeoArea) => geoArea.id) || [];

	const isIncluded = useStore(
		useCallback(state => !!(geoAreaId && state?.includedGeoAreaIds.includes(geoAreaId)), [geoAreaId])
	);
	const isExcluded = useStore(
		useCallback(state => !!(geoAreaId && state?.excludedGeoAreaIds.includes(geoAreaId)), [geoAreaId])
	);

	return {
		getGeoAreaById,
		getGeoAreasByParentId,
		getGeoAreasByIds,
		childrenIds: childrenToFetch,
		isIncluded,
		isExcluded
	};
};

export default useGeoArea;
