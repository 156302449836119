import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PlatformAdminUIState, PlatformAdminUIThunkType } from '../../../../store/store';
import BlockLoginActionView from './BlockLoginAction.view';
import {
	showSnackbar,
	SnackbarAction,
	SnackbarType
} from '../../../../shared/views/SnackbarNotification/store/SnackbarNotification.actions';
import { UserActionTypes } from '../../store/UserActionTypes';
import { ActionMenuItemProps } from '../../../../shared/views/ActionMenu/ActionMenu';
import {
	ClientOauthAccountBlockReasonEnum,
	ClientPersonBlockLoginByAdminRequest,
	ClientPersonExtended
} from '@DigitaleDoerfer/digitale-doerfer-api/models';
import { blockLoginByAdmin } from '../../store/User.actions';

interface Props extends ActionMenuItemProps {
	firstName?: string;
	lastName?: string;
	email?: string;
	user: ClientPersonExtended | null;
	showSnackbar: (snackbarAction: SnackbarAction) => void;
	blockLogin: (blockLoginRequestParams: ClientPersonBlockLoginByAdminRequest) => void;
}

class BlockLoginActionContainer extends Component<Props> {
	constructor(props: Props) {
		super(props);

		this.handleSave = this.handleSave.bind(this);
	}

	handleSave(): void {
		const { user, showSnackbar, blockLogin } = this.props;
		const personId = user?.id;
		if (personId !== undefined) {
			blockLogin({
				personId
			});
		} else {
			showSnackbar({
				type: SnackbarType.SNACKBAR_ERROR,
				message: 'Benutzer hat keine ID.'
			});
		}
	}

	render(): JSX.Element | null {
		const { firstName, lastName, email, user, renderMenuItem } = this.props;
		if (
			user?.deleted ||
			!user?.oauthAccount ||
			user.oauthAccount.blockReason === ClientOauthAccountBlockReasonEnum.MANUALLYBLOCKED ||
			!user.oauthAccount.oauthId
		) {
			return null;
		}
		return (
			<BlockLoginActionView
				renderMenuItem={renderMenuItem}
				firstName={firstName || ''}
				lastName={lastName || ''}
				email={email || ''}
				handleSave={this.handleSave}
			/>
		);
	}
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: PlatformAdminUIState) => {
	return {
		firstName: state.user.detail.user?.firstName,
		lastName: state.user.detail.user?.lastName,
		email: state.user.detail.user?.email,
		user: state.user.detail.user
	};
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch: PlatformAdminUIThunkType<UserActionTypes>) => {
	return {
		showSnackbar: (snackbarAction: SnackbarAction): Promise<void> => dispatch(showSnackbar(snackbarAction)),
		blockLogin: (blockLoginRequestParams: ClientPersonBlockLoginByAdminRequest): Promise<void> =>
			dispatch(blockLoginByAdmin(blockLoginRequestParams))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BlockLoginActionContainer);
