import React, { Component } from 'react';
import { Box, Grid, TextField } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { ActionMenuItemProps } from '../../../../shared/views/ActionMenu/ActionMenu';
import DialogActionView from '../../../../shared/views/ActionMenu/DialogAction.view';

interface Props extends ActionMenuItemProps {
	currentFirstName: string;
	currentLastName: string;
	handleSave: (newFirstName: string, newLastName: string) => void;
}

interface State {
	newFirstName: string;
	newLastName: string;
}

const initialState = (props: Props): State => ({
	newFirstName: props.currentFirstName,
	newLastName: props.currentLastName
});

class ChangeNameActionView extends Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = initialState(props);
		this.handleOpenDialog = this.handleOpenDialog.bind(this);
		this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
		this.handleLastNameChange = this.handleLastNameChange.bind(this);
		this.isSaveDisabled = this.isSaveDisabled.bind(this);
		this.handleSave = this.handleSave.bind(this);
	}

	handleOpenDialog(): void {
		this.setState({ ...initialState(this.props) });
	}

	handleSave(): void {
		this.props.handleSave(this.state.newFirstName, this.state.newLastName);
	}

	handleFirstNameChange(event: React.ChangeEvent<{ value: string }>): void {
		const newFirstName = event.target.value;

		this.setState({ newFirstName });
	}

	handleLastNameChange(event: React.ChangeEvent<{ value: string }>): void {
		const newLastName = event.target.value;

		this.setState({ newLastName });
	}

	isSaveDisabled(): boolean {
		return (
			!this.state.newFirstName ||
			!this.state.newFirstName.trim() ||
			!this.state.newLastName ||
			!this.state.newLastName.trim() ||
			(this.props.currentFirstName === this.state.newFirstName && this.props.currentLastName === this.state.newLastName)
		);
	}

	render(): JSX.Element {
		const { renderMenuItem } = this.props;
		const { newFirstName, newLastName } = this.state;
		return (
			<DialogActionView
				itemProps={{
					renderMenuItem,
					icon: <EditIcon color="primary" />,
					text: 'Name bearbeiten'
				}}
				dialogProps={{
					titleText: 'Name bearbeiten',
					saveButtonText: 'Speichern',
					handleOpen: this.handleOpenDialog,
					handleSave: this.handleSave,
					handleIsSaveDisabled: this.isSaveDisabled
				}}
			>
				<Box mb={3}>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<TextField
								variant="standard"
								id="firstName"
								name="firstName"
								label="Vorname"
								value={newFirstName}
								onChange={this.handleFirstNameChange}
								InputLabelProps={{
									shrink: true
								}}
								fullWidth
								autoFocus
								required
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								variant="standard"
								id="lastName"
								name="lastName"
								label="Nachname"
								value={newLastName}
								onChange={this.handleLastNameChange}
								InputLabelProps={{
									shrink: true
								}}
								fullWidth
								required
							/>
						</Grid>
					</Grid>
				</Box>
			</DialogActionView>
		);
	}
}

export default ChangeNameActionView;
