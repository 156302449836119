import { AnyGeoArea } from '../utils/AnyGeoArea';
import { ClientGroupExtendedDetail, ClientPersonExtended } from '@DigitaleDoerfer/digitale-doerfer-api/models';
import { GeoAreaChildrenElement, GeoAreaElement } from '../utils/GeoAreaElements';

export class GeoAreaService {
	/**
	 * Returns a new array with all sub-array (childGeoAreas) elements concatenated into it recursively up to 1 depth.
	 * @param geoAreas
	 */
	flatten(geoAreas: AnyGeoArea[] | undefined): AnyGeoArea[] {
		let flattenedGeoAreas: AnyGeoArea[] = [];
		if (geoAreas) {
			flattenedGeoAreas = geoAreas.reduce(
				(geoAreas: AnyGeoArea[], geoArea: AnyGeoArea) => [...geoAreas, geoArea, ...this.flatten(geoArea.childGeoAreas)],
				[]
			);
		}
		return flattenedGeoAreas;
	}

	pathToGivenGeoAreaId(geoAreas: AnyGeoArea[], targetId: string): string {
		let result = '';
		geoAreas.some((geoArea: AnyGeoArea) => {
			const { id, childGeoAreas } = geoArea;
			if (id === targetId) {
				return (result = id);
			}
			const temp = this.pathToGivenGeoAreaId(childGeoAreas ?? [], targetId);
			if (temp) {
				return (result = id + '.' + temp);
			}
			return '';
		});
		return result;
	}

	getGeoAreasPathToGivenGeoAreaId(
		geoAreas: AnyGeoArea[],
		flattenedGeoAreas: AnyGeoArea[],
		geoAreaId: string
	): AnyGeoArea[] {
		const geoAreasPathIds = this.pathToGivenGeoAreaId(geoAreas, geoAreaId).split('.');
		const geoAreasPath: AnyGeoArea[] = [];
		geoAreasPathIds.forEach((geoAreaId: string) => {
			const geoArea = flattenedGeoAreas.find(geoArea => geoArea.id === geoAreaId);
			if (geoArea) {
				geoAreasPath.push(geoArea);
			}
		});
		return geoAreasPath;
	}

	createGeoAreaElement(
		geoAreasPath: (AnyGeoArea | undefined)[],
		geoAreaId: string,
		manualSelected?: boolean,
		groupMember?: ClientPersonExtended,
		groupMemberGeoAreaDirectParent?: AnyGeoArea
	): GeoAreaElement {
		const geoAreaChildren: AnyGeoArea[] | undefined = groupMemberGeoAreaDirectParent?.childGeoAreas;
		let geoAreaChildrenElements: GeoAreaChildrenElement[] =
			(geoAreaChildren &&
				geoAreaChildren.map((childGeoArea: AnyGeoArea) => ({
					geoArea: childGeoArea,
					users: []
				}))) ??
			[];
		// geoArea Id is the same as parent id
		if (geoAreaId === groupMemberGeoAreaDirectParent?.id) {
			// Set the own parent as child
			geoAreaChildrenElements = [{ geoArea: groupMemberGeoAreaDirectParent, manualSelected: true, users: [] }];
		} else {
			const findGeoAreaChildIndex = geoAreaChildrenElements.findIndex(
				childGeoArea => childGeoArea.geoArea.id === geoAreaId
			);
			if (findGeoAreaChildIndex !== -1) {
				geoAreaChildrenElements[findGeoAreaChildIndex] = {
					...geoAreaChildrenElements[findGeoAreaChildIndex],
					users: groupMember ? [groupMember] : [],
					manualSelected: groupMember ? true : manualSelected ?? false
				};
			}
		}

		return {
			geoAreaParent: groupMemberGeoAreaDirectParent,
			geoAreaParentPaths: geoAreasPath ?? [],
			geoAreaChildren: geoAreaChildrenElements,
			isRemovable: !groupMember
		};
	}

	makeGeoAreaElements(group: ClientGroupExtendedDetail, allGeoAreas: AnyGeoArea[]): GeoAreaElement[] {
		const flattenedGeoAreas = this.flatten(allGeoAreas);
		const newGeoAreasElementList: GeoAreaElement[] = [];

		group.includedGeoAreas?.forEach(geoArea => {
			const geoAreasPath: AnyGeoArea[] = this.getGeoAreasPathToGivenGeoAreaId(
				allGeoAreas,
				flattenedGeoAreas,
				geoArea.id ?? ''
			);
			const geoAreasLength = geoAreasPath.length;
			// find groupMemberGeoArea direct parent
			const groupMemberGeoAreaDirectParent =
				geoAreasPath[geoAreasLength >= 2 ? geoAreasLength - 2 : geoAreasLength - 1];

			const findGeoAreaElementIndex = newGeoAreasElementList.findIndex(
				geoAreaElement => geoAreaElement?.geoAreaParent?.id === groupMemberGeoAreaDirectParent?.id
			);
			if (findGeoAreaElementIndex !== -1) {
				// Set geoArea child to selected true if geoAreaelement with the same parent already exists
				const geoAreaElementCopy = JSON.parse(JSON.stringify(newGeoAreasElementList[findGeoAreaElementIndex]));
				geoAreaElementCopy.geoAreaChildren = geoAreaElementCopy.geoAreaChildren.map(
					(geoAreaChild: GeoAreaChildrenElement) => {
						if (geoAreaChild.geoArea.id === geoArea.id) {
							geoAreaChild.manualSelected = true;
							return geoAreaChild;
						} else {
							return geoAreaChild;
						}
					}
				);
				newGeoAreasElementList[findGeoAreaElementIndex] = geoAreaElementCopy;
			} else {
				const createdGeoAreaElement = this.createGeoAreaElement(
					geoAreasPath,
					geoArea.id,
					true,
					undefined,
					groupMemberGeoAreaDirectParent
				);
				newGeoAreasElementList.push(createdGeoAreaElement);
			}
		});
		return newGeoAreasElementList;
	}

	/**
	 * Return the updated geoAreaElement with the new administrator.
	 * @param geoAreaElement GeoArea element to be updated.
	 * @param person Person to be added in users list of given geoAreaElement.
	 */
	updateGeoAreaElementUsersList(geoAreaElement: GeoAreaElement, person: ClientPersonExtended): GeoAreaElement {
		const { geoAreaChildren } = geoAreaElement;
		let foundGeoAreaChild = geoAreaChildren.find(childGeoArea => childGeoArea.geoArea.id === person.homeAreaId);
		const newUsersList: ClientPersonExtended[] = foundGeoAreaChild?.users ?? [];
		newUsersList.push(person);
		if (foundGeoAreaChild) {
			foundGeoAreaChild = { ...foundGeoAreaChild, users: newUsersList };
		}
		return geoAreaElement;
	}

	/**
	 * Returns the updated given geoAreaElements list.
	 * 1. If geoAreaParent of the given person homeAreaId already exits, updates geoAreaElement with new person.
	 * 2. If geoAreaParent does not exists, create new geoAreaElement.
	 * @param geoAreaElements GeoArea elements list.
	 * @param geoAreas All geoArea list.
	 * @param person Person.
	 */
	updatesGeoAreaElementsByPerson(
		geoAreaElements: GeoAreaElement[],
		geoAreas: AnyGeoArea[],
		person: ClientPersonExtended
	): GeoAreaElement[] {
		const geoAreasPath: AnyGeoArea[] = this.getGeoAreasPathToGivenGeoAreaId(
			geoAreas,
			this.flatten(geoAreas),
			person.homeAreaId ?? ''
		);
		const newGroupMembershipAdminGeoAreas = [...geoAreaElements];
		const geoAreasLength = geoAreasPath.length;
		const groupMemberGeoAreaDirectParent = geoAreasPath[geoAreasLength > 2 ? geoAreasLength - 2 : geoAreasLength - 1];
		const findGeoAreaElementIndex = newGroupMembershipAdminGeoAreas.findIndex(
			geoAreaElement => geoAreaElement?.geoAreaParent?.id === groupMemberGeoAreaDirectParent?.id
		);

		if (findGeoAreaElementIndex !== -1) {
			// Add administrator to the found geoAreaElement
			const geoAreaElementCopy = JSON.parse(JSON.stringify(newGroupMembershipAdminGeoAreas[findGeoAreaElementIndex]));
			newGroupMembershipAdminGeoAreas[findGeoAreaElementIndex] = this.updateGeoAreaElementUsersList(
				geoAreaElementCopy,
				person
			);
		} else {
			const newGeoAreaElement = this.createGeoAreaElement(
				geoAreasPath,
				person.homeAreaId ?? '',
				false,
				person,
				groupMemberGeoAreaDirectParent
			);
			groupMemberGeoAreaDirectParent && newGroupMembershipAdminGeoAreas.push(newGeoAreaElement);
		}
		return newGroupMembershipAdminGeoAreas;
	}
}
