import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PlatformAdminUIState, PlatformAdminUIThunkType } from '../../../../store/store';
import {
	showSnackbar,
	SnackbarAction,
	SnackbarType
} from '../../../../shared/views/SnackbarNotification/store/SnackbarNotification.actions';
import { UserActionTypes } from '../../store/UserActionTypes';
import { ActionMenuItemProps } from '../../../../shared/views/ActionMenu/ActionMenu';
import {
	ClientPersonExtended,
	ClientPersonExtendedVerificationStatusesEnum
} from '@DigitaleDoerfer/digitale-doerfer-api/models';
import ResendVerificationMailActionView from './ResendVerificationMailAction.view';
import { resendVerificationMailByAdmin } from '../../store/User.actions';

interface Props extends ActionMenuItemProps {
	user: ClientPersonExtended | null;
	showSnackbar: (snackbarAction: SnackbarAction) => void;
	resendVerificationMail: (userId: string) => void;
}

class ResendVerificationMailActionContainer extends Component<Props> {
	constructor(props: Props) {
		super(props);

		this.handleSave = this.handleSave.bind(this);
	}

	handleSave(): void {
		const { user, resendVerificationMail, showSnackbar } = this.props;
		const personId = user?.id;
		if (personId !== undefined) {
			resendVerificationMail(personId);
		} else {
			showSnackbar({
				type: SnackbarType.SNACKBAR_ERROR,
				message: 'Die Person hat keine ID.'
			});
		}
	}

	render(): JSX.Element | null {
		const { user, renderMenuItem } = this.props;
		if (user?.verificationStatuses?.includes(ClientPersonExtendedVerificationStatusesEnum.EMAILVERIFIED)) {
			return null;
		}
		return <ResendVerificationMailActionView renderMenuItem={renderMenuItem} handleSave={this.handleSave} />;
	}
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: PlatformAdminUIState) => {
	return {
		user: state.user.detail.user
	};
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch: PlatformAdminUIThunkType<UserActionTypes>) => {
	return {
		showSnackbar: (snackbarAction: SnackbarAction): Promise<void> => dispatch(showSnackbar(snackbarAction)),
		resendVerificationMail: (userId: string): Promise<void> => dispatch(resendVerificationMailByAdmin(userId))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ResendVerificationMailActionContainer);
