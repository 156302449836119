import React, { FC, useEffect, useState } from 'react';
import { ClientStatisticsReportReference } from '@DigitaleDoerfer/digitale-doerfer-api/models';
import StatisticsView from './Statistics.view';
import { getStatisticsReferences } from './Statistics.actions';

const StatisticsContainer: FC = () => {
	const [statisticsReferences, setStatisticsReferences] = useState<ClientStatisticsReportReference[]>([]);

	useEffect(() => {
		getStatisticsReferences()
			.then(result => setStatisticsReferences(result))
			.catch(error => console.log(error));
	}, []);

	return <StatisticsView statisticsReferences={statisticsReferences} />;
};

export default StatisticsContainer;
