import React from 'react';
import SideNavigationView from './side-navigation/SideNavigation.view';
import { Box } from '@mui/material';
import { TopNavigationView } from './top-navigation/TopNavigation.view';

interface Props {
	title: string;
	shouldRenderUserMenu: boolean;
	shouldRenderFlaggedContentMenu: boolean;
	shouldRenderGroupMenu: boolean;
	shouldRenderConfigurationMenu: boolean;
	shouldRenderExternalContributionsMenu: boolean;
	shouldRenderStatisticsMenu: boolean;
	logout: () => void;
}

class NavigationView extends React.Component<React.PropsWithChildren<Props>> {
	render(): JSX.Element {
		const {
			title,
			logout,
			children,
			shouldRenderUserMenu,
			shouldRenderFlaggedContentMenu,
			shouldRenderGroupMenu,
			shouldRenderConfigurationMenu,
			shouldRenderExternalContributionsMenu,
			shouldRenderStatisticsMenu
		} = this.props;
		return (
			<React.Fragment>
				<TopNavigationView title={title} logout={logout} />
				<Box display="flex" flexDirection="row">
					<SideNavigationView
						shouldRenderUserMenu={shouldRenderUserMenu}
						shouldRenderFlaggedContentMenu={shouldRenderFlaggedContentMenu}
						shouldRenderGroupMenu={shouldRenderGroupMenu}
						shouldRenderConfigurationMenu={shouldRenderConfigurationMenu}
						shouldRenderExternalContributionsMenu={shouldRenderExternalContributionsMenu}
						shouldRenderStatisticsMenu={shouldRenderStatisticsMenu}
					/>
					<Box component="main" sx={{ marginTop: 8, padding: 2, flexGrow: 1, minWidth: 0 }}>
						{children}
					</Box>
				</Box>
			</React.Fragment>
		);
	}
}

export default NavigationView;
