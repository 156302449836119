import React from 'react';
import clsx from 'clsx';
import { Box, Grid, Link, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { ClientAppVariantUsageExtended, ClientGeoArea } from '@DigitaleDoerfer/digitale-doerfer-api/models';
import { imageService, timeService } from '../../../ServiceFactory';
import { TimestampFormat } from '../../services/Time.service';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { getGeoAreaDetailsUrl } from '../../../modules/geo-areas/GeoAreasListRouting.container';
import history from '../../../history';
import { ImageSizes } from '../../services/Image.service';
import { nullSafeGermanStringAscComparator } from '../../utils/Util';

const defaultUnexpandedHeight = 30;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const styles = () => {
	return createStyles({
		elementContainer: {
			boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)'
		},
		selectedGeoAreasContainer: {
			cursor: 'pointer'
		},
		appVariantIcon: {
			background: '#98DEE8',
			width: '35px',
			height: '35px',
			borderRadius: '2px',
			'& img': {
				width: '100%',
				borderRadius: '2px'
			}
		},
		appVariantName: {
			width: '85%'
		},
		geoAreaText: {
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis'
		}
	});
};

interface Props extends WithStyles<typeof styles> {
	appVariantUsage: ClientAppVariantUsageExtended;
}

interface State {
	expanded: boolean;
}

class AppVariantElementView extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = { expanded: false };
		this.toggleExpanded = this.toggleExpanded.bind(this);
	}

	toggleExpanded(): void {
		const { expanded } = this.state;
		this.setState({ expanded: !expanded });
	}

	getChildrenGeoAreasLeaves(geoAreasNodes: ClientGeoArea[], childrenGeoAreas: ClientGeoArea[] = []): ClientGeoArea[] {
		for (const geoArea of geoAreasNodes) {
			if (!geoArea.childGeoAreas) {
				childrenGeoAreas.push(geoArea);
			} else {
				childrenGeoAreas = this.getChildrenGeoAreasLeaves(geoArea.childGeoAreas ?? [], childrenGeoAreas);
			}
		}
		return childrenGeoAreas;
	}

	render(): JSX.Element {
		const { expanded } = this.state;
		const { appVariantUsage, classes } = this.props;
		const { appVariant, lastUsage, selectedGeoAreas } = appVariantUsage;
		const { name, app } = appVariant;
		const variantLogo = app?.logo ? imageService().getAvailableImageUrl(app?.logo, ImageSizes.SMALL) : null;
		const hasGeoAreas = selectedGeoAreas.length > 0;
		const geoAreaListExpandHandler = hasGeoAreas ? this.toggleExpanded : undefined;
		const childrenGeoAreasLeaves = this.getChildrenGeoAreasLeaves(selectedGeoAreas).sort(
			nullSafeGermanStringAscComparator((geoArea: ClientGeoArea) => geoArea.name)
		);
		const geoAreasListHeight = expanded
			? defaultUnexpandedHeight + childrenGeoAreasLeaves.length * 20
			: defaultUnexpandedHeight;
		return (
			<Grid item>
				<Box className={classes.elementContainer}>
					<Box display="flex" alignItems="center" justifyContent="flex-start" width={400} height={60}>
						<Box p={1}>
							<Box className={classes.appVariantIcon}>{variantLogo && <img alt={name} src={variantLogo}></img>}</Box>
						</Box>
						<Box pl={0} pr={1} p={1} className={classes.appVariantName}>
							<Box>
								<Typography variant="h6" noWrap title={name}>
									{name}
								</Typography>
							</Box>
							<Box>
								{lastUsage !== undefined && lastUsage !== 0 && (
									<Typography variant="subtitle2" noWrap>
										letzter Zugriff: {timeService().parseTimestamp(lastUsage, TimestampFormat.DD_MM_YY_HH_MM)}
									</Typography>
								)}
							</Box>
						</Box>
					</Box>
					<Box width={400} height={geoAreasListHeight}>
						<Box pl={1} pr={1}>
							<Box
								display="flex"
								onClick={geoAreaListExpandHandler}
								className={clsx({ [classes.selectedGeoAreasContainer]: hasGeoAreas })}
							>
								<Typography
									noWrap
									variant="subtitle1"
									style={{ lineHeight: '25px' }}
								>{`Abonnierte Gemeinden: ${childrenGeoAreasLeaves.length}`}</Typography>
								{!expanded && hasGeoAreas && <ArrowRightIcon color="action" />}
								{expanded && <ArrowDropDownIcon color="action" />}
							</Box>
						</Box>
						{expanded &&
							childrenGeoAreasLeaves.map(geoArea => (
								<Box key={geoArea.id} pl={3} pr={1} className={classes.geoAreaText}>
									<Link
										href={getGeoAreaDetailsUrl(geoArea.id)}
										onClick={(event: React.MouseEvent): void => {
											event.preventDefault();
											history.push(getGeoAreaDetailsUrl(geoArea.id));
										}}
									>
										{geoArea.name}
									</Link>
								</Box>
							))}
					</Box>
				</Box>
			</Grid>
		);
	}
}

export default withStyles(styles)(AppVariantElementView);
