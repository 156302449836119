import React from 'react';
import { TextField } from '@mui/material';
import { ReadOnlyTextFieldView } from '../../../shared/views/ReadOnlyTextField.view';

interface Props {
	groupName: string;
	isReadOnlyField?: boolean;
	handleGroupNameChange?: (newGroupName: string) => void;
}

class GroupNameView extends React.Component<Props> {
	constructor(props: Props) {
		super(props);
		this.handleGroupNameChange = this.handleGroupNameChange.bind(this);
	}

	handleGroupNameChange(event: React.ChangeEvent<{ value: string }>): void {
		const { handleGroupNameChange } = this.props;
		handleGroupNameChange && handleGroupNameChange(event.target.value);
	}

	render(): JSX.Element {
		const { groupName, isReadOnlyField } = this.props;
		return isReadOnlyField ? (
			<ReadOnlyTextFieldView
				id="groupName"
				name="groupName"
				label="Gruppename"
				value={groupName}
				InputLabelProps={{ shrink: true }}
				InputProps={{ readOnly: true }}
				fullWidth
			/>
		) : (
			<TextField
				variant="standard"
				required
				id="groupName"
				name="groupName"
				label="Gruppename"
				value={groupName}
				onChange={this.handleGroupNameChange}
				InputLabelProps={{ shrink: true }}
				fullWidth
			/>
		);
	}
}

export default GroupNameView;
