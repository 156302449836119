import {
	ClientPersonReferenceWithEmailStatusEnum,
	ClientPostTypeEnum
} from '@DigitaleDoerfer/digitale-doerfer-api/models';
import { ListFlagsUsingGETIncludedStatusEnum } from '@DigitaleDoerfer/digitale-doerfer-api/apis';
import { FlaggedType } from '../store/FlaggedContent.state';

export class FlaggedContentViewService {
	renderInfo(info?: string): string {
		if (info) {
			return info;
		}

		return 'Keine Angabe';
	}

	getPostTypeDisplayname(type: ClientPostTypeEnum): string {
		switch (type) {
			case ClientPostTypeEnum.GOSSIP:
				return 'Plausch';
			case ClientPostTypeEnum.HAPPENING:
				return 'Veranstaltung';
			case ClientPostTypeEnum.NEWSITEM:
				return 'News';
			case ClientPostTypeEnum.OFFER:
				return 'Biete';
			case ClientPostTypeEnum.SEEKING:
				return 'Suche';
			case ClientPostTypeEnum.SUGGESTION:
				return "Sag's uns";
			case ClientPostTypeEnum.SPECIALPOST:
				return 'Hilf mit';
			default: {
				return `Sonstiges`;
			}
		}
	}

	getEntityTypeDisplayName(entityType: string): string {
		const lastWord = entityType.match(/(\w+)$/)?.[0].toUpperCase();

		switch (lastWord) {
			case 'CHAT': {
				return 'Chat';
			}
			case 'SEEKING': {
				return this.getPostTypeDisplayname(ClientPostTypeEnum.SEEKING);
			}
			case 'GOSSIP': {
				return this.getPostTypeDisplayname(ClientPostTypeEnum.GOSSIP);
			}
			case 'EVENT': {
				return 'Event';
			}
			case 'NEWSITEM': {
				return this.getPostTypeDisplayname(ClientPostTypeEnum.NEWSITEM);
			}
			case 'HAPPENING': {
				return this.getPostTypeDisplayname(ClientPostTypeEnum.HAPPENING);
			}
			case 'COMMENT': {
				return 'Kommentar';
			}
			case 'SUGGESTION': {
				return this.getPostTypeDisplayname(ClientPostTypeEnum.SUGGESTION);
			}
			case 'OFFER': {
				return this.getPostTypeDisplayname(ClientPostTypeEnum.OFFER);
			}
			case 'GROUP': {
				return 'Gruppe';
			}
			case 'SPECIALPOST': {
				return this.getPostTypeDisplayname(ClientPostTypeEnum.SPECIALPOST);
			}
			default: {
				return 'Sonstiges';
			}
		}
	}

	getStatusDisplayName(status: ClientPersonReferenceWithEmailStatusEnum): string {
		switch (status) {
			case ClientPersonReferenceWithEmailStatusEnum.REGISTERED: {
				return 'Registriert';
			}
			default: {
				return 'Sonstiges';
			}
		}
	}

	translateFlaggedTypeDE(entityType: string): string {
		switch (entityType) {
			case FlaggedType.CHAT:
				return 'Chat';
			case FlaggedType.COMMENT:
				return 'Kommentar';
			case FlaggedType.POST:
				return 'Beitrag';
			default:
				return 'Sonstiges';
		}
	}

	translateFlagStatusDE(flagStatus: string): string {
		switch (flagStatus) {
			case ListFlagsUsingGETIncludedStatusEnum.OPEN:
				return 'Offen';
			case ListFlagsUsingGETIncludedStatusEnum.INPROGRESS:
				return 'In Bearbeitung';
			case ListFlagsUsingGETIncludedStatusEnum.ACCEPTED:
				return 'Akzeptiert';
			case ListFlagsUsingGETIncludedStatusEnum.REJECTED:
				return 'Abgelehnt';
			default:
				return '';
		}
	}

	getPrefixedTitleByPostType(flaggedType: FlaggedType): string {
		switch (flaggedType) {
			case FlaggedType.CHAT:
			case FlaggedType.COMMENT:
			case FlaggedType.POST:
			case FlaggedType.OTHER:
				return 'Gemeldeter';
			default:
				return '';
		}
	}
}
