import React from 'react';
import { Box, Grid, Link, Paper, Theme } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { ClientPersonExtended, ClientRoleInformation } from '@DigitaleDoerfer/digitale-doerfer-api/models';
import { EmptyField, initialUserState, ListSearchParams } from '../store/User.state';
import { MouseEventType } from '../../../shared/utils/Util';
import BarLoadingIndicator from '../../../shared/views/LoadingIndicators/BarLoadingIndicator.view';
import { RoleManagementPermission } from '../../../shared/services/Role.service';
import TableView from '../../../shared/views/Table/Table.view';
import TablePaginationView from '../../../shared/views/Table/TablePagination.view';
import { TableCell, TableCellTooltip, TableColumn } from '../../../shared/views/Table/TableColumn';
import { TableSorting } from '../../../shared/views/Table/TableSorting';
import UserListSearchAndFilterView from './UserListSearchAndFilter.view';
import NavActionButton from '../../../shared/views/Buttons/NavActionButton.view';
import { getUsersAuth0DetailsUrl } from '../UsersRouting.container';

const FIXED_COLUMN_WIDTH = 200;
const MINWIDTH_COLUMN_WIDTH = 320;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-unused-vars
const styles = (theme: Theme) => {
	return createStyles({
		minWidthColumn: {
			minWidth: MINWIDTH_COLUMN_WIDTH
		},
		fixedColumn: {
			minWidth: FIXED_COLUMN_WIDTH,
			maxWidth: FIXED_COLUMN_WIDTH
		}
	});
};

interface Props extends WithStyles<typeof styles> {
	users: ClientPersonExtended[];
	loading: boolean;
	searchParams: ListSearchParams;
	allAvailableRoles: ClientRoleInformation[];
	roleManagementPermission: RoleManagementPermission;
	shouldRenderCreateUserButton: boolean;
	getUsersByText: (params: ListSearchParams) => void;
	getUsersByRole: (params: ListSearchParams) => void;
	createUserHandler: () => void;
	viewUserDetailsHandler: (userId: string) => void;
	viewAuth0UserDetailsHandler: (email: string) => void;
	openNewUserDetailsTab: (userId: string) => void;
}

const paginationRowsPerPageOptions = [initialUserState.list.searchParams.pagination.rowsPerPage, 50, 100, 200] as const;

interface State {
	newSearchParams: ListSearchParams;
}

class UserListView extends React.Component<Props, State> {
	searchNeedsUpdate = false;

	constructor(props: Props) {
		super(props);
		const { searchParams } = props;
		this.state = {
			newSearchParams: searchParams
		};
		this.updateSearch = this.updateSearch.bind(this);
		this.handleChangePage = this.handleChangePage.bind(this);
		this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
		this.handleSortingChange = this.handleSortingChange.bind(this);
		this.handleSearchTextChange = this.handleSearchTextChange.bind(this);
		this.handleSearchRoleChange = this.handleSearchRoleChange.bind(this);
		this.handleSearchReset = this.handleSearchReset.bind(this);
		this.handleMouseEvent = this.handleMouseEvent.bind(this);
		this.renderHomeCommunityName = this.renderHomeCommunityName.bind(this);
		this.renderHomeAreaName = this.renderHomeAreaName.bind(this);
		this.getColumns = this.getColumns.bind(this);
	}

	updateSearch(): void {
		if (!this.props.loading) {
			this.searchNeedsUpdate = false;

			const { getUsersByText, getUsersByRole } = this.props;

			const { newSearchParams } = this.state;
			const { text: newText, role: newRole } = newSearchParams;

			if (newRole !== EmptyField.NONE) {
				getUsersByRole(newSearchParams);
			} else {
				if (newText.length > 2 || newText.length === 0) {
					getUsersByText(newSearchParams);
				}
			}
		} else {
			this.searchNeedsUpdate = true;
		}
	}

	componentDidMount(): void {
		this.updateSearch();
	}

	componentDidUpdate(): void {
		if (this.searchNeedsUpdate) {
			this.updateSearch();
		}
	}

	handleChangePage(newPage: number): void {
		this.setState((state: State): State => {
			return {
				...state,
				newSearchParams: {
					...state.newSearchParams,
					pagination: { ...state.newSearchParams.pagination, page: newPage }
				}
			};
		}, this.updateSearch);
	}

	handleChangeRowsPerPage(newRowsPerPage: number): void {
		this.setState((state: State): State => {
			return {
				...state,
				newSearchParams: {
					...state.newSearchParams,
					pagination: { ...state.newSearchParams.pagination, rowsPerPage: newRowsPerPage, page: 0 }
				}
			};
		}, this.updateSearch);
	}

	handleSortingChange(newSorting: TableSorting): void {
		this.setState((state: State): State => {
			return {
				...state,
				newSearchParams: {
					...state.newSearchParams,
					pagination: { ...state.newSearchParams.pagination, page: 0 },
					sorting: newSorting
				}
			};
		}, this.updateSearch);
	}

	handleSearchTextChange(searchCriteria: string): void {
		this.setState((state: State): State => {
			return {
				...state,
				newSearchParams: {
					...state.newSearchParams,
					pagination: { ...state.newSearchParams.pagination, page: 0 },
					text: searchCriteria
				}
			};
		}, this.updateSearch);
	}

	handleSearchRoleChange(role: string): void {
		this.setState((state: State): State => {
			return {
				...state,
				newSearchParams: {
					...state.newSearchParams,
					role,
					pagination: { ...state.newSearchParams.pagination, page: 0 }
				}
			};
		}, this.updateSearch);
	}

	handleSearchReset(): void {
		this.setState((state: State): State => {
			return {
				...state,
				newSearchParams: initialUserState.list.searchParams
			};
		}, this.updateSearch);
	}

	/**
	 * Handle the next mouse events:
	 * - (ctrlkey & left click) or middle click --> Open a new tab.
	 * - left click --> Open detail view.
	 * @param event Mouse event.
	 * @param user User data.
	 */
	handleMouseEvent(event: React.MouseEvent, user: ClientPersonExtended): void {
		const { viewUserDetailsHandler, openNewUserDetailsTab } = this.props;
		const mouseEvent = event.nativeEvent.which;
		const userId = user.id || '';
		if ((event.ctrlKey && MouseEventType.MOUSE_LEFT === mouseEvent) || mouseEvent === MouseEventType.MOUSE_MIDDLE) {
			openNewUserDetailsTab(userId);
		} else if (mouseEvent === MouseEventType.MOUSE_LEFT) {
			viewUserDetailsHandler(userId);
		}
	}

	getKeyForRow(user: ClientPersonExtended): React.Key {
		if (!user.id) {
			console.warn('no id defined for ' + user.toString());
			return JSON.stringify(user);
		}

		return user.id;
	}

	isRowDeleted(user: ClientPersonExtended): boolean {
		return !!user.deleted;
	}

	renderHomeCommunityName(cell: TableCell<ClientPersonExtended>): React.ReactElement {
		const { homeCommunityName: name } = cell.row;
		const { classes } = this.props;
		return <span className={classes.fixedColumn}>{name ? name : ''}</span>;
	}

	renderHomeAreaName(cell: TableCell<ClientPersonExtended>): React.ReactElement {
		const { homeAreaName: name } = cell.row;
		const { classes } = this.props;
		return <span className={classes.fixedColumn}>{name ? name : ''}</span>;
	}

	getColumns(): TableColumn<ClientPersonExtended>[] {
		const { classes } = this.props;
		return [
			{
				id: 'firstName',
				headerLabel: 'Vorname',
				cellClassName: classes.fixedColumn,
				cellStyles: {},
				getCellStringValue: (user: ClientPersonExtended): string => user.firstName ?? '',
				filterable: true,
				sortable: true
			},
			{
				id: 'lastName',
				headerLabel: 'Nachname',
				cellClassName: classes.fixedColumn,
				cellStyles: {},
				getCellStringValue: (user: ClientPersonExtended): string => user.lastName ?? '',
				filterable: true,
				sortable: true
			},
			{
				id: 'email',
				headerLabel: 'E-Mail',
				cellClassName: classes.minWidthColumn,
				cellStyles: {},
				getCellStringValue: (user: ClientPersonExtended): string => user.email ?? '',
				filterable: true,
				sortable: true
			},
			{
				id: 'homeCommunity',
				headerLabel: 'Heimat-Mandant',
				cellClassName: classes.fixedColumn,
				cellStyles: {},
				headerTooltip: { title: 'Home Community', placement: 'left' },
				getCellTooltip: (cell: TableCell<ClientPersonExtended>): TableCellTooltip => ({
					title: cell.row.homeCommunityId,
					placement: 'left'
				}),
				getCellStringValue: (user: ClientPersonExtended): string => user.homeCommunityId ?? '',
				renderCell: this.renderHomeCommunityName,
				filterable: true,
				sortable: false
			},
			{
				id: 'homeArea',
				headerLabel: 'Heimatgemeinde',
				cellClassName: classes.fixedColumn,
				cellStyles: {},
				headerTooltip: { title: 'Home (Geo) Area', placement: 'left' },
				getCellTooltip: (cell: TableCell<ClientPersonExtended>): TableCellTooltip => ({
					title: cell.row.homeAreaId,
					placement: 'left'
				}),
				getCellStringValue: (user: ClientPersonExtended): string => user.homeAreaName ?? '',
				renderCell: this.renderHomeAreaName,
				filterable: true,
				sortable: false
			},
			{
				id: 'id',
				headerLabel: 'Id',
				cellClassName: classes.minWidthColumn,
				cellStyles: {},
				getCellStringValue: (user: ClientPersonExtended): string => user.id ?? '',
				filterable: true,
				sortable: true
			}
		];
	}
	renderTable(): JSX.Element {
		const { users, searchParams } = this.props;
		const { sorting } = searchParams;
		const columns = this.getColumns();
		return (
			<>
				<TableView<ClientPersonExtended>
					columns={columns}
					rows={users}
					getKeyForRow={this.getKeyForRow}
					isRowDeleted={this.isRowDeleted}
					sorting={sorting}
					handleSortingChange={this.handleSortingChange}
					handleRowClicked={this.handleMouseEvent}
				>
					{this.renderAuth0DetailsLink()}
				</TableView>
			</>
		);
	}

	renderSearchViews(): JSX.Element {
		const { allAvailableRoles, searchParams } = this.props;
		const { text, role } = searchParams;
		return (
			<UserListSearchAndFilterView
				allAvailableRoles={allAvailableRoles}
				text={text}
				role={role}
				handleTextChange={this.handleSearchTextChange}
				handleRoleChange={this.handleSearchRoleChange}
				handleReset={this.handleSearchReset}
			/>
		);
	}

	renderPagination(): JSX.Element {
		const { searchParams } = this.props;
		const { page, rowsPerPage, total } = searchParams.pagination;

		return (
			<TablePaginationView
				totalRows={total}
				rowLabelPlural="Benutzer"
				page={page}
				rowsPerPage={rowsPerPage}
				rowsPerPageOptions={paginationRowsPerPageOptions}
				handleChangePage={this.handleChangePage}
				handleChangeRowsPerPage={this.handleChangeRowsPerPage}
			/>
		);
	}

	renderAuth0DetailsLink(): JSX.Element | null {
		const { users, roleManagementPermission, viewAuth0UserDetailsHandler } = this.props;

		if (!roleManagementPermission.isGlobalUser && !roleManagementPermission.isRestAdmin) {
			return null;
		}

		const { newSearchParams } = this.state;
		const { text: newText } = newSearchParams;

		if (users.length || !newText.includes('@')) {
			return null;
		}

		return (
			<Box mt={4} ml={2}>
				<Link
					href={getUsersAuth0DetailsUrl(newText)}
					onClick={(event: React.MouseEvent): void => {
						const mouseEvent = event.nativeEvent.which;
						if (mouseEvent === MouseEventType.MOUSE_LEFT) {
							event.preventDefault();
							viewAuth0UserDetailsHandler(newText);
						}
					}}
				>
					Bei Auth0 nach E-Mail-Adresse {newText} suchen
				</Link>
			</Box>
		);
	}

	renderCreateButton(): JSX.Element | null {
		const { shouldRenderCreateUserButton, createUserHandler } = this.props;
		if (!shouldRenderCreateUserButton) {
			return null;
		}
		return (
			<Box style={{ margin: '8px' }}>
				<NavActionButton onClick={(): void => createUserHandler()}>Neuen Benutzer anlegen</NavActionButton>
			</Box>
		);
	}

	render(): JSX.Element {
		const { loading } = this.props;
		return (
			<>
				{this.renderCreateButton()}
				<Paper>
					<BarLoadingIndicator loading={loading} />
					<Grid container spacing={2}>
						{this.renderSearchViews()}
						<Grid item xs={12}>
							{this.renderTable()}
							{this.renderPagination()}
						</Grid>
					</Grid>
				</Paper>
			</>
		);
	}
}
export const UserList = UserListView;
export default withStyles(styles)(UserListView);
