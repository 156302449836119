import React, { ReactNode } from 'react';
import Autocomplete, { AutocompleteChangeReason, createFilterOptions } from '@mui/material/Autocomplete';
import { TextField, Typography } from '@mui/material';
import { geoAreaService } from '../../../ServiceFactory';
import { AnyGeoArea } from '../../utils/AnyGeoArea';
import { GroupStateChangeHandlers } from '../../../modules/groups/GroupStateChangeHandlers';
import { GeoAreaElement } from '../../utils/GeoAreaElements';
import { CreateFilterOptionsConfig } from '@mui/material/useAutocomplete';

interface Props {
	geoAreas: AnyGeoArea[];
	flattenedGeoAreas: AnyGeoArea[];
	manualSearchedGeoAreas: GeoAreaElement[];
	groupStateChangeHandlers: GroupStateChangeHandlers;
}

class GroupGeoAreasAutocompleteView extends React.Component<Props> {
	constructor(props: Props) {
		super(props);
		this.handleSelectedAutocompleteChange = this.handleSelectedAutocompleteChange.bind(this);
	}

	/**
	 * Handle the selected autocomplete geoArea.
	 * @param event The event source of the callback.
	 * @param selectedGeoArea The autocomplete selected geoArea.
	 * @param reason  One of "create-option", "select-option", "remove-option", "blur" or "clear".
	 */
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	handleSelectedAutocompleteChange(
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		event: object,
		selectedGeoArea: AnyGeoArea | null, // eslint-disable-next-line @typescript-eslint/no-unused-vars
		reason: AutocompleteChangeReason
	): void {
		const { geoAreas, flattenedGeoAreas, manualSearchedGeoAreas, groupStateChangeHandlers } = this.props;
		const { handleManualSearchedGeoAreasChange } = groupStateChangeHandlers;

		if (selectedGeoArea && handleManualSearchedGeoAreasChange) {
			// find geo area path
			const geoAreasPath: AnyGeoArea[] = geoAreaService().getGeoAreasPathToGivenGeoAreaId(
				geoAreas,
				flattenedGeoAreas,
				selectedGeoArea.id ?? ''
			);
			const geoAreasLength = geoAreasPath.length;
			// find groupMemberGeoArea direct parent
			const groupMemberGeoAreaDirectParent =
				geoAreasPath[geoAreasLength >= 2 ? geoAreasLength - 2 : geoAreasLength - 1];
			const createdGeoAreaElement = geoAreaService().createGeoAreaElement(
				geoAreasPath,
				selectedGeoArea.id,
				true,
				undefined,
				groupMemberGeoAreaDirectParent
			);
			const newSearchedGeoAreas = [...manualSearchedGeoAreas];
			newSearchedGeoAreas.push(createdGeoAreaElement);
			if (handleManualSearchedGeoAreasChange) {
				handleManualSearchedGeoAreasChange(newSearchedGeoAreas);
			}
		}
	}

	render(): JSX.Element {
		const { flattenedGeoAreas } = this.props;
		const config: CreateFilterOptionsConfig<AnyGeoArea> = { limit: 10 };
		const filterOptions = createFilterOptions(config);
		return (
			<Autocomplete
				options={flattenedGeoAreas}
				filterOptions={filterOptions}
				noOptionsText={'GeoArea wurde nicht gefunden'}
				onChange={this.handleSelectedAutocompleteChange}
				isOptionEqualToValue={(geoArea: AnyGeoArea, value: AnyGeoArea): boolean => geoArea.name === value.name}
				getOptionLabel={(geoArea: AnyGeoArea): string => geoArea?.name ?? ''}
				renderOption={(props, geoArea: AnyGeoArea): ReactNode => (
					<li {...props}>
						<Typography variant="h6">{geoArea?.name ?? ''}</Typography>
					</li>
				)}
				autoComplete
				renderInput={(params): ReactNode => (
					<TextField
						variant="standard"
						{...params}
						InputLabelProps={{
							shrink: true
						}}
						placeholder="Weiteres Gebiet hinzufügen"
						autoComplete={'off'}
						fullWidth
					/>
				)}
			/>
		);
	}
}

export default GroupGeoAreasAutocompleteView;
