import clsx from 'clsx';
import React from 'react';
import List from '@mui/material/List';
import Drawer from '@mui/material/Drawer';
import Home from '@mui/icons-material/Home';
import FlashOn from '@mui/icons-material/FlashOn';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SettingsIcon from '@mui/icons-material/Settings';
import Widgets from '@mui/icons-material/Widgets';
import AccountBox from '@mui/icons-material/AccountBox';
import AccountCircle from '@mui/icons-material/AccountCircle';
import PeopleIcon from '@mui/icons-material/People';
import MapIcon from '@mui/icons-material/Map';
import Description from '@mui/icons-material/Description';
import Report from '@mui/icons-material/Report';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import ListItemLinkView from '../../../shared/views/ListItemLink/ListItemLink.view';
import { Theme, ThemeProvider } from '@mui/material/styles';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { Collapse, createTheme, ListItemButton, ListItemIcon, ListItemText, StyledEngineProvider } from '@mui/material';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import BarChart from '@mui/icons-material/BarChart';

declare module '@mui/styles' {
	// eslint-disable-next-line @typescript-eslint/no-empty-interface
	interface DefaultTheme extends Theme {}
}

const drawerWidth = 240;
const drawerWidthClosed = 56;

const subMenuSpacing = 4;
const subMenuSpacingClosed = 2;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const styles = (theme: Theme) => {
	return createStyles({
		active: {
			backgroundColor: theme.palette.action.selected
		},
		drawer: {
			width: drawerWidth,
			transition: theme.transitions.create(['width', 'margin'], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen
			}),
			overflowX: 'hidden',
			whiteSpace: 'nowrap',

			'@media screen and (max-width: 1000px)': {
				width: drawerWidthClosed
			}
		},
		nested: {
			paddingLeft: theme.spacing(subMenuSpacing),
			'@media screen and (max-width: 1000px)': {
				paddingLeft: theme.spacing(subMenuSpacingClosed)
			}
		}
	});
};

const theme = createTheme({
	components: {
		MuiListItemIcon: {
			styleOverrides: {
				root: {
					minWidth: 40
				}
			}
		}
	}
});
interface Props extends RouteComponentProps, WithStyles<typeof styles> {
	shouldRenderUserMenu: boolean;
	shouldRenderFlaggedContentMenu: boolean;
	shouldRenderGroupMenu: boolean;
	shouldRenderConfigurationMenu: boolean;
	shouldRenderExternalContributionsMenu: boolean;
	shouldRenderStatisticsMenu: boolean;
}

interface State {
	configurationMenuOpen: boolean;
}

const initialState: State = {
	configurationMenuOpen: true
};

function isConfigurationSubMenu(location: string): boolean {
	return ['/push-events', '/app-configs', '/tenants', '/geo-areas'].includes(location);
}

class SideNavigationView extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = initialState;
		this.handleConfigurationMenuClick = this.handleConfigurationMenuClick.bind(this);
	}

	componentDidUpdate(prevProps: Props): void {
		const { pathname } = this.props.location;
		if (pathname !== prevProps.location.pathname) {
			if (isConfigurationSubMenu(pathname)) {
				this.setState({ configurationMenuOpen: true });
			}
		}
	}

	renderUserMenu(): JSX.Element | null {
		const { shouldRenderUserMenu, classes } = this.props;
		if (!shouldRenderUserMenu) {
			return null;
		}
		return (
			<>
				<ListItemLinkView
					key={'User'}
					icon={<AccountBox />}
					primary={'Benutzer*innen'}
					to="/users"
					highlight={(location): boolean => location.pathname === '/users' && !location.search.includes('profile')}
					activeClassName={classes.active}
				/>
				<ListItemLinkView
					key={'YourAccount'}
					icon={<AccountCircle />}
					primary={'Dein Profil'}
					to="/users/profile"
					highlight={(location): boolean =>
						location.pathname?.startsWith('/users') && location.search.includes('profile')
					}
					activeClassName={classes.active}
					subMenuClassName={classes.nested}
				/>
			</>
		);
	}

	renderFlaggedContentMenu(): JSX.Element | null {
		const { shouldRenderFlaggedContentMenu, classes } = this.props;
		if (!shouldRenderFlaggedContentMenu) {
			return null;
		}
		return (
			<ListItemLinkView
				key={'FlaggedContent'}
				icon={<Report />}
				primary={'Gemeldete Beiträge'}
				to="/flagged-contents"
				activeClassName={classes.active}
			/>
		);
	}

	renderGroupMenu(): JSX.Element | null {
		const { shouldRenderGroupMenu, classes } = this.props;
		if (!shouldRenderGroupMenu) {
			return null;
		}
		return (
			<ListItemLinkView
				key={'Groups'}
				icon={<SupervisedUserCircleIcon />}
				primary={'Gruppen'}
				to="/groups"
				activeClassName={classes.active}
			/>
		);
	}

	renderPushEventMenu(): JSX.Element | null {
		const { classes } = this.props;
		return (
			<ListItemLinkView
				key={'PushEvents'}
				icon={<FlashOn />}
				primary={'Push-Events'}
				to="/push-events"
				activeClassName={classes.active}
				subMenuClassName={classes.nested}
			/>
		);
	}

	renderAppConfigMenu(): JSX.Element | null {
		const { classes } = this.props;
		return (
			<ListItemLinkView
				key={'AppConfigs'}
				icon={<Widgets />}
				primary={'App-Konfigurationen'}
				to="/app-configs"
				activeClassName={classes.active}
				subMenuClassName={classes.nested}
			/>
		);
	}

	renderTenantsMenu(): JSX.Element | null {
		const { classes } = this.props;
		return (
			<ListItemLinkView
				key={'Tenants'}
				icon={<PeopleIcon />}
				primary={'Mandanten'}
				to="/tenants"
				activeClassName={classes.active}
				subMenuClassName={classes.nested}
			/>
		);
	}

	renderGeoAreasMenu(): JSX.Element | null {
		const { classes } = this.props;

		return (
			<ListItemLinkView
				key={'GeoAreas'}
				icon={<MapIcon />}
				primary={'Geo-Areas'}
				to="/geo-areas"
				activeClassName={classes.active}
				subMenuClassName={classes.nested}
			/>
		);
	}

	renderContractsMenu(): JSX.Element | null {
		const { classes } = this.props;

		return (
			<ListItemLinkView
				key={'Nutzungsverträge'}
				icon={<AccountTreeIcon />}
				primary={'Nutzungsverträge'}
				to="/contractAudit"
				activeClassName={classes.active}
				subMenuClassName={classes.nested}
			/>
		);
	}

	renderNewsSourcesMenu(): JSX.Element | null {
		const { classes } = this.props;

		return (
			<ListItemLinkView
				key={'News-Sources'}
				icon={<AnnouncementIcon />}
				primary={'News-Sources'}
				to="/News-sources"
				activeClassName={classes.active}
				subMenuClassName={classes.nested}
			/>
		);
	}

	renderConfigurationMenu(): JSX.Element | null {
		const { configurationMenuOpen } = this.state;
		const { shouldRenderConfigurationMenu } = this.props;
		if (!shouldRenderConfigurationMenu) {
			return null;
		}
		const children = [
			this.renderPushEventMenu(),
			this.renderAppConfigMenu(),
			this.renderTenantsMenu(),
			this.renderGeoAreasMenu(),
			this.renderContractsMenu(),
			this.renderNewsSourcesMenu()
		].filter(child => !!child);

		if (!children.length) {
			return null;
		}

		return (
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={theme}>
					<ListItemButton onClick={this.handleConfigurationMenuClick}>
						<ListItemIcon>
							<SettingsIcon />
						</ListItemIcon>
						<ListItemText primary="Konfiguration" />
						{configurationMenuOpen ? <ExpandLess /> : <ExpandMore />}
					</ListItemButton>
					<Collapse in={configurationMenuOpen} timeout="auto" unmountOnExit>
						{children}
					</Collapse>
				</ThemeProvider>
			</StyledEngineProvider>
		);
	}

	renderExternalContributions(): JSX.Element | null {
		const { classes } = this.props;
		const { shouldRenderExternalContributionsMenu } = this.props;
		if (!shouldRenderExternalContributionsMenu) {
			return null;
		}
		return (
			<ListItemLinkView
				key={'External Contributions'}
				icon={<Description />}
				primary={'Externe Beiträge'}
				to="/ExternalContributions"
				activeClassName={classes.active}
			/>
		);
	}

	renderStatisticsMenu(): JSX.Element | null {
		const { classes } = this.props;
		const { shouldRenderStatisticsMenu } = this.props;
		if (!shouldRenderStatisticsMenu) {
			return null;
		}
		return (
			<ListItemLinkView
				key={'Statistics'}
				icon={<BarChart />}
				primary={'Statistiken'}
				to="/statistics"
				activeClassName={classes.active}
			/>
		);
	}

	handleConfigurationMenuClick(): void {
		const { configurationMenuOpen } = this.state;
		this.setState({ configurationMenuOpen: !configurationMenuOpen });
	}
	render(): JSX.Element {
		const { classes } = this.props;

		return (
			<Drawer
				variant="permanent"
				classes={{
					paper: clsx(classes.drawer)
				}}
				className={clsx(classes.drawer)}
			>
				<List>
					<ListItemLinkView
						key={'Home'}
						icon={<Home />}
						primary={'Start'}
						to="/"
						highlightOnlyWhenExactUrlMatch={true}
						activeClassName={classes.active}
					/>
					{this.renderUserMenu()}
					{this.renderFlaggedContentMenu()}
					{this.renderGroupMenu()}
					{this.renderConfigurationMenu()}
					{this.renderExternalContributions()}
					{this.renderStatisticsMenu()}
				</List>
			</Drawer>
		);
	}
}

export default withStyles(styles)(withRouter(SideNavigationView));
