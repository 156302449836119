import React, { Component } from 'react';
import { Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { ActionMenuItemProps } from '../../../../shared/views/ActionMenu/ActionMenu';
import DialogActionView from '../../../../shared/views/ActionMenu/DialogAction.view';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-unused-vars
const styles = (theme: Theme) => {
	return createStyles({
		email: {
			textAlign: 'center',
			verticalAlign: 'middle',
			lineHeight: '100px',
			fontWeight: 'bold'
		}
	});
};

interface Props extends ActionMenuItemProps, WithStyles<typeof styles> {
	firstName: string;
	lastName: string;
	email: string;
	handleSave: () => void;
}

class SetEmailVerifiedActionView extends Component<Props> {
	constructor(props: Props) {
		super(props);

		this.handleSave = this.handleSave.bind(this);
	}

	handleSave(): void {
		this.props.handleSave();
	}

	render(): JSX.Element {
		const { renderMenuItem, firstName, lastName, email, classes } = this.props;
		return (
			<DialogActionView
				itemProps={{
					renderMenuItem,
					icon: <CheckCircleOutlineIcon color="primary" />,
					text: 'E-Mail verifizieren'
				}}
				dialogProps={{
					danger: true,
					titleText: 'E-Mail-Adresse verifizieren',
					saveButtonText: 'Ja, ich bin sicher!',
					handleSave: this.handleSave
				}}
			>
				<Typography>
					Bist du sicher, dass {firstName} {lastName} über folgende E-Mail-Adresse verfügt?
				</Typography>
				<Typography className={classes.email}>{email}</Typography>
			</DialogActionView>
		);
	}
}

export default withStyles(styles)(SetEmailVerifiedActionView);
