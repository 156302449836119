import React, { Component } from 'react';
import ClipboardView from './Clipboard.view';
import { ReadOnlyTextFieldView } from './ReadOnlyTextField.view';
import { InputAdornment, StandardTextFieldProps, Tooltip } from '@mui/material';

export interface Props {
	textToCopy: string;
	values: StandardTextFieldProps;
}

interface State {
	showCopyIcon: boolean;
}
class ReadOnlyTextFieldWithClipboardView extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			showCopyIcon: false
		};
		this.showCopyIcon = this.showCopyIcon.bind(this);
		this.hideCopyIcon = this.hideCopyIcon.bind(this);
	}

	hideCopyIcon(): void {
		this.setState({ showCopyIcon: false });
	}

	showCopyIcon(): void {
		this.setState({ showCopyIcon: true });
	}

	render(): JSX.Element {
		const { showCopyIcon } = this.state;
		const { values, textToCopy } = this.props;
		const { id, name, label, type, value, InputLabelProps, error, helperText } = values;

		return (
			<Tooltip title={textToCopy} placement="bottom-end">
				<ReadOnlyTextFieldView
					id={id}
					name={name}
					label={label}
					type={type}
					value={value}
					InputProps={{
						readOnly: true,
						endAdornment: value ? (
							<InputAdornment position="end">
								<ClipboardView textToCopy={textToCopy} showCopyIcon={showCopyIcon} />
							</InputAdornment>
						) : (
							<></>
						)
					}}
					InputLabelProps={InputLabelProps}
					error={error}
					helperText={helperText}
					onMouseOver={this.showCopyIcon}
					onMouseLeave={this.hideCopyIcon}
					fullWidth
				/>
			</Tooltip>
		);
	}
}
export default ReadOnlyTextFieldWithClipboardView;
