import { Grid, Paper } from '@mui/material';

import React, { FC, useEffect } from 'react';
import FilterBar from '../filter-bar';
import GeoAreaTree from '../geo-area-tree';
import useFetchAppVariant from '../hooks/useFetchAppVariant';
import useFetchContracts from '../hooks/useFetchContracts';
import useFetchTenants from '../hooks/useFetchTenants';
import useFilterBar from '../hooks/useFilterBar';
import ListSection from '../list-section';
import useSearch from '../Search/hook/useSearch';
import SelectedContractsSection from '../selected-contracts/SelectedContractsSection';
import SelectedItemDetails from '../selected-item-details';
import { ListType } from '../types';

const ContractAuditDetails: FC = () => {
	const { filters, removeAllFilters, removeFilter, filteredTenantIds, filteredAppVariantIds } = useFilterBar();
	const { searchQueryAppVariant, searchQueryTenant, searchQueryContract } = useSearch();

	const {
		isLoading: isLoadingAppVariants,
		isFetchingNextPage: isFetchingNextPageAppVariants,
		data: appVariants,
		fetchNextPage: fetchNextAppVariantsPage
	} = useFetchAppVariant(filteredTenantIds, searchQueryAppVariant);
	const {
		isLoading: isLoadingTenants,
		data: tenants,
		fetchNextPage: fetchNextTenantsPage,
		isFetchingNextPage: isFetchingNextPageTenants
	} = useFetchTenants(filteredAppVariantIds, searchQueryTenant);
	const {
		isLoading: isLoadingContracts,
		isFetchingNextPage: isFetchingNextPageContracts,
		data: contracts,
		fetchNextPage: fetchNextContractsPage,
		hasNextPage: hasNextContractsPage
	} = useFetchContracts(filteredTenantIds, filteredAppVariantIds, searchQueryContract);

	const isTenantFilterSet = filteredTenantIds.length > 0;

	// Prefetch
	useEffect(() => {
		if (hasNextContractsPage && !isFetchingNextPageContracts) {
			fetchNextContractsPage();
		}
	}, [isFetchingNextPageContracts, hasNextContractsPage, fetchNextContractsPage]);

	return (
		<Paper>
			<Grid
				container
				sx={{
					minHeight: 'calc(100vh - 150px)',
					justifyContent: 'center'
				}}
			>
				<Grid container item xs={12} md={8} spacing={1}>
					<Grid item xs={12}>
						<Paper
							variant="outlined"
							sx={{
								color: 'secondary',
								display: 'flex'
							}}
						>
							<FilterBar filters={filters} handleRemoveFilter={removeFilter} handleRemoveAllFilter={removeAllFilters} />
						</Paper>
					</Grid>
					<Grid xs={12} container item>
						<ListSection
							type={ListType.APP_VARIANT}
							title={'App-Varianten'}
							isLoading={isLoadingAppVariants}
							isFetchingNextPage={isFetchingNextPageAppVariants}
							data={appVariants}
							fetchNextPage={fetchNextAppVariantsPage}
							isTenantFilterSet={isTenantFilterSet}
						/>

						<ListSection
							type={ListType.TENANT}
							title={'Mandanten'}
							data={tenants}
							isLoading={isLoadingTenants}
							isFetchingNextPage={isFetchingNextPageTenants}
							fetchNextPage={fetchNextTenantsPage}
						/>
						<ListSection
							type={ListType.CONTRACT}
							title={'Nutzungsverträge'}
							data={contracts}
							isLoading={isLoadingContracts}
							isFetchingNextPage={isFetchingNextPageContracts}
							fetchNextPage={fetchNextContractsPage}
							showSelectAll={true}
						/>
					</Grid>
					<Grid item xs={12}>
						<SelectedItemDetails />
					</Grid>
				</Grid>
				<Grid container item xs={12} md={4} spacing={0}>
					<Grid item xs={12}>
						<SelectedContractsSection hasNextPage={hasNextContractsPage} />
						<Paper
							variant="outlined"
							sx={{
								color: 'secondary',
								maxHeight: 'calc(100vh - 180px)',
								overflowY: 'scroll'
							}}
						>
							<GeoAreaTree />
						</Paper>
					</Grid>
				</Grid>
			</Grid>
		</Paper>
	);
};
export default ContractAuditDetails;
