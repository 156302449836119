import React, { FC, useState } from 'react';
import { Button } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import DoneIcon from '@mui/icons-material/Done';
import { getStatisticsReportContent } from './Statistics.actions';
import { showSnackbarError } from '../../shared/views/SnackbarNotification/store/SnackbarNotification.actions';
import { useDispatch } from 'react-redux';
import { PlatformAdminUIThunkType } from '../../store/store';
import { ShowSnackbar } from '../../shared/views/SnackbarNotification/store/SnackbarNotificationActionTypes';

interface StatisticsReportDownloadButtonProps {
	buttonText: string;
	fileName?: string;
}

const StatisticsReportDownloadButton: FC<StatisticsReportDownloadButtonProps> = ({
	buttonText,
	fileName
}: StatisticsReportDownloadButtonProps) => {
	const [isDownloaded, setIsDownloaded] = useState<boolean>(false);
	const dispatch = useDispatch<PlatformAdminUIThunkType<ShowSnackbar>>();

	const triggerFileDownload = (fileContent: string): void => {
		const contentType = 'text/' + (fileName?.substring(fileName.lastIndexOf('.') + 1) === 'csv' ? 'csv' : 'plain');
		const url = window.URL.createObjectURL(new Blob(['\ufeff', fileContent], { type: contentType }));
		const a = document.createElement('a');
		a.href = url;
		a.download = fileName?.substring(fileName.lastIndexOf('/') + 1) || '';
		document.body.appendChild(a);
		a.click();
		a.remove();
	};

	return (
		<Button
			startIcon={isDownloaded ? <DoneIcon /> : <GetAppIcon />}
			style={{ marginRight: '1rem' }}
			disabled={fileName === undefined}
			onClick={(): void => {
				getStatisticsReportContent(fileName || '')
					.then(fileContent => {
						triggerFileDownload(fileContent);
						setIsDownloaded(true);
					})
					.catch(error => {
						console.log(error);
						dispatch(showSnackbarError(error));
					});
			}}
		>
			{buttonText}
		</Button>
	);
};

export default StatisticsReportDownloadButton;
