import React from 'react';
import { Box, IconButton, Link, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AvatarView from '../../../shared/views/Avatar.view';
import DeleteIcon from '@mui/icons-material/Delete';
import { AnyPerson } from '../../utils/AnyPerson';

const FIXED_AVATAR_WIDTH_CONTAINER = 60;
const FIXED_REMOVE_ICON_WIDTH_CONTAINER = 45;
const FIXED_TEXT_WIDTH_HANDLER_USER_DELETION_CONTAINER = 215;
const FIXED_TEXT_WIDTH_CONTAINER = 260;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStyles = makeStyles(() => ({
	elementContainer: {
		boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)'
	}
}));

interface Props {
	user: AnyPerson & { email?: string };
	isSearchFieldElement?: boolean;
	handleUserDeletion?: () => void;
}

const GroupUserElementView = (props: Props): JSX.Element => {
	const { user, isSearchFieldElement, handleUserDeletion } = props;
	const classes = useStyles();

	return (
		<Box className={!isSearchFieldElement ? classes.elementContainer : ''}>
			<Box display="flex" alignItems="center" justifyContent="flex-start" width={320} height={60}>
				<Box p={1} width={FIXED_AVATAR_WIDTH_CONTAINER}>
					<AvatarView avatarPicture={user.profilePicture} />
				</Box>
				<Box
					pl={0}
					pr={1}
					p={1}
					width={handleUserDeletion ? FIXED_TEXT_WIDTH_HANDLER_USER_DELETION_CONTAINER : FIXED_TEXT_WIDTH_CONTAINER}
				>
					<Box>
						<Typography variant="h3" noWrap>{`${user.firstName ?? ''} ${user.lastName ?? ''}`}</Typography>
					</Box>
					<Box>
						{user.email && (
							<Typography variant="caption" color="primary" noWrap>
								<Link href={`mailto:${user.email}`} display="block" noWrap>
									{user.email}
								</Link>
							</Typography>
						)}
					</Box>
				</Box>
				{handleUserDeletion && (
					<Box p={1} width={FIXED_REMOVE_ICON_WIDTH_CONTAINER}>
						<IconButton edge="end" onClick={(): void => handleUserDeletion()} size="small">
							<DeleteIcon />
						</IconButton>
					</Box>
				)}
			</Box>
		</Box>
	);
};

export default GroupUserElementView;
