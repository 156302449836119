import React from 'react';
import { Checkbox } from '@mui/material';
import { Theme } from '@mui/material/styles';

import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-unused-vars
const styles = (theme: Theme) => {
	return createStyles({
		loudCheckBox: {
			padding: 0
		}
	});
};

interface Props extends WithStyles<typeof styles> {
	loud: boolean;
}

const LoudView = (props: Props): JSX.Element => {
	const { classes } = props;
	return <Checkbox checked={props.loud} disabled className={classes.loudCheckBox} />;
};

export default withStyles(styles)(LoudView);
