import React from 'react';
import { FormHelperText, Grid, Theme, useMediaQuery, useTheme } from '@mui/material';
import { ClientPersonExtended, ClientPersonOwn } from '@DigitaleDoerfer/digitale-doerfer-api/models';
import AvatarView from '../Avatar.view';
import { ImageSizes } from '../../services/Image.service';
import { ReadOnlyTextFieldView } from '../ReadOnlyTextField.view';

export interface Props {
	user: ClientPersonExtended | ClientPersonOwn;
}

export function PersonNameWithAvatarPictureFormView(props: Props): JSX.Element {
	const { user } = props;
	const theme: Theme = useTheme();
	const { firstName, lastName, profilePicture, deleted } = user;
	const isSmallBreakPoint = useMediaQuery(theme.breakpoints.down('md'));
	const dorfFunkName =
		firstName || lastName
			? `Im DorfFunk angezeigt als ${firstName ?? ''}${lastName ? ` ${lastName.charAt(0)}` : ''}.`
			: '';
	const avatarGridView = (
		<Grid item md={6}>
			<AvatarView avatarPicture={profilePicture} deleted={deleted} size={ImageSizes.MEDIUM} />
		</Grid>
	);
	return (
		<>
			{isSmallBreakPoint ? avatarGridView : ''}
			<Grid item container spacing={3} md={6}>
				<Grid item xs={12}>
					<ReadOnlyTextFieldView
						id="firstName"
						name="firstName"
						label="Vorname"
						value={firstName ?? ''}
						InputProps={{ readOnly: true }}
						InputLabelProps={{
							shrink: true
						}}
						fullWidth
					/>
				</Grid>
				<Grid item xs={12}>
					<ReadOnlyTextFieldView
						id="lastName"
						name="lastName"
						label="Nachname"
						value={lastName ?? ''}
						InputProps={{ readOnly: true }}
						InputLabelProps={{
							shrink: true
						}}
						fullWidth
					/>
					<FormHelperText>{dorfFunkName}</FormHelperText>
				</Grid>
			</Grid>
			{isSmallBreakPoint ? '' : avatarGridView}
		</>
	);
}
export default PersonNameWithAvatarPictureFormView;
