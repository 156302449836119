import React from 'react';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import { NotFoundErrorView } from '../error/ErrorPages.view';
import Groups from './group-list/GroupList.container';
import GroupsDetails from './group-details/GroupDetails.container';
import GroupCreateContainer from './group-create/GroupCreate.container';

export const GROUPS_URL = '/groups';
export const GROUP_CREATE_URL = GROUPS_URL + '/create';
const GROUPS_DETAILS_URL_BASE = GROUPS_URL + '/details/:id';

export function getGroupsDetailsUrlWithId(groupId: string): string {
	return GROUPS_DETAILS_URL_BASE.replace(':id', groupId);
}

interface Props extends RouteComponentProps {}

class GroupRoutingContainer extends React.Component<Props> {
	render(): JSX.Element {
		const {
			match: { url }
		} = this.props;

		return (
			<Switch>
				<Route exact path={url} component={Groups} />
				<Route path={GROUP_CREATE_URL} component={GroupCreateContainer} />
				<Route path={GROUPS_DETAILS_URL_BASE} component={GroupsDetails} />
				<Route component={NotFoundErrorView} />
			</Switch>
		);
	}
}

export default withRouter(GroupRoutingContainer);
