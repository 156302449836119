import React from 'react';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import TenantsList from './TenantsList.container';

export const TENANTS_URL = '/tenants';

interface Props extends RouteComponentProps {}

class TenantsListRoutingContainer extends React.Component<Props> {
	render(): JSX.Element {
		const {
			match: { url }
		} = this.props;

		return (
			<Switch>
				<Route exact path={url} component={TenantsList} />
			</Switch>
		);
	}
}

export default withRouter(TenantsListRoutingContainer);
