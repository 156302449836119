import React from 'react';
import { InputAdornment, Link } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { ReadOnlyTextFieldView } from '../ReadOnlyTextField.view';

const useStyles = makeStyles({
	emailLinkContainer: {
		width: 'inherit',
		fontWeight: 'bold'
	},
	input: {
		width: '0' // Since we are not using the input but label to show the email, we do not need the input space.
	}
});

interface Props {
	email?: string;
	emailVerified?: boolean;
}

const EmailTextFieldView = (props: Props): JSX.Element => {
	const { email, emailVerified } = props;
	const classes = useStyles();

	return (
		<ReadOnlyTextFieldView
			id="email"
			name="email"
			label="E-Mail-Adresse"
			type="email"
			InputProps={{
				startAdornment: (
					<InputAdornment position="start" className={classes.emailLinkContainer}>
						<Link href={`mailto:${email ?? ''}`}>{email ?? ''}</Link>
					</InputAdornment>
				),
				endAdornment: (
					<InputAdornment position="end">
						{emailVerified ? <CheckCircleOutlineIcon color="primary" /> : ''}
					</InputAdornment>
				),
				readOnly: true,
				classes: { input: classes.input }
			}}
			InputLabelProps={{
				shrink: true
			}}
			fullWidth
		/>
	);
};

export default EmailTextFieldView;
