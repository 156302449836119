import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Box, Grid, Paper } from '@mui/material';
import GroupDetailsView from './GroupDetails.view';
import { getGroup } from '../store/Group.actions';
import { connect } from 'react-redux';
import { ClientGroupExtendedDetail } from '@DigitaleDoerfer/digitale-doerfer-api/models';
import { PlatformAdminUIState, PlatformAdminUIThunkType } from '../../../store/store';
import { GroupActionTypes } from '../store/GroupActionTypes';
import MainLoadingIndicator from '../../../shared/views/LoadingIndicators/MainLoadingIndicator.view';
import GroupDetailsActionMenuContainer from './GroupDetailsActionMenu.container';
import GroupActivitiesView from './GroupActivities.view';
import { RoleManagementPermission } from '../../../shared/services/Role.service';
import { AnyGeoArea } from '../../../shared/utils/AnyGeoArea';
import { getGeoAreas } from '../../../store/Global.actions';
interface Identifiable {
	id?: string;
}

interface Props extends RouteComponentProps<Identifiable> {
	group: ClientGroupExtendedDetail | null;
	loading: boolean;
	roleManagementPermission: RoleManagementPermission;
	getGroup: (groupId: string) => void;
	allGeoAreas: AnyGeoArea[];
	getAllGeoAreas: () => void;
}

interface State {
	contentRefreshed: boolean;
}

class GroupDetailsContainer extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = { contentRefreshed: false };
	}

	componentDidMount(): void {
		const {
			match: { params },
			getGroup,
			allGeoAreas,
			getAllGeoAreas
		} = this.props;
		const groupId = params.id;
		if (groupId) {
			getGroup(groupId);
			this.setState({ contentRefreshed: true });
		}
		if (allGeoAreas.length === 0) {
			getAllGeoAreas();
		}
	}

	render(): JSX.Element {
		const { group, loading, allGeoAreas, roleManagementPermission } = this.props;
		const { contentRefreshed } = this.state;
		return (
			<>
				<MainLoadingIndicator loading={loading || !contentRefreshed} />
				{contentRefreshed && !loading && group && (
					<Grid container>
						<Grid item xs={12} md={8}>
							<Paper>
								<GroupDetailsView
									group={group}
									allGeoAreas={allGeoAreas}
									actionMenu={<GroupDetailsActionMenuContainer />}
								/>
							</Paper>
						</Grid>
						<Grid item xs={12} md={4}>
							<Paper>
								<Box pt={3} pb={1}>
									<GroupActivitiesView group={group} roleManagementPermission={roleManagementPermission} />
								</Box>
							</Paper>
						</Grid>
					</Grid>
				)}
			</>
		);
	}
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: PlatformAdminUIState) => {
	return {
		group: state.group.detail.group,
		loading: state.group.detail.loading || state.global.geoAreasData.loading,
		roleManagementPermission: state.global.roleManagementPermission,
		allGeoAreas: state.global.geoAreasData.geoAreas
	};
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch: PlatformAdminUIThunkType<GroupActionTypes>) => {
	return {
		getGroup: (groupId: string): Promise<void> => dispatch(getGroup(groupId)),
		getAllGeoAreas: (): Promise<void> => dispatch(getGeoAreas())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupDetailsContainer);
