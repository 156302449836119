import React, { FC } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import ContractAuditDetails from './ContractAuditDetails';

const queryClient = new QueryClient({ defaultOptions: { queries: { staleTime: Infinity } } });

const ContractAuditDetailsContainer: FC = () => {
	return (
		<QueryClientProvider client={queryClient}>
			<ContractAuditDetails />
			<ReactQueryDevtools initialIsOpen={false} />
		</QueryClientProvider>
	);
};
export default ContractAuditDetailsContainer;
