import React from 'react';
import {
	AppBar,
	Box,
	Button,
	Container,
	CssBaseline,
	Drawer,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Paper,
	StyledEngineProvider,
	Toolbar,
	Typography
} from '@mui/material';
import { Theme, ThemeProvider } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Home from '@mui/icons-material/Home';
import AccountBox from '@mui/icons-material/AccountBox';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Report from '@mui/icons-material/Report';
import logo from '../assets/images/administierbar-logo.svg';
import theme from './Prototypes.theme';

declare module '@mui/styles' {
	// eslint-disable-next-line @typescript-eslint/no-empty-interface
	interface DefaultTheme extends Theme {}
}

const useStyles = makeStyles({
	logo: {
		marginLeft: '-10px',
		marginRight: '20px',
		height: '60px',
		width: '60px',
		padding: '5px'
	}
});

export const PrototypeHomeView = (): JSX.Element => {
	const classes = useStyles();

	return (
		<React.Fragment>
			{/* CssBaseline applies a collection of HTML element and attribute style-normalizations.
				Read more about it at https://material-ui.com/components/css-baseline/ */}
			<CssBaseline />
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={theme}>
					{/* TOP BAR (TopNavigationView) */}
					<AppBar position="fixed" style={{ userSelect: 'none' }}>
						<Toolbar>
							<img src={logo} alt="AdministrierBar Logo" className={classes.logo} />
							<Typography variant="h4" style={{ flexGrow: 1 }}>
								AdministrierBar
							</Typography>
							<Button color="inherit">Ausloggen</Button>
						</Toolbar>
					</AppBar>

					{/* PART BELOW TOP BAR */}
					<Box display="flex" flexDirection="row">
						{/* NAVIGATION MENU (SideNavigationView) */}
						<Drawer variant="permanent">
							<List>
								<ListItemButton
									sx={{
										backgroundColor: 'action.selected'
									}}
								>
									<ListItemIcon>
										<Home />
									</ListItemIcon>
									<ListItemText primary="Start" />
								</ListItemButton>
								<ListItemButton>
									<ListItemIcon>
										<AccountBox />
									</ListItemIcon>
									<ListItemText primary="Benutzer" />
								</ListItemButton>
								<ListItemButton
									sx={{
										paddingLeft: 4
									}}
								>
									<ListItemIcon>
										<AccountCircle />
									</ListItemIcon>
									<ListItemText primary="Mein Profil" />
								</ListItemButton>
								<ListItemButton>
									<ListItemIcon>
										<Report />
									</ListItemIcon>
									<ListItemText primary="Gemeldete Beiträge" />
								</ListItemButton>
							</List>
						</Drawer>

						{/* MAIN VIEW */}
						<Box
							component="main"
							sx={{
								marginTop: 8,
								padding: 2,
								flexGrow: 1,
								minWidth: 0
							}}
						>
							<Container fixed maxWidth="md">
								<Paper>Willkommen zur AdministrierBar!</Paper>
							</Container>
						</Box>
					</Box>
				</ThemeProvider>
			</StyledEngineProvider>
		</React.Fragment>
	);
};
