import { Auth0Client } from '@auth0/auth0-spa-js';
import { BaseError } from '../../../shared/errors/Errors';

export enum AuthStatus {
	UNKNOWN,
	AUTHENTICATING,
	AUTHENTICATED,
	SIGNED_OUT,
	ERROR
}

export interface AuthState {
	auth0Client: Auth0Client | null;
	authStatus: AuthStatus;
	error: BaseError | null;
}

export const initialAuthState: AuthState = {
	auth0Client: null,
	authStatus: AuthStatus.UNKNOWN,
	error: null
};
