import React from 'react';
import { ActionButtonProps } from './ButtonProps';
import BaseButton from './BaseButton.view';

function CancelButton(props: ActionButtonProps): JSX.Element {
	return (
		<BaseButton variant="text" {...props}>
			{props.children}
		</BaseButton>
	);
}

export default CancelButton;
