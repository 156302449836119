import React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import PacmanLoader from 'react-spinners/PacmanLoader';
import { Box } from '@mui/system';

export interface Props {
	size?: number;
	color?: string;
	loading?: boolean;
}

function MainLoadingIndicator(props: Props): JSX.Element {
	const theme: Theme = useTheme();
	const { loading } = props;
	let { size, color } = props;
	size = size ? size : 20; // default 20
	color = color ? color : theme.palette.primary.main; // default color
	return (
		<Box
			sx={{
				margin: 0,
				position: 'absolute',
				top: '50%',
				left: '50%',
				transform: 'translate(-50%, -50%)'
			}}
		>
			<PacmanLoader size={size} color={color} loading={loading} />
		</Box>
	);
}
export default MainLoadingIndicator;
