import React, { FC } from 'react';
import { Badge, Switch } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { GeoArea } from '../types';

const useStyles = makeStyles(() => ({
	root: {
		paddingLeft: 3,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		textAlign: 'left',
		'& .MuiBadge-root': {
			marginRight: 8,
			marginTop: -2
		}
	}
}));

type TreeNodeLabelProps = {
	geoAreaId: GeoArea['id'];
	geoAreaName: GeoArea['name'];
	toggleIsChecked: boolean;
	showToggle: boolean;
	onClick: () => void;
};

const TreeNodeLabel: FC<TreeNodeLabelProps> = ({
	geoAreaId,
	geoAreaName,
	toggleIsChecked,
	showToggle,
	onClick
}: TreeNodeLabelProps) => {
	const classes = useStyles();

	const showIndicator = false;

	const handleClick: React.MouseEventHandler = (event): void => {
		event.stopPropagation();
		onClick();
	};

	return (
		<div id={geoAreaId} className={classes.root} onClick={handleClick}>
			<span>
				{showIndicator && <Badge badgeContent={''} variant="dot" color={'secondary'} />}
				{geoAreaName}
			</span>

			{showToggle && (
				<span>
					<Switch
						color={'primary'}
						checked={toggleIsChecked}
						size={'small'}
						title={`Unterebenen von ${geoAreaName} darstellen`}
					/>
				</span>
			)}
		</div>
	);
};

export default TreeNodeLabel;
