import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PlatformAdminUIState, PlatformAdminUIThunkType } from '../../../store/store';
import { RouteComponentProps } from 'react-router-dom';
import {
	ClientGeoAreaExtended,
	ClientGroupCreateByAdminRequest,
	ClientGroupExtendedAccessibilityEnum,
	ClientGroupExtendedContentVisibilityEnum,
	ClientPersonExtended
} from '@DigitaleDoerfer/digitale-doerfer-api/models';
import { BaseError } from '../../../shared/errors/Errors';
import MainLoadingIndicator from '../../../shared/views/LoadingIndicators/MainLoadingIndicator.view';
import { GROUPS_URL } from '../GroupRouting.container';
import { groupCreateByAdmin } from '../store/Group.actions';
import GroupCreateView from './GroupCreate.view';
import { GroupActionTypes } from '../store/GroupActionTypes';
import { getGeoAreas } from '../../../store/Global.actions';
import { AnyGeoArea } from '../../../shared/utils/AnyGeoArea';
import { geoAreaService, participantsAdminuiPersonApi } from '../../../ServiceFactory';
import queryString from 'query-string';
import {
	showSnackbar,
	SnackbarAction,
	SnackbarType
} from '../../../shared/views/SnackbarNotification/store/SnackbarNotification.actions';
import { GeoAreaElement } from '../../../shared/utils/GeoAreaElements';

/**
 * Returns list of geoAreas Ids based on the next conditions:
 * 1. Those geoAreas that has assigned users.
 * 2. Those geoAreas that are manually selected.
 * 2.1. Those geoAreas that has children.
 * Filter out those repeated geoAreas ids.
 * @param groupMembershipAdminGeoAreas GeoAreaElements based on the selected administrators.
 * @param manualSearchedGeoAreas GeoArea Elements based on manually search and selection.
 */
export function getSelectedGeoAreasIds(
	groupMembershipAdminGeoAreas: GeoAreaElement[],
	manualSearchedGeoAreas: GeoAreaElement[]
): string[] {
	const mergeGeoAreasList = groupMembershipAdminGeoAreas.concat(manualSearchedGeoAreas);
	let geoAreasIds: string[] = [];
	mergeGeoAreasList.forEach(geoAreaElement => {
		const allGeoAreaChildrenSelected = geoAreaElement.geoAreaChildren.every(geoArea => geoArea.manualSelected);
		if (allGeoAreaChildrenSelected) {
			geoAreaElement?.geoAreaParent?.id && geoAreasIds.push(geoAreaElement.geoAreaParent.id);
		} else {
			geoAreaElement.geoAreaChildren.forEach(geoAreaChild => {
				if (geoAreaChild.users.length > 0 || geoAreaChild.manualSelected) {
					geoAreasIds.push(geoAreaChild.geoArea.id);
				}
			});
		}
	});

	// Filter out those repeated geoAreas ids.
	geoAreasIds = geoAreasIds.filter((geoAreaId, index) => geoAreasIds.indexOf(geoAreaId) === index);
	return geoAreasIds;
}

const getGeoAreasElementsByGroupMembershipAdmins = (
	geoAreas: AnyGeoArea[],
	groupMembershipAdmins: ClientPersonExtended[]
): GeoAreaElement[] => {
	let groupMembershipAdminGeoAreas: GeoAreaElement[] = [];

	for (const groupAdmin of groupMembershipAdmins) {
		groupMembershipAdminGeoAreas = geoAreaService().updatesGeoAreaElementsByPerson(
			groupMembershipAdminGeoAreas,
			geoAreas,
			groupAdmin
		);
	}
	return groupMembershipAdminGeoAreas;
};

const getClientGroupExtendedAccessibilityEnum = (
	groupType: string | null
): ClientGroupExtendedAccessibilityEnum | null => {
	switch (groupType) {
		case ClientGroupExtendedAccessibilityEnum.PUBLIC:
			return ClientGroupExtendedAccessibilityEnum.PUBLIC;
		case ClientGroupExtendedAccessibilityEnum.APPROVALREQUIRED:
			return ClientGroupExtendedAccessibilityEnum.APPROVALREQUIRED;
		default:
			return null;
	}
};

const getClientGroupExtendedContentVisibilityEnum = (
	groupVisibility: string | null
): ClientGroupExtendedContentVisibilityEnum | null => {
	switch (groupVisibility) {
		case ClientGroupExtendedContentVisibilityEnum.ANYONE:
			return ClientGroupExtendedContentVisibilityEnum.ANYONE;
		case ClientGroupExtendedContentVisibilityEnum.MEMBERS:
			return ClientGroupExtendedContentVisibilityEnum.MEMBERS;
		case ClientGroupExtendedContentVisibilityEnum.SAMEHOMEAREA:
			return ClientGroupExtendedContentVisibilityEnum.SAMEHOMEAREA;
		default:
			return null;
	}
};

async function getGroupAdminstByAdminsEmails(groupAdminsEmails: string[]): Promise<[ClientPersonExtended[], string[]]> {
	const groupMembershipAdmins: ClientPersonExtended[] = [];
	const groupAdminsEmailsNotFound: string[] = [];
	for (const groupAdminEmail of groupAdminsEmails) {
		const response = await participantsAdminuiPersonApi().getPersonsUsingGET({
			search: groupAdminEmail
		});
		if (response && response.content && response.content.length > 0) {
			const responseList: ClientPersonExtended[] = response.content;
			groupMembershipAdmins.push(responseList[0]);
		} else {
			groupAdminsEmailsNotFound.push(groupAdminEmail);
		}
	}
	return [groupMembershipAdmins, groupAdminsEmailsNotFound];
}

enum AttachGeoAreaEnum {
	HOME = 'HOME',
	PARENT = 'PARENT'
}
export interface PreFilledState {
	groupName: string;
	groupShortName: string;
	groupType: ClientGroupExtendedAccessibilityEnum | null;
	groupContentVisibility: boolean;
	groupVisibility: ClientGroupExtendedContentVisibilityEnum | null;
	groupMembershipAdmins: ClientPersonExtended[];
	groupMembershipAdminGeoAreas: GeoAreaElement[];
	groupSelectedTenantId: string | null;
}

interface Props extends RouteComponentProps {
	loading: boolean;
	error: BaseError | null;
	groupHasBeenCreated: boolean;
	geoAreas: ClientGeoAreaExtended[];
	showSnackbar: (snackbarAction: SnackbarAction) => void;
	getGeoAreas: () => void;
	createGroup: (groupData: ClientGroupCreateByAdminRequest) => void;
}

interface State {
	flattenedGeoAreas: AnyGeoArea[];
	prefilledState?: PreFilledState;
	processingParams: boolean;
	geoAreaLoading: boolean;
}

const initialState: State = {
	flattenedGeoAreas: [],
	prefilledState: undefined,
	processingParams: false,
	geoAreaLoading: false
};

class GroupCreateContainer extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = initialState;
		this.dispatchCreateGroup = this.dispatchCreateGroup.bind(this);
	}

	showError(groupAdminsEmailsNotFound: string[], invalidParms: string[]): void {
		const { showSnackbar } = this.props;
		let error = '';
		// Prepare error messages
		if (groupAdminsEmailsNotFound.length > 0) {
			error +=
				groupAdminsEmailsNotFound.length === 1
					? `Kein Benutzer mit E-Mail ${groupAdminsEmailsNotFound[0]} gefunden.`
					: `Keine Benutzer mit E-Mail ${groupAdminsEmailsNotFound.join(', ')} gefunden.`;
		}
		error +=
			invalidParms.length > 0
				? `${error ? '\n' : ''} Folgende(r) URL-Parameter konnte(n) nicht ausgewertet werden: ${invalidParms.join(
						', '
				  )}`
				: '';

		if (error) {
			showSnackbar({
				type: SnackbarType.SNACKBAR_ERROR,
				message: error
			});
		}
	}

	/**
	 * Process the params to fulfill the fields with them
	 */
	async processParams(): Promise<void> {
		const { location, geoAreas } = this.props;
		const { search } = location;
		const { name, shortname, visibility, accessibility, contentVisibility, attachTo, groupAdmins } = queryString.parse(
			search,
			{
				decode: true
			}
		);
		// if we have at least one valid param
		if (name || shortname || visibility || accessibility || contentVisibility || attachTo || groupAdmins) {
			this.setState({ processingParams: true });
			const invalidParams: string[] = [];
			let groupMembershipAdmins: ClientPersonExtended[] = [];
			let groupAdminsEmailsNotFound: string[] = [];
			let groupVisibility = null;
			let groupType = null;
			let groupContentVisibility = false;
			let groupMembershipAdminGeoAreas: GeoAreaElement[] = [];
			let groupAdminsEmails: string[] = [];
			let groupName = '';
			let groupShortName = '';
			let groupSelectedTenantId = null;

			if (typeof name === 'string') {
				groupName = name;
			} else {
				name && invalidParams.push(Object.keys({ name })[0]);
			}

			if (typeof shortname === 'string' && shortname.length <= 2) {
				groupShortName = shortname;
			} else {
				shortname && invalidParams.push(Object.keys({ shortname })[0]);
			}

			if (typeof visibility === 'string' && getClientGroupExtendedContentVisibilityEnum(visibility)) {
				groupVisibility = getClientGroupExtendedContentVisibilityEnum(visibility);
			} else {
				visibility && invalidParams.push(Object.keys({ visibility })[0]);
			}

			if (typeof accessibility === 'string' && getClientGroupExtendedAccessibilityEnum(accessibility)) {
				groupType = getClientGroupExtendedAccessibilityEnum(accessibility);
			} else {
				accessibility && invalidParams.push(Object.keys({ accessibility })[0]);
			}

			if (typeof contentVisibility === 'string' && getClientGroupExtendedContentVisibilityEnum(contentVisibility)) {
				groupContentVisibility = contentVisibility === ClientGroupExtendedContentVisibilityEnum.ANYONE ? true : false;
			} else {
				contentVisibility && invalidParams.push(Object.keys({ contentVisibility })[0]);
			}

			if (typeof groupAdmins === 'string' && groupAdmins) {
				groupAdminsEmails = groupAdmins.split(',').map(email => email.trim());
			}

			[groupMembershipAdmins, groupAdminsEmailsNotFound] = await getGroupAdminstByAdminsEmails(groupAdminsEmails);
			groupMembershipAdminGeoAreas = getGeoAreasElementsByGroupMembershipAdmins(geoAreas, groupMembershipAdmins);

			if (typeof attachTo === 'string' && attachTo in AttachGeoAreaEnum) {
				// When attachTo is equals to PARENT we need to select all children of the first geoArea element
				if (attachTo === AttachGeoAreaEnum.PARENT && groupMembershipAdminGeoAreas.length > 0) {
					groupMembershipAdminGeoAreas[0].geoAreaChildren = groupMembershipAdminGeoAreas[0].geoAreaChildren.map(
						geoAreaChild =>
							geoAreaChild.users.length > 0 ? { ...geoAreaChild } : { ...geoAreaChild, manualSelected: true }
					);
				}
			}

			if (groupMembershipAdmins.length > 0) {
				groupSelectedTenantId = groupMembershipAdmins[0].homeAreaTenantId
					? groupMembershipAdmins[0].homeAreaTenantId
					: null;
			}

			this.showError(groupAdminsEmailsNotFound, invalidParams);

			const prefilledGroupInfo: PreFilledState = {
				groupName,
				groupShortName,
				groupContentVisibility,
				groupMembershipAdminGeoAreas,
				groupMembershipAdmins,
				groupType,
				groupVisibility,
				groupSelectedTenantId
			};
			this.setState({ prefilledState: prefilledGroupInfo, processingParams: false });
		}
	}

	componentDidMount(): void {
		const { geoAreas, getGeoAreas } = this.props;
		if (geoAreas.length === 0) {
			this.setState({ geoAreaLoading: true });
			getGeoAreas();
		} else {
			this.updateAutocompleteGeoAreaOptions(geoAreas);
		}
	}

	componentDidUpdate(prevProps: Props): void {
		const { geoAreas } = this.props;
		if (prevProps.geoAreas !== geoAreas) {
			this.updateAutocompleteGeoAreaOptions(geoAreas);
			if (geoAreas.length > 0) {
				this.processParams();
				this.setState({ geoAreaLoading: false });
			}
		}
	}

	updateAutocompleteGeoAreaOptions(geoAreas: ClientGeoAreaExtended[]): void {
		const flattenedGeoAreas = geoAreaService().flatten(geoAreas);
		this.setState({ flattenedGeoAreas });
	}

	dispatchCreateGroup(groupData: ClientGroupCreateByAdminRequest): void {
		const { createGroup } = this.props;
		createGroup(groupData);
	}

	cancelHandler = (): void => {
		this.props.history.push(GROUPS_URL);
	};

	render(): JSX.Element {
		const { loading, groupHasBeenCreated, geoAreas } = this.props;
		const { flattenedGeoAreas, prefilledState, processingParams, geoAreaLoading } = this.state;

		return (
			<>
				<MainLoadingIndicator loading={loading || processingParams || geoAreaLoading} />
				{flattenedGeoAreas.length > 0 && geoAreas.length > 0 && !processingParams && !geoAreaLoading && !loading && (
					<GroupCreateView
						loading={loading}
						groupHasBeenCreated={groupHasBeenCreated}
						geoAreas={geoAreas}
						flattenedGeoAreas={flattenedGeoAreas}
						prefilledState={prefilledState}
						cancelHandler={this.cancelHandler}
						dispatchCreateGroup={this.dispatchCreateGroup}
					/>
				)}
			</>
		);
	}
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: PlatformAdminUIState) => {
	return {
		loading: state.group.create.loading,
		error: state.group.create.error,
		geoAreas: state.global.geoAreasData.geoAreas,
		groupHasBeenCreated: state.group.create.groupHasBeenCreated
	};
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch: PlatformAdminUIThunkType<GroupActionTypes>) => {
	return {
		showSnackbar: (snackbarAction: SnackbarAction): Promise<void> => dispatch(showSnackbar(snackbarAction)),
		createGroup: (groupData: ClientGroupCreateByAdminRequest): Promise<void> => dispatch(groupCreateByAdmin(groupData)),
		getGeoAreas: (): Promise<void> => dispatch(getGeoAreas())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupCreateContainer);
