import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
	ClientExternalPostDeleteConfirmation,
	ClientNewsSource,
	PageOfClientNewsSource,
	PageOfClientPost
} from '@DigitaleDoerfer/digitale-doerfer-api/models';
import {
	showSnackbar,
	showSnackbarError,
	SnackbarType
} from '../../shared/views/SnackbarNotification/store/SnackbarNotification.actions';
import { GetAllExternalPostsByNewsSourceUsingGETPostTypesEnum as PostTypeEnum } from '@DigitaleDoerfer/digitale-doerfer-api/apis';
import { grapevineAdminuiNewssourceApi, grapevineAdminuiPostEventApi, grapevinePostApi } from '../../ServiceFactory';
import ExternalPostsView from './ExternalPosts.view';
import Pagination from '@mui/material/Pagination';
import { PlatformAdminUIThunkType } from '../../store/store';
import { ShowSnackbar } from '../../shared/views/SnackbarNotification/store/SnackbarNotificationActionTypes';

interface ExternalPostType {
	name: string;
	postType?: PostTypeEnum;
}

interface Pagination {
	page: number;
	rowsPerPage: number;
	totalElements: number;
	totalPages: number;
}

function ExternalPostsContainer(): JSX.Element {
	const dispatch = useDispatch<PlatformAdminUIThunkType<ShowSnackbar>>();
	const [externalPostsType, setExternalPostsType] = useState<ExternalPostType[]>([]);

	const initialPaginationParams = {
		page: 0,
		rowsPerPage: 9,
		totalElements: 0,
		totalPages: 0
	};

	const [pagination, setPagination] = useState<Pagination>(initialPaginationParams);
	const [pageIndex, setPageIndex] = useState<number>(0);
	const [searchString, setSearchString] = useState<string | undefined>('Initial Search');
	const [pageOfClientNewsSource, setPageOfClientNewsSource] = useState<ClientNewsSource[]>([]);
	const [IndividualclientNews, setIndividualclientNews] = useState<PageOfClientPost>();
	const [selectedPostTypes, setSelectedPostTypes] = useState<string | undefined>('Alles');
	const [clientExternalPostDeleteConfirmation, setclientExternalPostDeleteConfirmation] =
		useState<ClientExternalPostDeleteConfirmation>();
	const [loading, setLoading] = useState(true);

	const getProcessedPostType = (ep: string | undefined): PostTypeEnum | undefined => {
		if (ep === 'Alles') {
			return undefined;
		}
		if (ep === 'NEWS_ITEM') {
			return PostTypeEnum.NEWSITEM;
		}
		if (ep === 'HAPPENING') {
			return PostTypeEnum.HAPPENING;
		}
		if (ep === 'GOSSIP') {
			return PostTypeEnum.GOSSIP;
		}
		if (ep === 'OFFER') {
			return PostTypeEnum.OFFER;
		}
		if (ep === 'SEEKING') {
			return PostTypeEnum.SEEKING;
		}
		if (ep === 'SPECIAL_POST') {
			return PostTypeEnum.SEEKING;
		}
		if (ep === 'SUGGESTION') {
			return PostTypeEnum.SUGGESTION;
		}
	};

	const handleChangePostType = (newPostType: string): void => {
		setPageIndex(initialPaginationParams.page);
		if (!newPostType) {
			return;
		}
		setSelectedPostTypes(newPostType);
	};

	const handleChangePage = (event: React.ChangeEvent<unknown>, newPage: number): void => {
		// Check if new page is the first or last page
		if (event) {
			event.preventDefault();
		}
		if (newPage === -1) {
			return;
		}
		// Scroll to the top of the page
		window.scrollTo(0, 0);
		setPageIndex(newPage - 1);
	};

	const handleSearch = async (searchString: string): Promise<void> => {
		setSearchString(searchString);
		setPageIndex(initialPaginationParams.page);
	};

	const handleDelete = async (PostID: string | undefined): Promise<void> => {
		try {
			const clientExternalPostDeleteConfirmation: ClientExternalPostDeleteConfirmation =
				await grapevineAdminuiPostEventApi().externalPostDeleteRequestUsingPOST({
					request: { postId: PostID }
				});
			setclientExternalPostDeleteConfirmation(clientExternalPostDeleteConfirmation);
			dispatch(
				showSnackbar({
					type: SnackbarType.SNACKBAR_SUCCESS,
					message: 'Der Beitrag wird gelöscht.'
				})
			);
		} catch (error) {
			dispatch(showSnackbarError(error));
		}
	};

	useEffect(() => {
		const externalPostList = [
			{ name: 'Alles', postType: 'Alles' },
			{ name: 'Neuigkeiten', postType: 'NEWS_ITEM' },
			{ name: 'Veranstaltungen', postType: 'HAPPENING' }
		];
		const getProccesedExternalPosts = (): ExternalPostType[] => {
			return externalPostList.map(ep => {
				switch (ep.postType) {
					case 'Alles':
						return { name: 'Alles', postType: undefined };
					case PostTypeEnum.HAPPENING:
						return { name: ep.name, postType: PostTypeEnum.HAPPENING };
					case PostTypeEnum.NEWSITEM:
						return { name: ep.name, postType: PostTypeEnum.NEWSITEM };
					// Next cases are for future updates
					case PostTypeEnum.SUGGESTION:
						return { name: ep.name, postType: PostTypeEnum.SUGGESTION };
					case PostTypeEnum.GOSSIP:
						return { name: ep.name, postType: PostTypeEnum.GOSSIP };
					case PostTypeEnum.OFFER:
						return { name: ep.name, postType: PostTypeEnum.OFFER };
					case PostTypeEnum.SEEKING:
						return { name: ep.name, postType: PostTypeEnum.SEEKING };
					default:
						return { name: 'Nicht definiert', postType: undefined };
				}
			});
		};

		const extPosts = getProccesedExternalPosts();
		setExternalPostsType(extPosts);
		const getExternalPosts = async (): Promise<void> => {
			try {
				const pageOfClientNewsSourceTemp: PageOfClientNewsSource =
					await grapevineAdminuiNewssourceApi().getNewsSourcesUsingGET({});

				const pageOfClientNewsSource: PageOfClientNewsSource =
					await grapevineAdminuiNewssourceApi().getNewsSourcesUsingGET({
						count: pageOfClientNewsSourceTemp.totalElements
					});

				if (pageOfClientNewsSource.content) {
					setPageOfClientNewsSource(pageOfClientNewsSource.content);
				}
			} catch (error) {
				// handle error
			}
			setLoading(false);
		};
		getExternalPosts();
	}, []);

	useEffect(() => {
		const initialPaginationParams = {
			page: 0,
			rowsPerPage: 9,
			totalElements: 0,
			totalPages: 0
		};
		const getIndividualclientNews = async (): Promise<void> => {
			const page = pageIndex ?? initialPaginationParams.page;
			const rowsPerPage = initialPaginationParams.rowsPerPage;
			const IndividualclientNews: PageOfClientPost = await grapevinePostApi().getAllExternalPostsByNewsSourceUsingGET({
				postTypes: getProcessedPostType(selectedPostTypes),
				page,
				newsSourceId: searchString,
				count: rowsPerPage
			});
			if (IndividualclientNews.totalPages && IndividualclientNews.content?.length !== 0) {
				const paginationResponse: Pagination = {
					page: IndividualclientNews?.pageable?.pageNumber ?? initialPaginationParams.page,
					rowsPerPage: initialPaginationParams.rowsPerPage,
					totalElements: IndividualclientNews.totalElements ?? initialPaginationParams.totalElements,
					totalPages: IndividualclientNews?.totalPages ?? initialPaginationParams.totalPages
				};
				setPagination(paginationResponse);
			} else {
				setPagination(initialPaginationParams);
			}
			setIndividualclientNews(IndividualclientNews);
		};
		getIndividualclientNews();
	}, [searchString, pageIndex, selectedPostTypes, clientExternalPostDeleteConfirmation]);

	return (
		<>
			<ExternalPostsView
				loading={loading}
				externalPosts={IndividualclientNews}
				externalPostsTypes={externalPostsType}
				pageOfClientNewsSource={pageOfClientNewsSource}
				selectedPostTypes={selectedPostTypes}
				handleChangePostType={handleChangePostType}
				pagination={pagination}
				handleChangePage={handleChangePage}
				handleSearch={handleSearch}
				handleDelete={handleDelete}
			/>
		</>
	);
}

export default ExternalPostsContainer;
