import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import logo from '../../../assets/images/administierbar-logo.svg';

interface Props {
	title: string;
	logout: () => void;
}

const useStyles = makeStyles(() => ({
	logoStyle: {
		marginLeft: '-10px',
		marginRight: '20px',
		height: '60px',
		width: '60px',
		padding: '5px'
	},
	mainTitle: {
		flexGrow: 1,
		fontStyle: 'normal',
		fontWeight: 500,
		fontSize: '28px',
		lineHeight: '37px',
		letterSpacing: '0.02em',
		color: '#FFFFFF'
	}
}));

export const TopNavigationView = (props: Props): JSX.Element => {
	const classes = useStyles();
	const { title } = props;

	function onLogout(e: React.MouseEvent): void {
		e.preventDefault();
		const { logout } = props;
		logout();
	}

	return (
		<>
			<AppBar position="fixed" style={{ userSelect: 'none' }}>
				<Toolbar>
					<img src={logo} alt="AdministrierBar Logo" className={classes.logoStyle} />
					<Typography className={classes.mainTitle}>{title}</Typography>
					<Button color="inherit" onClick={onLogout}>
						Ausloggen
					</Button>
				</Toolbar>
			</AppBar>
		</>
	);
};
