import { Auth0Client, createAuth0Client } from '@auth0/auth0-spa-js';
import { ThunkAction } from 'redux-thunk';
import { BACKEND_URL, CLIENT_ID, DOMAIN, LOGOUT_URL } from '../../../shared/utils/AuthSettings';
import DigitaleDoerferAPIFactory from '../../../shared/utils/DigitaleDoerferAPIFactory';
import { AppState } from '../App.state';
import { BaseError } from '../../../shared/errors/Errors';
import { wrapIntoErrorObject } from '../../../shared/errors/ErrorWrapper';
import { PlatformAdminUIState, PlatformAdminUIThunkType } from '../../../store/store';

export const AUTH0_FAILURE = 'AUTH0_FAILURE';

export const AUTH0_CLIENT_CREATED = 'AUTH0_CLIENT_CREATED';
export const AUTH0_LOGIN_INITIATED = 'AUTH0_LOGIN_INITIATED';
export const AUTH0_LOGIN_FINISHED = 'AUTH0_LOGIN_FINISHED';
export const AUTH0_LOGOUT_FINISHED = 'AUTH0_LOGOUT_FINISHED';

export interface Auth0FailureAction {
	type: typeof AUTH0_FAILURE;
	error: BaseError;
}

export interface Auth0ClientCreatedAction {
	type: typeof AUTH0_CLIENT_CREATED;
	auth0Client: Auth0Client;
}

export interface Auth0LoginInitiatedAction {
	type: typeof AUTH0_LOGIN_INITIATED;
}

export interface Auth0LoginFinishedAction {
	type: typeof AUTH0_LOGIN_FINISHED;
}

export interface Auth0LogoutFinishedAction {
	type: typeof AUTH0_LOGOUT_FINISHED;
}

export type AuthActionTypes =
	| Auth0FailureAction
	| Auth0ClientCreatedAction
	| Auth0LoginInitiatedAction
	| Auth0LoginFinishedAction
	| Auth0LogoutFinishedAction;

export type ThunkResult<R> = ThunkAction<R, PlatformAdminUIState, undefined, AuthActionTypes>;

export function auth0Failure(error: BaseError): Auth0FailureAction {
	return {
		type: AUTH0_FAILURE,
		error
	};
}

export function createAuth0ClientSuccessAction(auth0Client: Auth0Client): Auth0ClientCreatedAction {
	return {
		type: AUTH0_CLIENT_CREATED,
		auth0Client
	};
}

async function getAuth0Client(
	dispatch: PlatformAdminUIThunkType<AuthActionTypes>,
	getState: () => PlatformAdminUIState
): Promise<Auth0Client> {
	const state = getState();
	const { auth0Client } = state.auth;
	if (!auth0Client) {
		const newAuth0Client = await createAuth0Client({
			domain: DOMAIN,
			clientId: CLIENT_ID,
			authorizationParams: {
				audience: BACKEND_URL
			}
		});
		dispatch(createAuth0ClientSuccessAction(newAuth0Client));
		return newAuth0Client;
	}
	return auth0Client;
}

export function initiateLoginAction(appState: AppState): ThunkResult<Promise<void>> {
	return async (
		dispatch: PlatformAdminUIThunkType<AuthActionTypes>,
		getState: () => PlatformAdminUIState
	): Promise<void> => {
		try {
			const auth0Client = await getAuth0Client(dispatch, getState);
			const isAuthenticated = await auth0Client.isAuthenticated();
			if (isAuthenticated) {
				DigitaleDoerferAPIFactory.createMiddleware(auth0Client);
				dispatch({
					type: AUTH0_LOGIN_FINISHED
				});
				return;
			}
			await auth0Client.loginWithRedirect({
				authorizationParams: {
					redirect_uri: window.location.origin
				},
				appState
			});
			dispatch({
				type: AUTH0_LOGIN_INITIATED
			});
		} catch (error) {
			dispatch(auth0Failure(wrapIntoErrorObject(error)));
		}
	};
}

export function finishLoginAction(): ThunkResult<Promise<AppState>> {
	// TODO check if we can void any here
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return async (dispatch, getState): Promise<any> => {
		try {
			const auth0Client = await getAuth0Client(dispatch, getState);
			const result = await auth0Client.handleRedirectCallback();
			DigitaleDoerferAPIFactory.createMiddleware(auth0Client);
			dispatch({
				type: AUTH0_LOGIN_FINISHED
			});
			return result.appState;
		} catch (error) {
			dispatch(auth0Failure(wrapIntoErrorObject(error)));
		}
	};
}

export function logoutAction(): ThunkResult<Promise<void>> {
	return async (dispatch, getState): Promise<void> => {
		try {
			const auth0Client = await getAuth0Client(dispatch, getState);
			auth0Client.logout({
				clientId: CLIENT_ID,
				logoutParams: {
					returnTo: LOGOUT_URL
				}
			});
			dispatch({
				type: AUTH0_LOGOUT_FINISHED
			});
		} catch (error) {
			dispatch(auth0Failure(wrapIntoErrorObject(error)));
		}
	};
}
