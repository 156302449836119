import React from 'react';
import CommonButtonProps from './ButtonProps';
import BaseButton from './BaseButton.view';

function NavActionButton(props: CommonButtonProps): JSX.Element {
	return (
		<BaseButton color="primary" variant="contained" {...props}>
			{props.children}
		</BaseButton>
	);
}

export default NavActionButton;
