import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { AuthStatus } from './modules/auth/store/Auth.state';
import Typography from '@mui/material/Typography';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { StyledEngineProvider, Theme, ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import AuthErrorDialogView from './modules/auth/AuthErrorDialog.view';
import { AppState } from './modules/auth/App.state';
import NavigationView from './modules/navigation/Navigation.view';
import { BaseError } from './shared/errors/Errors';
import SnackbarNotification from './shared/views/SnackbarNotification/SnackbarNotification';
import MainLoadingIndicator from './shared/views/LoadingIndicators/MainLoadingIndicator.view';

declare module '@mui/styles' {
	// eslint-disable-next-line @typescript-eslint/no-empty-interface
	interface DefaultTheme extends Theme {}
}

interface Props extends RouteComponentProps, React.PropsWithChildren<object> {
	authStatus: AuthStatus;
	error: BaseError | null;
	shouldRenderUserMenu: boolean;
	shouldRenderFlaggedContentMenu: boolean;
	shouldRenderGroupMenu: boolean;
	shouldRenderConfigurationMenu: boolean;
	shouldRenderExternalContributionsMenu: boolean;
	shouldRenderStatisticsMenu: boolean;
	loading: boolean;
	initiateLogin: (appState: AppState) => void;
	finishLogin: () => Promise<AppState>;
	logout: () => void;
}

class PlatformAdminUIView extends React.Component<Props> {
	renderLoading(): JSX.Element {
		return <MainLoadingIndicator />;
	}

	renderError(error: BaseError): JSX.Element {
		return <AuthErrorDialogView error={error} />;
	}

	renderSignedOut(): JSX.Element {
		return <Typography>You have been logged out!</Typography>;
	}

	renderMain(): JSX.Element {
		const {
			logout,
			shouldRenderUserMenu,
			shouldRenderFlaggedContentMenu,
			shouldRenderGroupMenu,
			shouldRenderConfigurationMenu,
			shouldRenderExternalContributionsMenu,
			shouldRenderStatisticsMenu,
			children
		} = this.props;
		return (
			<NavigationView
				title="AdministrierBar"
				logout={logout}
				shouldRenderUserMenu={shouldRenderUserMenu}
				shouldRenderFlaggedContentMenu={shouldRenderFlaggedContentMenu}
				shouldRenderGroupMenu={shouldRenderGroupMenu}
				shouldRenderConfigurationMenu={shouldRenderConfigurationMenu}
				shouldRenderExternalContributionsMenu={shouldRenderExternalContributionsMenu}
				shouldRenderStatisticsMenu={shouldRenderStatisticsMenu}
			>
				<SnackbarNotification />
				{children}
			</NavigationView>
		);
	}

	renderContent(): JSX.Element {
		const { authStatus, error, loading } = this.props;
		if (authStatus === AuthStatus.ERROR && error) {
			return this.renderError(error);
		}
		if (authStatus === AuthStatus.SIGNED_OUT) {
			this.renderSignedOut();
		}
		if (authStatus !== AuthStatus.AUTHENTICATED || loading) {
			return this.renderLoading();
		}
		return this.renderMain();
	}

	render(): JSX.Element {
		return (
			<>
				{/* CssBaseline applies a collection of HTML element and attribute style-normalizations.
                Read more about it at https://material-ui.com/components/css-baseline/ */}
				<StyledEngineProvider injectFirst>
					<ThemeProvider theme={theme}>
						<CssBaseline />
						{this.renderContent()}
					</ThemeProvider>
				</StyledEngineProvider>
			</>
		);
	}
}

export default withRouter(PlatformAdminUIView);
