import React, { FC, useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { TreeView } from '@mui/lab';
import { ChevronRight as ChevronRightIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { App, Contract, ListType, Tenant, ValueOf } from '../../types';
import SelectAll from './SelectAll';
import TreeListNode from './TreeListNode';
import { FetchNextPageOptions, InfiniteData } from 'react-query';
import { useStore } from '../../store';

type TreeListProps = {
	isLoading: boolean;
	type: ValueOf<typeof ListType>;
	data?: InfiniteData<App[] | Tenant[] | Contract[]> | undefined;
	isFetchingNextPage: boolean;
	fetchNextPage: (options?: FetchNextPageOptions | undefined) => void;
	showSelectAll?: boolean;
	isTenantFilterSet?: boolean;
};

const TreeList: FC<TreeListProps> = ({
	type,
	isLoading,
	data,
	isFetchingNextPage,
	fetchNextPage,
	showSelectAll = false,
	isTenantFilterSet = false
}: TreeListProps) => {
	const [expandedAppVariants, setExpandedAppVariants] = useState<string[]>([]);
	const [isSelectAllCheckboxDisabled, setIsSelectAllCheckboxDisabled] = useState<boolean>(false);
	const { shouldUseAllContracts, selectContracts } = useStore();

	const handleScroll = (event: React.UIEvent<HTMLUListElement, UIEvent>): void => {
		if (isFetchingNextPage) return;
		const element = event.currentTarget;
		const isEndOfTheTreeListScroll = element.scrollHeight - element.scrollTop === element.clientHeight;
		if (isEndOfTheTreeListScroll) {
			fetchNextPage();
		}
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const handleToggle = (event: React.ChangeEvent<unknown>, nodeIds: string[]): void => {
		setExpandedAppVariants(nodeIds);
	};

	useEffect(() => {
		if (type === ListType.APP_VARIANT && isTenantFilterSet && data?.pages) {
			setExpandedAppVariants(oldExpanded => oldExpanded.concat(data.pages.flat().map(item => item.id) ?? []));
		}
	}, [isTenantFilterSet, data, type]);

	useEffect(() => {
		if (type === ListType.CONTRACT && shouldUseAllContracts && data?.pages) {
			selectContracts(data.pages.flat());
		}
	}, [shouldUseAllContracts, data, type, selectContracts]);

	return isLoading ? (
		<CircularProgress />
	) : (
		<>
			{showSelectAll && (
				<SelectAll
					type={type}
					isCheckboxDisabled={isSelectAllCheckboxDisabled}
					disableCheckbox={(): void => setIsSelectAllCheckboxDisabled(false)}
				/>
			)}

			<TreeView
				onScroll={handleScroll}
				aria-label={`list of ${type}`}
				defaultCollapseIcon={<ExpandMoreIcon />}
				defaultExpandIcon={<ChevronRightIcon />}
				expanded={expandedAppVariants}
				onNodeToggle={handleToggle}
				style={{ overflow: 'scroll' }}
			>
				{data?.pages?.map((items: Array<App | Tenant | Contract>, i) => {
					return (
						<React.Fragment key={i}>
							{items?.map((item: App | Tenant | Contract) => (
								<TreeListNode
									key={item.id}
									id={item.id}
									name={item.name}
									subItems={(item as App)?.subItems}
									type={type}
								/>
							))}
						</React.Fragment>
					);
				})}

				{isFetchingNextPage && type !== ListType.CONTRACT && <CircularProgress size={20} />}
			</TreeView>
		</>
	);
};

export default TreeList;
