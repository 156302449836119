import useFilterBar from '../../hooks/useFilterBar';
import { useStore } from '../../store';
import { App, AppVariant, Contract, Filter, FilterType, ListType, Tenant, TreeNodeItem, ValueOf } from '../../types';
import { InfiniteData, useQueryClient } from 'react-query';
import useSearch from '../../Search/hook/useSearch';

type UseTreeList = () => {
	onCheckboxClick: (nodeItem: TreeNodeItem<AppVariant | Tenant | Contract>) => void;
	onLabelClick: (nodeItem: TreeNodeItem<AppVariant | Tenant | Contract>) => void;
	isFilterSet: (filterType: ValueOf<typeof FilterType>, itemId: string) => boolean;
	isContractSelected: (contractId: string) => boolean;
	getFetchedPaginatedContracts: () => InfiniteData<Contract[]> | undefined;
	getFetchedContractsData: () => Contract[] | undefined;
};

const useTreeList: UseTreeList = () => {
	const { addFilter, removeFilter, addFilters, removeFilters, toggleSelectedContract, setSelectedListItem } =
		useStore();
	const { searchQueryAppVariant, searchQueryContract, searchQueryTenant } = useSearch();
	const { filters, filteredTenantIds, filteredAppVariantIds } = useFilterBar();
	const selectedContracts = useStore(state => state.selectedContracts);
	const queryClient = useQueryClient();

	const getFetchedApps = (): InfiniteData<App[]> | undefined =>
		queryClient.getQueryData<InfiniteData<App[]>>([
			ListType.APP_VARIANT,
			{ tenantIds: filteredTenantIds, search: searchQueryAppVariant }
		]);

	const getFetchedPaginatedContracts = (): InfiniteData<Contract[]> | undefined =>
		queryClient.getQueryData<InfiniteData<Contract[]>>([
			ListType.CONTRACT,
			{
				tenantIds: filteredTenantIds,
				appVariantIds: filteredAppVariantIds,
				search: searchQueryContract
			}
		]);

	const getAllFetchedContracts = (): Contract[] | undefined =>
		queryClient.getQueryData<Contract[]>([
			ListType.CONTRACT,
			{
				tenantIds: filteredTenantIds,
				appVariantIds: filteredAppVariantIds,
				search: searchQueryContract,
				allFlag: 'all'
			}
		]);

	const getFetchedContractsData = (): Contract[] | undefined => {
		const paginatedContracts = getFetchedPaginatedContracts();
		console.log(paginatedContracts?.pages.flat().length);
		return paginatedContracts?.pages.flat();
	};

	const getFetchedTenants = (): InfiniteData<Tenant[]> | undefined =>
		queryClient.getQueryData<InfiniteData<Tenant[]>>([
			ListType.TENANT,
			{ appVariantIds: filteredAppVariantIds, search: searchQueryTenant }
		]);

	const isApp = (nodeItem: TreeNodeItem<AppVariant>): App | undefined => {
		let foundApp;
		getFetchedApps()?.pages.forEach(apps => (foundApp = apps.find(app => app.id === nodeItem.id)));
		return foundApp;
	};

	const isFilterSet = (filterType: ValueOf<typeof FilterType>, itemId: string): boolean => {
		if (filterType === FilterType.APP_VARIANT) {
			let foundApp: App | undefined;
			getFetchedApps()?.pages.forEach(apps => (foundApp = apps.find(app => app.id === itemId)));
			if (foundApp) {
				return foundApp.subItems
					? foundApp.subItems?.every(appVariant => !!filters?.[filterType]?.[appVariant.id])
					: true;
			}
		}

		return !!filters?.[filterType]?.[itemId];
	};

	const handleAppVariantFilter = (nodeItem: TreeNodeItem<AppVariant>): void => {
		if (nodeItem.type !== FilterType.APP_VARIANT) {
			return;
		}

		const app = isApp(nodeItem);

		if (app) {
			if (!isFilterSet(nodeItem.type, nodeItem.id)) {
				addFilters(
					ListType.APP_VARIANT,
					app?.subItems?.map(({ id, name }) => ({ id, name, type: ListType.APP_VARIANT } as Filter<AppVariant>)) || []
				);
				addFilter({ ...nodeItem, isParentNode: true } as Filter<AppVariant>);
			} else {
				removeFilters(
					ListType.APP_VARIANT,
					app?.subItems?.map(({ id, name }) => ({ id, name, type: ListType.APP_VARIANT } as Filter<AppVariant>)) || []
				);
				removeFilter(nodeItem as Filter<AppVariant>);
			}
		} else {
			if (!isFilterSet(nodeItem.type, nodeItem.id)) {
				addFilter(nodeItem as Filter<AppVariant | Tenant>);
			} else {
				removeFilter(nodeItem as Filter<AppVariant | Tenant>);
			}
		}
	};

	const onCheckboxClick = (nodeItem: TreeNodeItem<AppVariant | Tenant | Contract>): void => {
		if (nodeItem.type === ListType.CONTRACT) {
			const foundContract = getFetchedContractsData()?.find(contract => contract.id === nodeItem.id);

			if (foundContract) {
				toggleSelectedContract(foundContract);
			}
		} else {
			if (nodeItem.type === ListType.APP_VARIANT) {
				handleAppVariantFilter(nodeItem as TreeNodeItem<AppVariant>);
			} else {
				if (!isFilterSet(nodeItem.type, nodeItem.id)) {
					addFilter(nodeItem as Filter<AppVariant | Tenant>);
				} else {
					removeFilter(nodeItem as Filter<AppVariant | Tenant>);
				}
			}
		}
	};

	const isContractSelected = (contractId: string): boolean => !!selectedContracts[contractId];

	// eslint-disable-next-line
	const onLabelClick = (nodeItem: TreeNodeItem<AppVariant | Tenant | Contract>): void => {
		const getSelectedItemsDetails = (): AppVariant | Tenant | Contract | undefined => {
			if (nodeItem.type === 'appVariant') {
				const apps = getFetchedApps()?.pages?.flat();
				if (apps) {
					for (const app of apps) {
						if (app.id === nodeItem.id) {
							return app;
						}
						const subapp = app.subItems?.find(subItem => subItem.id === nodeItem.id);
						if (subapp) {
							return subapp;
						}
					}
				}
			} else if (nodeItem.type === 'contract') {
				return getFetchedContractsData()?.find(contract => contract.id === nodeItem.id);
			} else if (nodeItem.type === 'tenant') {
				return getFetchedTenants()
					?.pages?.flat()
					?.find(tenant => tenant.id === nodeItem.id);
			}
		};

		const selectedItemsDetails = getSelectedItemsDetails();
		if (selectedItemsDetails) {
			setSelectedListItem(selectedItemsDetails, nodeItem.type);
		}
	};

	return {
		onCheckboxClick,
		onLabelClick,
		isFilterSet,
		isContractSelected,
		getFetchedPaginatedContracts,
		getAllFetchedContracts,
		getFetchedContractsData
	};
};

export default useTreeList;
