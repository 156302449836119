import React, { Component } from 'react';
import { TextField } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import { ActionMenuItemProps } from './ActionMenu';
import { matchesEmailPattern } from '../../utils/Util';
import DialogActionView from './DialogAction.view';

const emailValidationMessage = 'Verwende bitte eine gültige E-Mail-Adresse wie anna@beispiel.de';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-unused-vars
const styles = (theme: Theme) => {
	return createStyles({
		emailField: {
			overflowY: 'visible',
			height: 100
		}
	});
};

interface Props extends ActionMenuItemProps, WithStyles<typeof styles> {
	currentEmail: string;
	handleSave: (newEmail: string) => void;
}

interface State {
	newEmail: string;
	newEmailValid: boolean;
}

const initialState = (props: Props): State => ({
	newEmail: props.currentEmail,
	newEmailValid: true
});

class ChangeEmailActionView extends Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = initialState(props);
		this.handleOpenDialog = this.handleOpenDialog.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleSave = this.handleSave.bind(this);
		this.isSaveDisabled = this.isSaveDisabled.bind(this);
	}

	handleOpenDialog(): void {
		this.setState({ ...initialState(this.props) });
	}

	handleSave(): void {
		this.props.handleSave(this.state.newEmail);
	}

	handleChange(event: React.ChangeEvent<{ value: string }>): void {
		const newEmail = event.target.value;

		const newEmailValid = matchesEmailPattern(newEmail);

		this.setState({ newEmail, newEmailValid });
	}

	isSaveDisabled(): boolean {
		return !this.state.newEmailValid || this.props.currentEmail === this.state.newEmail;
	}

	render(): JSX.Element {
		const { renderMenuItem, classes } = this.props;
		const { newEmail, newEmailValid } = this.state;
		return (
			<DialogActionView
				itemProps={{
					renderMenuItem,
					icon: <AlternateEmailIcon color="primary" />,
					text: 'E-Mail bearbeiten'
				}}
				dialogProps={{
					titleText: 'E-Mail-Adresse bearbeiten',
					saveButtonText: 'Speichern',
					handleOpen: this.handleOpenDialog,
					handleSave: this.handleSave,
					handleIsSaveDisabled: this.isSaveDisabled
				}}
			>
				<TextField
					variant="standard"
					id="email"
					name="email"
					label="E-Mail-Adresse"
					type="email"
					value={newEmail}
					className={classes.emailField}
					onChange={this.handleChange}
					InputLabelProps={{
						shrink: true
					}}
					fullWidth
					autoFocus
					error={!newEmailValid}
					helperText={!newEmailValid ? emailValidationMessage : ''}
				/>
			</DialogActionView>
		);
	}
}

export default withStyles(styles)(ChangeEmailActionView);
