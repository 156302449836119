import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PlatformAdminUIState, PlatformAdminUIThunkType } from '../../../../store/store';
import ChangeNameActionView from './ChangeNameAction.view';
import {
	showSnackbar,
	SnackbarAction,
	SnackbarType
} from '../../../../shared/views/SnackbarNotification/store/SnackbarNotification.actions';
import { UserActionTypes } from '../../store/UserActionTypes';
import { ActionMenuItemProps } from '../../../../shared/views/ActionMenu/ActionMenu';
import {
	ClientPersonChangeNameByAdminRequest,
	ClientPersonExtended
} from '@DigitaleDoerfer/digitale-doerfer-api/models';
import { changeNameByAdmin } from '../../store/User.actions';

interface Props extends ActionMenuItemProps {
	currentFirstName?: string;
	currentLastName?: string;
	user: ClientPersonExtended | null;
	showSnackbar: (snackbarAction: SnackbarAction) => void;
	changeName: (changeNameRequestParams: ClientPersonChangeNameByAdminRequest) => void;
}

class ChangeNameActionContainer extends Component<Props> {
	constructor(props: Props) {
		super(props);

		this.handleSave = this.handleSave.bind(this);
	}

	handleSave(newFirstName: string, newLastName: string): void {
		const { user, showSnackbar, changeName } = this.props;
		const personId = user?.id;
		if (personId !== undefined) {
			changeName({
				firstName: newFirstName,
				lastName: newLastName,
				personId
			});
		} else {
			showSnackbar({
				type: SnackbarType.SNACKBAR_ERROR,
				message: 'Benutzer hat keine ID.'
			});
		}
	}

	render(): JSX.Element {
		const { currentFirstName, currentLastName, renderMenuItem } = this.props;
		return (
			<ChangeNameActionView
				renderMenuItem={renderMenuItem}
				currentFirstName={currentFirstName || ''}
				currentLastName={currentLastName || ''}
				handleSave={this.handleSave}
			/>
		);
	}
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: PlatformAdminUIState) => {
	return {
		currentFirstName: state.user.detail.user?.firstName,
		currentLastName: state.user.detail.user?.lastName,
		user: state.user.detail.user
	};
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch: PlatformAdminUIThunkType<UserActionTypes>) => {
	return {
		showSnackbar: (snackbarAction: SnackbarAction): Promise<void> => dispatch(showSnackbar(snackbarAction)),
		changeName: (changeNameRequestParams: ClientPersonChangeNameByAdminRequest): Promise<void> =>
			dispatch(changeNameByAdmin(changeNameRequestParams))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeNameActionContainer);
