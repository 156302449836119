import { ClientOauthAccount, ClientPersonExtended } from '@DigitaleDoerfer/digitale-doerfer-api/models';
import { BaseError } from '../../../shared/errors/Errors';
import { TableColumnSortingDirection, TableSorting } from '../../../shared/views/Table/TableSorting';
import { Pagination } from '../../../shared/services/InMemoryTable.service';

export interface RelatedEntity {
	id: string;
	name: string;
}

export enum EmptyField {
	NONE = 'NONE'
}

export interface ListSearchParams {
	text: string;
	role: string;
	sorting: TableSorting;
	pagination: Pagination;
}

export interface UserState {
	list: {
		users: ClientPersonExtended[];
		searchParams: ListSearchParams;
		loading: boolean;
		error: BaseError | null;
	};
	create: {
		userHasBeenCreated: boolean;
		loading: boolean;
		error: BaseError | null;
	};
	detail: {
		user: ClientPersonExtended | null;
		loading: boolean;
		loadingRoleAssignments: boolean;
	};
	auth0Detail: {
		auth0User: ClientOauthAccount;
		loading: boolean;
	};
}

export const initialUserState: UserState = {
	list: {
		users: [],
		searchParams: {
			text: '',
			role: EmptyField.NONE,
			pagination: {
				page: 0,
				rowsPerPage: 20,
				total: 0
			},
			sorting: { columnId: 'email', direction: TableColumnSortingDirection.ASC }
		},
		loading: false,
		error: null
	},
	create: {
		userHasBeenCreated: false,
		loading: false,
		error: null
	},
	detail: {
		user: null,
		loading: false,
		loadingRoleAssignments: false
	},
	auth0Detail: {
		auth0User: {},
		loading: false
	}
};
