import { TableColumnSortingDirection, TableSorting } from '../views/Table/TableSorting';
import { TableColumn } from '../views/Table/TableColumn';
import { nullSafeGermanStringAscComparator } from '../utils/Util';

export interface Pagination {
	page: number;
	rowsPerPage: number;
	total: number;
}

export class InMemoryTableService {
	applyFilter<T>(inputRows: readonly T[], searchCriteria: string, filterColumns: readonly TableColumn<T>[]): T[] {
		let ret = [...inputRows];

		if (searchCriteria) {
			const searchCriteriaWords = searchCriteria
				.split(/\s+/)
				.filter(word => word && word.trim())
				.map(searchCriteriaWord => searchCriteriaWord.toLowerCase());

			// find all entities that contain all the search terms in at least one of the filterKeys
			ret = ret.filter((row: T): boolean => {
				for (const searchCriteriaWord of searchCriteriaWords) {
					let containedInOneOfTheFilterPropertyValues = false;

					for (const filterColumn of filterColumns) {
						if (filterColumn.getCellStringValue(row).toLowerCase().match(searchCriteriaWord)) {
							containedInOneOfTheFilterPropertyValues = true;
							break;
						}
					}
					if (!containedInOneOfTheFilterPropertyValues) {
						return false;
					}
				}

				return true;
			});
		}

		return ret;
	}

	applySorting<T>(
		inputRows: T[],
		sorting: TableSorting,
		idToColumn: (columnId: string) => TableColumn<T> | undefined
	): T[] {
		const { columnId, direction } = sorting;
		let ret = [...inputRows];

		if (columnId) {
			const column = idToColumn(columnId);

			if (column) {
				ret = ret.sort(
					nullSafeGermanStringAscComparator(
						(row: T) => column.getCellStringValue(row),
						direction === TableColumnSortingDirection.DESC
					)
				);
			}
		}

		return ret;
	}

	applyPagination<T>(inputRows: T[], pagination: Pagination): T[] {
		const { page, rowsPerPage } = pagination;

		return inputRows.slice(page * rowsPerPage, (page + 1) * rowsPerPage);
	}
}
