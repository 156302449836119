import React from 'react';
import { ClientRoleAssignment, ClientRoleInformation } from '@DigitaleDoerfer/digitale-doerfer-api/models';
import { Grid, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import RoleAssignmentListView from './RoleAssignmentList.view';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const useStyles = makeStyles((theme: Theme) => ({
	gridTitle: {
		paddingTop: '0 !important',
		paddingBottom: '0 !important',
		marginBottom: '0 !important'
	},
	gridTable: {
		paddingTop: '0 !important'
	}
}));

interface Props {
	allAvailableRoles: ClientRoleInformation[];
	userRoles: ClientRoleAssignment[];
	roleActionProvider?: (role: ClientRoleAssignment) => JSX.Element;
}

function RoleAssignmentListFormView(props: Props): JSX.Element | null {
	const { allAvailableRoles, userRoles, roleActionProvider } = props;
	const classes = useStyles();

	return (
		<Grid container>
			<Grid item xs={12} className={classes.gridTitle}>
				<Typography variant="h2" gutterBottom>
					Rollen
				</Typography>
			</Grid>
			<Grid item xs={12} className={classes.gridTable}>
				<RoleAssignmentListView
					allAvailableRoles={allAvailableRoles}
					userRoles={userRoles}
					roleActionProvider={roleActionProvider}
				/>
			</Grid>
		</Grid>
	);
}

export default RoleAssignmentListFormView;
