import React, { useState } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { Theme } from '@mui/material/styles';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { Paper, Popper } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-unused-vars
const styles = (theme: Theme) => {
	return createStyles({
		icon: {
			color: 'rgba(0, 0, 0, 0.54)'
		},
		paperContainer: {
			backgroundColor: '#494B4F',
			color: 'white'
		}
	});
};

type Props = React.PropsWithChildren<object> & WithStyles<typeof styles>;

const HoverInfoTooltipView = (props: Props): JSX.Element => {
	const { classes } = props;
	const [anchorEl, setAnchorEl] = useState<SVGElement | null>(null);

	return (
		<>
			<InfoIcon
				className={classes.icon}
				onMouseEnter={(event): void => {
					setAnchorEl(event.currentTarget);
				}}
				onMouseLeave={(): void => setAnchorEl(null)}
			/>
			<Popper open={!!anchorEl} anchorEl={anchorEl} placement="bottom-start">
				<Paper elevation={8} className={classes.paperContainer}>
					{props.children}
				</Paper>
			</Popper>
		</>
	);
};

export default withStyles(styles)(HoverInfoTooltipView);
