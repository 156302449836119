import { History } from 'history';

export interface AppState {
	path: string;
}

export function createAppState(): AppState {
	const path = window.location.pathname;
	return {
		path
	};
}

export function restoreAppState(history: History, appState: AppState): void {
	const { path } = appState;
	const newPath = path ? path : window.location.pathname;
	history.replace(newPath);
}
