import React from 'react';
import Typography from '@mui/material/Typography';
import { GIT_HEAD, STAGE, VERSION } from '../../shared/utils/EnvironmentSettings';
import { Box, Paper } from '@mui/material';

class HomeView extends React.Component {
	titleStyle = { fontWeight: 300 };
	render(): JSX.Element {
		return (
			<Paper>
				<Typography variant="h1">
					<span style={this.titleStyle}>Willkommen </span>zur AdministrierBar!
				</Typography>
				<Box pt={1}>
					<Typography variant="body1" component="p">
						<span style={this.titleStyle}>Version </span> {VERSION}-{STAGE} ({GIT_HEAD})
					</Typography>
				</Box>
			</Paper>
		);
	}
}

export default HomeView;
