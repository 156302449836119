import React, { Component } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import { ActionMenuItemProps } from '../../../../shared/views/ActionMenu/ActionMenu';
import DialogActionView from '../../../../shared/views/ActionMenu/DialogAction.view';
import { Grid, Switch, TextField, Typography } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { disableEnterKeyEventHandler, FlagActionDialogStyles, withFlagActionDialogStyes } from './FlagActionCommons';

interface Props extends ActionMenuItemProps, FlagActionDialogStyles {
	entityAlreadyDeleted: boolean;
	supportsDeletion: boolean;
	handleStatusChangeAction: (comment: string, deleteAndNotifiy: boolean) => void;
}

interface State {
	comment: string;
	commentValid: boolean;
	deleteAndNotify: boolean;
	selectedOption: string;
	otherReason: string;
}

const initialState = (props: Props): State => {
	let deleteAndNotify: boolean;
	if (!props.supportsDeletion) {
		deleteAndNotify = false;
	} else {
		deleteAndNotify = !props.entityAlreadyDeleted;
	}

	return {
		comment: '',
		commentValid: false,
		deleteAndNotify,
		selectedOption: '',
		otherReason: ''
	};
};

class AcceptFlagActionView extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = initialState(props);
		this.handleOpenDialog = this.handleOpenDialog.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleSwitchToggled = this.handleSwitchToggled.bind(this);
		this.isSubmitDisabled = this.isSubmitDisabled.bind(this);
	}

	handleOpenDialog(): void {
		this.setState(initialState(this.props));
	}

	handleSubmit(): void {
		const { selectedOption, otherReason, deleteAndNotify } = this.state;
		const comment = selectedOption === 'Sonstiges' ? otherReason : selectedOption;
		this.props.handleStatusChangeAction(comment, deleteAndNotify);
	}

	handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
		// Update to handle radio button change
		const { value, name } = event.target;
		if (name === 'selectedOption') {
			this.setState({ selectedOption: value });
		} else if (name === 'otherReason') {
			this.setState({ otherReason: value });
		}
	}

	isSubmitDisabled(): boolean {
		const { selectedOption, otherReason } = this.state;

		// If "Sonstiges" is selected and no text is provided, allow submission
		if (selectedOption === 'Sonstiges' && otherReason.trim() === '') {
			return false;
		}

		// Otherwise, disable submission if no option is selected
		return !selectedOption;
	}

	// Unused first parameter must be declared anyway...
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	handleSwitchToggled(event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void {
		this.setState({ deleteAndNotify: checked });
	}

	render(): JSX.Element {
		const { renderMenuItem, classes, entityAlreadyDeleted, supportsDeletion } = this.props;
		const { deleteAndNotify, otherReason } = this.state;

		// Sorted options
		const sortedOptions = [
			'Falschdarstellung',
			'Anstößige Inhalte',
			'Beleidigung',
			'Belästigung',
			'Gewalt',
			'Hassrede',
			'Nicht themenbezogene Inhalte',
			'Personenbezogene Daten',
			'Unvollständiger Name',
			'Urheberrechtsverletzung',
			'Werbung'
		].sort();

		return (
			<DialogActionView
				itemProps={{
					renderMenuItem,
					icon: <CancelIcon color="secondary" />,
					text: 'Inhalt als kritisch kennzeichnen'
				}}
				dialogProps={{
					danger: true,
					titleText: 'Inhalt als kritisch kennzeichnen und Fall abschließen',
					saveButtonText: 'Fertig',
					handleOpen: this.handleOpenDialog,
					handleSave: this.handleSubmit,
					handleIsSaveDisabled: this.isSubmitDisabled
				}}
			>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Typography>
							Bitte begründe, warum der Inhalt gegen unsere Nutzungsbedingungen verstößt! Dieser Fall wird dann mit
							Status &quot;Akzeptiert&quot; abgeschlossen.
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<RadioGroup
							name="selectedOption"
							value={this.state.selectedOption}
							onChange={this.handleChange}
							onKeyDown={disableEnterKeyEventHandler}
						>
							{/* Render sorted options */}
							{sortedOptions.map(option => (
								<FormControlLabel key={option} value={option} control={<Radio />} label={option} />
							))}
							<FormControlLabel value="Sonstiges" control={<Radio />} label="Sonstiges" />
						</RadioGroup>
					</Grid>
					{this.state.selectedOption === 'Sonstiges' && (
						<Grid item xs={12}>
							<TextField
								name="otherReason"
								label="Sonstiges"
								value={otherReason}
								onChange={this.handleChange}
								fullWidth
							/>
						</Grid>
					)}
					{supportsDeletion && (
						<Grid item xs={12}>
							<FormGroup row>
								<FormControlLabel
									control={<Switch checked={deleteAndNotify} onChange={this.handleSwitchToggled} name="checkedA" />}
									label={
										<>
											{deleteAndNotify && (
												<span className={classes.errorText}>
													{!entityAlreadyDeleted ? 'Inhalt löschen und ' : ''}
													Ersteller*in{entityAlreadyDeleted ? ' erneut ' : ' '}per Mail benachrichtigen
												</span>
											)}
											{!deleteAndNotify && <span>Fall abschließen ohne weitere Aktion.</span>}
										</>
									}
								/>
							</FormGroup>
						</Grid>
					)}
				</Grid>
			</DialogActionView>
		);
	}
}

export default withFlagActionDialogStyes(AcceptFlagActionView);
