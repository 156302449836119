import React from 'react';
import { Box, Grid, Paper, Typography } from '@mui/material';
import BarLoadingIndicator from '../../shared/views/LoadingIndicators/BarLoadingIndicator.view';
import TablePaginationView from '../../shared/views/Table/TablePagination.view';
import TableView from '../../shared/views/Table/Table.view';
import { TableCell, TableColumn } from '../../shared/views/Table/TableColumn';
import { TableColumnSortingDirection, TableSorting } from '../../shared/views/Table/TableSorting';
import { Pagination } from '../../shared/services/InMemoryTable.service';
import { inMemoryTableService } from '../../ServiceFactory';
import { arraysEqual } from '../../shared/utils/Util';
import { ClientNewsSource } from '@DigitaleDoerfer/digitale-doerfer-api/models';
import SearchBarWithResetButtonView from '../../shared/views/SearchBarWithResetButton/SearchBarWithResetButton.view';
import { searchEventsConfigsTooltipText } from '../../shared/views/HelperTexts';
import { ImageSizes } from '../../shared/services/Image.service';
import AvatarView from '../../shared/views/Avatar.view';
import { Theme } from '@mui/material/styles';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import NavActionButton from '../../shared/views/Buttons/NavActionButton.view';
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-unused-vars
const styles = (theme: Theme) => {
	return createStyles({
		createButtonContainer: {
			margin: '14px 14px auto !important;'
		}
	});
};
interface Props extends WithStyles<typeof styles> {
	clientNewsSource: ClientNewsSource[];
	loading: boolean;
	createNewsSourcesHandler: () => void;
}

const renderText = (cell: TableCell<ClientNewsSource>): React.ReactElement => (
	<span>{cell.column.getCellStringValue(cell.row)}</span>
);

const renderWebsiteCell = (clientNewsSource: ClientNewsSource): React.ReactElement | null => {
	return (
		<Grid container spacing={1} direction="row" wrap="nowrap">
			<Grid item>
				<AvatarView avatarPicture={clientNewsSource?.logo} deleted={false} size={ImageSizes.THUMBNAIL} />
			</Grid>
			<Grid item>
				<Typography variant="h6" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
					{clientNewsSource.siteName}
				</Typography>
			</Grid>
		</Grid>
	);
};

const renderWebsite = (cell: TableCell<ClientNewsSource>): React.ReactElement | null => {
	return renderWebsiteCell(cell.row);
};

const columns: readonly TableColumn<ClientNewsSource>[] = [
	{
		id: 'website',
		headerLabel: 'Website',
		cellStyles: {},
		getCellStringValue: (clientNewsSource: ClientNewsSource): string => clientNewsSource.siteName ?? '',
		renderCell: renderWebsite,
		filterable: true,
		sortable: true
	},
	{
		id: 'url',
		headerLabel: 'URL',
		cellStyles: {},
		getCellStringValue: (clientNewsSource: ClientNewsSource): string => clientNewsSource.siteUrl ?? '',

		renderCell: renderText,
		filterable: true,
		sortable: true
	},
	{
		id: 'appVariant',
		headerLabel: 'AppVariant',
		cellStyles: {},
		getCellStringValue: (clientNewsSource: ClientNewsSource): string => clientNewsSource.appVariantId ?? '',

		renderCell: renderText,
		filterable: true,
		sortable: true
	}
	// {
	// 	id: 'geoArea',
	// 	headerLabel: 'GeoArea',
	// 	cellStyles: {},
	// 	getCellStringValue: (clientNewsSource: ClientNewsSource): string => clientNewsSource.,
	// 	renderCell: renderText,
	// 	filterable: true,
	// 	sortable: true
	// }
] as const;

function idToColumn(columnId: string): TableColumn<ClientNewsSource> | undefined {
	return columns.find(column => column.id === columnId);
}

const paginationRowsPerPageOptions = [20, 50, 100, 200] as const;
const initialPaginationRowsPerPage: (typeof paginationRowsPerPageOptions)[number] = 100;

const initialSearchParams = {
	searchCriteria: '',
	pagination: { page: 0, rowsPerPage: initialPaginationRowsPerPage, total: 0 },
	sorting: { columnId: columns[0].id, direction: TableColumnSortingDirection.ASC }
};

interface State {
	displayedclientNewsSource: ClientNewsSource[];
	searchCriteria: string;
	pagination: Pagination;
	sorting: TableSorting;
}

class NewsSourcesView extends React.Component<Props, State> {
	searchNeedsUpdate = false;

	constructor(props: Props) {
		super(props);

		this.state = {
			displayedclientNewsSource: [],
			...initialSearchParams
		};
		this.updateDisplayedRowsAndPagination = this.updateDisplayedRowsAndPagination.bind(this);
		this.handleChangePage = this.handleChangePage.bind(this);
		this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
		this.handleSortingChange = this.handleSortingChange.bind(this);
		this.handleSearchChange = this.handleSearchChange.bind(this);
		this.handleSearchReset = this.handleSearchReset.bind(this);
	}

	updateDisplayedRowsAndPagination(): void {
		const clientNewsSourceAfterFiltering = inMemoryTableService().applyFilter(
			this.props.clientNewsSource,
			this.state.searchCriteria,
			columns
		);

		const clientNewsSourceAfterSorting = inMemoryTableService().applySorting(
			clientNewsSourceAfterFiltering,
			this.state.sorting,
			idToColumn
		);
		const clientNewsSourceAfterPagination = inMemoryTableService().applyPagination(
			clientNewsSourceAfterSorting,
			this.state.pagination
		);

		this.setState({
			pagination: { ...this.state.pagination, total: clientNewsSourceAfterSorting.length },
			displayedclientNewsSource: clientNewsSourceAfterPagination
		});
	}

	componentDidMount(): void {
		this.updateDisplayedRowsAndPagination();
	}

	componentDidUpdate(prevProps: Props): void {
		if (!arraysEqual(prevProps.clientNewsSource, this.props.clientNewsSource)) {
			this.updateDisplayedRowsAndPagination();
		}
	}

	handleChangePage(newPage: number): void {
		this.setState((state: State): State => {
			return { ...state, pagination: { ...state.pagination, page: newPage } };
		}, this.updateDisplayedRowsAndPagination);
	}

	handleChangeRowsPerPage(newRowsPerPage: number): void {
		this.setState((state: State): State => {
			return { ...state, pagination: { ...state.pagination, rowsPerPage: newRowsPerPage, page: 0 } };
		}, this.updateDisplayedRowsAndPagination);
	}

	handleSortingChange(newSorting: TableSorting): void {
		this.setState((state: State): State => {
			return {
				...state,
				pagination: { ...state.pagination, page: 0 },
				sorting: newSorting
			};
		}, this.updateDisplayedRowsAndPagination);
	}

	handleSearchChange(searchCriteria: string): void {
		this.setState(
			{ ...this.state, searchCriteria, pagination: { ...this.state.pagination, page: 0 } },
			this.updateDisplayedRowsAndPagination
		);
	}

	handleSearchReset(): void {
		this.setState((state: State): State => {
			return {
				...state,
				...initialSearchParams
			};
		}, this.updateDisplayedRowsAndPagination);
	}

	getKeyForRow(clientNewsSource: ClientNewsSource): React.Key {
		const { id, appVariantId } = clientNewsSource;
		return `${id} ${appVariantId}`;
	}

	render(): JSX.Element {
		const { loading, classes, createNewsSourcesHandler } = this.props;
		const { displayedclientNewsSource, pagination, searchCriteria, sorting } = this.state;
		const { page, rowsPerPage, total } = pagination;

		return (
			<>
				<Box className={classes.createButtonContainer}>
					<NavActionButton onClick={(): void => createNewsSourcesHandler()}>NewsSource anlegen</NavActionButton>
				</Box>
				<Paper>
					<BarLoadingIndicator loading={loading} />
					<Grid container spacing={2}>
						<SearchBarWithResetButtonView
							text={searchCriteria}
							label="NewsSources durchsuchen"
							placeholder="Suche nach NewsSources"
							tooltipText={searchEventsConfigsTooltipText}
							handleTextChange={this.handleSearchChange}
							handleReset={this.handleSearchReset}
						/>
						<Grid item xs={12}>
							<TableView<ClientNewsSource>
								columns={columns}
								rows={displayedclientNewsSource}
								getKeyForRow={this.getKeyForRow}
								sorting={sorting}
								handleSortingChange={this.handleSortingChange}
							/>
							<TablePaginationView
								totalRows={total}
								rowLabelPlural="App-Konfigurationen"
								page={page}
								rowsPerPage={rowsPerPage}
								rowsPerPageOptions={paginationRowsPerPageOptions}
								handleChangePage={this.handleChangePage}
								handleChangeRowsPerPage={this.handleChangeRowsPerPage}
							/>
						</Grid>
					</Grid>
				</Paper>
			</>
		);
	}
}

export default withStyles(styles)(NewsSourcesView);
