import React from 'react';

import {
	AppBar,
	Avatar,
	Box,
	Button,
	Container,
	CssBaseline,
	Divider,
	Drawer,
	Grid,
	InputAdornment,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Paper,
	StyledEngineProvider,
	TextField,
	Toolbar,
	Typography
} from '@mui/material';
import { Theme, ThemeProvider } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import defaultAvatar from '../assets/images/avatar_default.svg';
import apple from '../assets/images/social-media/apple.svg';
import database from '../assets/images/database.svg';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Home from '@mui/icons-material/Home';
import AccountBox from '@mui/icons-material/AccountBox';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Report from '@mui/icons-material/Report';
import logo from '../assets/images/administierbar-logo.svg';
import theme from './Prototypes.theme';

declare module '@mui/styles' {
	// eslint-disable-next-line @typescript-eslint/no-empty-interface
	interface DefaultTheme extends Theme {}
}

const useStyles = makeStyles((theme: Theme) => ({
	logo: {
		marginLeft: '-10px',
		marginRight: '20px',
		height: '60px',
		width: '60px',
		padding: '5px'
	},
	mainContent: {
		marginTop: 64,
		padding: 16,
		flexGrow: 1,
		minWidth: 0
	},
	active: {
		backgroundColor: theme.palette.action.selected
	},
	nested: {
		paddingLeft: theme.spacing(4)
	},
	headerTitle: {
		paddingBottom: '5px',
		display: 'flex'
	},
	headerContainer: {
		marginBottom: theme.spacing(3)
	},
	socialMediaIconsContainer: {
		paddingLeft: '5px',
		paddingRight: '5px',
		display: 'flex'
	},
	socialMediaIcon: {
		height: '24px !important',
		width: '24px !important'
	},
	avatarContainer: {
		textAlign: 'end'
	},
	avatarMd: {
		height: '100px !important',
		width: '100px !important',
		margin: '0 0 0 auto'
	}
}));
export const PrototypeUserDetailsView = (): JSX.Element => {
	const classes = useStyles();
	return (
		<React.Fragment>
			{/* CssBaseline applies a collection of HTML element and attribute style-normalizations.
			Read more about it at https://material-ui.com/components/css-baseline/ */}
			<CssBaseline />
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={theme}>
					{/* TOP BAR (TopNavigationView) */}
					<AppBar position="fixed" style={{ userSelect: 'none' }}>
						<Toolbar>
							<img src={logo} alt="AdministrierBar Logo" className={classes.logo} />
							<Typography variant="h4" style={{ flexGrow: 1 }}>
								AdministrierBar
							</Typography>
							<Button color="inherit">Ausloggen</Button>
						</Toolbar>
					</AppBar>

					{/* PART BELOW TOP BAR */}
					<Box display="flex" flexDirection="row">
						{/* NAVIGATION MENU (SideNavigationView) */}
						<Drawer variant="permanent">
							<List>
								<ListItemButton>
									<ListItemIcon>
										<Home />
									</ListItemIcon>
									<ListItemText primary="Start" />
								</ListItemButton>
								<ListItemButton>
									<ListItemIcon>
										<AccountCircle />
									</ListItemIcon>
									<ListItemText primary="Mein Profil" />
								</ListItemButton>
								<ListItemButton className={classes.active}>
									<ListItemIcon>
										<AccountBox />
									</ListItemIcon>
									<ListItemText primary="Benutzer" />
								</ListItemButton>
								<ListItemButton>
									<ListItemIcon>
										<Report />
									</ListItemIcon>
									<ListItemText primary="Gemeldete Beiträge" />
								</ListItemButton>
							</List>
						</Drawer>

						<main className={classes.mainContent}>
							<Container fixed maxWidth="md">
								<Paper>
									<div className={classes.headerContainer}>
										<div className={classes.headerTitle}>
											<Typography variant="h5">Christian Tamanini</Typography>
											<div className={classes.socialMediaIconsContainer}>
												<Avatar key="1" src={database} className={classes.socialMediaIcon} />
												<Avatar key="2" src={apple} className={classes.socialMediaIcon} />
											</div>
										</div>
										<Divider variant="fullWidth" />
									</div>
									<Grid container spacing={3}>
										<Grid item container spacing={3}>
											<Grid item xs={12} md={6} container justifyContent="flex-start" alignItems="flex-end">
												<TextField
													variant="standard"
													id="id"
													name="id"
													label="ID"
													value="9974c499-ec3e-4452-ab32-6e5563550d7b"
													InputProps={{ readOnly: true }}
													InputLabelProps={{
														shrink: true
													}}
													fullWidth
												/>
											</Grid>
											<Grid item xs={12} md={6}>
												<div className={classes.avatarContainer}>
													<Avatar src={defaultAvatar} className={classes.avatarMd} />
												</div>
											</Grid>
											<Grid item xs={12} md={6}>
												<TextField
													variant="standard"
													id="firstName"
													name="firstName"
													label="Vorname"
													value="Christian"
													InputProps={{ readOnly: true }}
													InputLabelProps={{
														shrink: true
													}}
													fullWidth
												/>
											</Grid>
											<Grid item xs={12} md={6}>
												<TextField
													variant="standard"
													id="lastName"
													name="lastName"
													label="Nachname"
													value="Tamanini"
													InputProps={{ readOnly: true }}
													InputLabelProps={{
														shrink: true
													}}
													fullWidth
												/>
											</Grid>
											<Grid item xs={12} md={6}>
												<TextField
													variant="standard"
													id="email"
													name="email"
													label="E-Mail-Adresse"
													type="email"
													value="christian.tamanini@banana.org"
													InputProps={{
														endAdornment: (
															<InputAdornment position="end">
																<CheckCircleOutlineIcon color="primary" />
															</InputAdornment>
														),
														readOnly: true
													}}
													InputLabelProps={{
														shrink: true
													}}
													fullWidth
												/>
											</Grid>
											<Grid item xs={12} md={6}>
												<TextField
													variant="standard"
													id="oauthAccount"
													name="oauthAccount"
													label="OAuth-ID"
													value="auth0|59785458dd867e7dd2f7b74f"
													InputProps={{ readOnly: true }}
													InputLabelProps={{
														shrink: true
													}}
													fullWidth
												/>
											</Grid>
											<Grid item xs={12} md={6}>
												<TextField
													variant="standard"
													id="homeCommunityName"
													name="homeCommunityName"
													label="Heimat-Mandant (Home Community)"
													value="Kaiserslautern, Stadt"
													InputProps={{ readOnly: true }}
													InputLabelProps={{
														shrink: true
													}}
													fullWidth
												/>
											</Grid>
											<Grid item xs={12} md={6}>
												<TextField
													variant="standard"
													id="homeCommunityId"
													name="homeCommunityId"
													label="ID des Heimat-Mandanten"
													value="bd5835c1-9815-40ce-b90e-80281cd5d4b8"
													InputProps={{ readOnly: true }}
													InputLabelProps={{
														shrink: true
													}}
													fullWidth
												/>
											</Grid>
											<Grid item xs={12}>
												<Typography variant="subtitle1">
													Christian ist registriert seit 30.11.2018 um 09:39 Uhr und war zum letzten Mal am 28.01.2020
													um 09:29 Uhr aktiv. Bei Auth0 gab es 293 erfolgreiche Login-Vorgänge.
												</Typography>
											</Grid>
										</Grid>
									</Grid>
								</Paper>
							</Container>
						</main>
					</Box>
				</ThemeProvider>
			</StyledEngineProvider>
		</React.Fragment>
	);
};
