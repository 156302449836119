import React, { Component } from 'react';
import { Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import BlockIcon from '@mui/icons-material/Block';
import { ActionMenuItemProps } from '../../../../shared/views/ActionMenu/ActionMenu';
import DialogActionView from '../../../../shared/views/ActionMenu/DialogAction.view';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-unused-vars
const styles = (theme: Theme) => {
	return createStyles({
		name: {
			textAlign: 'center',
			verticalAlign: 'middle',
			lineHeight: '100px',
			fontWeight: 'bold'
		}
	});
};

interface Props extends ActionMenuItemProps, WithStyles<typeof styles> {
	firstName: string;
	lastName: string;
	email: string;
	handleSave: () => void;
}

class BlockLoginActionView extends Component<Props> {
	constructor(props: Props) {
		super(props);

		this.handleSave = this.handleSave.bind(this);
	}

	handleSave(): void {
		this.props.handleSave();
	}

	render(): JSX.Element {
		const { renderMenuItem, firstName, lastName, email, classes } = this.props;
		return (
			<DialogActionView
				itemProps={{
					renderMenuItem,
					icon: <BlockIcon color="primary" />,
					text: 'Person sperren'
				}}
				dialogProps={{
					danger: true,
					titleText: 'Person sperren',
					saveButtonText: 'Person sperren',
					handleSave: this.handleSave
				}}
			>
				<Typography>Soll folgende Person wirklich gesperrt werden?</Typography>
				<Typography className={classes.name}>{`${firstName} ${lastName} ${email ? `(${email})` : ''}`}</Typography>
			</DialogActionView>
		);
	}
}

export default withStyles(styles)(BlockLoginActionView);
