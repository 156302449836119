import React from 'react';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { ClientComment } from '@DigitaleDoerfer/digitale-doerfer-api/models';
import { Divider, ListItemAvatar, Typography } from '@mui/material';
import { flaggedContentViewService, timeService } from '../../../../ServiceFactory';
import { TimestampFormat } from '../../../../shared/services/Time.service';
import clsx from 'clsx';
import { commonStyles } from './FlaggedContentDetailsCommons';
import AvatarView from '../../../../shared/views/Avatar.view';
import ImageGallery from '../../../../shared/views/ImageGallery.view';

const useStyles = makeStyles({
	...commonStyles,
	...createStyles({
		listItem: {
			paddingLeft: '15px'
		}
	})
});

export interface Props {
	commentsOfPost: ClientComment[];
	highlightedCommentId: string;
}

function FlaggedContentCommentsView(props: Props): JSX.Element {
	const classes = useStyles();
	const { commentsOfPost, highlightedCommentId } = props;

	if (commentsOfPost.length === 0) {
		return <Typography variant="h6">Keine Kommentare</Typography>;
	}

	return (
		<List>
			{commentsOfPost.map(comment => {
				const createdTime = flaggedContentViewService().renderInfo(
					comment.created ? timeService().parseTimestamp(comment.created, TimestampFormat.DD_MM_YYYY_HH_MM) : undefined
				);
				const commentTitle = `${comment.creator?.firstName} ${comment.creator?.lastName} - ${createdTime}`;
				const selectedCommentClass = highlightedCommentId === comment.id ? classes.highlight : '';
				return (
					<React.Fragment key={comment.id}>
						<ListItem className={clsx(classes.listItem, selectedCommentClass)} alignItems="flex-start">
							<ListItemAvatar>
								<AvatarView avatarPicture={comment.creator?.profilePicture} deleted={comment.creator?.deleted} />
							</ListItemAvatar>
							<ListItemText
								primary={
									<>
										<Typography component="span" variant="h6" color="textPrimary">
											{commentTitle}
										</Typography>
									</>
								}
								secondary={
									<>
										<Typography
											component="span"
											variant="h6"
											color="textPrimary"
											className={clsx(classes.textWrap, comment.deleted ? classes.textMarkedAsDeleted : undefined)}
										>
											{comment.text}
										</Typography>
										{comment.deleted && (
											<>
												<br />
												<Typography
													component="span"
													variant="h6"
													color="textPrimary"
													className={classes.deletionTextIndicator}
												>
													Dieser Kommentar wurde gelöscht.
												</Typography>
											</>
										)}
									</>
								}
							/>
						</ListItem>
						<ImageGallery leftGalleryBoxPadding={'65px'} images={comment.images ?? []} />
						<Divider variant="middle" component="li" />
					</React.Fragment>
				);
			})}
		</List>
	);
}
export default FlaggedContentCommentsView;
