import React from 'react';
import { ActionButtonProps } from './ButtonProps';
import BaseButton from './BaseButton.view';

function ConfirmButton(props: ActionButtonProps): JSX.Element {
	return (
		<BaseButton variant="contained" {...props}>
			{props.children}
		</BaseButton>
	);
}

export default ConfirmButton;
