import { SetState } from 'zustand';
import { AppVariant, Filter, Filters, FilterType, Tenant, ValueOf } from '../../types';

export interface FilterSlice {
	filters: Filters;
	addFilter: (newFilter: Filter<AppVariant | Tenant>) => void;
	addFilters: (filterType: ValueOf<typeof FilterType>, filterItems: Filter<AppVariant | Tenant>[]) => void;
	removeFilter: (removeFilter: Filter<AppVariant | Tenant>) => void;
	removeFilters: (filterType: ValueOf<typeof FilterType>, filterItems: Filter<AppVariant | Tenant>[]) => void;
	removeAllFilters: () => void;
}

const getInitialFiltersState = (): Filters => ({ [FilterType.APP_VARIANT]: {}, [FilterType.TENANT]: {} });

const createFilterSlice = (set: SetState<FilterSlice>): FilterSlice => ({
	filters: getInitialFiltersState(),
	addFilter: (newFilter): void =>
		set(state => {
			state.filters[newFilter.type][newFilter.id] = newFilter;
		}),
	addFilters: (filterType, filterItems): void =>
		set(state => {
			filterItems.forEach(filterItem => (state.filters[filterType][filterItem.id] = filterItem));
		}),
	removeFilters: (filterType, filterItems): void =>
		set(state => {
			filterItems.forEach(filterItem => delete state.filters[filterType][filterItem.id]);
		}),
	removeFilter: ({ id, type }): void =>
		set(state => {
			delete state.filters?.[type]?.[id];
		}),
	removeAllFilters: (): void =>
		set(state => {
			state.filters = getInitialFiltersState();
		})
});

export default createFilterSlice;
