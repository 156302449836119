import React from 'react';
import { Typography } from '@mui/material';

interface Props {
	person?: { firstName?: string; lastName?: string; deleted?: boolean };
	textVariant: 'body1' | 'body2' | 'h6';
}

/**
 * Return the complete persona name.
 * Case 1: Returns 'Unbekannt' if person is undefined
 * Case 2: Returns 'Gelöschte Person' if person is deleted
 * Case 3: Returns 'Firstname Lastname' if person is not undefined
 * @param person
 */
export const getCompletePersonName = (person?: {
	firstName?: string;
	lastName?: string;
	deleted?: boolean;
}): string => {
	let completeName = '';
	if (!person) {
		completeName = 'Unbekannt';
	} else if (person?.deleted) {
		completeName = 'Gelöschte Person';
	} else {
		if (person.firstName && person.lastName) {
			completeName = `${person.firstName} ${person.lastName}`;
		} else if (!person.firstName && person.lastName) {
			completeName = `${person.lastName}`;
		} else if (person.firstName && !person.lastName) {
			completeName = `${person.lastName}`;
		}
	}
	return completeName;
};

const PersonNameView = (props: Props): JSX.Element => {
	const { person, textVariant } = props;
	return (
		<Typography variant={textVariant} color="primary">
			{getCompletePersonName(person)}
		</Typography>
	);
};

export default PersonNameView;
