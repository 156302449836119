import React, { FC } from 'react';
import { TreeItem, TreeItemProps } from '@mui/lab';
import { alpha } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { AppVariant, Contract, ListType, Tenant, TreeNodeItem, ValueOf } from '../../types';
import TreeListNodeLabel from './TreeListNodeLabel';

const StyledTreeListItem = withStyles(theme => ({
	root: {},
	iconContainer: {
		'& .close': {
			opacity: 0.3
		}
	},
	group: {
		marginLeft: 7,
		paddingLeft: 18,
		borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`
	}
}))((props: TreeItemProps) => <TreeItem {...props} />);

type TreeListNodeProps = TreeNodeItem<AppVariant | Tenant | Contract> & {
	subItems?: AppVariant[];
	type: ValueOf<typeof ListType>;
};
const TreeListNode: FC<TreeListNodeProps> = ({ type, id, name, subItems }: TreeListNodeProps) => {
	return (
		<StyledTreeListItem nodeId={id} label={<TreeListNodeLabel name={name} id={id} showCheckbox={true} type={type} />}>
			{subItems?.map(subItem => {
				return <TreeListNode key={subItem.id} id={subItem.id} name={subItem.name} type={type} />;
			})}
		</StyledTreeListItem>
	);
};

export default TreeListNode;
