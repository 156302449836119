import withStyles from '@mui/styles/withStyles';
import { TextField } from '@mui/material';

export const ReadOnlyTextFieldView = withStyles({
	root: {
		'& label.Mui-focused': {
			color: 'black'
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: 'black'
		}
	}
})(TextField);
