import {
	CREATE_NEWS_SOURCES_FAILURE,
	CREATE_NEWS_SOURCES_REQUEST,
	CREATE_NEWS_SOURCES_SUCCESS,
	CreateNewsSourcesFailureAction,
	CreateNewsSourcesRequestAction,
	CreateNewsSourcesSuccessAction,
	GET_NEWS_SOURCES_FAILURE,
	GET_NEWS_SOURCES_REQUEST,
	GET_NEWS_SOURCES_SUCCESS,
	GetNewsSourcesFailureAction,
	GetNewsSourcesRequestAction,
	GetNewsSourcesSuccessAction,
	NewsSourcesActionTypes
} from './NewsSourcesActionTypes';
import { initialNewsSourcseState, NewsSourcseState } from './NewsSources.state';

const getNewsSourcesRequest = (state: NewsSourcseState, action: GetNewsSourcesRequestAction): NewsSourcseState => {
	return {
		...state,
		list: {
			...state.list,
			loading: true,
			error: null
		}
	};
};

const getNewsSourcesSuccess = (state: NewsSourcseState, action: GetNewsSourcesSuccessAction): NewsSourcseState => {
	return {
		...state,
		list: {
			...state.list,
			loading: false,
			clientNewsSource: action.clientNewsSource
		}
	};
};

const getNewsSourcesFailure = (state: NewsSourcseState, action: GetNewsSourcesFailureAction): NewsSourcseState => {
	return {
		...state,
		list: {
			...state.list,
			loading: false,
			error: action.error
		}
	};
};
const createNewsSourcesRequest = (
	state: NewsSourcseState,
	action: CreateNewsSourcesRequestAction
): NewsSourcseState => {
	return {
		...state,
		create: {
			...state.create,
			loading: true,
			error: null
		}
	};
};

const createNewsSourcesSuccess = (
	state: NewsSourcseState,
	action: CreateNewsSourcesSuccessAction
): NewsSourcseState => {
	return {
		...state,
		create: {
			...state.create,
			loading: false,
			newsSourceHasBeenCreated: true
		}
	};
};

const createNewsSourcesFailure = (
	state: NewsSourcseState,
	action: CreateNewsSourcesFailureAction
): NewsSourcseState => {
	return {
		...state,
		create: {
			...state.create,
			loading: false,
			error: action.error
		}
	};
};
const reducer = (state = initialNewsSourcseState, action: NewsSourcesActionTypes): NewsSourcseState => {
	switch (action.type) {
		case GET_NEWS_SOURCES_REQUEST:
			return getNewsSourcesRequest(state, action);
		case GET_NEWS_SOURCES_SUCCESS:
			return getNewsSourcesSuccess(state, action);
		case GET_NEWS_SOURCES_FAILURE:
			return getNewsSourcesFailure(state, action);
		case CREATE_NEWS_SOURCES_FAILURE:
			return createNewsSourcesFailure(state, action);
		case CREATE_NEWS_SOURCES_REQUEST:
			return createNewsSourcesRequest(state, action);
		case CREATE_NEWS_SOURCES_SUCCESS:
			return createNewsSourcesSuccess(state, action);
		default:
			return state;
	}
};

export default reducer;
