import { connect } from 'react-redux';
import React, { Component } from 'react';
import AcceptFlagActionView from './AcceptFlagAction.view';
import { acceptFlagAndDeleteContent, changeFlagStatus } from '../../store/FlaggedContent.actions';
import { UserActionTypes } from '../../../users/store/UserActionTypes';
import { ActionMenuItemProps } from '../../../../shared/views/ActionMenu/ActionMenu';
import { PlatformAdminUIState, PlatformAdminUIThunkType } from '../../../../store/store';
import { ClientUserGeneratedContentFlagStatusChangeRequestNewStatusEnum } from '@DigitaleDoerfer/digitale-doerfer-api/models';
import { FlaggedContentState, FlaggedType } from '../../store/FlaggedContent.state';

interface Props extends ActionMenuItemProps {
	flag: FlaggedContentState['detail'];
	acceptFlagAndDeleteContent: (flagId: string, comment: string) => void;
	changeFlagStatus: (
		flagId: string,
		newStatus: ClientUserGeneratedContentFlagStatusChangeRequestNewStatusEnum,
		comment: string,
		snackbarMessage?: string,
		returnToTable?: boolean
	) => void;
}

class AcceptFlagActionContainer extends Component<Props> {
	constructor(props: Props) {
		super(props);
		this.handleStatusChangeAction = this.handleStatusChangeAction.bind(this);
	}

	handleStatusChangeAction(comment: string, deleteAndNotify: boolean): void {
		const { flag, acceptFlagAndDeleteContent, changeFlagStatus } = this.props;
		if (deleteAndNotify) {
			acceptFlagAndDeleteContent(flag.flagId, comment);
			return;
		}
		changeFlagStatus(
			flag.flagId,
			ClientUserGeneratedContentFlagStatusChangeRequestNewStatusEnum.ACCEPTED,
			comment,
			'Fall abgeschlossen. Der Status wurde auf "Akzeptiert" gesetzt.',
			true
		);
	}

	render(): JSX.Element {
		const { flag, renderMenuItem } = this.props;
		const alreadyDeleted = !!(
			(flag.type === FlaggedType.POST && flag.post?.postDeleted) ||
			(flag.type === FlaggedType.COMMENT && flag.comment?.flagComment?.deleted)
		);
		const supportsDeletion = flag.type === FlaggedType.POST || flag.type === FlaggedType.COMMENT;

		return (
			<AcceptFlagActionView
				supportsDeletion={supportsDeletion}
				renderMenuItem={renderMenuItem}
				handleStatusChangeAction={this.handleStatusChangeAction}
				entityAlreadyDeleted={alreadyDeleted}
			/>
		);
	}
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: PlatformAdminUIState) => {
	return {
		flag: state.flaggedContent.detail
	};
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch: PlatformAdminUIThunkType<UserActionTypes>) => {
	return {
		acceptFlagAndDeleteContent: (flagId: string, comment: string): Promise<void> =>
			dispatch(acceptFlagAndDeleteContent(flagId, comment)),
		changeFlagStatus: (
			flagId: string,
			newStatus: ClientUserGeneratedContentFlagStatusChangeRequestNewStatusEnum,
			comment: string,
			snackbarMessage?: string,
			returnToTable?: boolean
		): Promise<void> => dispatch(changeFlagStatus(flagId, newStatus, comment, snackbarMessage, returnToTable))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AcceptFlagActionContainer);
