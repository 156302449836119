import { ClientRoleAssignment, ClientRoleInformation } from '@DigitaleDoerfer/digitale-doerfer-api/models';
import { ParticipantsRoleAssignmentApi } from '@DigitaleDoerfer/digitale-doerfer-api/apis';
import { EntityName } from './RoleAssignmentRelatedEntity.service';

export interface RoleManagementPermission {
	isRestAdmin: boolean;
	isGlobalUser: boolean;
	tenantIdsToManage: string[];
	roles: ClientRoleAssignment[];
}

export enum RoleKey {
	COMMON_HELP_DESK = 'COMMON_HELP_DESK',
	COMMUNITY_HELP_DESK = 'COMMUNITY_HELP_DESK',
	GLOBAL_EXTERNAL_POST_ADMIN = 'GLOBAL_EXTERNAL_POST_ADMIN',
	GLOBAL_GROUP_ADMIN = 'GLOBAL_GROUP_ADMIN',
	GLOBAL_USER_ADMIN = 'GLOBAL_USER_ADMIN',
	GLOBAL_USER_GENERATED_CONTENT_ADMIN = 'GLOBAL_USER_GENERATED_CONTENT_ADMIN',
	GROUP_ADMIN = 'GROUP_ADMIN',
	GROUP_MEMBERSHIP_ADMIN = 'GROUP_MEMBERSHIP_ADMIN',
	IESE_ADMIN = 'IESE_ADMIN',
	POOLINGSTATION_OPERATOR = 'POOLINGSTATION_OPERATOR',
	SUPER_ADMIN = 'SUPER_ADMIN',
	SELLING_VEHICLE_DRIVER = 'SELLING_VEHICLE_DRIVER',
	SHOP_MANAGER = 'SHOP_MANAGER',
	SHOP_OWNER = 'SHOP_OWNER',
	SUGGESTION_FIRST_RESPONDER = 'SUGGESTION_FIRST_RESPONDER',
	SUGGESTION_WORKER = 'SUGGESTION_WORKER',
	USER_ADMIN = 'USER_ADMIN',
	USER_GENERATED_CONTENT_ADMIN = 'USER_GENERATED_CONTENT_ADMIN',
	VG_ADMIN = 'VG_ADMIN',
	ROLE_MANAGER = 'ROLE_MANAGER',
	UNDEFINED_ROLE = 'UNDEFINED_ROLE',
	GLOBAL_CONFIGURATION_ADMIN = 'GLOBAL_CONFIGURATION_ADMIN',
	NEWS_SOURCE_POST_ADMIN = 'NEWS_SOURCE_POST_ADMIN'
}

export const noPermission = {
	isRestAdmin: false,
	isGlobalUser: false,
	tenantIdsToManage: [],
	roles: []
};

export const emptyClientRoleInformation: ClientRoleInformation = {
	description: '',
	displayName: '',
	key: '',
	relatedEntity: ''
};

export class RoleService {
	constructor(public participantsRoleAssignmentApi: ParticipantsRoleAssignmentApi) {}

	// List of unassignable roles
	unassignableRoles: string[] = [
		'POOLINGSTATION_OPERATOR',
		'COMMON_HELP_DESK',
		'COMMUNITY_HELP_DESK',
		'SELLING_VEHICLE_DRIVER',
		'SHOP_OWNER'
	];

	// List of valid roles to render user menu
	validRolesToRenderUserMenu: RoleKey[] = [
		RoleKey.SUPER_ADMIN,
		RoleKey.USER_ADMIN,
		RoleKey.GLOBAL_USER_ADMIN,
		RoleKey.ROLE_MANAGER
	];

	// List of valid roles to render flagged content menu
	validRolesToRenderFlaggedContentMenu: RoleKey[] = [
		RoleKey.USER_GENERATED_CONTENT_ADMIN,
		RoleKey.GLOBAL_USER_GENERATED_CONTENT_ADMIN
	];

	// List of valid roles to render group menu
	validRolesToRenderGroupMenu: RoleKey[] = [RoleKey.GROUP_ADMIN, RoleKey.GLOBAL_GROUP_ADMIN];

	// List of valid roles to render tenant menu
	validRolesToRenderConfigurationMenu: RoleKey[] = [RoleKey.GLOBAL_CONFIGURATION_ADMIN, RoleKey.SUPER_ADMIN];

	// List of valid roles to render External Contributions menu
	validRolesToRenderExternalContributionsMenu: RoleKey[] = [
		RoleKey.GLOBAL_EXTERNAL_POST_ADMIN,
		RoleKey.GLOBAL_CONFIGURATION_ADMIN,
		RoleKey.SUPER_ADMIN
	];

	/**
	 * Returns all assignable roles.
	 * - If the current user has SUPER_ADMIN or GLOBAL_USER_ADMIN role should be included in the returned role list.
	 * @param roles List of assignable roles of current user.
	 * @param permission Role management permissions of the current user.
	 */
	async getAssignableRoles(
		roles: ClientRoleInformation[],
		permission: RoleManagementPermission
	): Promise<ClientRoleInformation[]> {
		let assignableRoles = roles.filter(role => {
			if (role.key) {
				return !this.unassignableRoles.some(uRole => uRole === role.key);
			}
			return false;
		});

		assignableRoles = this.sortRolesByDisplayName(assignableRoles);

		if (permission.isRestAdmin) {
			return assignableRoles;
		} else if (permission.isGlobalUser) {
			return assignableRoles.filter(role => role.key !== RoleKey.SUPER_ADMIN);
		}

		assignableRoles = assignableRoles.filter(
			role =>
				role.key !== RoleKey.GLOBAL_USER_ADMIN &&
				role.key !== RoleKey.SUPER_ADMIN &&
				role.key !== RoleKey.GLOBAL_GROUP_ADMIN &&
				role.key !== RoleKey.GLOBAL_USER_GENERATED_CONTENT_ADMIN
		);

		if (!permission.roles.some(role => role.roleKey === RoleKey.USER_ADMIN)) {
			assignableRoles = assignableRoles.filter(role => role.key !== RoleKey.USER_ADMIN);

			if (!permission.roles.some(role => role.roleKey === RoleKey.ROLE_MANAGER)) {
				return [];
			}
		}

		return assignableRoles;
	}

	getRoleDisplayName(roleKey: string, roles: ClientRoleInformation[]): string {
		const foundRole = roles.find(role => role.key === roleKey);
		const displayName = foundRole && foundRole.displayName ? foundRole.displayName : roleKey;
		return displayName;
	}

	/**
	 * Returns the proccessed related entity name. If entity name is:
	 * - 'Community' should return the 'relatedEntityTenantName'.
	 * - 'RelatedEntityIsNull' should return empty string.
	 * - 'Shop', 'Group' or 'PoolingStation' should return 'relatedEntityName (relatedEntityTenantName)'.
	 * @param roleAssignment Role assignment with the role key needed to get the entity name.
	 * @param allAvailableRoles List of all roles.
	 */
	getRelatedEntityNameByRoleAssignment(
		roleAssignment: ClientRoleAssignment,
		allAvailableRoles: ClientRoleInformation[]
	): string {
		const relatedRole: ClientRoleInformation =
			allAvailableRoles.find(role => role.key === roleAssignment.roleKey) || emptyClientRoleInformation;
		const relatedEntity = relatedRole ? relatedRole.relatedEntity : EntityName.RELATED_ENTITY_IS_NULL;
		let relatedEntityName = '';
		if (relatedEntity === EntityName.COMMUNITY) {
			const relatedEntityTenantName = roleAssignment.relatedEntityTenantName
				? roleAssignment.relatedEntityTenantName
				: '';
			relatedEntityName = `${relatedEntityTenantName}`;
		} else if (relatedEntity !== EntityName.RELATED_ENTITY_IS_NULL) {
			const relatedEntityTenantName = roleAssignment.relatedEntityTenantName
				? `(${roleAssignment.relatedEntityTenantName})`
				: '';
			relatedEntityName = `${roleAssignment.relatedEntityName} ${relatedEntityTenantName}`;
		}
		return relatedEntityName;
	}

	async getRoleManagementPermission(): Promise<RoleManagementPermission> {
		const roles: ClientRoleAssignment[] = await this.participantsRoleAssignmentApi.getOwnRoleAssignmentsUsingGET({});
		const isRestAdmin = roles.some(role => role.roleKey === RoleKey.SUPER_ADMIN);
		const isGlobalUser = roles.some(role => role.roleKey === RoleKey.GLOBAL_USER_ADMIN);
		let rolesForTenantsToManage;
		if (isRestAdmin || isGlobalUser) {
			rolesForTenantsToManage = roles;
		} else {
			rolesForTenantsToManage = roles.filter(
				role => role.roleKey === RoleKey.USER_ADMIN || role.roleKey === RoleKey.ROLE_MANAGER
			);
		}
		const tenantIdsToManage = rolesForTenantsToManage.map(role => role.relatedEntityId ?? '').filter(role => !!role);
		return { isRestAdmin, isGlobalUser, tenantIdsToManage, roles };
	}

	/**
	 * Returns true if some of the inputRoles is in the validRoles.
	 * Otherwise, return false.
	 * @param inputRoles List of roles to validate.
	 * @param validRoles List of valid roles key.
	 */
	hasClientRoleInformationSomeValidRole(inputRoles: ClientRoleInformation[], validRoles: RoleKey[]): boolean {
		const inputRoleKeys: string[] = inputRoles.map(role => {
			return role.key || '';
		});

		const validRoleKeys: string[] = validRoles.map(key => RoleKey[key]);

		return inputRoleKeys.some(role => validRoleKeys.includes(role));
	}

	/**
	 * Returns true if some of the inputRoles is in the validRoles.
	 * Otherwise, return false.
	 * @param inputRoles List of roles to validate.
	 * @param validRoles List of valid roles key.
	 */
	hasClientRoleAssignmentSomeValidRole(inputRoles: ClientRoleAssignment[], validRoles: RoleKey[]): boolean {
		const inputRoleKeys: string[] = inputRoles.map(role => {
			return role.roleKey || '';
		});

		const validRoleKeys: string[] = validRoles.map(key => RoleKey[key]);

		return inputRoleKeys.some(role => validRoleKeys.includes(role));
	}

	/**
	 * Returns true if one of the ownRolesAssignment has valid roles.
	 * Otherwise, return false.
	 * @param ownRolesAssignment List of roles to validate.
	 */
	shouldRenderUserMenu(ownRolesAssignment: ClientRoleAssignment[]): boolean {
		const rolesValidation: boolean[] = ownRolesAssignment.map(ownRoleAssignment =>
			this.validRolesToRenderUserMenu.some(requestedRole => ownRoleAssignment.roleKey === requestedRole)
		);
		return rolesValidation.some(roleValidation => roleValidation === true);
	}

	/**
	 * Returns true if one of the ownRolesAssignment has valid roles.
	 * Otherwise, return false.
	 * @param ownRolesAssignment List of roles to validate.
	 */
	shouldRenderFlaggedContentMenu(ownRolesAssignment: ClientRoleAssignment[]): boolean {
		const rolesValidation: boolean[] = ownRolesAssignment.map(ownRoleAssignment =>
			this.validRolesToRenderFlaggedContentMenu.some(requestedRole => ownRoleAssignment.roleKey === requestedRole)
		);
		return rolesValidation.some(roleValidation => roleValidation === true);
	}

	/**
	 * Returns true if one of the ownRolesAssignment has valid roles.
	 * Otherwise, return false.
	 * @param ownRolesAssignment List of roles to validate.
	 */
	shouldRenderGroupMenu(ownRolesAssignment: ClientRoleAssignment[]): boolean {
		const rolesValidation: boolean[] = ownRolesAssignment.map(ownRoleAssignment =>
			this.validRolesToRenderGroupMenu.some(requestedRole => ownRoleAssignment.roleKey === requestedRole)
		);
		return rolesValidation.some(roleValidation => roleValidation === true);
	}

	/**
	 * Returns true if one of the ownRolesAssignment has valid roles.
	 * Otherwise, return false.
	 * @param ownRolesAssignment List of roles to validate.
	 */
	shouldRenderConfigurationMenu(ownRolesAssignment: ClientRoleAssignment[]): boolean {
		const rolesValidation: boolean[] = ownRolesAssignment.map(ownRoleAssignment =>
			this.validRolesToRenderConfigurationMenu.some(requestedRole => ownRoleAssignment.roleKey === requestedRole)
		);
		return rolesValidation.some(roleValidation => roleValidation === true);
	}

	/**
	 * Returns true if selected ownRolesAssignment has valid roles in combination.
	 * Otherwise, return false.
	 * @param ownRolesAssignment List of roles to validate.
	 */
	shouldRenderExternalContributionsMenu(ownRolesAssignment: ClientRoleAssignment[]): boolean {
		const ownRoles = ownRolesAssignment.map(r => r.roleKey);
		if (ownRoles.includes(RoleKey.SUPER_ADMIN)) {
			return true;
		}
		if (
			ownRoles.includes(RoleKey.GLOBAL_CONFIGURATION_ADMIN) &&
			ownRoles.includes(RoleKey.GLOBAL_EXTERNAL_POST_ADMIN)
		) {
			return true;
		} else {
			return false;
		}
	}

	/**
	 * Returns true if one of the ownRolesAssignment has the valid role.
	 * Otherwise, return false.
	 * @param ownRolesAssignment List of roles to validate.
	 */
	shouldRenderStatisticsMenu(ownRolesAssignment: ClientRoleAssignment[]): boolean {
		const ownRoles = ownRolesAssignment.map(r => r.roleKey);
		if (ownRoles.includes(RoleKey.GLOBAL_CONFIGURATION_ADMIN)) {
			return true;
		}
		return false;
	}

	/**
	 * Returns the RoleKey Enum based on the string.
	 * @param roleKey RoleKey Enum.
	 */
	convertStringtoRoleEnum(roleKey?: string): RoleKey {
		const roleKeyEnum = RoleKey[roleKey as RoleKey];
		return roleKeyEnum ? roleKeyEnum : RoleKey.UNDEFINED_ROLE;
	}

	/**
	 * Returns sorted list of roles based on the role's displayName.
	 * @param roles Roles to be sorted.
	 */
	sortRolesByDisplayName(roles: ClientRoleInformation[]): ClientRoleInformation[] {
		return roles.sort((a: ClientRoleInformation, b: ClientRoleInformation) => {
			// FIXME: Remove || '' when this (DD-7206) is fixed
			return (a.displayName || '').localeCompare(b.displayName || '', 'de');
		});
	}
}
