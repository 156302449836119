import React, { PropsWithChildren } from 'react';
import { Box } from '@mui/system';

const FormFooterView = (props: PropsWithChildren<object>) => {
	const { children } = props;

	return (
		<Box
			sx={{
				marginTop: 3
			}}
		>
			{children}
		</Box>
	);
};
export default FormFooterView;
